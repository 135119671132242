import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSystemContext } from '../../../../Context/SystemContext';

import Error from '../../../Error/Error';

import ChatlineAPI from '../../../../utils/ChatlineAPI';

import Helmet from 'react-helmet';
import MainLayout from '../../../../Components/Layouts/MainLayout';

import { Table, Button, Modal, Form, Input, Select, message, Tag, Spin } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Members = (props) => {
    const { 
        systemVariables
    } = useSystemContext();

    const { clientId } = useParams();

    const [error, setError] = useState(null);

    const [clientData, setClientData] = useState(null);
    const [membersData, setMembersData] = useState(null);

    const getMembers = useCallback((page = 1) => {
        ChatlineAPI.HttpGetRequest('clients/' + clientId + '/members?page=' + page, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMembersData(res.data); }
        });
    }, [clientId]);

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('clients/' + clientId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setClientData(res.data); }
        });

        getMembers(1);
    }, [clientId, getMembers]);

    const [membersTableCurrentPage, setMembersTableCurrentPage] = useState(1);
    const handleMembersTableChange = (pagination, filters, sorter) => {
        setMembersTableCurrentPage(pagination.current);
        getMembers(pagination.current);
    }

    const [addMemberErrors, setAddMemberErrors] = useState([]);
    const [addMemberModalIsVisible, setAddMemberModalVisibility] = useState(false);
    const showAddMemberModal = () => { setAddMemberModalVisibility(true) };
    const handleAddMemberModalCancel = e => { setAddMemberModalVisibility(false) };
    const onAddMemberFinish = values => {
        ChatlineAPI.HttpPostRequest('clients/' + clientId + '/members/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    // setAddMemberErrors(err.data.errors);
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setAddMemberErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setAddMemberModalVisibility(false);
                getMembers();
            }
        });
    };

    const handleDeleteMember = (clientId, memberId, displayName) => {
        Modal.confirm({
            title: 'Member: ' + displayName,
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('clients/' + clientId + '/members/' + memberId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }
        
                    if(res.data && res.data.result === true) {
                        message.success('Selected record has successfully been deleted.');
                        getMembers(membersTableCurrentPage); return;
                    }
                });
            },
            onCancel() {

            },
        });
    }

    const columns = [
        {
            title: 'Display Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '120px',
            render: (role) => roleValue2Tag(role),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (status) => statusValue2Tag(status),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '160px',
            render: (val, row) => {
                return (
                    <>
                        <Link to={ '/clients/' + row.clientId + '/members/' + row._id + '/edit' } className="btnActions edit"><EditOutlined /></Link>
                        { (row.status !== -1 ? <DeleteOutlined onClick={ () => handleDeleteMember(row.clientId, row._id, row.displayName) } style={{ color: '#6246EA' }} /> : null) }
                    </>
                );
            }
        }
    ];

    const statusValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 1: return <Tag color="green">Active</Tag>;
            case 0: return <Tag color="magenta">Inactive</Tag>;
            case -1: return <Tag color="red">Deleted</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };
    
    const roleValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 10: return <Tag color="blue">Operator</Tag>;
            case 99: return <Tag color="purple">Admin</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Members | Client | Clients | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (clientData && clientData.client) ? (
                            <>
                                <div style={{ textAlign: 'right', margin: '0 0 8px 0' }}>
                                    <Button className="btnDefault" size="medium" onClick={ showAddMemberModal }><PlusCircleOutlined /> Add</Button>
                                </div>
                                
                                <Table rowKey="_id" columns={ columns } pagination={ (membersData && membersData.pagination ? membersData.pagination : {}) } dataSource={ (membersData && membersData.members ? membersData.members : []) } onChange={ handleMembersTableChange } />

                                <Modal
                                    title="Add Member"
                                    visible={ addMemberModalIsVisible }
                                    onCancel={ handleAddMemberModalCancel }
                                    footer={ null }
                                >
                                    <Form name="addMember" onFinish={ onAddMemberFinish } layout="vertical" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="displayName"
                                            label="Display Name"
                                            validateStatus={ addMemberErrors && addMemberErrors.displayName ? "error" : null }
                                            help={ addMemberErrors && addMemberErrors.displayName ? addMemberErrors.displayName : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="username"
                                            label="Username"
                                            validateStatus={ addMemberErrors && addMemberErrors.username ? "error" : null }
                                            help={ addMemberErrors && addMemberErrors.username ? addMemberErrors.username : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="email"
                                            label="Email Address"
                                            validateStatus={ addMemberErrors && addMemberErrors.email ? "error" : null }
                                            help={ addMemberErrors && addMemberErrors.email ? addMemberErrors.email : null }
                                        >
                                            <Input type="email" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="password"
                                            label="Password"
                                            validateStatus={ addMemberErrors && addMemberErrors.password ? "error" : null }
                                            help={ addMemberErrors && addMemberErrors.password ? addMemberErrors.password : null }
                                        >
                                            <Input type="password" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="role"
                                            label="Role"
                                            validateStatus={ addMemberErrors && addMemberErrors.role ? "error" : null }
                                            help={ addMemberErrors && addMemberErrors.password ? addMemberErrors.role : null }
                                        >
                                            <Select>
                                                {
                                                    systemVariables && systemVariables.user && systemVariables.user.roleOptions
                                                    ?
                                                    Object.keys(systemVariables.user.roleOptions).map(roleId => {
                                                        return (
                                                            <Select.Option key={ roleId } value={ roleId }>{ systemVariables.user.roleOptions[roleId] }</Select.Option>
                                                        );
                                                    })
                                                    :
                                                    null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Button type="default" onClick={ handleAddMemberModalCancel } style={{ margin: '0 8px 0 0' }}>Cancel</Button>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Members;