import React from 'react';
import { useSystemContext } from '../../../../../Context/SystemContext';

import { Handle } from 'react-flow-renderer';

import { Button } from 'antd';

const CarouselNode = ({ data, isConnectable, ...restProps }) => {
    const {
        systemVariables
    } = useSystemContext();
    
    const buttonTypeStyles = (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeStyles) || null;
    
    return (
        <div style={ data.node.withError && { background: 'rgba(255, 0, 0, 0.25)', border: '1px solid rgb(255, 0, 0)', borderRadius: '5px' } }>
            <Handle
                type="target"
                position="top"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            <Handle
                type="source"
                position="bottom"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            {
                (data && data.node && data.node.parameters && data.node.parameters.carouselItems && Array.isArray(data.node.parameters.carouselItems) && data.node.parameters.carouselItems.length > 0) && (
                    <div>
                        <img
                            src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + data.node.chatbotId + '/nodes/files/view-image/' + data.node.parameters.carouselItems[0].image.fileName } alt={ data.node.parameters.carouselItems[0].image.fileName } 
                            style={{ width: '100%', borderRadius: '5px' }} 
                            onDragStart={ (e) => { e.preventDefault(); } }
                        />

                        {
                            (data.node.parameters.carouselItems[0] && data.node.parameters.carouselItems[0].title && data.node.parameters.carouselItems[0].title !== '') && (
                                <div className="p-2" style={{ border: '1px solid #EBECFF', borderRadius: 0, textAlign: 'left' }}>
                                    { data.node.parameters.carouselItems[0].title }
                                </div>
                            )
                        }

                        {
                            (data.node.parameters.carouselItems[0] && data.node.parameters.carouselItems[0].description && data.node.parameters.carouselItems[0].description !== '') && (
                                <div className="p-2" style={{ overflow: 'scroll', border: '1px solid #EBECFF', borderRadius: 0, maxHeight: '120px', textAlign: 'left' }}>
                                    { data.node.parameters.carouselItems[0].description }
                                </div>
                            )
                        }

                        {
                            data.node.parameters.carouselItems[0] && data.node.parameters.carouselItems[0].buttons && Array.isArray(data.node.parameters.carouselItems[0].buttons) && data.node.parameters.carouselItems[0].buttons.length > 0 && data.node.parameters.carouselItems[0].buttons.map((button, index) => {
                                return (
                                    <Button 
                                        key={ index }
                                        onClick={ () => {} }
                                        style={ 
                                            button.type && buttonTypeStyles && buttonTypeStyles[button.type] ? (
                                                {
                                                    ...buttonTypeStyles[button.type],
                                                    display: 'block',
                                                    borderRadius: '0',
                                                    width: '100%',
                                                    margin: (index !== 0 ? '1px 0 0 0' : '0 0 0 0')
                                                }
                                            ) : (
                                                {
                                                    display: 'block',
                                                    background: '#EBECFF',
                                                    borderRadius: '0',
                                                    border: '1px solid #EBECFF',
                                                    width: '100%',
                                                    margin: (index !== 0 ? '1px 0 0 0' : '0 0 0 0'),
                                                    padding: '4px 16px',
                                                    fontSize: '11px',
                                                    lineHeight: '14px',
                                                    color: '#40456B',
                                                }
                                            )
                                        }
                                    >
                                        { button.label }
                                    </Button>
                                );
                            })
                            }
                    </div>
                )
            }
        </div>
    );
};

export default CarouselNode;

                    