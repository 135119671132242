import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';

import path from 'path';

import MainLayout from '../../../Components/Layouts/MainLayout';
import Helmet from 'react-helmet';

import ChatlineAPI from '../../../utils/ChatlineAPI';
import TextEditor from '../../../utils/TextEditor';

import { getDefaultStyleCode } from './DefaultStyleCode';
import { getDefaultExternalStyleCode } from './DefaultExternalStyleCode';
import CodeEditor from '@uiw/react-textarea-code-editor';

import { Link } from 'react-router-dom';
import { Button, Form, Input, Select, message, Spin, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import './Widget.css';
import '../../../lib/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { Dragger } = Upload;

const Widget = (props) => {
    const { 
        client, fetchClient
    } = useSystemContext();

    const [customiseWidgetForm] = Form.useForm();

    const [logoFileList, setLogoFileList] = useState(null);
    const [launcherImageFileList, setLauncherImageFileList] = useState(null);

    const [preChat1_greetingHTML, setPreChat1_greetingHTML] = useState(null);
    const [preChat2_greetingHTML, setPreChat2_greetingHTML] = useState(null);
    const getNewHTMLForPreChat1 = (html) => {
        setPreChat1_greetingHTML(html);
    }
    const getNewHTMLForPreChat2 = (html) => {
        setPreChat2_greetingHTML(html);
    }

    const [customizeWidgetErrors, setCustomizeWidgetErrors] = useState([]);
    const onCustomizeWidgetFinish = values => {
        customiseWidgetForm.setFieldsValue({
            ...values,
            preChat1_greeting: (preChat1_greetingHTML ? preChat1_greetingHTML : (client && client.parameters && client.parameters.widget && client.parameters.widget.preChat1_greeting ? client.parameters.widget.preChat1_greeting : '')),
            preChat2_greeting: (preChat2_greetingHTML ? preChat2_greetingHTML : (client && client.parameters && client.parameters.widget && client.parameters.widget.preChat2_greeting ? client.parameters.widget.preChat2_greeting : '')),
        });

        ChatlineAPI.HttpPostRequest('client/customize-widget', customiseWidgetForm.getFieldsValue(), (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setCustomizeWidgetErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                fetchClient();
                props.history.push('/control-panel');
                return;
            }
        });
    };

    useEffect(() => {
        if(client && client.parameters && client.parameters.widget) {
            if(client.parameters.widget.logo && client.parameters.widget.logo.path) {
                setLogoFileList(
                    [
                        {
                            uid: path.basename(client.parameters.widget.logo.path),
                            name: path.basename(client.parameters.widget.logo.path),
                            status: 'done',
                            url: process.env.REACT_APP_CHATLINE_API_ADDRESS + client.parameters.widget.logo.path.replace(/^\/|\/$/g, '')
                        }
                    ]
                );
            } else {
                setLogoFileList([]);
            }

            if(client.parameters.widget.launcherImage && client.parameters.widget.launcherImage.path) {
                setLauncherImageFileList(
                    [
                        {
                            uid: path.basename(client.parameters.widget.launcherImage.path),
                            name: path.basename(client.parameters.widget.launcherImage.path),
                            status: 'done',
                            url: process.env.REACT_APP_CHATLINE_API_ADDRESS + client.parameters.widget.launcherImage.path.replace(/^\/|\/$/g, '')
                        }
                    ]
                );
            } else {
                setLauncherImageFileList([]);
            }
        }
    }, [client]);

    return (
        <>
            <Helmet>
                <title>Widget | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (client) ? (
                            <>
                                <div className="pageBody">
                                    <h1>Customise Widget</h1>

                                    <Form form={ customiseWidgetForm } className="mt-4" initialValues={{}} name="editMember" onFinish={ onCustomizeWidgetFinish } layout="vertical" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="language"
                                            label="Language"
                                            initialValue={{ value: ((client && client.parameters && client.parameters.widget && client.parameters.widget.language) || 'tr') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ 'en' } value={ 'en' }>[EN] English</Select.Option>
                                                <Select.Option key={ 'tr' } value={ 'tr' }>[TR] Turkish</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item
                                            label="Logo"
                                        >
                                            <Dragger
                                                action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'client/customize-widget/logo/upload' }
                                                headers={ (localStorage.getItem('token') ? { 'Authorization': localStorage.getItem('token') + '' } : {}) }
                                                accept="image/*"
                                                name="logo"
                                                multiple={ false }
                                                onChange= { (info) => {
                                                    if(info.file.status === 'done') {
                                                        fetchClient();
                                                        return;
                                                    }
                                                }}
                                                showUploadList={ true }
                                                listType="picture"
                                                fileList={ (logoFileList ? logoFileList : []) }
                                                showDownloadIcon={ false }
                                                showRemoveIcon={ true }
                                                onRemove={ (file) => {
                                                    ChatlineAPI.HttpPostRequest('client/customize-widget/logo/delete', {}, (err, res) => {
                                                        if(err || !res) {
                                                            message.error('An unknown error occured while deleting process, please try again.');
                                                            return;
                                                        }
                                            
                                                        if(res.data && res.data.result === true) {
                                                            fetchClient();
                                                            return;
                                                        }
                                                    });
                                                }}
                                            >
                                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                                <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                                            </Dragger>
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item
                                            label="Launcher Image"
                                        >
                                            <Dragger
                                                action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'client/customize-widget/launcher-image/upload' }
                                                headers={ (localStorage.getItem('token') ? { 'Authorization': localStorage.getItem('token') + '' } : {}) }
                                                accept="image/*"
                                                name="launcherImage"
                                                multiple={ false }
                                                onChange= { (info) => {
                                                    if(info.file.status === 'done') {
                                                        fetchClient();
                                                        return;
                                                    }
                                                }}
                                                showUploadList={ true }
                                                listType="picture"
                                                fileList={ (launcherImageFileList ? launcherImageFileList : []) }
                                                showDownloadIcon={ false }
                                                showRemoveIcon={ true }
                                                onRemove={ (file) => {
                                                    ChatlineAPI.HttpPostRequest('client/customize-widget/launcher-image/delete', {}, (err, res) => {
                                                        if(err || !res) {
                                                            message.error('An unknown error occured while deleting process, please try again.');
                                                            return;
                                                        }
                                            
                                                        if(res.data && res.data.result === true) {
                                                            fetchClient();
                                                            return;
                                                        }
                                                    });
                                                }}
                                            >
                                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                                <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                                                <p className="ant-upload-hint">Preferred size: 60px x 60px (72dpi)</p>
                                            </Dragger>
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item 
                                            label="Widget Styling CSS"
                                            name="styling"
                                            initialValue={ (client && client.parameters && client.parameters.widget && client.parameters.widget.styling) || getDefaultStyleCode() }
                                        >
                                            <CodeEditor
                                                language="css"
                                                padding={ 15 }
                                                style={{
                                                    // overflow: 'auto',
                                                    // height: '520px',
                                                    marginTop: '2px',
                                                    fontSize: 12,
                                                    backgroundColor: "#f5f5f5",
                                                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                }}
                                            />
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item 
                                            label="External Page Styling CSS (to inject)"
                                            name="externalStyling"
                                            initialValue={ (client && client.parameters && client.parameters.widget && client.parameters.widget.externalStyling) || getDefaultExternalStyleCode() }
                                        >
                                            <CodeEditor
                                                language="css"
                                                padding={ 15 }
                                                style={{
                                                    // overflow: 'auto',
                                                    // height: '520px',
                                                    marginTop: '2px',
                                                    fontSize: 12,
                                                    backgroundColor: "#f5f5f5",
                                                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                }}
                                            />
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item 
                                            name="preChat1_isVisible"
                                            label="PreChat [1] Is Visible?"
                                            initialValue={{ value: (client && client.parameters && client.parameters.widget && typeof(client.parameters.widget.preChat1_isVisible) !== 'undefined' && client.parameters.widget.preChat1_isVisible !== null ? client.parameters.widget.preChat1_isVisible+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        
                                        <Form.Item 
                                            name="preChat1_widgetTitle"
                                            label="PreChat [1]: Title"
                                            initialValue={ client && client.parameters && client.parameters.widget && client.parameters.widget.preChat1_widgetTitle ? client.parameters.widget.preChat1_widgetTitle : null }
                                            validateStatus={ customizeWidgetErrors && customizeWidgetErrors.preChat1_widgetTitle ? "error" : null }
                                            help={ customizeWidgetErrors && customizeWidgetErrors.preChat1_widgetTitle ? customizeWidgetErrors.preChat1_widgetTitle : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="preChat1_greeting"
                                            label="PreChat [1]: Greeting"
                                            validateStatus={ customizeWidgetErrors && customizeWidgetErrors.preChat1_greeting ? "error" : null }
                                            help={ customizeWidgetErrors && customizeWidgetErrors.preChat1_greeting ? customizeWidgetErrors.preChat1_greeting : null }
                                        >
                                            <>
                                                <TextEditor
                                                    initialHTML={ ((client && client.parameters && client.parameters.widget && client.parameters.widget.preChat1_greeting) || '') }
                                                    getNewHTML={ getNewHTMLForPreChat1 }
                                                />
                                                
                                                <Input type="hidden" />
                                            </>
                                        </Form.Item>

                                        <Form.Item 
                                            name="preChat1_isLiveChatOptionEnabled"
                                            label="PreChat [1] Is Livechat Option Enabled?"
                                            initialValue={{ value: '1' }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="preChat1_isTicketOptionEnabled"
                                            label="PreChat [1] Is Ticket Option Enabled?"
                                            initialValue={{ value: (client && client.parameters && client.parameters.widget && typeof(client.parameters.widget.preChat1_isTicketOptionEnabled) !== 'undefined' && client.parameters.widget.preChat1_isTicketOptionEnabled !== null ? client.parameters.widget.preChat1_isTicketOptionEnabled+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item 
                                            name="preChat2_widgetTitle"
                                            label="PreChat [2]: Title"
                                            initialValue={ client && client.parameters && client.parameters.widget && client.parameters.widget.preChat2_widgetTitle ? client.parameters.widget.preChat2_widgetTitle : null }
                                            validateStatus={ customizeWidgetErrors && customizeWidgetErrors.preChat2_widgetTitle ? "error" : null }
                                            help={ customizeWidgetErrors && customizeWidgetErrors.preChat2_widgetTitle ? customizeWidgetErrors.preChat2_widgetTitle : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="preChat2_greeting"
                                            label="PreChat [2]: Greeting"
                                            validateStatus={ customizeWidgetErrors && customizeWidgetErrors.preChat2_greeting ? "error" : null }
                                            help={ customizeWidgetErrors && customizeWidgetErrors.preChat2_greeting ? customizeWidgetErrors.preChat2_greeting : null }
                                        >
                                            <>
                                                <TextEditor
                                                    initialHTML={ ((client && client.parameters && client.parameters.widget && client.parameters.widget.preChat2_greeting) || '') }
                                                    getNewHTML={ getNewHTMLForPreChat2 }
                                                />
                                                
                                                <Input type="hidden" />
                                            </>
                                        </Form.Item>

                                        <Form.Item 
                                            name="preChat2_isEmailFieldVisible"
                                            label="PreChat [2] Is Email Field Visible?"
                                            initialValue={{ value: (client && client.parameters && client.parameters.widget && typeof(client.parameters.widget.preChat2_isEmailFieldVisible) !== 'undefined' && client.parameters.widget.preChat2_isEmailFieldVisible !== null ? client.parameters.widget.preChat2_isEmailFieldVisible+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <div style={{ background: '#F0F0F0', width: '100%', height: '1px', margin: '40px 0 40px 0' }} />

                                        <Form.Item 
                                            name="fileSharingEnabled"
                                            label="File Sharing Enabled?"
                                            initialValue={{ value: (client && client.parameters && client.parameters.widget && typeof(client.parameters.widget.fileSharingEnabled) !== 'undefined' && client.parameters.widget.fileSharingEnabled !== null ? client.parameters.widget.fileSharingEnabled+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Link to="/control-panel" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Widget;
