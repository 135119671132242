import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSystemContext } from '../../../Context/SystemContext';

import { socket } from '../../../service/socket';

import moment from 'moment';

import Helmet from 'react-helmet';
import MainLayout from '../../../Components/Layouts/MainLayout';
import CardWithShadow from '../../../Components/Cards/CardWithShadow';
import Error from '../../Error/Error';

import ChatlineAPI from '../../../utils/ChatlineAPI';

import { Spin, message, Form, Button, Mentions, Select, Row, Col, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './Ticket.css';
import classes from './Ticket.module.css';

const nl2br = require('react-nl2br');

const Ticket = (props) => {
    const {
        systemVariables
    } = useSystemContext();

    const { ticketId } = useParams();

    const [form] = Form.useForm();

    const [departmentsDataset, setDepartmentsDataset] = useState(null);
    
    const [ticketData, setTicketData] = useState(null);
    const [commentsData, setCommentsData] = useState(null);
    const [membersData, setMembersData] = useState(null);
    const [membersById, setMembersById] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('departments', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            setDepartmentsDataset((res && res.data) || {});
        });

        ChatlineAPI.HttpGetRequest('members', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) {
                setMembersData(res.data)

                if(res.data.members && Array.isArray(res.data.members) && res.data.members.length > 0) {
                    let xMembersById = [];
                    for(let member of res.data.members) {
                        xMembersById[member._id] = member;
                    }

                    setMembersById(xMembersById);
                }
            }
        });
    }, []);

    const getComments = useCallback((page = 1) => {
        ChatlineAPI.HttpGetRequest('tickets/' + ticketId + '/comments?page=' + page, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setCommentsData(res.data); }
        });
    }, [ticketId]);

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('tickets/' + ticketId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setTicketData(res.data); }
        });

        getComments();
    }, [ticketId, getComments]);

    useEffect(() => {
        if(ticketData && ticketData.ticket && commentsData && commentsData.comments && membersData && membersData.members) {
            setTimeout(() => {
                var bodyC = document.getElementById("bodyC");
                if(bodyC) bodyC.scrollTop = bodyC.scrollHeight;
            }, 100);
        }
    });

    const onAssignTicket = (value) => {
        ChatlineAPI.HttpPostRequest('tickets/' + ticketId + '/assign', { assignedTo: value }, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.status && res.status === 200) {
                ChatlineAPI.HttpGetRequest('tickets/' + ticketId, (err, res) => {
                    if(err) {
                        setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
                    }
        
                    if(res && res.data) { setTicketData(res.data); }
                });
            }
        });
    }

    const onStatusChange = (value) => {
        ChatlineAPI.HttpPostRequest('tickets/' + ticketId + '/change-status', { status: value }, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.status && res.status === 200) {
                ChatlineAPI.HttpGetRequest('tickets/' + ticketId, (err, res) => {
                    if(err) {
                        setError(<Error status={ (err.status ? err.status : 500) } />);
                        return;
                    }
        
                    if(res && res.data) { setTicketData(res.data); }
                });
            }
        });
    }

    const [submitCommentErrors, setSubmitCommentErrors] = useState(null);
    const onSubmitCommentFinish = values => {
        ChatlineAPI.HttpPostRequest('tickets/' + ticketId + '/comments/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formErr of err.data.errors) {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    }
                    setSubmitCommentErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                if(res.data.notifications && Array.isArray(res.data.notifications) && res.data.notifications.length > 0) {
                    for(let notification of res.data.notifications) {
                        socket.emit('operator.notifications.new', notification);
                    }
                }

                form.resetFields();
                getComments();
            }
        });
    };

    const statusValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 2: return <Tag color="green">Resolved</Tag>;
            case 1: return <Tag color="gold">Assigned</Tag>;
            case 0: return <Tag color="magenta">Pending</Tag>;
            case -1: return <Tag color="red">Deleted</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };

    const statusOptions = (systemVariables && systemVariables.ticket && systemVariables.ticket.statusOptions ? systemVariables.ticket.statusOptions : null);

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Tickets | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (membersData && membersData.members && ticketData && ticketData.ticket && commentsData && commentsData.comments) ? (
                            <div className={ classes.flexRowLG }>
                                <div className={ classes.colContentPaneLeft }>
                                    <CardWithShadow>
                                        <div className={ classes.headerC + ' py-2 px-3' }>
                                            <Link to="/tickets"><Button className="btnBack"><ArrowLeftOutlined /> Back</Button></Link> <h2 className="ml-1" style={{ display: 'inline' }}>Ticket Details</h2>
                                        </div>

                                        <div className={ classes.assignedToC + ' py-3 px-3' }>
                                            <div className={ classes.label + ' mb-1' }>Ticket assigned to: </div>

                                            <Select style={{ width: '100%' }} className="filterOptions" onChange={ onAssignTicket } value={ ticketData.ticket.assignedTo+'' ? ticketData.ticket.assignedTo+'' : '' }>
                                                {
                                                    departmentsDataset && departmentsDataset.departments && departmentsDataset.departments[ticketData.ticket.departmentId] && departmentsDataset.departments[ticketData.ticket.departmentId].memberIds && Array.isArray(departmentsDataset.departments[ticketData.ticket.departmentId].memberIds) && departmentsDataset.departments[ticketData.ticket.departmentId].memberIds.length > 0 && departmentsDataset.departments[ticketData.ticket.departmentId].memberIds.map((memberId) => {
                                                        let member = membersById && membersById[memberId];
                                                        if(!member) return null;

                                                        return <Select.Option key={ member._id } value={ member._id }>{ member.displayName }</Select.Option>;
                                                    })
                                                }
                                            </Select>
                                            
                                            { ticketData.ticket.assignedBy ?
                                                <div className={ classes.helper + ' mt-1' }>Ticket assigned by: <b>{ membersById && membersById[ticketData.ticket.assignedBy] ? membersById[ticketData.ticket.assignedBy].displayName : ticketData.ticket.assignedBy }</b></div>
                                            : null }
                                        </div>

                                        <div className={ classes.summary + ' py-3 px-3' }>
                                            <div className={ classes.label + ' mb-1' }>Ticket Summary: </div>

                                            <Row gutter={ 4 }>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>Ticket ID</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ ticketData.ticket._id }</div></Col>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>Subject</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ ticketData.ticket.subject }</div></Col>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>Created at</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ moment(ticketData.ticket.createdAt).format('DD.MM.YYYY') }</div></Col>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>Status</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ statusValue2Tag(ticketData.ticket.status) }</div></Col>
                                            </Row>
                                        </div>

                                        <div className={ classes.reference + ' py-3 px-3' }>
                                            <div className={ classes.label + ' mb-1' }>Ticket Reference: </div>

                                            <Row gutter={ 4 }>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>IP</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ (ticketData.ticket.visitor && ticketData.ticket.visitor.ip ? ticketData.ticket.visitor.ip : 'N/A') }</div></Col>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>Full name</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ (ticketData.ticket.visitor && ticketData.ticket.visitor.name ? ticketData.ticket.visitor.name : 'N/A') }</div></Col>
                                                <Col className="mt-1" span={ 6 }><span className={ classes.subtitle }>Email</span></Col> <Col className="mt-1" span={ 18 }><div className={ classes.info }>{ (ticketData.ticket.visitor && ticketData.ticket.visitor.email ? ticketData.ticket.visitor.email : 'N/A') }</div></Col>
                                            </Row>
                                        </div>
                                    </CardWithShadow>
                                </div>

                                <div className={ classes.colContentPaneRight }>
                                    <CardWithShadow>
                                        <div className={ classes.headerC + ' py-2 px-3' }>
                                            <div style={{ float: 'right' }}>
                                                <span className={ classes.changeStatusLabel + ' mr-1' }>Status</span>
                                                <Select onChange={ onStatusChange } className={ (statusOptions && statusOptions[ticketData.ticket.status] ? statusOptions[ticketData.ticket.status] + ' changeStatusSelector' : 'changeStatusSelector') } style={{ width: '120px' }} value={ ticketData.ticket.status+'' ? ticketData.ticket.status+'' : null }>
                                                    {
                                                        statusOptions ?
                                                        Object.keys(statusOptions).map(statusId => {
                                                            return (
                                                                <Select.Option key={ statusId } value={ statusId }>{ statusOptions[statusId] }</Select.Option>
                                                            );
                                                        })
                                                        : null }
                                                </Select>
                                            </div>

                                            <h1>{ ticketData.ticket.subject ? ticketData.ticket.subject : 'Ticket' }</h1>
                                        </div>

                                        <div id="bodyC" className={ classes.bodyC + ' p-3'}>
                                            <div className={ classes.ticketDescriptionC }>
                                                <div className={ classes.fromC + ' py-2 px-3' }>
                                                    <span className={ classes.fullName }>{ (ticketData.ticket.visitor && ticketData.ticket.visitor.name) }</span>
                                                    From: <span className={ classes.email }>{ (ticketData.ticket.visitor && ticketData.ticket.visitor.email) }</span>
                                                    <div className={ classes.dateTime }>
                                                        { moment(ticketData.ticket.createdAt).format('DD.MM.YYYY - hh:mm:ss') }
                                                    </div>
                                                </div>

                                                <div className={ classes.messageC + ' py-3 px-3' }>
                                                    <div>{ ticketData.ticket.description ? nl2br(ticketData.ticket.description) : 'Description' }</div>
                                                </div>
                                            </div>

                                            <div style={{ margin: '24px 0 24px 0' }}>
                                                { commentsData.comments && Array.isArray(commentsData.comments) && commentsData.comments.length > 0 ?
                                                    commentsData.comments.map(comment => {
                                                        return (
                                                            <div key={ comment._id } className={ classes.commentC }>
                                                                <div className={ classes.fromC + ' py-2 px-3' }>
                                                                    <span className={ classes.displayName }>{ comment.userId && membersById && membersById[comment.userId] ? membersById[comment.userId].displayName : 'Display Name' }</span>
                                                                    Username: <span className={ classes.email }>{ comment.userId && membersById && membersById[comment.userId] ? membersById[comment.userId].username : 'Username' }</span>
                                                                    <div className={ classes.dateTime }>
                                                                        { moment(comment.createdAt).format('DD.MM.YYYY - hh:mm:ss') }
                                                                    </div>
                                                                </div>

                                                                <div className={ classes.messageC + ' py-3 px-3' }>
                                                                    <div>{ comment.body ? nl2br(comment.body) : 'Comment Body' }</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                : null }
                                            </div>
                                        </div>

                                        <div className={ classes.textFieldC + ' p-3' }>         
                                            <Form form={ form } name="submitComment" onFinish={ onSubmitCommentFinish } layout="vertical" hideRequiredMark={ true }>
                                                <Form.Item 
                                                    name="body"
                                                    label="Make a comment"
                                                    validateStatus={ submitCommentErrors && submitCommentErrors.body ? "error" : null }
                                                    help={ submitCommentErrors && submitCommentErrors.body ? submitCommentErrors.body : null }
                                                >
                                                    <Mentions rows={ 2 } notFoundContent={ null }>
                                                        { Array.isArray(membersData.members) && membersData.members.length > 0 ?
                                                            membersData.members.map(member => {
                                                                return <Mentions.Option key={ member._id } value={ member.username }>{ member.username + ' [' + member.displayName + ']' }</Mentions.Option>;
                                                            })
                                                        : null }
                                                    </Mentions>
                                                </Form.Item>

                                                <Form.Item style={{ textAlign: 'right' }}>
                                                    <Button className="btnAdd" type="primary" htmlType="submit">Send</Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </CardWithShadow>
                                </div>
                            </div>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
}

export default Ticket;