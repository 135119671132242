import React from 'react';

import { Handle } from 'react-flow-renderer';

import { CloseOutlined } from '@ant-design/icons';

const EndChatNode = ({ data, isConnectable, ...restProps }) => {
    return (
        <div className="px-1 py-2" style={ data.node.withError && { background: 'rgba(255, 0, 0, 0.25)', border: '1px solid rgb(255, 0, 0)', borderRadius: '5px', color: '#cc0000' } }>
            <Handle
                type="target"
                position="top"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            <Handle
                type="source"
                position="bottom"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            <span style={{ fontSize: '16px', fontWeight: 'bold' }}> <CloseOutlined className="mb-2" /> <br /> End of chat </span>
        </div>
    );
};

export default EndChatNode;