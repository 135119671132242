import React, { useState } from 'react';
import { useSystemContext } from '../../../../../Context/SystemContext';

import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';
import MainLayout from '../../../../../Components/Layouts/MainLayout';

import ChatlineAPI from '../../../../../utils/ChatlineAPI';

import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const AddOperator = (props) => {
    const { 
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const statusOptions = (systemVariables && systemVariables.user && systemVariables.user.statusOptions ? systemVariables.user.statusOptions : null);

    const [addOperatorErrors, setAddOperatorErrors] = useState([]);
    const onAddOperatorFinish = values => {
        ChatlineAPI.HttpPostRequest('members/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setAddOperatorErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                props.history.push('/control-panel/operators');
                return;
            }
        });
    };

    const statusKey2Value = (status) => {
        if(typeof(statusOptions[status]) !== 'undefined') return statusOptions[status];
        return 'Unknown';
    };

    return (
        <>
            <Helmet>
                <title>Add Operator | Operators | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    <div style={{ margin: '0 0 8px 0' }}>
                        <Link to="/control-panel/operators"><Button className="btnBack" size="medium" onClick={ () => {} }><ArrowLeftOutlined /> Operators</Button></Link>
                    </div>

                    <div className="pageBody">
                        <h1 className="mb-4">Add Operator</h1>

                        <Form initialValues={{}} name="addOperator" onFinish={ onAddOperatorFinish } layout="vertical" hideRequiredMark={ true }>
                            <Form.Item 
                                name="displayName"
                                label="Display Name"
                                validateStatus={ addOperatorErrors && addOperatorErrors.displayName ? 'error' : null }
                                help={ addOperatorErrors && addOperatorErrors.displayName ? addOperatorErrors.displayName : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item 
                                name="username"
                                label="Username"
                                validateStatus={ addOperatorErrors && addOperatorErrors.username ? 'error' : null }
                                help={ addOperatorErrors && addOperatorErrors.username ? addOperatorErrors.username : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="Email Address"
                                validateStatus={ addOperatorErrors && addOperatorErrors.email ? 'error' : null }
                                help={ addOperatorErrors && addOperatorErrors.email ? addOperatorErrors.email : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                validateStatus={ addOperatorErrors && addOperatorErrors.password ? 'error' : null }
                                help={ addOperatorErrors && addOperatorErrors.password ? addOperatorErrors.password : null }
                            >
                                <Input type="password" />
                            </Form.Item>

                            <Form.Item 
                                name="role"
                                label="Role"
                                validateStatus={ addOperatorErrors && addOperatorErrors.role ? 'error' : null }
                                help={ addOperatorErrors && addOperatorErrors.role ? addOperatorErrors.role : null }
                            >
                                <Select>
                                    {
                                        roleOptions
                                        ?
                                        Object.keys(roleOptions).map(roleId => {
                                            return (
                                                <Select.Option key={ roleId } value={ roleId }>{ roleOptions[roleId] }</Select.Option>
                                            );
                                        })
                                        :
                                        null
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item 
                                name="status"
                                label="Status"
                            >
                                <Select>
                                    { (statusOptions && Object.keys(statusOptions).map(statusId => <Select.Option key={ statusId } value={ statusId }>{ statusKey2Value(statusId) }</Select.Option>)) }
                                </Select>
                            </Form.Item>

                            <hr />

                            <Form.Item 
                                name="automaticallyAcceptChats"
                                label="Automatically accept chats?"
                            >
                                <Select labelInValue>
                                    <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                    <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item 
                                name="maximumNumberOfConcurrentChats"
                                label="Maximum number of concurrent chats that can be automatically accepted"
                            >
                                <InputNumber min={ 1 } />
                            </Form.Item>

                            <Form.Item 
                                name="isAllowedToManuallyPickFromPendingChats"
                                label="Is allowed to manually pick from pending chats?"
                            >
                                <Select labelInValue>
                                    <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                    <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item style={{ textAlign: 'center' }}>
                                <Link to="/control-panel/operators" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </>
            </MainLayout>
        </>
    )
}

export default AddOperator;