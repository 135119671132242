import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';

import Helmet from 'react-helmet';
import MainLayout from '../../../Components/Layouts/MainLayout';

import Error from '../../Error/Error';

import ChatlineAPI from '../../../utils/ChatlineAPI';

import { Link } from 'react-router-dom';
import { Table, Button, Modal, Form, Select, message, Tag, Spin, Tabs, Row, Col } from 'antd';
import { UserAddOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';

const Operators = (props) => {
    const { 
        currentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const statusOptions = (systemVariables && systemVariables.user && systemVariables.user.statusOptions ? systemVariables.user.statusOptions : null);

    const [membersData, setMembersData] = useState(null);
    const [membersQueryParams, setMembersQueryParams] = useState(null);

    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    
    useEffect(() => {
        ChatlineAPI.HttpGetRequest('members', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMembersData(res.data); }
        });
    }, []);

    const handleMembersTableChange = (pagination, filters, sorter) => {
        let queryParams = {
            ...membersQueryParams,
            page: pagination.current
        }
        setMembersQueryParams(queryParams);

        let queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

        ChatlineAPI.HttpGetRequest('members?' + queryString, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMembersData(res.data); }
        });
    }

    const statusValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 1: return <Tag color="green">Active</Tag>;
            case 0: return <Tag color="magenta">Inactive</Tag>;
            case -1: return <Tag color="red">Deleted</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };
    
    const roleValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 10: return <Tag color="blue">Operator</Tag>;
            case 20: return <Tag color="cyan">Supervisor</Tag>;
            case 99: return <Tag color="purple">Admin</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };

    const handleDeleteMember = (memberId, displayName) => {
        Modal.confirm({
            title: 'Member: ' + displayName,
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('members/' + memberId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }
        
                    if(res.data && res.data.result === true) {
                        message.success('Selected record has successfully been deleted.');
                        
                        let queryParams = {
                            ...membersQueryParams
                        }
                        setMembersQueryParams(queryParams);
                
                        let queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
                
                        ChatlineAPI.HttpGetRequest('members?' + queryString, (err, res) => {
                            if(err) {
                                setError(<Error status={ (err.status ? err.status : 500) } />);
                                return;
                            }
                
                            if(res && res.data) { setMembersData(res.data); }
                        });

                        return;
                    }
                });
            },
            onCancel() {

            },
        });
    }

    const columns = [
        {
            title: 'Display Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '120px',
            render: (role) => roleValue2Tag(role),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (status) => statusValue2Tag(status),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '240px',
            render: (val, row) => {
                return (
                    <>
                        { 
                            (currentUser && currentUser._id+'' !== row._id+'') && ((currentUser && currentUser.role && roleOptions && ([''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin')].includes(''+currentUser.role) || ([''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role) && row.role+'' === ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Operator'))))) && (
                                <Link to={ '/control-panel/operators/' + row._id + '/edit' } className="btnActions edit"><Button className="btnEdit btnInline"><EditOutlined /> Edit</Button></Link>
                            )
                        }

                        { 
                            (currentUser && currentUser._id+'' !== row._id+'') && (
                                (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin')].includes(''+currentUser.role)) && (
                                    <Button className="btnDelete btnInline" onClick={ () => handleDeleteMember(row._id, row.displayName) }><DeleteOutlined /> Delete</Button>
                                )
                            )
                        }
                    </>
                );
            }
        }
    ];

    const onTabChange = (key) => {
        let role = null;
        if(roleOptions) {
            role = Object.keys(roleOptions).find(xKey => roleOptions[xKey] === key);
        }

        let queryParams = {
            ...membersQueryParams,
            role: role
        }
        setMembersQueryParams(queryParams);

        let queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

        ChatlineAPI.HttpGetRequest('members?' + queryString, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMembersData(res.data); }
        });
    }

    const onApplyFilter = (values) => {
        let queryParams = {
            ...membersQueryParams
        }

        let status = (values.status+'' === "All" ? null : values.status);
        if(status) {
            queryParams = {
                ...queryParams,
                status: status
            }
        }

        let limit = values.limit;
        if(limit) {
            queryParams = {
                ...queryParams,
                limit: limit
            }
        }

        setMembersQueryParams(queryParams);
        
        let queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

        ChatlineAPI.HttpGetRequest('members?' + queryString, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMembersData(res.data); }
        });
    }

    const onResetFields = () => {
        ChatlineAPI.HttpGetRequest('members', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMembersData(res.data); }
        });

        form.resetFields();
    }

    const statusKey2Value = (status) => {
        if(typeof(statusOptions[status]) !== 'undefined') return statusOptions[status];
        return 'Unknown';
    };

    const formFilterOptions = (
        <Form form={ form } initialValues={{}} name="editMember" onFinish={ onApplyFilter } layout="vertical" hideRequiredMark={ true }>
            <Row gutter={ 16 }>
                <Col xs={ 24 } sm={ 8 } xl={ 4 }>
                    <Form.Item 
                        name="status"
                        label="Status"
                        initialValue="All"
                    >
                        <Select className="filterOptions">
                            {
                                statusOptions ?
                                Object.keys(statusOptions).map(statusId => {
                                    return (
                                        <Select.Option key={ statusId } value={ statusId }>{ statusKey2Value(statusId) }</Select.Option>
                                    );
                                })
                                : null }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                    <Form.Item 
                        name="limit"
                        label="Show"
                        initialValue={ membersData && membersData.pagination && membersData.pagination.pageSize ? membersData.pagination.pageSize : null }
                    >
                        <Select className="filterOptions">
                            <Select.Option value={ 10 }>10</Select.Option>
                            <Select.Option value={ 25 }>25</Select.Option>
                            <Select.Option value={ 50 }>50</Select.Option>
                            <Select.Option value={ 100 }>100</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                    <Form.Item>
                        <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                        <Button className="btnBack" onClick={ onResetFields }>Reset</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Operators | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (membersData && membersData.members) ? (
                            <>
                                <div style={{ margin: '0 0 8px 0' }}>
                                    {
                                        (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin')].includes(''+currentUser.role)) && (
                                            <Link to="/control-panel/operators/add"><Button className="btnAdd" size="medium" onClick={ () => {} } style={{ float: 'right' }}><UserAddOutlined /> Add Operator</Button></Link>
                                        )
                                    }
                                    <Link to="/control-panel"><Button className="btnBack" size="medium" onClick={ () => {} }><ArrowLeftOutlined /> Control Panel</Button></Link>
                                </div>

                                <Tabs defaultActiveKey="1" onChange={ onTabChange }>
                                    <Tabs.TabPane tab="All Members" key="all">
                                        { formFilterOptions }
                                        <Table rowKey="_id" columns={ columns } pagination={ (membersData && membersData.pagination ? membersData.pagination : {}) } dataSource={ (membersData && membersData.members ? membersData.members : []) } onChange={ handleMembersTableChange } />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Operators" key="Operator">
                                        { formFilterOptions }
                                        <Table rowKey="_id" columns={ columns } pagination={ (membersData && membersData.pagination ? membersData.pagination : {}) } dataSource={ (membersData && membersData.members ? membersData.members : []) } onChange={ handleMembersTableChange } />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Supervisors" key="Supervisor">
                                        { formFilterOptions }
                                        <Table rowKey="_id" columns={ columns } pagination={ (membersData && membersData.pagination ? membersData.pagination : {}) } dataSource={ (membersData && membersData.members ? membersData.members : []) } onChange={ handleMembersTableChange } />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Administrators" key="Admin">
                                        { formFilterOptions }
                                        <Table rowKey="_id" columns={ columns } pagination={ (membersData && membersData.pagination ? membersData.pagination : {}) } dataSource={ (membersData && membersData.members ? membersData.members : []) } onChange={ handleMembersTableChange } />
                                    </Tabs.TabPane>
                                </Tabs>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Operators;
