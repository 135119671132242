import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useHistory } from 'react-router-dom';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import debounce from 'lodash.debounce';

import moment from 'moment';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Spin, Button, Table, Tabs, message, Form, Row, Col, Input, Select } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';

import ReactCountryFlag from 'react-country-flag';

import './ChatQueue.less';

const ChatQueueActiveChats = () => {
    const { 
        currentUser,
        isSocketConnected
    } = useSystemContext();

    const [departments, setDepartments] = useState(null);
    const [operators, setOperators] = useState(null);

    const [isActiveChatsLoading, setIsActiveChatsLoading] = useState(false);
    const [activeChats, updateActiveChats] = useImmer(null);
    const [activeChatsPagination, setActiveChatsPagination] = useState(null);
    const [activeChatsFilters, setActiveChatsFilters] = useState(null);

    const [activeChatsFiltersForm] = Form.useForm();

    const history = useHistory();

    const loadActiveChats = useCallback((page = 1, filters = null) => {
        setIsActiveChatsLoading(true);

        socket.emit('operator.chats.active.get', { page: page, filters: filters }, (ack) => {
            setIsActiveChatsLoading(false);

            setActiveChatsPagination((ack && ack.result && ack.response && ack.response.pagination) || {});

            updateActiveChats(activeChats => {
                if(!activeChats) return (ack && ack.result && ack.response && ack.response.chats ? ack.response.chats : {});

                if(ack && ack.response && ack.response.chats && Object.values(ack.response.chats).length > 0) {
                    for(let chat of Object.values(ack.response.chats)) {
                        activeChats[chat._id] = chat;
                    }
                }
            });
        });
    }, [updateActiveChats]);

    window.onscroll = debounce(() => {
        let hasMore = (activeChatsPagination && activeChatsPagination.pageSize && activeChatsPagination.current && activeChatsPagination.total && (activeChatsPagination.pageSize * activeChatsPagination.current) < activeChatsPagination.total);

        if(isActiveChatsLoading || !hasMore) return;

        // Checks that the page has scrolled to the bottom
        if(window.innerHeight + document.documentElement.scrollTop === document.getElementById('root').offsetHeight) {
            loadActiveChats(((activeChatsPagination && activeChatsPagination.current && (parseInt(activeChatsPagination.current) + 1)) || 1), activeChatsFilters);
        }
    }, 100);

    useEffect(() => {
        if(isSocketConnected) {
            loadActiveChats(1, activeChatsFilters);
        }
    }, [isSocketConnected, loadActiveChats, activeChatsFilters]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.chats.chatQueueBadgeCount.get');

            socket.emit('operator.client.departments.get', null, (ack) => {
                if(ack && ack.result && ack.departments) {
                    setDepartments(ack.departments);
                }
            });
        }
    }, [isSocketConnected]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.operators.all.get', null, (ack) => {
                if(ack && ack.result && ack.operators) setOperators(operators => (ack.operators ? ack.operators : {}));
            });
        }
    }, [isSocketConnected]);

    useEffect(() => {
        if(isSocketConnected && departments) {
            socket.off('operator.chats.chat.start').on('operator.chats.chat.start', (pChat) => {
                if(!pChat) return;

                if(!departments || !departments[pChat.departmentId]) return; // if the current user does not have access to the chat's department

                if(pChat.operatorId) {
                    updateActiveChats(activeChats => {
                        if(!activeChats) activeChats = {};
                        activeChats[pChat._id] = { ...pChat };
                    });
                } else {
                    updateActiveChats(activeChats => {
                        if(activeChats && activeChats[pChat._id]) delete activeChats[pChat._id];
                    });
                }
            });
        }

        return function cleanup() {
            socket.off('operator.chats.chat.start');
        }
    }, [isSocketConnected, departments, updateActiveChats]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('operator.chats.chat.end').on('operator.chats.chat.end', (pChat) => {
                if(!pChat) return;

                updateActiveChats(activeChats => {
                    if(activeChats && activeChats[pChat._id]) delete activeChats[pChat._id];
                });
            });
        }

        return function cleanup() {
            socket.off('operator.chats.chat.end');
        }
    }, [isSocketConnected, updateActiveChats]);

    const handleStartChat = (chatId) => {
        socket.emit('operator.chat.join', { chatId: chatId }, (ack) => {
            if(!ack) return;

            if(ack.result && ack.chat) {
                history.push('/my-chats/' + ack.chat._id);
            } else if(ack.error) {
                message.error(ack.error);
            }
        });
    };

    const handleObserveChat = (chatId) => {
        socket.emit('operator.chat.observe', { chatId: chatId }, (ack) => {
            if(ack && ack.result && ack.chat) history.push('/my-chats/' + ack.chat._id);
        });
    }

    const onApplyActiveChatsFilter = (values) => {
        if(!values) {
            setActiveChatsPagination(null);
            setActiveChatsFilters(null);
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            if(values[key]) filters[key] = (''+values[key]).trim();
        }
        
        setActiveChatsPagination(null);
        setActiveChatsFilters(filters);
    }

    const onResetActiveChatsFilters = () => {
        activeChatsFiltersForm.resetFields();
        setActiveChatsPagination(null);
        setActiveChatsFilters(null);
    }

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (val, row) => { return (row.visitor && row.visitor.username ? row.visitor.username : 'N/A'); }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (val, row) => { return (row.visitor && row.visitor.name ? row.visitor.name : 'N/A'); }
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            render: (val, row) => { return (row.visitor && row.visitor.ip ? <span> { row.visitor.ip } { (row.visitor.countryCode && <ReactCountryFlag countryCode={ row.visitor.countryCode } style={{ fontSize: '18px'}} />) } </span> : 'N/A'); }
        },
        {
            title: 'Registration Date',
            dataIndex: 'registeredAt',
            key: 'registeredAt',
            render: (val, row) => { return (row.visitor && row.visitor.registeredAt ? moment.unix(row.visitor.registeredAt+'').format('DD/MM/YYYY') : 'N/A'); }
        },
        {
            title: 'Department',
            dataIndex: 'departmentId',
            key: 'departmentId',
            render: (val, row) => { return (row.departmentId && departments && departments[row.departmentId] ? departments[row.departmentId].name : 'N/A'); }
        },
        {
            title: 'Operator',
            dataIndex: 'operatorId',
            key: 'operatorId',
            render: (val, row) => { return (row.operatorId && operators && operators[row.operatorId] ? operators[row.operatorId].displayName : 'N/A'); }
        },
        {
            title: 'Current Page',
            dataIndex: 'currentPage',
            key: 'currentPage',
            className: 'currentPage',
            render: (val, row) => {
                let currentPage = null;
                currentPage = (
                    !row.visitor || !row.visitor.currentPage || (!row.visitor.currentPage.title && !row.visitor.currentPage.URL) ? (
                        <span>N/A</span>
                    ) : (
                        row.visitor.currentPage && row.visitor.currentPage.title && row.visitor.currentPage.URL && <div><a href={ row.visitor.currentPage.URL } target="_blank" rel="noopener noreferrer">{  row.visitor.currentPage.title }</a></div>
                    )
                );

                return currentPage;
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (val, row) => { return (row.createdAt ? moment.duration(moment().diff(moment(row.createdAt))).humanize() : 'N/A'); }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '280px',
            render: (val, row) => {
                return (
                    <>
                        { 
                            currentUser && (
                                <>
                                    <Button onClick={ () => { handleStartChat(row._id) } } className={ (row.operatorId ? 'btnDisabled' : 'btnAdd') + ' mr-1' } disabled={ row.operatorId ? true : false }>{ row.operatorId ? 'In Chat' : 'Chat' }</Button>

                                    { 
                                        (row.operatorId === currentUser._id || (row.operatorsJoined && Array.isArray(row.operatorsJoined) && row.operatorsJoined.includes(currentUser._id)) || (row.observers && Array.isArray(row.observers) && row.observers.includes(currentUser._id))) ? (
                                            <Button className="btnDisabled mr-1" disabled={ true }>Join</Button>
                                        ) : (
                                            <Button onClick={ () => { handleStartChat(row._id) } } className="btnSecondary mr-1">Join</Button>
                                        )
                                    }
                                    
                                    {
                                        (row.operatorId === currentUser._id || (row.operatorsJoined && Array.isArray(row.operatorsJoined) && row.operatorsJoined.includes(currentUser._id)) || (row.observers && Array.isArray(row.observers) && row.observers.includes(currentUser._id))) ? (
                                            <Button className="btnDisabled" disabled={ true }>Observe</Button>
                                        ) : (
                                            <Button onClick={ () => { handleObserveChat(row._id) } } className="btnSecondary">Observe</Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                );
            }
        }
    ];

    const onTabChange = (key) => {
        if(key === 'PendingChats') {
            history.push('/chat-queue/pending-chats');
        } else if(key === 'ActiveChats') {
            history.push('/chat-queue/active-chats');
        }
    }

    return (
        <>
            <Helmet>
                <title>Active Chats | Chat Queue | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (currentUser && departments && activeChats) ? (
                            <Tabs defaultActiveKey="ActiveChats" onChange={ onTabChange }>
                                <Tabs.TabPane tab="Pending" key="PendingChats">
                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Active" key="ActiveChats">
                                    <Form form={ activeChatsFiltersForm } initialValues={{}} name="activeChatsFiltersForm" onFinish={ onApplyActiveChatsFilter } layout="vertical" hideRequiredMark={ true }>
                                        <Row gutter={ 16 }>
                                            <Col xs={ 24 } sm={ 12 } xl={ 8 }>
                                                <Form.Item 
                                                    name="search"
                                                    label="Search by"
                                                >
                                                    <Input className="filterOptions searchBar" placeholder="Search by visitor name, username or IP address..." prefix={ <SearchOutlined /> } />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={ 24 } sm={ 8 } xl={ 4 }>
                                                <Form.Item 
                                                    name="operatorId"
                                                    label="Operator"
                                                >
                                                    <Select className="filterOptions">
                                                        <Select.Option key={ '' } value={ '' }></Select.Option>
                                                        { operators && Object.values(operators) && Object.values(operators).map(operator => <Select.Option key={ operator._id } value={ operator._id }>{ operator.displayName }</Select.Option>) }
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                                                <Form.Item>
                                                    <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                    <Button className="btnBack" onClick={ onResetActiveChatsFilters }>Reset</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    
                                    <Table rowKey="_id" columns={ columns } dataSource={ (activeChats ? Object.values(activeChats) : []) } pagination={ false } />

                                    { isActiveChatsLoading && <div style={{ padding: '20px 0 20px 0', textAlign: 'center' }}><Spin /></div> }
                                </Tabs.TabPane>
                            </Tabs>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
};

export default ChatQueueActiveChats;
