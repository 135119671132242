import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useParams, Link } from 'react-router-dom';

import Helmet from 'react-helmet';
import MainLayout from '../../../../../Components/Layouts/MainLayout';

import Error from '../../../../Error/Error';

import ChatlineAPI from '../../../../../utils/ChatlineAPI';

import { Table, Button, Modal, Form, Select, message, Spin, Row, Col, Input } from 'antd';
import { SearchOutlined, PlusCircleOutlined, DeleteOutlined, ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';

const Members = (props) => {
    const { departmentId } = useParams();

    const [department, setDepartment] = useState(null);
    const [membersDataset, setMembersDataset] = useState(null);
    const [membersParameters, updateMembersParameters] = useImmer(null);
    const [memberFieldDataSource, setMemberFieldDataSource] = useState(null);

    const [addMemberModalIsVisible, setAddMemberModalVisibility] = useState(false);
    const [addMemberErrors, setAddMemberErrors] = useState(null);

    const [filtersForm] = Form.useForm();
    const [addMemberForm] = Form.useForm();

    const [error, setError] = useState(null);

    const getMembers = useCallback((page = 1, filters = null) => {
        filters = (filters && Object.keys(filters).length > 0 ? Object.keys(filters).map(key => key + '=' + filters[key]).join('&') : '');

        ChatlineAPI.HttpGetRequest('departments/' + departmentId + '/members?page=' + page + (filters ? ('&'+filters) : ''), (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) setMembersDataset(res.data);
        })
    }, [departmentId]);

    const getMembersToAdd = useCallback(() => {
        ChatlineAPI.HttpGetRequest('departments/' + departmentId + '/members-to-add', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }
            
            if(res && res.data && res.data.members) {
                let membersToAdd = Object.values(res.data.members);
                if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.length > 0) {
                    let memberFieldDataSource = [];
                    for(let member of membersToAdd) {
                        memberFieldDataSource.push({ value: member._id, text: member.displayName });
                    }

                    setMemberFieldDataSource(memberFieldDataSource);
                }
            }
        })
    }, [departmentId]);

    useEffect(() => {
        if(departmentId) {
            ChatlineAPI.HttpGetRequest('departments/' + departmentId, (err, res) => {
                if(err) {
                    setError(<Error status={ (err.status ? err.status : 500) } />);
                    return;
                }

                if(res && res.data && res.data.department) { setDepartment(res.data.department); }
            });
        }
    }, [departmentId]);

    useEffect(() => {
        if(departmentId) {
            if(membersParameters) {
                getMembers((membersParameters.page || 1), (membersParameters.filters || null));
                getMembersToAdd();
            }
        }
    }, [departmentId, membersParameters, getMembers, getMembersToAdd]);

    useEffect(() => {
        if(departmentId) {
            updateMembersParameters(membersParameters => {
                return { page: 1, filters: null };
            });
        }
    }, [departmentId, updateMembersParameters]);

    const handleMembersTableChange = (pagination, filters, sorter) => {
        updateMembersParameters(membersParameters => {
            membersParameters.page = 1;
        });
    }

    const onApplyFilter = (values) => {
        if(!values) {
            updateMembersParameters(membersParameters => {
                membersParameters.page = 1;
                membersParameters.filters = null;
            });
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            if(values[key]) filters[key] = (''+values[key]).trim();
        }
        
        updateMembersParameters(membersParameters => {
            membersParameters.page = 1;
            membersParameters.filters = filters;
        });
    }

    const onResetFields = () => {
        filtersForm.resetFields();

        updateMembersParameters(membersParameters => {
            membersParameters.page = 1;
            membersParameters.filters = null;
        });
    }

    const onMemberFieldFilter = (query, option) => {
        if(option.props.children.toLowerCase().includes(query.toLowerCase())) return true;
        return false;
    };

    const handleAddMember = values => {
        ChatlineAPI.HttpPostRequest('departments/' + departmentId + '/members/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setAddMemberErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.'); return;
            }

            if(res.data && res.data.result === true) {
                setAddMemberModalVisibility(false);

                addMemberForm.resetFields();

                updateMembersParameters(membersParameters => {
                    return { ...membersParameters };
                });
            }
        });
    };

    const handleDeleteMember = (memberId, displayName) => {
        Modal.confirm({
            title: 'Member: ' + displayName,
            content: 'Are you sure you want to remove the selected member from this department?',
            onOk() {
                ChatlineAPI.HttpPostRequest('departments/' + departmentId + '/members/' + memberId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.'); return;
                    }
        
                    if(res.data && res.data.result === true) {
                        message.success('Selected record has successfully been deleted.');

                        updateMembersParameters(membersParameters => {
                            return { ...membersParameters };
                        });

                        return;
                    }
                });
            },
            onCancel() {
            },
        });
    }

    const columns = [
        {
            title: 'Display Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '160px',
            render: (val, row) => {
                return (
                    <>
                        { (row.status !== -1 ? <Button className="btnDelete btnInline" onClick={ () => handleDeleteMember(row._id, row.displayName) }><DeleteOutlined /> Delete</Button> : null) }
                    </>
                );
            }
        }
    ];

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Members | Department | Departments | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (department) ? (
                            <>
                                <div style={{ margin: '0 0 16px 0' }}>
                                    <Button className="btnAdd" size="medium" onClick={ () => { setAddMemberModalVisibility(true) } } style={{ float: 'right' }}><PlusCircleOutlined /> Add Member</Button>
                                    <Link to="/control-panel/departments"><Button className="btnBack" size="medium" onClick={ () => {} }><ArrowLeftOutlined /> Departments</Button></Link>
                                </div>

                                <Form form={ filtersForm } initialValues={{}} onFinish={ onApplyFilter } layout="vertical" hideRequiredMark={ true }>
                                    <Row gutter={ 16 }>
                                        <Col xs={ 24 } sm={ 8 } xl={ 4 }>
                                            <Form.Item 
                                                name="search"
                                                label="Search by"
                                            >
                                                <Input className="searchBar filterOptions" placeholder="Display name..." prefix={ <SearchOutlined /> } />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                                            <Form.Item 
                                                name="limit"
                                                label="Show"
                                                initialValue={ 25 }
                                            >
                                                <Select className="filterOptions">
                                                    <Select.Option value={ 10 }>10</Select.Option>
                                                    <Select.Option value={ 25 }>25</Select.Option>
                                                    <Select.Option value={ 50 }>50</Select.Option>
                                                    <Select.Option value={ 100 }>100</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                                            <Form.Item>
                                                <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                <Button className="btnBack" onClick={ onResetFields }>Reset</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Table rowKey="_id" columns={ columns } pagination={ (membersDataset && membersDataset.pagination ? membersDataset.pagination : {}) } dataSource={ (membersDataset && membersDataset.members ? Object.values(membersDataset.members) : []) } onChange={ handleMembersTableChange } />

                                <Modal
                                    visible={ addMemberModalIsVisible }
                                    onCancel={ () => { setAddMemberModalVisibility(false) } }
                                    footer={ null }
                                >
                                    <h1>Add Member</h1>

                                    <Form form={ addMemberForm } onFinish={ handleAddMember } layout="horizontal" hideRequiredMark={ true }>
                                        <Form.Item
                                            name="memberId"
                                            label="Member"
                                            validateStatus={ addMemberErrors && addMemberErrors.memberId ? "error" : null }
                                            help={ addMemberErrors && addMemberErrors.memberId ? addMemberErrors.memberId : null }
                                        >
                                            <Select
                                                showSearch={ true }
                                                labelInValue={ false }
                                                notFoundContent={null}
                                                showArrow={ false }
                                                filterOption={ onMemberFieldFilter }
                                                style={{ width: '100%' }}
                                            >
                                                {
                                                    memberFieldDataSource && Array.isArray(memberFieldDataSource) && memberFieldDataSource.length > 0 && memberFieldDataSource.map(d => (
                                                        <Select.Option key={ d.value }>{ d.text }</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Members;