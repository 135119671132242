import React from 'react';

import Helmet from 'react-helmet';
import classes from './Error.module.css';

const Error = (props) => {
    return (
        <>
            <Helmet>
                <title>Error | { process.env.REACT_APP_NAME }</title>
            </Helmet>
            
            <div className={ classes.errorLayout }>
                {
                    (props.status !== null) ? (
                        <span className={ classes.status }>{ props.status }</span>
                    ) : (
                        <span className={ classes.status }>500</span>
                    )
                }

                {
                    (parseInt(props.status) === 400) ? (
                        <span className={ classes.message }>Bad Request</span>
                    ) : (parseInt(props.status) === 401 || parseInt(props.status) === 403) ? (
                        <span className={ classes.message }>Unauthorized</span>
                    ) : (parseInt(props.status) === 404) ? (
                        <span className={ classes.message }>Not Found</span>
                    ) : (
                        <span className={ classes.message }>Internal Server Error</span>
                    )
                }
            </div>
        </>
    );
}

export default Error;