import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { useParams} from 'react-router';
import { useImmer } from "use-immer";
import { usePrevious } from '../../hooks/custom';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import string2Hex from '../../utils/String2Hex';
import ChatlineAPI from '../../utils/ChatlineAPI';

import { v4 as uuid } from 'uuid';
import Linkify from 'react-linkify';
import nl2br from 'react-nl2br';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';
import CardWithShadow from '../../Components/Cards/CardWithShadow';
import './Operators.less';

import { Link, useHistory } from 'react-router-dom';
import { Spin, Typography, Row, Col, Avatar, Badge, Tabs, Form, Input, Button, Upload, Progress, message, Modal, Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, SendOutlined, LoadingOutlined, SmileOutlined, PaperClipOutlined, EyeOutlined, UpOutlined, DownloadOutlined, FileOutlined, UserOutlined, ArrowLeftOutlined, FontSizeOutlined, ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import newMessageAudioAsset from '../../assets/audio/new-message.mp3';

var moment = require('moment');
const { Text } = Typography;

const Operators = (props) => {
    const { 
        currentUser, updateCurrentUserParameter,
        systemVariables,
        isSocketConnected,
        operatorsBadgeCountChatIds, updateOperatorsBadgeCountChatIds
    } = useSystemContext();

    const availabilityOptions = (systemVariables && systemVariables.user && systemVariables.user.availabilityOptions ? systemVariables.user.availabilityOptions : null);
    const chatRoomFontSizeOptions = (systemVariables && systemVariables.user && systemVariables.user.chatRoomFontSizeOptions ? systemVariables.user.chatRoomFontSizeOptions : null);
    
    const [operators, setOperators] = useState(null);
    const [onlineOperators, updateOnlineOperators] = useImmer(null);
    const [chats, updateChats] = useImmer(null);

    const [initialised, setInitialised] = useState(false);

    const { activeChatId } = useParams();
    const prevActiveChatId = usePrevious(activeChatId);
    
    const [scrollChatLogToBottomActivated, setScrollChatLogToBottomActivated] = useState(true);
    const messageDraftsByChatId = useRef({});
    const latestChatHistoryRequestTimestamp = useRef(null);
    const [messagesPagination, setMessagesPagination] = useState(null);
    const [messages, updateMessages] = useImmer(null);
    const [messageToBeEdited, setMessageToBeEdited] = useState(null);
    const [sendMessageFormEmojiPickerVisible, setSendMessageFormEmojiPickerVisible] = useState(false);
    const [editMessageFormEmojiPickerVisible, setEditMessageFormEmojiPickerVisible] = useState(false);
    const [uploads, updateUploads] = useImmer(null);

    const [startNewMessageContainerIsVisible, setStartNewMessageContainerIsVisible] = useState(false);

    const [fontSizeSelection, setFontSizeSelection] = useState(null);
    const [sendMessageForm] = Form.useForm();
    const [editMessageForm] = Form.useForm();

    let newMessageAudioRef = useRef(null);

    const history = useHistory();

    const updateLastSeenBy = useCallback((chatId) => {
        socket.emit('operator.internalChats.chat.updateLastSeenBy', chatId);

        updateChats(chats => {
            if(chats && chats[chatId]) {
                chats[chatId].unseenMessagesCount = 0;
            }
        });
    }, [updateChats]);

    const handleChatHistoryCallback = useCallback((response) => {
        if(response.chat && response.chat._id && ''+response.chat._id !== ''+activeChatId) return;

        if(response.requestTimestamp && latestChatHistoryRequestTimestamp.current && response.requestTimestamp < latestChatHistoryRequestTimestamp.current) {
            return;
        }

        if(response.pagination) {
            setMessagesPagination(response.pagination);
        }

        if(response.pagination && response.pagination.current && parseInt(response.pagination.current) === 1) {
            updateMessages(messages => (response.history ? response.history : {}));
            updateLastSeenBy(activeChatId);
        } else {
            if(response.history) {
                updateMessages(messages => (messages ? { ...response.history, ...messages } : { ...response.history }));
                setTimeout(setScrollChatLogToBottomActivated.bind(null, true), 1000);
            }
        }
    }, [activeChatId, latestChatHistoryRequestTimestamp, updateMessages, updateLastSeenBy]);

    const scrollChatLogToBottom = useCallback(() => {
        if(!scrollChatLogToBottomActivated) return;

        let chatLog = document.getElementById('chat-log');
        if(!chatLog) chatLog = (document.getElementsByClassName('chat-log') ? document.getElementsByClassName('chat-log')[0] : null);
        if(!chatLog) return;
        
        chatLog.scrollTop = chatLog.scrollHeight;
    }, [scrollChatLogToBottomActivated]);

    
    useEffect(() => {
        if(activeChatId && messages) {
            setTimeout(scrollChatLogToBottom, 100);
        }
    }, [activeChatId, messages, scrollChatLogToBottom]);

    useEffect(() => {
        if(activeChatId) {
            setTimeout(() => {
                const selector = document.getElementById('sendMessage_message');
                if(selector) selector.focus();
            }, 100);
        }
    }, [activeChatId]);

    useEffect(() => {
        if(isSocketConnected) {
            setTimeout(() => {
                socket.emit('operator.chats.operatorsBadgeCountChatIds.get');
            }, 1000);
            
            socket.emit('operator.operators.all.get', null, (ack) => {
                setOperators((ack && ack.result && ack.operators) || {});
            });

            socket.emit('operator.operators.online.get', null, (ack) => {
                updateOnlineOperators(onlineOperators => (ack && ack.result && ack.onlineOperators) || {});
            });

            socket.off('operator.operators.operator.connected').on('operator.operators.operator.connected', (pOperator) => {
                updateOnlineOperators(onlineOperators => {
                    if(!onlineOperators) onlineOperators = {};
                    onlineOperators[pOperator.userId] = { ...pOperator };
                });
            });

            socket.off('operator.operators.operator.away').on('operator.operators.operator.away', (pOperator) => {
                updateOnlineOperators(onlineOperators => {
                    if(!onlineOperators) onlineOperators = {};
                    onlineOperators[pOperator.userId] = { ...pOperator };
                });
            });

            socket.off('operator.operators.operator.disconnected').on('operator.operators.operator.disconnected', (pOperator) => {
                updateOnlineOperators(onlineOperators => {
                    if(onlineOperators && Object.keys(onlineOperators).length > 0) {
                        if(onlineOperators[pOperator.userId]) {
                            delete onlineOperators[pOperator.userId];
                            if(!onlineOperators) onlineOperators = {};
                        }
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('operator.operators.operator.connected');
            socket.off('operator.operators.operator.away');
            socket.off('operator.operators.operator.disconnected');
        }
    }, [isSocketConnected, updateOnlineOperators]);

    const loadInternalChats = useCallback(() => {
        socket.emit('operator.internalChats.get', null, (ack) => {
            updateChats(chats => (ack && ack.result && ack.chats) || {});
            setInitialised(true);
        });
    }, [updateChats]);

    useEffect(() => {
        if(isSocketConnected) {
            loadInternalChats();
        }
    }, [isSocketConnected, loadInternalChats]);

    useEffect(() => {
        if(!initialised && operators && onlineOperators && chats) {
            setInitialised(true);
        }
    }, [initialised, operators, onlineOperators, chats]);

    useEffect(() => {
        if(isSocketConnected && initialised && activeChatId) {
            updateMessages(messages => null);

            let requestTimestamp = moment().format('x');
            latestChatHistoryRequestTimestamp.current = requestTimestamp;

            socket.emit('operator.internalChats.chat.history', { chatId: activeChatId, page: 1, timestamp: requestTimestamp }, (ack) => {
                if(ack && ack.result && ack.response) {
                    handleChatHistoryCallback(ack.response);
                }
            });
        }
    }, [isSocketConnected, initialised, activeChatId, updateMessages, handleChatHistoryCallback]);

    useEffect(() => {
        if(isSocketConnected) {
            if(prevActiveChatId && prevActiveChatId !== activeChatId) {
                updateLastSeenBy(prevActiveChatId);

                updateMessages(messages => null);
            }

            /*
            // Handled in handleChatHistoryCallback
            if(activeChatId && prevActiveChatId !== activeChatId) {
                updateLastSeenBy(activeChatId);
            }
            */
        }
    });

    useEffect(() => {
        if(chats) {
            updateOperatorsBadgeCountChatIds(operatorsBadgeCountChatIds => {
                if(operatorsBadgeCountChatIds && operatorsBadgeCountChatIds.internalChats && Object.keys(operatorsBadgeCountChatIds.internalChats).length > 0) {
                    let nInternalChatIds = {};

                    for(let iChatId of Object.keys(operatorsBadgeCountChatIds.internalChats)) {
                        if(chats && chats[iChatId] && chats[iChatId].unseenMessagesCount && parseInt(chats[iChatId].unseenMessagesCount) > 0) {
                            nInternalChatIds[''+iChatId] = 1;
                        }
                    }

                    return { ...operatorsBadgeCountChatIds, internalChats: nInternalChatIds };
                }
            });
        }
    }, [chats, updateOperatorsBadgeCountChatIds]);

    useEffect(() => {
        if(operators && chats) {
            for(let chatId of Object.keys(chats)) {
                let operatorIds = chats[chatId].operatorIds;

                // eslint-disable-next-line no-unused-vars
                const [operator1Id, operator2Id, ...rest] = operatorIds;

                let operatorId = (operator1Id+'' !== currentUser._id+'' ? operator1Id : operator2Id);
                let operator = (operators && operators[operatorId] ? operators[operatorId] : null);

                if(operator) {
                    updateChats(chats => {
                        if(chats && chats[chatId]) {
                            chats[chatId].operator = operator;
                        }
                    });
                }
            }
        }
    }, [operators, chats, currentUser, updateChats]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('operator.internalChats.chat.new').on('operator.internalChats.chat.new', (chat) => {
                if(!chat || !chat._id) return;

                updateChats(chats => {                        
                    let nChats = (chats ? { ...chats } : {});
                    if(!nChats[chat._id]) {
                        let nChat = {};
                        nChat[chat._id] = chat;

                        nChats = { ...nChat, ...nChats };
                        return nChats;
                    }
                });
            });

            socket.off('operator.internalChats.chat.update').on('operator.internalChats.chat.update', (chat) => {
                if(!chat || !chat._id) return;

                updateChats(chats => {
                    if(chats && chats[chat._id]) {
                        chats[chat._id] = chat;
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('operator.internalChats.chat.new');
            socket.off('operator.internalChats.chat.update');
        }
    }, [isSocketConnected, updateChats]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('internalChats.chat.message').on('internalChats.chat.message', (chatMessage) => {
                if(chatMessage && chatMessage.messageBy && !(chatMessage.messageBy.userType === 'operator' && chatMessage.messageBy.userId === currentUser._id)) {
                    newMessageAudioRef && newMessageAudioRef.current && newMessageAudioRef.current.play().catch(e => { console.log(e) });
                }

                if(activeChatId && ''+chatMessage.chatId === ''+activeChatId) {
                    updateMessages(messages => {
                        if(!messages) return { [chatMessage._id]: chatMessage };
                        messages[chatMessage._id] = chatMessage;
                    });
                }
                
                updateChats(chats => {
                    if(!chats || !chats[chatMessage.chatId]) {
                        loadInternalChats(); // causes reload!!!
                    } else {
                        if(chats && chats[chatMessage.chatId]) {
                            if(!activeChatId || ''+chatMessage.chatId !== ''+activeChatId) {
                                if(!chats[chatMessage.chatId].unseenMessagesCount) chats[chatMessage.chatId].unseenMessagesCount = 0;
                                chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                            }
                            chats[chatMessage.chatId].latestMessage = chatMessage;
                            chats[chatMessage.chatId].updatedAt = Date.now();
                        }
                    }
                });

                if(!activeChatId || ''+chatMessage.chatId !== ''+activeChatId) {
                    updateOperatorsBadgeCountChatIds(operatorsBadgeCountChatIds => {
                        if(!operatorsBadgeCountChatIds || !operatorsBadgeCountChatIds.internalChats) {
                            let nOperatorsBadgeCountChatIds = (operatorsBadgeCountChatIds ? { ...operatorsBadgeCountChatIds } : {});
    
                            if(!nOperatorsBadgeCountChatIds.internalChats) nOperatorsBadgeCountChatIds.internalChats = {};
                            if(!nOperatorsBadgeCountChatIds.groupChats) nOperatorsBadgeCountChatIds.groupChats = {};
                            
                            nOperatorsBadgeCountChatIds.internalChats[''+chatMessage.chatId] = 1;
    
                            return nOperatorsBadgeCountChatIds;
                        }
    
                        if(!operatorsBadgeCountChatIds.internalChats[''+chatMessage.chatId]) operatorsBadgeCountChatIds.internalChats[''+chatMessage.chatId] = 1;
                    });
                }
            });
        }

        return function cleanup() {
            socket.off('internalChats.chat.message');
        }
    }, [isSocketConnected, chats, activeChatId, currentUser, loadInternalChats, updateChats, updateMessages, updateOperatorsBadgeCountChatIds]);

    useEffect(() => {
        if(chats && activeChatId) {
            if(!chats[activeChatId]) {
                history.push('/operators');
            }
        }
    }, [chats, activeChatId, history]);

    useEffect(() => {
        if(sendMessageForm && sendMessageForm.__INTERNAL__ && sendMessageForm.__INTERNAL__.name) {
            if(activeChatId) sendMessageForm.setFieldsValue({ message: (messageDraftsByChatId && messageDraftsByChatId.current && messageDraftsByChatId.current[activeChatId] ? messageDraftsByChatId.current[activeChatId] : '') });
        }
    }, [sendMessageForm, activeChatId, messageDraftsByChatId]);

    const loadMoreMessages = useCallback((page) => {
        setScrollChatLogToBottomActivated(false);

        let requestTimestamp = moment().format('x');
        latestChatHistoryRequestTimestamp.current = requestTimestamp;

        socket.emit('operator.internalChats.chat.history', { chatId: activeChatId, page: page, timestamp: requestTimestamp }, (ack) => {
            if(ack && ack.result && ack.response) {
                handleChatHistoryCallback(ack.response);
            }
        });
    }, [activeChatId, handleChatHistoryCallback]);

    const onTabChange = (key) => {
        if(key === 'Operators') {
            history.push('/operators/chats');
        } else if(key === 'Channels') {
            history.push('/operators/channels');
        }
    }

    const getBadgeStatusByOperatorId = (operatorId) => {
        if(operatorId && onlineOperators && onlineOperators[operatorId] && onlineOperators[operatorId] && onlineOperators[operatorId].availability) {
            if(availabilityOptions) {
                if(availabilityOptions[onlineOperators[operatorId].availability] === 'Online') {
                    return 'success'
                } else if(availabilityOptions[onlineOperators[operatorId].availability] === 'Away') {
                    return 'warning'
                }
            }
        }
        
        return 'error';
    }

    const handleOnStartChat = () => {
        setStartNewMessageContainerIsVisible(true);
    }

    const handleStartChatWithOperator = (operatorId) => {
        socket.emit('operator.internalChats.chat.start', operatorId, (ack) => {
            if(ack && ack.result && ack.chat) {
                setStartNewMessageContainerIsVisible(false);

                socket.emit('operator.internalChats.get', null, (ack2) => {
                    if(ack2 && ack2.result && ack2.chats) {
                        updateChats(chats => ack2.chats);
                        history.push('/operators/chats/' + ack.chat._id);
                    }
                });
            }
        });
    };

    const createdAt2When = (createdAt) => {
        createdAt = moment(createdAt);
        let when = createdAt.format('HH:mm');

        if(moment().startOf('day') > createdAt) {
            when = createdAt.fromNow();
            if(when === '1 day ago') when = 'Yesterday';
        }

        return when;
    }

    const editMessageForm_addEmoji = (emoji) => {
        editMessageForm.setFieldsValue({
            message: (editMessageForm.getFieldValue('message') ? editMessageForm.getFieldValue('message') : '') + emoji.native
        });
    }

    const sendMessageForm_addEmoji = (emoji) => {
        sendMessageForm.setFieldsValue({
            message: (sendMessageForm.getFieldValue('message') ? sendMessageForm.getFieldValue('message') : '') + emoji.native
        });
    }

    const beforeUpload = (file, fileList) => {
        return true;
    }
    
    const [attachmentToDisplay, setAttachmentToDisplay] = useState(null);
    const viewFile = (attachment) => {
        setAttachmentToDisplay(attachment);
    }

    const downloadFile = async (clientId, chatId, fileName, type, originalFileName) => {
        ChatlineAPI.FileReader(clientId, 'internal-chats/' + chatId + '/attachments/' + fileName, type, originalFileName, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
        });
    }

    const sendMessage_onKeyDown = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendMessageForm.submit();
        }
    }

    const editMessage_onKeyDown = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            editMessageForm.submit();
        }
    }

    const ackWithTimeout = (onSuccess, onTimeout, timeout) => {
        let called = false;

        const timer = setTimeout(() => {
            if(called) return;
            called = true;
            onTimeout();
        }, timeout);

        return (...args) => {
            if(called) return;
            called = true;
            clearTimeout(timer);
            onSuccess.apply(this, args);
        }
    }
    
    const emitOperatorChatMessage = (pData, timeout = false) => {
        if(timeout && parseInt(timeout) > 10000) {
            updateMessages(messages => {
                if(messages && messages[pData.tempChatMessageId]) {
                    messages[pData.tempChatMessageId].isFailed = true;
                }
            });

            return;
        }

        const successCallback = (ack) => {
            if(ack && ack.result && ack.chatMessageId && ack.tempChatMessageId) {
                updateMessages(messages => {
                    if(messages && messages[ack.tempChatMessageId]) {
                        messages[ack.tempChatMessageId].isSent = true;
                        messages[ack.chatMessageId] = { ...messages[ack.tempChatMessageId], _id: ack.chatMessageId };
                        delete messages[ack.tempChatMessageId];
                    }
                });
            }
            
            setIsFormSubmitting(false);
        };

        if(!timeout || parseInt(timeout) <= 0) {
            socket.emit('operator.internalChats.chat.message', pData, successCallback);
            return;
        }

        const timeoutCallback = () => {
            emitOperatorChatMessage(pData, parseInt(timeout * 1.25));
        }

        socket.emit('operator.internalChats.chat.message', pData, ackWithTimeout(successCallback, timeoutCallback, timeout));
    }

    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const onSendMessage = (values) => {
        if(isFormSubmitting) return;
        setIsFormSubmitting(true);

        let message = sendMessageForm.getFieldValue('message');
        if(!message) { setIsFormSubmitting(false); return; }

        message = message.trim();
        if(!message) { setIsFormSubmitting(false); return; }

        if(messageDraftsByChatId && messageDraftsByChatId.current && messageDraftsByChatId.current[activeChatId]) messageDraftsByChatId.current[activeChatId] = '';
        sendMessageForm.setFieldsValue({ message: '' });

        let chatMessage = {
            _id: uuid(),
            chatId: activeChatId,
            messageType: (systemVariables && systemVariables.internalChat && systemVariables.internalChat.messageTypes && Object.keys(systemVariables.internalChat.messageTypes).find(key => systemVariables.internalChat.messageTypes[key] === 'Text')),
            messageBy: { userType: 'operator', userId: currentUser._id, name: currentUser.displayName },
            message: message,
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
        };

        updateMessages(messages => {
            if(!messages) return { [chatMessage._id]: chatMessage };
            messages[chatMessage._id] = chatMessage;
        });

        updateChats(chats => {
            if(chats && chats[chatMessage.chatId]) {
                if(''+chatMessage.chatId !== ''+activeChatId) chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                chats[chatMessage.chatId].latestMessage = chatMessage;
                chats[chatMessage.chatId].updatedAt = Date.now();
            }
        });

        emitOperatorChatMessage({ chatId: activeChatId, message: chatMessage.message, tempChatMessageId: chatMessage._id }, 5000);
    }

    useEffect(() => {
        if(currentUser) {
            if(currentUser.parameters && currentUser.parameters.preferredFontSizeForChatRoom) {
                setFontSizeSelection(currentUser.parameters.preferredFontSizeForChatRoom);
            }
        }
    }, [currentUser]);

    useEffect(() => {
        socket.on('internalChats.chat.message.edit', (chatMessage) => {
            updateMessages(messages => {
                if(!messages) return { [chatMessage._id]: chatMessage };
                messages[chatMessage._id] = chatMessage;
            });
        });
    
        socket.on('internalChats.chat.message.delete', (chatMessage) => {
            updateMessages(messages => {
                if(!messages) return { [chatMessage._id]: chatMessage };
                messages[chatMessage._id] = chatMessage;
            });
        });

        return function cleanup() {
            socket.off('internalChats.chat.message.edit');
            socket.off('internalChats.chat.message.delete');
        }
    }, [isSocketConnected, updateMessages])

    useLayoutEffect(() => {
        setTimeout(function() {
            const selector = document.getElementById('editMessage_message');
            if(selector) selector.setSelectionRange(selector.value.length, selector.value.length);
        }, 1);
    }, [messageToBeEdited])

    const handleEditMessageButtonClicked = (chatId, messageId, message) => {
        setMessageToBeEdited(messageId);

        editMessageForm.setFieldsValue({ message: message });
    }

    const handleEditMessage = (chatId, messageId) => {
        socket.emit('operator.internalChats.chat.message.edit', { chatId: chatId, messageId: messageId, message: editMessageForm.getFieldValue("message") }, (ack) => {
            if(ack && ack.result === true && ack.chatMessage) {
                let chatMessage = ack.chatMessage;

                updateMessages(messages => {
                    if(!messages) return { [chatMessage._id]: chatMessage };
                    messages[chatMessage._id] = chatMessage;
                });
            }
        })

        setMessageToBeEdited(null);
    }

    const handleDeleteMessage = (chatId, messageId) => {
        Modal.confirm({
            title: 'Delete message',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure that you want to delete this record?',
            onOk() {
                socket.emit('operator.internalChats.chat.message.delete', { chatId: chatId, messageId: messageId }, (ack) => {
                    if(ack && ack.result === true && ack.chatMessage) {
                        let chatMessage = ack.chatMessage;

                        updateMessages(messages => {
                            if(!messages) return { [chatMessage._id]: chatMessage };
                            messages[chatMessage._id] = chatMessage;
                        });
                    }
                })
            },
            onCancel() {},
        });
    }

    const handleFontSizeSelection = (fontSizeOption) => {
        updateCurrentUserParameter('preferredFontSizeForChatRoom', fontSizeOption, () => {
            setFontSizeSelection(fontSizeOption);
        });
    }

    const onFileUploadSuccess = (response) => {
        let chatMessage = response.chatMessage || null;
        if(!chatMessage) return;

        chatMessage.messageBy = { userType: 'operator', userId: currentUser._id, name: currentUser.displayName };

        updateMessages(messages => {
            if(!messages) return { [chatMessage._id]: chatMessage };
            messages[chatMessage._id] = chatMessage;
        });

        updateChats(chats => {
            if(chats && chats[chatMessage.chatId]) {
                if(''+chatMessage.chatId !== ''+activeChatId) chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                chats[chatMessage.chatId].latestMessage = chatMessage;
                chats[chatMessage.chatId].updatedAt = Date.now();
            }
        });

        socket.emit('operator.internalChats.chat.message', { ...chatMessage }, (ack) => {
            if(ack && ack.result && ack.tempChatMessageId) {
                updateMessages(messages => {
                    if(messages && messages[ack.tempChatMessageId]) {
                        messages[ack.tempChatMessageId].isSent = true;
                    }
                });
            }
        });
    }

    const onFileUploadError = (err = null) => {
        let chatMessage = {
            _id: uuid(),
            chatId: activeChatId,
            messageType: (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && Object.keys(systemVariables.chat.messageTypes).find(key => systemVariables.chat.messageTypes[key] === 'Error')),
            messageBy: { userType: 'operator', userId: currentUser._id, name: currentUser.displayName },
            message: (err && err.message ? err.message : 'An unknown error occured while uploading the file. Please try again.'),
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
        };

        updateMessages(messages => {
            if(!messages) return { [chatMessage._id]: chatMessage };
            messages[chatMessage._id] = chatMessage;
        });

        updateChats(chats => {
            if(chats && chats[chatMessage.chatId]) {
                if(''+chatMessage.chatId !== ''+activeChatId) chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                chats[chatMessage.chatId].latestMessage = chatMessage;
                chats[chatMessage.chatId].updatedAt = Date.now();
            }
        });

        socket.emit('operator.internalChats.chat.message', { chatId: chatMessage.chatId, messageType: chatMessage.messageType, message: chatMessage.message, tempChatMessageId: chatMessage._id }, (ack) => {
            if(ack && ack.result && ack.tempChatMessageId) {
                updateMessages(messages => {
                    if(messages && messages[ack.tempChatMessageId]) {
                        messages[ack.tempChatMessageId].isSent = true;
                    }
                });
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Operators | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (currentUser && operators && chats) ? (
                            <>
                                <div className="page-Operators">
                                    <Row type="flex" gutter={ 8 }>
                                        <Col span={ 6 } style={{ height: 'calc(100vh - 160px)' }}>
                                            <CardWithShadow style={{ overflow: 'auto', height: '100%' }}>
                                                <div className="py-2 px-3">
                                                    <Tabs defaultActiveKey="Operators" onChange={ onTabChange }>
                                                        <Tabs.TabPane tab={ 'Operators' + (operatorsBadgeCountChatIds && operatorsBadgeCountChatIds.internalChats && Array.isArray(operatorsBadgeCountChatIds.internalChats) && operatorsBadgeCountChatIds.internalChats.length > 0 ? ' ['+operatorsBadgeCountChatIds.internalChats.length+']' : '') } key="Operators">
                                                            <Button className={ 'btn-start-chat' } onClick={ handleOnStartChat }><EditOutlined /> Start a new chat</Button>

                                                            <section className="chat-list pt-2 pb-2">
                                                                {
                                                                    (chats && Object.keys(chats).length > 0) ? (
                                                                        <>
                                                                            {
                                                                                Object.keys(chats).map((chatId) => {
                                                                                    
                                                                                    let chat = chats[chatId];
                                                                                    let operatorIds = chat.operatorIds;

                                                                                    // eslint-disable-next-line no-unused-vars
                                                                                    const [operator1Id, operator2Id, ...rest] = operatorIds;

                                                                                    let operatorId = (operator1Id+'' !== currentUser._id+'' ? operator1Id : operator2Id);
                                                                                    let operator = (operators && operators[operatorId] ? operators[operatorId] : null);

                                                                                    return (
                                                                                        operator && (
                                                                                            <Link key={ chat._id } to={ '/operators/chats/' + chat._id }>
                                                                                                <div className={ 'chat-list-item'+(chat._id === activeChatId ? ' active' : '') }>
                                                                                                    <div className="avatar-container"><Badge dot status={ getBadgeStatusByOperatorId(operator._id) }><Avatar style={{ background: string2Hex(operator.displayName), color: '#000' }} size={ 35 } className="avatar">{ operator.displayName.substring(0, 1).toUpperCase() }</Avatar></Badge></div>
                                                                                    
                                                                                                    <div className="container">
                                                                                                        <div className="row1">
                                                                                                            <Text ellipsis className="username">{ operator.displayName }</Text>
                                                                                                            <Text className="time">{ chat.latestMessage && createdAt2When(chat.latestMessage.createdAt) }</Text>
                                                                                                        </div>
                                                                                                        <div className="row2">
                                                                                                            <Text ellipsis className="message">{ chat.latestMessage && chat.latestMessage.message ? chat.latestMessage.message : (chat.latestMessage && chat.latestMessage.attachment && chat.latestMessage.attachment.originalFileName ? chat.latestMessage.attachment.originalFileName : '') }</Text>
                                                                                                            <div className="badge-container"><Badge className="badge" count={ (chat.unseenMessagesCount ? chat.unseenMessagesCount : 0) } style={{ backgroundColor: 'rgba(98,70,234, 1)', color: '#ffffff' }} /></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style={{ clear: 'both' }}></div>
                                                                                                </div>
                                                                                            </Link>
                                                                                        )
                                                                                    );
                                                                                })
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <span style={{ fontWeight: 300, color: '#333' }}>No active chats found!</span>
                                                                    )
                                                                }
                                                            </section>
                                                        </Tabs.TabPane>

                                                        <Tabs.TabPane tab={ 'Channels' + (operatorsBadgeCountChatIds && operatorsBadgeCountChatIds.groupChats && Array.isArray(operatorsBadgeCountChatIds.groupChats) && operatorsBadgeCountChatIds.groupChats.length > 0 ? ' ['+operatorsBadgeCountChatIds.groupChats.length+']' : '') } key="Channels">
                                                        </Tabs.TabPane>
                                                    </Tabs>
                                                </div>
                                            </CardWithShadow>

                                            { startNewMessageContainerIsVisible && (
                                                <div className="start-new-message-container">
                                                    <CardWithShadow style={{ overflow: 'auto', height: '100%' }}>
                                                        <div className="py-2 px-3">
                                                            <div className="section-title pt-1">
                                                                <h4><Button className="btn-back" onClick={ () => { setStartNewMessageContainerIsVisible(false); } }><ArrowLeftOutlined /></Button> Start a new chat</h4>
                                                            </div>

                                                            <div className="operator-list">
                                                                {
                                                                    operators && Object.values(operators).map((operator) => {
                                                                        if(operator._id === currentUser._id) return '';

                                                                        return (
                                                                            <div key={ 'operator-' + operator._id } className="operator" onClick={ () => { handleStartChatWithOperator(operator._id) } }>
                                                                                <Badge dot status={ getBadgeStatusByOperatorId(operator._id) }><Avatar style={{ background: string2Hex(operator.displayName), color: '#000' }} size={ 35 } className="avatar">{ operator.displayName.substring(0, 1).toUpperCase() }</Avatar></Badge>
                                                                                <Text ellipsis className="username">{ operator.displayName }</Text>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </CardWithShadow>
                                                </div>
                                            )}
                                        </Col>

                                        {
                                            (systemVariables && activeChatId && chats && chats[activeChatId] && chats[activeChatId].operator) && (
                                                <>
                                                    <Col span={ 13 } style={{ height: 'calc(100vh - 160px)' }}>
                                                        <CardWithShadow>
                                                            {
                                                                (systemVariables && activeChatId && chats && chats[activeChatId]) ? (

                                                                    <section className="chat-container">
                                                                        <div className="section-title pt-2 pb-2 px-3">
                                                                            <h4>{ chats[activeChatId].operator.displayName }</h4>
                                                                        </div>

                                                                        <div className="chat-log-wrapper">
                                                                            <div id="chat-log" className="chat-log">
                                                                                <>
                                                                                    <div style={{ textAlign: 'center' }}>
                                                                                        { messagesPagination && messagesPagination.current < Math.ceil(messagesPagination.total / messagesPagination.pageSize) && <Button className="btnSecondary mr-1" onClick={ () => { loadMoreMessages(((messagesPagination && messagesPagination.current + 1) || 1)); } }><UpOutlined /> Load Previous Messages</Button> }

                                                                                        {
                                                                                            activeChatId && messages && Object.values(messages) && Object.values(messages).length > 0 && Object.values(messages).map((message, i) => {
                                                                                                let cDate = null;

                                                                                                if(i === 0) {
                                                                                                    cDate = moment(message.createdAt).format('LL');
                                                                                                } else if(i > 0) {
                                                                                                    let cPreviousMessage = Object.values(messages)[i-1];
                                                                                                    if(cPreviousMessage) {
                                                                                                        if(moment(cPreviousMessage.createdAt).startOf('day').format('LL') !== moment(message.createdAt).startOf('day').format('LL')) {
                                                                                                            cDate = moment(message.createdAt).format('LL');
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                return (
                                                                                                    !message.visibleToSenderOnly || (message.messageBy.userId && message.messageBy.userId === currentUser._id) ? (
                                                                                                        (systemVariables.internalChat && systemVariables.internalChat.messageTypes && systemVariables.internalChat.messageTypes[message.messageType] && systemVariables.internalChat.messageTypes[message.messageType] === 'Info') ? (
                                                                                                            <div key={ 'chat-message-'+i } className={ 'chat-message-info' }>
                                                                                                                <Avatar style={{ background: (message.messageBy.userId === currentUser._id ? '#FFD967' : string2Hex(message.messageBy.name)), color: '#000' }} className="avatar">{ message.messageBy.name.substring(0, 1).toUpperCase() }</Avatar>
                                                                                                                <p className={ 'chat-message'+(message.messageBy.userType === 'operator' && message.messageBy.userId === currentUser._id ? ' chat-message--right' : '')+(message.messageBy.userType === 'operator' && message.messageBy.userId !== currentUser._id ? ' chat-message--left--operator' : '')+(chatRoomFontSizeOptions && fontSizeSelection && chatRoomFontSizeOptions[fontSizeSelection] ? ' '+chatRoomFontSizeOptions[fontSizeSelection].toLowerCase() : '') }>{ nl2br(message.message) }</p>
                                                                                                                <p className="chat-message-datetime">{ moment(message.createdAt).format('HH:mm:ss') }</p>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div key={ 'chat-message-'+i } className={ 'chat-message'+(message.messageBy.userType === 'operator' && message.messageBy.userId === currentUser._id ? ' chat-message--right' : '')+(message.messageBy.userType === 'operator' && message.messageBy.userId === currentUser._id ? ' chat-message--right' : '')+(message.messageBy.userType === 'operator' && message.messageBy.userId !== currentUser._id ? ' chat-message--left--operator' : '')+(chatRoomFontSizeOptions && fontSizeSelection && chatRoomFontSizeOptions[fontSizeSelection] ? ' '+chatRoomFontSizeOptions[fontSizeSelection].toLowerCase() : '') }>
                                                                                                                { cDate && <div className="chat-date-container"><span className="chat-date py-2 px-3">{ cDate }</span></div> }
                                                                                                            
                                                                                                                <div className="row1">
                                                                                                                    <span className="chat-message-by">{ message.messageBy.name }</span>
                                                                                                                </div>

                                                                                                                <div className="row2">
                                                                                                                    {
                                                                                                                        (message.messageBy.userType === 'operator') ? (
                                                                                                                            <Badge dot status={ onlineOperators && onlineOperators[message.messageBy.userId] ? 'success' : 'error' }><Avatar style={{ background: (message.messageBy.userId === currentUser._id ? '#FFD967' : string2Hex(message.messageBy.name)) }} size={ 35 } className="chat-message-avatar">{ message.messageBy.name.substring(0, 1).toUpperCase() }</Avatar></Badge>
                                                                                                                        ) : (
                                                                                                                            <Avatar style={{ background: (message.messageBy.userId === currentUser._id ? '#FFD967' : string2Hex(message.messageBy.name)) }} size={ 35 } className="chat-message-avatar">{ message.messageBy.name.substring(0, 1).toUpperCase() }</Avatar>
                                                                                                                        )
                                                                                                                    }
                                                                                                                
                                                                                                                    {
                                                                                                                        (message.status && systemVariables && systemVariables.internalChatMessage && systemVariables.internalChatMessage.statusOptions && systemVariables.internalChatMessage.statusOptions[message.status] && systemVariables.internalChatMessage.statusOptions[message.status] === 'Deleted') ? (
                                                                                                                            <div className="chat-message-text">
                                                                                                                                <p><StopOutlined style={{ fontSize: '11px' }} /> This message has been deleted.</p>
                                                                                                                            </div>
                                                                                                                        ) : (systemVariables && systemVariables.internalChat && systemVariables.internalChat.messageTypes && systemVariables.internalChat.messageTypes[message.messageType] && systemVariables.internalChat.messageTypes[message.messageType] === 'File') ? (
                                                                                                                            <>
                                                                                                                                <div className="chat-message-text file">
                                                                                                                                    <div className="file-wrapper">
                                                                                                                                        { 
                                                                                                                                            ['image/jpeg', 'image/png', 'image/gif'].includes(message.attachment.mime) ? (
                                                                                                                                                <>
                                                                                                                                                    <img onClick={ () => viewFile(message.attachment) } className="imgFile" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + message.attachment.filePath.replace(/^public\//, '') } alt={ message.attachment.originalFileName } />

                                                                                                                                                    <div className="file-actions">
                                                                                                                                                        <Button onClick={ () => viewFile(message.attachment) } className="action"><EyeOutlined /></Button>
                                                                                                                                                        <Button onClick={ () => downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName) } className="action"><DownloadOutlined /></Button>
                                                                                                                                                    </div>
                                                                                                                                                </>
                                                                                                                                            ) : (
                                                                                                                                                <div className="file"><FileOutlined /> { message.attachment.originalFileName } <Button onClick={ () => downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName) } className="action ml-2"><DownloadOutlined /></Button></div>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                {
                                                                                                                                    (message.messageBy.userType === 'operator' && message.messageBy.userId === currentUser._id) && (
                                                                                                                                        <div style={{ float: 'left' }}>
                                                                                                                                            <DeleteOutlined onClick={ () => { handleDeleteMessage(message.chatId, message._id) } } className="message-action mr-1" />
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        ) : (systemVariables && systemVariables.internalChat && systemVariables.internalChat.messageTypes && systemVariables.internalChat.messageTypes[message.messageType] && systemVariables.internalChat.messageTypes[message.messageType] === 'Error') ? (
                                                                                                                            <div className="chat-message-text error">
                                                                                                                                <span ><FileOutlined /> { nl2br(message.message) }</span>
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    (messageToBeEdited && messageToBeEdited+'' === message._id+'') ? (
                                                                                                                                        <>
                                                                                                                                            <div className="container-edit-message-form p-2">
                                                                                                                                                <Form form={ editMessageForm } className="edit-message-form" name="editMessage" onFinish={ () => { handleEditMessage(message.chatId, message._id) } } layout="vertical" hideRequiredMark={ true }>
                                                                                                                                                    <Form.Item className="edit-message-form-emoji-picker-container">
                                                                                                                                                        <span>
                                                                                                                                                            <span className="icon mr-1" onClick={ () => { setEditMessageFormEmojiPickerVisible(!editMessageFormEmojiPickerVisible) } }><SmileOutlined /></span>
                                                                                                                                                            <span className="picker" style={{ display: (editMessageFormEmojiPickerVisible ? 'block' : 'none'), bottom: 'auto' }}><Picker set="apple" title={ '' } showPreview={ false } showSkinTones={ false } onSelect={ editMessageForm_addEmoji } /></span>
                                                                                                                                                        </span> 
                                                                                                                                                    </Form.Item>

                                                                                                                                                    <div className="edit-message-form-input-C" onKeyDown={ editMessage_onKeyDown }>
                                                                                                                                                        <Form.Item name="message" className={ 'edit-message-form-input'+(chatRoomFontSizeOptions && fontSizeSelection && chatRoomFontSizeOptions[fontSizeSelection] ? ' '+chatRoomFontSizeOptions[fontSizeSelection].toLowerCase() : '') }>
                                                                                                                                                            <Input.TextArea
                                                                                                                                                                id="editMessage_message"
                                                                                                                                                                autoSize={ { minRows: 1, maxRows: 3 } }
                                                                                                                                                                autoFocus={ true }
                                                                                                                                                            />
                                                                                                                                                        </Form.Item>
                                                                                                                                                    </div>

                                                                                                                                                    <Form.Item className="edit-message-form-buttons">
                                                                                                                                                        <div>
                                                                                                                                                            {
                                                                                                                                                                (isFormSubmitting) ? (
                                                                                                                                                                    <Button htmlType="submit" className="edit-message-form-submit-button" disabled="disabled"><LoadingOutlined /></Button>
                                                                                                                                                                ) : (
                                                                                                                                                                    <Button htmlType="submit" className="edit-message-form-submit-button"><SendOutlined /></Button>
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </Form.Item>
                                                                                                                                                </Form>
                                                                                                                                            </div>

                                                                                                                                            <Button onClick={ () => { setMessageToBeEdited(null) } } className="btnSecondary px-2 mr-2" style={{ fontSize: '12px' }}>Cancel</Button>
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                        <>
                                                                                                                                            <div className="chat-message-text">
                                                                                                                                                <p><Linkify componentDecorator={ (href, text, key) => (<a href={ href}  key={ key } target="_blank" rel="noopener noreferrer">{ text }</a>) }>{ nl2br(message.message) }</Linkify></p>
                                                                                                                                            </div>

                                                                                                                                            {
                                                                                                                                                (message.messageBy.userType === 'operator' && message.messageBy.userId === currentUser._id) && (
                                                                                                                                                    <div style={{ float: 'left' }}>
                                                                                                                                                        <DeleteOutlined onClick={ () => { handleDeleteMessage(message.chatId, message._id) } } className="message-action mr-1" />
                                                                                                                                                        <EditOutlined onClick={ () => { handleEditMessageButtonClicked(message.chatId, message._id, message.message) } } className="message-action mr-2" />
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className="row3">
                                                                                                                    <span className="chat-message-datetime">{ (message.isSent ? <span>Sent</span> : null) } { moment(message.createdAt).format('HH:mm:ss') } { (message.isEdited ? <span> - Edited</span> : null) } </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    ) : (
                                                                                                        null
                                                                                                    )
                                                                                                );
                                                                                            })
                                                                                        }

                                                                                        { 
                                                                                            (activeChatId && uploads && uploads[activeChatId] && uploads[activeChatId] !== 0) && (
                                                                                                <div className={ 'chat-message chat-message--right' }>
                                                                                                    <div className="row1">
                                                                                                        <span className="chat-message-by">{ currentUser.displayName }</span>
                                                                                                    </div>

                                                                                                    <div className="row2">
                                                                                                        <Avatar style={{ background: '#FFD967' }} size={ 35 } className="chat-message-avatar">{ currentUser.displayName.substring(0, 1).toUpperCase() }</Avatar>
                                                                                                        
                                                                                                        <div className="chat-message-text">
                                                                                                            <span>Uploading file...</span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="row3">
                                                                                                        <Progress className="mx-2" percent={ uploads[activeChatId] } status={ (uploads[activeChatId] > 0 ? 'active' : 'exception') } />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>

                                                                        <div className="chat-form-emoji-picker-overlay" style={{ display: (sendMessageFormEmojiPickerVisible ? 'block' : 'none') }} onClick={ () => { setSendMessageFormEmojiPickerVisible(false) } }></div>
                                                                        
                                                                        <div className="container-chat-form p-3">
                                                                            <Form form={ sendMessageForm } className="chat-form" name="sendMessage" onFinish={ onSendMessage } layout="vertical" hideRequiredMark={ true }>
                                                                                <Form.Item className="chat-form-font-size-selector-container">
                                                                                    <span>
                                                                                        <Dropdown 
                                                                                            overlay={
                                                                                                <Menu className="mb-3" selectedKeys={ fontSizeSelection ? [fontSizeSelection+''] : [] }>
                                                                                                    { 
                                                                                                        chatRoomFontSizeOptions && Object.keys(chatRoomFontSizeOptions).map(key => {
                                                                                                            return (
                                                                                                                <Menu.Item key={ key+'' } onClick={ () => { handleFontSizeSelection(key) } }>
                                                                                                                    <span className="px-1 font-size-selector-menu-item" style={{ fontWeight: '500', color: '#767990' }}>{ chatRoomFontSizeOptions[key] }</span>
                                                                                                                </Menu.Item>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </Menu>
                                                                                            } 
                                                                                            trigger={['click']}
                                                                                            placement="topLeft"
                                                                                        >
                                                                                            <Button className="ant-dropdown-link" style={{ outline: '0', border: '0 none', padding: '0 0 0 0' }}>
                                                                                                <span className="icon" onClick={ () => {} }><FontSizeOutlined /></span>
                                                                                            </Button>
                                                                                        </Dropdown>
                                                                                    </span> 
                                                                                </Form.Item>
                                                                                
                                                                                <Form.Item className="chat-form-emoji-picker-container">
                                                                                    <span>
                                                                                        <span className="icon mr-1" onClick={ () => { setSendMessageFormEmojiPickerVisible(!sendMessageFormEmojiPickerVisible) } }><SmileOutlined /></span>
                                                                                        <span className="picker" style={{ display: (sendMessageFormEmojiPickerVisible ? 'block' : 'none') }}><Picker set="apple" title={ '' } showPreview={ false } showSkinTones={ false } onSelect={ sendMessageForm_addEmoji } /></span>
                                                                                    </span> 
                                                                                </Form.Item>

                                                                                <div className="chat-form-input-C" onKeyDown={ sendMessage_onKeyDown }>
                                                                                    <Form.Item name="message" className={ 'chat-form-input'+(chatRoomFontSizeOptions && fontSizeSelection && chatRoomFontSizeOptions[fontSizeSelection] ? ' '+chatRoomFontSizeOptions[fontSizeSelection].toLowerCase() : '') }>
                                                                                        <Input.TextArea
                                                                                            id="sendMessage_message"
                                                                                            autoSize={ { minRows: 1, maxRows: 3 } }
                                                                                            autoFocus={ true }
                                                                                            onChange={ (event) => {
                                                                                                if(messageDraftsByChatId && messageDraftsByChatId.current) messageDraftsByChatId.current[activeChatId] = event.target.value;
                                                                                            } }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>

                                                                                <Form.Item className="chat-form-buttons">
                                                                                    <div>
                                                                                        <Upload
                                                                                            name="file"
                                                                                            className="chat-form-upload-button" 
                                                                                            action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'internal-chats/' + activeChatId + '/upload' }
                                                                                            headers={{ authorization: localStorage.getItem('token'), clientId: currentUser.clientId }}
                                                                                            showUploadList={ false } 
                                                                                            beforeUpload={ beforeUpload }
                                                                                            onChange={ 
                                                                                                (info) => {
                                                                                                    if(info.file.status === 'uploading') {
                                                                                                        updateUploads(uploads => {
                                                                                                            if(!uploads) uploads = {};
                                                                                                            uploads[activeChatId] = info.file.percent || 0;
                                                                                                            return uploads;
                                                                                                        });
                                                                                                    } else if(info.file.status === 'done') {
                                                                                                        updateUploads(uploads => {
                                                                                                            if(uploads && uploads[activeChatId]) delete uploads[activeChatId];
                                                                                                            return uploads;
                                                                                                        });

                                                                                                        onFileUploadSuccess((info && info.file && info.file.response) || null);
                                                                                                    } else if (info.file.status === 'error') {
                                                                                                        updateUploads(uploads => {
                                                                                                            if(uploads && uploads[activeChatId]) uploads[activeChatId] = -1;
                                                                                                            return uploads;
                                                                                                        });

                                                                                                        onFileUploadError((info && info.error) || null);
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        >
                                                                                            <Button><PaperClipOutlined /></Button>
                                                                                        </Upload>
                                                                                        
                                                                                        {
                                                                                            (isFormSubmitting) ? (
                                                                                                <Button htmlType="submit" className="chat-form-submit-button" disabled="disabled"><LoadingOutlined /></Button>
                                                                                            ) : (
                                                                                                <Button htmlType="submit" className="chat-form-submit-button"><SendOutlined /></Button>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </Form.Item>
                                                                            </Form>
                                                                        </div>
                                                                    </section>
                                                                ) : (
                                                                    <section className="chat-container-empty"></section>
                                                                )
                                                            }
                                                        </CardWithShadow>
                                                    </Col>

                                                    <Col span={ 5 }>
                                                        <CardWithShadow style={{ height: 'auto' }}>
                                                            <div className="section-title py-2 px-3">
                                                                <h4 style={{ color: '#6800D0' }}><span style={{ float: 'right' }}><UserOutlined /></span> User Details</h4>
                                                            </div>

                                                            <div className="user-details py-2 px-3">
                                                                <Row>
                                                                    <Col span={ 8 }><b>Username</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].operator && chats[activeChatId].operator.username ? chats[activeChatId].operator.username : 'N/A') } </Col>
                                                                    <Col className="mt-1" span={ 8 }><b>    Name</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].operator && chats[activeChatId].operator.displayName ? chats[activeChatId].operator.displayName : 'N/A') } </Col>
                                                                </Row>
                                                            </div>

                                                            <div className="pb-2"></div>
                                                        </CardWithShadow>
                                                    </Col>
                                                </>
                                            )
                                        }
                                    </Row>
                                </div>

                                <Modal width={ '40%' } onCancel={ () => setAttachmentToDisplay(null) } className="imgModal" visible={ attachmentToDisplay ? true : false } footer={ null }>
                                    {  attachmentToDisplay && attachmentToDisplay.filePath ? <img style={{ width: '100%' }} src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + attachmentToDisplay.filePath.replace(/^public\//, '') } alt={ attachmentToDisplay.originalFileName ? attachmentToDisplay.originalFileName : 'Image' } /> : null }
                                </Modal>

                                <audio ref={ newMessageAudioRef }><source src={ newMessageAudioAsset } type="audio/mpeg" /></audio>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
};

export default Operators;