import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import { Link } from 'react-router-dom';

import { Menu, Badge } from 'antd';
import { UnorderedListOutlined, DesktopOutlined, TeamOutlined, CommentOutlined, HistoryOutlined, SolutionOutlined, CustomerServiceOutlined, MergeCellsOutlined, ThunderboltOutlined, StopOutlined, RobotOutlined } from '@ant-design/icons';

import './Sidebar.css';
import classes from './Sidebar.module.css';

import logo from '../../logo.png';
import selectedMenuItemIndicator from './assets/selected-menu-item-indicator.png';

const Sidebar = () => {
    const { 
        currentUser,
        systemVariables,
        isSocketConnected,
        chatQueueBadgeCount, updateChatQueueBadgeCount,
        myChatsBadgeCountChatIds, updateMyChatsBadgeCountChatIds,
        operatorsBadgeCountChatIds, updateOperatorsBadgeCountChatIds
    } = useSystemContext();

    const availabilityOptions = (systemVariables && systemVariables.user && systemVariables.user.availabilityOptions ? systemVariables.user.availabilityOptions : null);
    
    const location = useLocation();

    useEffect(() => {
        socket.on('connect_error', (err) => {
            let errorMessage = err.message + '';
            if(errorMessage.includes('auth error')) {
                window.location.href = '/login';
            }
        });
    }, [isSocketConnected])

    useEffect(() => {
        if(isSocketConnected && currentUser) {
            socket.off('operator.chats.chatQueueBadgeCount').on('operator.chats.chatQueueBadgeCount', (badgeCount) => {
                if(!badgeCount) badgeCount = 0;
                updateChatQueueBadgeCount(chatQueueBadgeCount => badgeCount);
            });

            socket.off('operator.chats.chatQueueBadgeCount.update').on('operator.chats.chatQueueBadgeCount.update', () => {
                socket.emit('operator.chats.chatQueueBadgeCount.get');
            });

            socket.off('operator.chats.myChatsBadgeCountChatIds').on('operator.chats.myChatsBadgeCountChatIds', (chatIds) => {
                updateMyChatsBadgeCountChatIds(myChatsBadgeCountChatIds => chatIds);
            });

            socket.off('operator.chats.myChatsBadgeCountChatIds.update').on('operator.chats.myChatsBadgeCountChatIds.update', (badgeCount) => {
                socket.emit('operator.chats.myChatsBadgeCountChatIds.get');
            });

            if(window && window.location && window.location.pathname && !window.location.pathname.startsWith('/my-chats')) {
                socket.off('operator.chats.chat.new').on('operator.chats.chat.new', (chat) => {
                    updateMyChatsBadgeCountChatIds(myChatsBadgeCountChatIds => {
                        if(!myChatsBadgeCountChatIds) {
                            let nMyChatsBadgeCountChatIds = {};
                            nMyChatsBadgeCountChatIds[''+chat._id] = 1;
                            return nMyChatsBadgeCountChatIds;
                        }

                        if(!myChatsBadgeCountChatIds[''+chat._id]) myChatsBadgeCountChatIds[''+chat._id] = 1;
                    });
                });

                socket.off('chat.message').on('chat.message', (chatMessage) => {
                    if(chatMessage.messageBy && chatMessage.messageBy.userType && chatMessage.messageBy.userType === 'operator' && chatMessage.messageBy.userId && currentUser && currentUser._id && chatMessage.messageBy.userId+'' === currentUser._id+'') return;
                    
                    let currentPathName = window && window.location && window.location.pathname;
                    if(currentPathName.startsWith('/my-chats/')) {
                        let activeChatId = currentPathName.substring(currentPathName.lastIndexOf('/') + 1);
                        if(activeChatId && chatMessage.chatId === activeChatId) return;
                    }
                    
                    updateMyChatsBadgeCountChatIds(myChatsBadgeCountChatIds => {
                        if(!myChatsBadgeCountChatIds) {
                            let nMyChatsBadgeCountChatIds = {};
                            nMyChatsBadgeCountChatIds[''+chatMessage.chatId] = 1;
                            return nMyChatsBadgeCountChatIds;
                        }

                        if(!myChatsBadgeCountChatIds[''+chatMessage.chatId]) myChatsBadgeCountChatIds[''+chatMessage.chatId] = 1;
                    });
                });

                socket.off('chat.end').on('chat.end', (pData) => {
                    if(!pData || !pData.chatId) return;

                    if(!pData.endedBy || pData.endedBy !== 'operator') {
                        socket.emit('operator.chats.chat.leave', pData.chatId);
                    }

                    updateMyChatsBadgeCountChatIds(myChatsBadgeCountChatIds => {
                        if(myChatsBadgeCountChatIds && myChatsBadgeCountChatIds[''+pData.chatId]) {
                            delete myChatsBadgeCountChatIds[''+pData.chatId];
                        }
                    });

                    if(currentUser && currentUser.parameters && currentUser.parameters.automaticallyAcceptChats && parseInt(currentUser.parameters.automaticallyAcceptChats) === 1) {
                        if(availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Online') {
                            let takeChatCallback = (ack) => {
                                if(!ack) return;

                                if(!ack.result) {
                                    if(ack.retry) {
                                        socket.emit('operator.chats.take', { force: false }, takeChatCallback);
                                        return;
                                    }
                                }
                            };

                            socket.emit('operator.chats.take', { force: false }, takeChatCallback);
                        }
                    }
                });
            }
			
            socket.off('operator.chats.operatorsBadgeCountChatIds').on('operator.chats.operatorsBadgeCountChatIds', (chatIds) => {
                updateOperatorsBadgeCountChatIds(operatorsBadgeCountChatIds => chatIds);
            });

            if(window && window.location && window.location.pathname && !window.location.pathname.startsWith('/operators')) {
                socket.off('operator.internalChats.chat.new').on('operator.internalChats.chat.new', (chat) => {
                    updateOperatorsBadgeCountChatIds(operatorsBadgeCountChatIds => {
                        if(!operatorsBadgeCountChatIds || !operatorsBadgeCountChatIds.internalChats) {
                            let nOperatorsBadgeCountChatIds = (operatorsBadgeCountChatIds ? { ...operatorsBadgeCountChatIds } : {});

                            if(!nOperatorsBadgeCountChatIds.internalChats) nOperatorsBadgeCountChatIds.internalChats = {};
                            if(!nOperatorsBadgeCountChatIds.groupChats) nOperatorsBadgeCountChatIds.groupChats = {};
                            
                            nOperatorsBadgeCountChatIds.internalChats[''+chat._id] = 1;

                            return nOperatorsBadgeCountChatIds;
                        }

                        if(!operatorsBadgeCountChatIds.internalChats[''+chat._id]) operatorsBadgeCountChatIds.internalChats[''+chat._id] = 1;
                    });
                });

                socket.off('internalChats.chat.message').on('internalChats.chat.message', (chatMessage) => {
                    if(chatMessage.messageBy && chatMessage.messageBy.userType && chatMessage.messageBy.userType === 'operator' && chatMessage.messageBy.userId && currentUser && currentUser._id && chatMessage.messageBy.userId+'' === currentUser._id+'') return;

                    let currentPathName = window && window.location && window.location.pathname;
                    if(currentPathName.startsWith('/operators/')) {
                        let activeChatId = currentPathName.substring(currentPathName.lastIndexOf('/') + 1);
                        if(activeChatId && chatMessage.chatId+'' === activeChatId+'') return;
                    }

                    updateOperatorsBadgeCountChatIds(operatorsBadgeCountChatIds => {
                        if(!operatorsBadgeCountChatIds || !operatorsBadgeCountChatIds.internalChats) {
                            let nOperatorsBadgeCountChatIds = (operatorsBadgeCountChatIds ? { ...operatorsBadgeCountChatIds } : {});

                            if(!nOperatorsBadgeCountChatIds.internalChats) nOperatorsBadgeCountChatIds.internalChats = {};
                            if(!nOperatorsBadgeCountChatIds.groupChats) nOperatorsBadgeCountChatIds.groupChats = {};
                            
                            nOperatorsBadgeCountChatIds.internalChats[''+chatMessage.chatId] = 1;

                            return nOperatorsBadgeCountChatIds;
                        }

                        if(!operatorsBadgeCountChatIds.internalChats[''+chatMessage.chatId]) operatorsBadgeCountChatIds.internalChats[''+chatMessage.chatId] = 1;
                    });
                });
            }
            
            if(window && window.location && window.location.pathname && !window.location.pathname.startsWith('/operators/channels')) {
                socket.off('groupChats.chat.message').on('groupChats.chat.message', (chatMessage) => {
                    if(chatMessage.messageBy && chatMessage.messageBy.userType && chatMessage.messageBy.userType === 'operator' && chatMessage.messageBy.userId && currentUser && currentUser._id && chatMessage.messageBy.userId+'' === currentUser._id+'') return;

                    let currentPathName = window && window.location && window.location.pathname;
                    if(currentPathName.startsWith('/operators/channels/')) {
                        let activeChatId = currentPathName.substring(currentPathName.lastIndexOf('/') + 1);
                        if(activeChatId && chatMessage.chatId+'' === activeChatId+'') return;
                    }

                    updateOperatorsBadgeCountChatIds(operatorsBadgeCountChatIds => {
                        if(!operatorsBadgeCountChatIds || !operatorsBadgeCountChatIds.internalChats) {
                            let nOperatorsBadgeCountChatIds = (operatorsBadgeCountChatIds ? { ...operatorsBadgeCountChatIds } : {});

                            if(!nOperatorsBadgeCountChatIds.internalChats) nOperatorsBadgeCountChatIds.internalChats = {};
                            if(!nOperatorsBadgeCountChatIds.groupChats) nOperatorsBadgeCountChatIds.groupChats = {};
                            
                            nOperatorsBadgeCountChatIds.groupChats[''+chatMessage.chatId] = 1;

                            return nOperatorsBadgeCountChatIds;
                        }

                        if(!operatorsBadgeCountChatIds.groupChats[''+chatMessage.chatId]) operatorsBadgeCountChatIds.groupChats[''+chatMessage.chatId] = 1;
                    });
                });
            }
        }

        return function cleanup() {
			socket.off('operator.chats.chatQueueBadgeCount');
			socket.off('operator.chats.chatQueueBadgeCount.update');
			socket.off('operator.chats.myChatsBadgeCountChatIds');
            socket.off('operator.chats.myChatsBadgeCountChatIds.update');

            if(window && window.location && window.location.pathname && !window.location.pathname.startsWith('/my-chats')) {
                socket.off('operator.chats.chat.new');
                socket.off('chat.message');
                socket.off('chat.end');
            }
            
			socket.off('operator.chats.operatorsBadgeCountChatIds');

            if(window && window.location && window.location.pathname && !window.location.pathname.startsWith('/operators')) {
                socket.off('operator.internalChats.chat.new');
                socket.off('internalChats.chat.message');
            }

            if(window && window.location && window.location.pathname && !window.location.pathname.startsWith('/operators/channels')) {
                socket.off('groupChats.chat.message');
            }
        }
	}, [isSocketConnected, currentUser, availabilityOptions, updateChatQueueBadgeCount, updateMyChatsBadgeCountChatIds, updateOperatorsBadgeCountChatIds]);

    return (
        <>
            <div className={ classes.logoContainer }>
                <Link to="/"><img className={ classes.logo } src={ logo } alt={ process.env.REACT_APP_NAME } /></Link>
            </div>
            
            {
                (currentUser) && (
                    (currentUser.role && currentUser.role === 999) ? (
                        <Menu theme="light" mode="inline" selectedKeys={ location && location.pathname ? location.pathname : [] }>
                            <Menu.Item key="/clients"><Link to="/clients">{ location && location.pathname && location.pathname.includes('/visitors') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> }<span className="px-4">Clients</span></Link></Menu.Item>
                        </Menu>
                    ) : (currentUser.role && currentUser.role === 99) ? (
                        <Menu theme="light" mode="inline" selectedKeys={ location && location.pathname ? location.pathname : [] } defaultOpenKeys={ location && location.pathname && location.pathname.includes('/reports') ? ['/reports'] : [] }>
                            <Menu.Item key="/monitor"><Link to="/monitor">{ location && location.pathname && location.pathname.includes('/monitor') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left', }} /> } <span className="px-4"><DesktopOutlined className="mr-2" /> Monitor</span></Link></Menu.Item>
                            <Menu.Item key="/visitors"><Link to="/visitors">{ location && location.pathname && location.pathname.includes('/visitors') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left', }} /> } <span className="px-4"><TeamOutlined className="mr-2" /> Visitors</span></Link></Menu.Item>
                            <Menu.Item key="/chat-queue"><Link to="/chat-queue">{ location && location.pathname && location.pathname.includes('/chat-queue') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><MergeCellsOutlined className="mr-2" /> Chat Queue { chatQueueBadgeCount > 0 && <Badge className="navbar-badge ml-1" count={ chatQueueBadgeCount } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/my-chats"><Link to="/my-chats">{ location && location.pathname && location.pathname.includes('/my-chats') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><CommentOutlined className="mr-2" /> My Chats { myChatsBadgeCountChatIds && Object.keys(myChatsBadgeCountChatIds).length > 0 && <Badge className="navbar-badge ml-1" count={ Object.keys(myChatsBadgeCountChatIds).length } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/chat-history"><Link to="/chat-history">{ location && location.pathname && location.pathname.includes('/chat-history') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><HistoryOutlined className="mr-2" /> Chat History</span></Link></Menu.Item>
                            <Menu.Item key="/operators"><Link to="/operators">{ location && location.pathname && location.pathname.includes('/operators') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><CustomerServiceOutlined className="mr-2"/> Operators { operatorsBadgeCountChatIds && operatorsBadgeCountChatIds.internalChats && operatorsBadgeCountChatIds.groupChats && (Object.keys(operatorsBadgeCountChatIds.internalChats).length > 0 || Object.keys(operatorsBadgeCountChatIds.groupChats).length > 0) && <Badge className="navbar-badge ml-1" count={ (Object.keys(operatorsBadgeCountChatIds.internalChats).length + Object.keys(operatorsBadgeCountChatIds.groupChats).length) } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/tickets"><Link to="/tickets">{ location && location.pathname && location.pathname.includes('/tickets') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><SolutionOutlined className="mr-2" /> Tickets</span></Link></Menu.Item>
                            <Menu.Item key="/personal-shortcuts"><Link to="/personal-shortcuts">{ location && location.pathname && location.pathname.includes('/personal-shortcuts') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><ThunderboltOutlined className="mr-2" /> Personal Shortcuts</span></Link></Menu.Item>
                            <Menu.Item key="/ban-list"><Link to="/ban-list">{ location && location.pathname && location.pathname.includes('/ban-list') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><StopOutlined className="mr-2" /> Ban List</span></Link></Menu.Item>
                            <Menu.Item key="/chatbots"><Link to="/chatbots">{ location && location.pathname && location.pathname.includes('/chatbots') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><RobotOutlined className="mr-2" /> Chatbot</span></Link></Menu.Item>
                            <Menu.SubMenu key="/reports" icon={ <UnorderedListOutlined /> } title="Reports">
                                <Menu.Item key="/reports/real-time">
                                    <Link to="/reports/real-time">Real Time</Link>
                                </Menu.Item>
                                <Menu.Item key="/reports/chat-volume">
                                    <Link to="/reports/chat-volume">Chat Volume</Link>
                                </Menu.Item>
                                <Menu.Item key="/reports/operator-performance">
                                    <Link to="/reports/operator-performance">Operator Performance</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    ) : (currentUser.role && currentUser.role === 20) ? (
                        <Menu theme="light" mode="inline" selectedKeys={ location && location.pathname ? location.pathname : [] } defaultOpenKeys={ location && location.pathname && location.pathname.includes('/reports') ? ['/reports'] : [] }>
                            <Menu.Item key="/monitor"><Link to="/monitor">{ location && location.pathname && location.pathname.includes('/monitor') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left', }} /> } <span className="px-4"><DesktopOutlined className="mr-2" /> Monitor</span></Link></Menu.Item>
                            <Menu.Item key="/visitors"><Link to="/visitors">{ location && location.pathname && location.pathname.includes('/visitors') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><TeamOutlined className="mr-2" /> Visitors</span></Link></Menu.Item>
                            <Menu.Item key="/chat-queue"><Link to="/chat-queue">{ location && location.pathname && location.pathname.includes('/chat-queue') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><MergeCellsOutlined className="mr-2" /> Chat Queue { chatQueueBadgeCount > 0 && <Badge className="navbar-badge ml-1" count={ chatQueueBadgeCount } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/my-chats"><Link to="/my-chats">{ location && location.pathname && location.pathname.includes('/my-chats') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><CommentOutlined className="mr-2" /> My Chats { myChatsBadgeCountChatIds && Object.keys(myChatsBadgeCountChatIds).length > 0 && <Badge className="navbar-badge ml-1" count={ Object.keys(myChatsBadgeCountChatIds).length } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/chat-history"><Link to="/chat-history">{ location && location.pathname && location.pathname.includes('/chat-history') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><HistoryOutlined className="mr-2" /> Chat History</span></Link></Menu.Item>
                            <Menu.Item key="/operators"><Link to="/operators">{ location && location.pathname && location.pathname.includes('/operators') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><CustomerServiceOutlined className="mr-2"/> Operators { operatorsBadgeCountChatIds && operatorsBadgeCountChatIds.internalChats && operatorsBadgeCountChatIds.groupChats && (Object.keys(operatorsBadgeCountChatIds.internalChats).length > 0 || Object.keys(operatorsBadgeCountChatIds.groupChats).length > 0) && <Badge className="navbar-badge ml-1" count={ (Object.keys(operatorsBadgeCountChatIds.internalChats).length + Object.keys(operatorsBadgeCountChatIds.groupChats).length) } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/tickets"><Link to="/tickets">{ location && location.pathname && location.pathname.includes('/tickets') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><SolutionOutlined className="mr-2" /> Tickets</span></Link></Menu.Item>
                            <Menu.Item key="/personal-shortcuts"><Link to="/personal-shortcuts">{ location && location.pathname && location.pathname.includes('/personal-shortcuts') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><ThunderboltOutlined className="mr-2" /> Personal Shortcuts</span></Link></Menu.Item>
                            <Menu.Item key="/ban-list"><Link to="/ban-list">{ location && location.pathname && location.pathname.includes('/ban-list') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><StopOutlined className="mr-2" /> Ban List</span></Link></Menu.Item>
                            <Menu.Item key="/chatbots"><Link to="/chatbots">{ location && location.pathname && location.pathname.includes('/chatbots') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><RobotOutlined className="mr-2" /> Chatbot</span></Link></Menu.Item>
                            <Menu.SubMenu key="/reports" icon={ <UnorderedListOutlined /> } title="Reports">
                                <Menu.Item key="/reports/real-time">
                                    <Link to="/reports/real-time">Real Time</Link>
                                </Menu.Item>
                                <Menu.Item key="/reports/chat-volume">
                                    <Link to="/reports/chat-volume">Chat Volume</Link>
                                </Menu.Item>
                                <Menu.Item key="/reports/operator-performance">
                                    <Link to="/reports/operator-performance">Operator Performance</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    ) : (currentUser.role && currentUser.role === 10) ? (
                        <Menu theme="light" mode="inline" selectedKeys={ location && location.pathname ? location.pathname : [] }>
                            <Menu.Item key="/monitor"><Link to="/monitor">{ location && location.pathname && location.pathname.includes('/monitor') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left', }} /> } <span className="px-4"><DesktopOutlined className="mr-2" /> Monitor</span></Link></Menu.Item>
                            <Menu.Item key="/visitors"><Link to="/visitors">{ location && location.pathname && location.pathname.includes('/visitors') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><TeamOutlined className="mr-2" /> Visitors</span></Link></Menu.Item>
                            <Menu.Item key="/chat-queue"><Link to="/chat-queue">{ location && location.pathname && location.pathname.includes('/chat-queue') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><MergeCellsOutlined className="mr-2" /> Chat Queue { chatQueueBadgeCount > 0 && <Badge className="navbar-badge ml-1" count={ chatQueueBadgeCount } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/my-chats"><Link to="/my-chats">{ location && location.pathname && location.pathname.includes('/my-chats') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><CommentOutlined className="mr-2" /> My Chats { myChatsBadgeCountChatIds && Object.keys(myChatsBadgeCountChatIds).length > 0 && <Badge className="navbar-badge ml-1" count={ Object.keys(myChatsBadgeCountChatIds).length } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/chat-history"><Link to="/chat-history">{ location && location.pathname && location.pathname.includes('/chat-history') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><HistoryOutlined className="mr-2" /> Chat History</span></Link></Menu.Item>
                            <Menu.Item key="/operators"><Link to="/operators">{ location && location.pathname && location.pathname.includes('/operators') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><CustomerServiceOutlined className="mr-2"/> Operators { operatorsBadgeCountChatIds && operatorsBadgeCountChatIds.internalChats && operatorsBadgeCountChatIds.groupChats && (Object.keys(operatorsBadgeCountChatIds.internalChats).length > 0 || Object.keys(operatorsBadgeCountChatIds.groupChats).length > 0) && <Badge className="navbar-badge ml-1" count={ (Object.keys(operatorsBadgeCountChatIds.internalChats).length + Object.keys(operatorsBadgeCountChatIds.groupChats).length) } /> }</span></Link></Menu.Item>
                            <Menu.Item key="/tickets"><Link to="/tickets">{ location && location.pathname && location.pathname.includes('/tickets') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><SolutionOutlined className="mr-2" /> Tickets</span></Link></Menu.Item>
                            <Menu.Item key="/personal-shortcuts"><Link to="/personal-shortcuts">{ location && location.pathname && location.pathname.includes('/personal-shortcuts') && <img src={ selectedMenuItemIndicator } alt="|" style={{ float: 'left' }} /> } <span className="px-4"><ThunderboltOutlined className="mr-2" /> Personal Shortcuts</span></Link></Menu.Item>
                        </Menu>
                    ) : (null)
                )
            }
        </>
    );
}

export default Sidebar;
