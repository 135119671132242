import React, { useState } from 'react';
import { useSystemContext } from '../../../../Context/SystemContext';
import { useImmer } from 'use-immer';

import { socket } from '../../../../service/socket';

import { Modal, Row, Col, Form, Input, Select, Switch, Upload, Progress, Button, message  } from 'antd';
import { SmileOutlined, MinusCircleOutlined, PlusOutlined, InboxOutlined, PictureOutlined, DeleteOutlined, EditOutlined, SaveOutlined, ArrowRightOutlined, ArrowLeftOutlined, PauseOutlined } from '@ant-design/icons';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

const AddNodeModal = ({ chatbotId, nodeType, onSuccess, onCancel, ...restProps }) => {
    const {
        currentUser,
        systemVariables
    } = useSystemContext();
    
    const nodeTypeOptions = (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.typeOptions) || null;
    const buttonTypeOptions = (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions) || null;
    const buttonTypeStyles = (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeStyles) || null;

    const [addBlockForm] = Form.useForm();

    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const addEmoji = (emoji) => {
        addBlockForm.setFieldsValue({
            message: (addBlockForm.getFieldValue('message') ? addBlockForm.getFieldValue('message') : '') + emoji.native
        });
    }

    const [buttonTypesByFieldKey, updateButtonTypesByFieldKey] = useImmer({});

    const [imageForAddImageNodeFormValues, setImageForAddImageNodeFormValues] = useState(null);

    const [addCarouselNodeStep, setAddCarouselNodeStep] = useState(1);
    const [addCarouselNodeFields, updateAddCarouselNodeFields] = useImmer({ image: true });
    const [carouselItemsImages, updateCarouselItemsImages] = useImmer([]);
    const [carouselItemsButtons, updateCarouselItemsButtons] = useImmer([]);
    const [addCarouselButtonFormVisibleFor, setAddCarouselButtonFormVisibleFor] = useState(null);
    const [editCarouselButtonFormVisibleFor, setEditCarouselButtonFormVisibleFor] = useState(null);
    const [buttonToBeAddedForAddCarouselNode, updateButtonToBeAddedForAddCarouselNode] = useImmer(null);

    const [uploadProgress, setUploadProgress] = useState(null);

    const handleAddBlockFinish = (values) => {
        if(nodeTypeOptions && nodeTypeOptions[nodeType] && nodeTypeOptions[nodeType] === 'Image') {
            if(!imageForAddImageNodeFormValues) {
                message.error('You must upload a single image file to create an image block.');
                return;
            }

            socket.emit('operator.chatbots.chatbot.nodes.add', { chatbotId: chatbotId, file: imageForAddImageNodeFormValues, type: nodeType, ...values }, (ack) => {
                if(ack && ack.result) {
                    onSuccess();
                }
            });
        } else if(nodeTypeOptions && nodeTypeOptions[nodeType] && nodeTypeOptions[nodeType] === 'Carousel') {
            if(addBlockForm.getFieldValue("carouselItems") && Array.isArray(addBlockForm.getFieldValue("carouselItems")) && addBlockForm.getFieldValue("carouselItems").length !== carouselItemsImages.length) {
                message.error('Each carousel item must have an image.');
                return;
            }

            socket.emit('operator.chatbots.chatbot.nodes.add', { chatbotId: chatbotId, type: nodeType, ...values, fields: addCarouselNodeFields, buttons: carouselItemsButtons, images: carouselItemsImages }, (ack) => {
                if(ack && ack.result) {
                    onSuccess();
                }
            });
        } else {
            socket.emit('operator.chatbots.chatbot.nodes.add', { chatbotId: chatbotId, type: nodeType, ...values }, (ack) => {
                if(ack && ack.result) {
                    onSuccess();
                }
            });
        }
    }

    return (
        <Modal
            visible={ true }
            onCancel={ onCancel }
            footer={ null }
            width={ (nodeTypeOptions[nodeType] === 'Carousel' && addCarouselNodeStep === 2 ? 1080 : 720) }
        >
            <h1 style={{ marginBottom: '20px' }}>Add Block</h1>

            <Form form={ addBlockForm } onFinish={ handleAddBlockFinish } layout="vertical" hideRequiredMark={ true }>
                <div className="chatbot-blocks-form-W">    
                    {
                        (nodeType && nodeTypeOptions && nodeTypeOptions[nodeType]) && (
                            (nodeTypeOptions[nodeType] === 'Text') ? (
                                <>
                                    <div className="emojiPicker-W mr-2" style={{ float: 'left' }}>
                                        <span className="icon mr-1" onClick={ () => { setEmojiPickerVisible(!emojiPickerVisible) } }><SmileOutlined /></span>
                                        <span className="picker" style={{ display: (emojiPickerVisible ? 'block' : 'none'), bottom: 'auto' }}><Picker set="apple" title={ '' } showPreview={ false } showSkinTones={ false } onSelect={ addEmoji } /></span>
                                    </div>
                                    
                                    <Form.Item name="message" rules={ [{ required: true, message: 'Please enter a message for the text block.' }] }>
                                        <Input.TextArea
                                            className="chatbot-blocks-textArea"
                                            rows={ 4 }
                                            placeholder="Enter your message..."
                                        >
                                        </Input.TextArea>
                                    </Form.Item>

                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { onCancel(); } }>Cancel</Button>
                                        <Button className="btnAdd" type="primary" htmlType="submit">Save</Button>
                                    </Form.Item>
                                </>
                            ) : (nodeTypeOptions[nodeType] === 'User response') ? (
                                <>
                                    <div className="emojiPicker-W mr-2" style={{ float: 'left' }}>
                                        <span className="icon mr-1" onClick={ () => { setEmojiPickerVisible(!emojiPickerVisible) } }><SmileOutlined /></span>
                                        <span className="picker" style={{ display: (emojiPickerVisible ? 'block' : 'none'), bottom: 'auto' }}><Picker set="apple" title={ '' } showPreview={ false } showSkinTones={ false } onSelect={ addEmoji } /></span>
                                    </div>
                                    
                                    <Form.Item name="message" rules={ [{ required: true, message: 'Please enter a message for the text block.' }] }>
                                        <Input.TextArea
                                            className="chatbot-blocks-textArea"
                                            rows={ 4 }
                                            placeholder="Enter a message for user repsonse..."
                                        >
                                        </Input.TextArea>
                                    </Form.Item>

                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { onCancel(); } }>Cancel</Button>
                                        <Button className="btnAdd" type="primary" htmlType="submit">Save</Button>
                                    </Form.Item>
                                </>
                            ) : (nodeTypeOptions[nodeType] === 'Button') ? (
                                <>
                                    <Form.List name="buttons" initialValue={ [{ type: null, label: '', url: '' }] }>
                                        { (fields, { add, remove }, { errors }) => (
                                            <>
                                                { fields.map(({ key, name, fieldKey, ...restField }) => (
                                                    <div className="mb-2" key={ key }>
                                                        <Form.Item
                                                            { ...restField }
                                                            name={ [name, 'type'] }
                                                            fieldKey={ [fieldKey, 'type'] }
                                                            className="no-error-message m-0"
                                                            style={{ display: 'inline-block' }}
                                                            rules={ [{ required: true, message: 'Please select a valid button type.' }] }
                                                        >
                                                            <Select
                                                                labelInValue
                                                                placeholder="Select a button type..."
                                                                style={{ width: '170px' }}
                                                                onChange={
                                                                    (selected) => {
                                                                        updateButtonTypesByFieldKey(buttonTypesByFieldKey => {
                                                                            if(selected && selected.value) {
                                                                                return {
                                                                                    ...buttonTypesByFieldKey,
                                                                                    [fieldKey]: selected.value
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            >
                                                                {
                                                                    buttonTypeOptions && Object.keys(buttonTypeOptions).map(key => <Select.Option key={ key } value={ key }>{ buttonTypeOptions[key] }</Select.Option>)
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            { ...restField }
                                                            name={ [name, 'label'] }
                                                            fieldKey={ [fieldKey, 'label'] }
                                                            className="no-error-message m-0"
                                                            style={{ display: 'inline-block' }}
                                                            rules={ [{ required: true, message: 'Please enter a label for the button.' }] }
                                                        >
                                                            <Input className="curvedInput mx-3" placeholder={ 'Label for button...' } style={{ maxWidth: '200px' }}/>
                                                        </Form.Item>

                                                        {
                                                            buttonTypesByFieldKey && buttonTypesByFieldKey[fieldKey] && buttonTypesByFieldKey[fieldKey] === Object.keys(buttonTypeOptions).find(key => buttonTypeOptions[key] === 'Link') && (
                                                                <Form.Item
                                                                    { ...restField }
                                                                    name={ [name, 'url'] }
                                                                    fieldKey={ [fieldKey, 'url'] }
                                                                    className="no-error-message m-0"
                                                                    style={{ display: 'inline-block' }}
                                                                    rules={ [{ required: true, message: 'Please enter a url for the button.' }] }
                                                                >
                                                                    <Input className="curvedInput mx-3" placeholder={ 'Target URL for button...' } style={{ maxWidth: '200px' }}/>
                                                                </Form.Item>
                                                            )
                                                        }

                                                        { 
                                                            fields.length > 0 ? (
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button ml-3 mt-2"
                                                                    style={{ fontSize: '16px' }}
                                                                    onClick={ () => remove(name) }
                                                                />
                                                            ) : null
                                                        }
                                                    </div>
                                                ))}
                                            
                                                <Form.Item>
                                                    <Button
                                                        style={{ background: 'transparent', border: '0 none', boxShadow: 'none', padding: '0 0 0 0', fontWeight: 500, color: '#6800D0' }}
                                                        onClick={ () => add() }
                                                        icon={ <PlusOutlined /> }
                                                    >
                                                        Add field
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>

                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { onCancel(); } }>Cancel</Button>
                                        <Button className="btnAdd" type="primary" htmlType="submit">Save</Button>
                                    </Form.Item>
                                </>
                            ) : (nodeTypeOptions[nodeType] === 'Image') ? (
                                <>  
                                    <Form.Item>
                                        <Upload.Dragger
                                            name="file"
                                            action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + chatbotId + '/nodes/files/upload-image' }
                                            headers={{ authorization: localStorage.getItem('token'), clientId: currentUser.clientId }}
                                            accept="image/*"
                                            multiple={ false }
                                            onChange={
                                                (info) => {
                                                    setUploadProgress(parseInt(info.file.percent));

                                                    if(info.file.status === 'uploading') {

                                                    } else if(info.file.status === 'done') {
                                                        if(info.file.response && info.file.response.file) {
                                                            setImageForAddImageNodeFormValues(info.file.response.file);
                                                        }
                                                    } else if (info.file.status === 'error') {

                                                    }
                                                }
                                            }
                                            showUploadList={ false }
                                        >
                                            <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                        </Upload.Dragger>

                                        <div className="mt-4">
                                            {
                                                (imageForAddImageNodeFormValues) && (   
                                                    <img
                                                        src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + chatbotId + '/nodes/files/view-image/' + imageForAddImageNodeFormValues.fileName } alt={ imageForAddImageNodeFormValues.fileName } 
                                                        style={{ maxWidth: '160px', maxHeight: '160px', borderRadius: '4px' }} 
                                                        onDragStart={ (e) => { e.preventDefault(); } }
                                                    />
                                                )
                                            }

                                            { uploadProgress && <Progress className="mt-2 ml-2" type="circle" percent={ uploadProgress } width={ 60 } /> }
                                        </div>
                                    </Form.Item>

                                    <Row className="mt-4" gutter={ 16 }>
                                        <Col span={ 16 }>
                                            <Form.Item
                                                name="linkTo"
                                                label="Link to"
                                            >
                                                <Input placeholder="Enter the url to go to when the image is clicked..." />
                                            </Form.Item>
                                        </Col>

                                        <Col span={ 8 }>
                                            <Form.Item
                                                name="target"
                                                label="Open in"
                                                initialValue={{ value: "_blank" }}
                                            >
                                                <Select labelInValue>
                                                    <Select.Option value="_parent">This window</Select.Option>
                                                    <Select.Option value="_blank">New window</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <div style={{ margin: '20px 0 0 0' }}></div>

                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { onCancel(); } }>Cancel</Button>
                                        <Button className="btnAdd" type="primary" htmlType="submit">Save</Button>
                                    </Form.Item>
                                </>
                            ) : (nodeTypeOptions[nodeType] === 'Carousel') ? (
                                (addCarouselNodeStep === 1) ? (
                                    <>
                                        <Form.Item>
                                            Image 
                                            <Switch 
                                                style={{ float: 'right' }}
                                                defaultChecked
                                                checked={ addCarouselNodeFields && addCarouselNodeFields['image'] ? true : false }
                                                disabled 
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            Title 
                                            <Switch
                                                style={{ float: 'right' }} 
                                                checked={ addCarouselNodeFields && addCarouselNodeFields['title'] ? true : false }
                                                onChange={ (checked) => { updateAddCarouselNodeFields(addCarouselNodeFields => { return { ...addCarouselNodeFields, title: checked } }) } } 
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            Description
                                            <Switch 
                                                style={{ float: 'right' }} 
                                                checked={ addCarouselNodeFields && addCarouselNodeFields['description'] ? true : false }
                                                onChange={ (checked) => { updateAddCarouselNodeFields(addCarouselNodeFields => { return { ...addCarouselNodeFields, description: checked } }) } } 
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            Button(s)
                                            <Switch
                                                style={{ float: 'right' }}
                                                checked={ addCarouselNodeFields && addCarouselNodeFields['buttons'] ? true : false }
                                                onChange={ (checked) => { updateAddCarouselNodeFields(addCarouselNodeFields => { return { ...addCarouselNodeFields, buttons: checked } }) } } 
                                            />
                                        </Form.Item>

                                        <div style={{ margin: '60px 0 0 0' }}></div>

                                        <Form.Item style={{ textAlign: 'right' }}>
                                            <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { onCancel(); } }>Cancel</Button>
                                            <Button className="btnAdd" onClick={ () => { setAddCarouselNodeStep(addCarouselNodeStep + 1) } }>Next</Button>
                                        </Form.Item>
                                    </>
                                ) : (addCarouselNodeStep === 2) ? (
                                    <>
                                        <Form.List name="carouselItems" initialValue={ [{ title: '', description: '', buttons: null }] }>
                                            { (fields, { add, move, remove }) => (
                                                <>
                                                    <Form.Item>
                                                        <Button className="btnPlain" onClick={ () => { add() } } style={{ width: '270px', textAlign: 'left', fontWeight: 500 }}>
                                                            <PlusOutlined /> Add a carousel item
                                                        </Button>
                                                    </Form.Item>

                                                    <div style={{ display: 'flex', alignItems: 'flex-start', overflow: 'auto' }}>
                                                        { fields.map((carouselItemField) => (
                                                            <div key={ carouselItemField.key }>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {
                                                                        (carouselItemField.name > 0) ? (
                                                                            <Button 
                                                                                className="btnTransparent box-shadow-none px-1" 
                                                                                onClick={
                                                                                    () => {
                                                                                        move(carouselItemField.name, carouselItemField.name - 1);
                                                                                        updateCarouselItemsImages(carouselItemsImages => {
                                                                                            carouselItemsImages.splice(carouselItemField.name - 1, 0, carouselItemsImages.splice(carouselItemField.name, 1)[0]);
                                                                                            return carouselItemsImages;
                                                                                        });
                                                                                        updateCarouselItemsButtons(carouselItemsButtons => {
                                                                                            carouselItemsButtons.splice(carouselItemField.name - 1, 0, carouselItemsButtons.splice(carouselItemField.name, 1)[0]);
                                                                                            return carouselItemsButtons;
                                                                                        });
                                                                                    }
                                                                                }
                                                                            >
                                                                                <ArrowLeftOutlined style={{ fontSize: '18px', color: '#40456B' }} />
                                                                            </Button>
                                                                        ) : (
                                                                            <Button className="btnTransparent box-shadow-none px-1" disabled>
                                                                                <PauseOutlined style={{ fontSize: '18px', color: '#CCCCCC' }} />
                                                                            </Button>
                                                                        )
                                                                    }

                                                                    {
                                                                        (carouselItemField.name < fields.length - 1) ? (
                                                                            <Button
                                                                                className="btnTransparent box-shadow-none px-1"
                                                                                onClick={
                                                                                    () => {
                                                                                        move(carouselItemField.name, carouselItemField.name + 1);
                                                                                        updateCarouselItemsImages(carouselItemsImages => {
                                                                                            carouselItemsImages.splice(carouselItemField.name + 1, 0, carouselItemsImages.splice(carouselItemField.name, 1)[0]);
                                                                                            return carouselItemsImages;
                                                                                        });
                                                                                        updateCarouselItemsButtons(carouselItemsButtons => {
                                                                                            carouselItemsButtons.splice(carouselItemField.name + 1, 0, carouselItemsButtons.splice(carouselItemField.name, 1)[0]);
                                                                                            return carouselItemsButtons;
                                                                                        });
                                                                                    }
                                                                                }
                                                                            >
                                                                                <ArrowRightOutlined style={{ fontSize: '18px', color: '#40456B' }} />
                                                                            </Button>
                                                                        ) : (
                                                                            <Button className="btnTransparent box-shadow-none px-1" disabled>
                                                                                <PauseOutlined style={{ fontSize: '18px', color: '#CCCCCC' }} />
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="mx-4 my-3" style={{ position: 'relative', flex: '0 0 270px', width: '270px', border: '1px solid #EBECFF' }}>
                                                                    <Upload.Dragger
                                                                        name="file"
                                                                        accept="image/*"
                                                                        className="dragger_addCarouselNode"
                                                                        multiple={ false }
                                                                        action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + chatbotId + '/nodes/files/upload-image' }
                                                                        headers={{ authorization: localStorage.getItem('token'), clientId: currentUser.clientId }}
                                                                        style={{ position: 'relative', background: '#F0F0F0', border: '1px solid #EBECFF', padding: '0 0 0 0 !important' }}
                                                                        itemRender={ () => null }
                                                                        onChange={
                                                                            (info) => {
                                                                                setUploadProgress(parseInt(info.file.percent));

                                                                                if(info.file) {
                                                                                    if(info.file.status === 'uploading') {

                                                                                    } else if(info.file.status === 'done') {
                                                                                        if(info.file.response && info.file.response.file) {
                                                                                            updateCarouselItemsImages(carouselItemsImages => {
                                                                                                if(!carouselItemsImages || !Array.isArray(carouselItemsImages) || carouselItemsImages.length <= 0) carouselItemsImages = [];

                                                                                                for(let i = 0; i <= carouselItemField.name; i++) {
                                                                                                    if(!carouselItemsImages[i]) carouselItemsImages[i] = null;
                                                                                                }

                                                                                                carouselItemsImages[carouselItemField.name] = info.file.response.file;

                                                                                                return carouselItemsImages;
                                                                                            });
                                                                                        }
                                                                                    } else if (info.file.status === 'error') {
        
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    >
                                                                        {
                                                                            (carouselItemsImages && carouselItemsImages[carouselItemField.name]) ? (
                                                                                <>
                                                                                    <img
                                                                                        src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + chatbotId + '/nodes/files/view-image/' + carouselItemsImages[carouselItemField.name].fileName } alt={ carouselItemsImages[carouselItemField.name].fileName } 
                                                                                        style={{ width: '100%' }} 
                                                                                        onDragStart={ (e) => { e.preventDefault(); } }
                                                                                    />

                                                                                    <div style={{ position: 'absolute', top: 'calc(50% - 24px)', left: 'calc(50% - 23px)', background: 'rgba(0, 0, 0, 0.75)', borderRadius: '100px', padding: '12px 16px' }}>
                                                                                        <EditOutlined style={{ fontSize: '16px', color: '#FFFFFF' }} />
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <div className="py-4">
                                                                                    <p className="ant-upload-drag-icon pl-4 pr-4"><PictureOutlined style={{ color: '#9F9F9F' }} /></p>
                                                                                    <p className="ant-upload-text pl-4 pr-4" style={{ fontSize: '13px', color: '#9F9F9F' }}>Click or drag single file to this area to upload.</p>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Upload.Dragger>

                                                                    {
                                                                        (addCarouselNodeFields && addCarouselNodeFields.title) && (
                                                                            <Form.Item
                                                                                name={ [carouselItemField.name, 'title'] }
                                                                                fieldKey={ [carouselItemField.fieldKey, 'title'] }
                                                                                className="box-shadow-none m-0"
                                                                            >
                                                                                <Input placeholder="Enter a title..." style={{ border: '1px solid #EBECFF', borderRadius: 0, lineHeight: '32px' }} />
                                                                            </Form.Item>
                                                                        )
                                                                    }

                                                                    {
                                                                        (addCarouselNodeFields && addCarouselNodeFields.description) && (
                                                                            <Form.Item
                                                                                name={ [carouselItemField.name, 'description'] }
                                                                                fieldKey={ [carouselItemField.fieldKey, 'description'] }
                                                                                className="box-shadow-none m-0"
                                                                            >
                                                                                <Input.TextArea placeholder="Enter a description..." rows={ 4 } style={{ border: '1px solid #EBECFF', borderRadius: 0, lineHeight: '32px' }} />
                                                                            </Form.Item>
                                                                        )
                                                                    }

                                                                    {
                                                                        (addCarouselNodeFields && addCarouselNodeFields.buttons) && (
                                                                            <>
                                                                                {
                                                                                    carouselItemsButtons && carouselItemsButtons[carouselItemField.name] && Array.isArray(carouselItemsButtons[carouselItemField.name]) && carouselItemsButtons[carouselItemField.name].length > 0 && carouselItemsButtons[carouselItemField.name].map((button, index) => {
                                                                                        return (
                                                                                            <Button 
                                                                                                key={ index }
                                                                                                onClick={
                                                                                                    () => {
                                                                                                        setAddCarouselButtonFormVisibleFor(null);

                                                                                                        setTimeout(() => {
                                                                                                            setEditCarouselButtonFormVisibleFor(null);
                                                                                                            setEditCarouselButtonFormVisibleFor({ carouselItem: carouselItemField.name, buttonIndex: index });
                                                                                                        }, 100);
                                                                                                    }
                                                                                                }
                                                                                                className="m-0"
                                                                                                style={ 
                                                                                                    button.type && buttonTypeStyles && buttonTypeStyles[button.type] ? (
                                                                                                        {
                                                                                                            ...buttonTypeStyles[button.type],
                                                                                                            display: 'block',
                                                                                                            borderRadius: '0',
                                                                                                            width: '100%'
                                                                                                        }
                                                                                                    ) : (
                                                                                                        {
                                                                                                            display: 'block',
                                                                                                            background: '#EBECFF',
                                                                                                            borderRadius: '0',
                                                                                                            border: '1px solid #EBECFF',
                                                                                                            width: '100%',
                                                                                                            padding: '4px 16px',
                                                                                                            fontSize: '11px',
                                                                                                            lineHeight: '14px',
                                                                                                            color: '#40456B',
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                { button.label }
                                                                                            </Button>
                                                                                        );
                                                                                    })
                                                                                }

                                                                                {
                                                                                    (editCarouselButtonFormVisibleFor && carouselItemField.name === editCarouselButtonFormVisibleFor.carouselItem) && (
                                                                                        <>
                                                                                            <Select
                                                                                                labelInValue
                                                                                                placeholder="Select a button type..."
                                                                                                className="addNodeModal_addCarouselBlock_select box-shadow-none"
                                                                                                style={{ width: '100%', border: '0 none', borderRadius: 0 }}
                                                                                                defaultValue={
                                                                                                    () => {
                                                                                                        if(carouselItemsButtons && Array.isArray(carouselItemsButtons) && carouselItemsButtons.length > 0) {
                                                                                                            if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem] && Array.isArray(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem]) && carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem].length > 0) {
                                                                                                                if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex]) {
                                                                                                                    return { value: carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex].type };
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        return {};
                                                                                                    }
                                                                                                }
                                                                                                onChange={
                                                                                                    (selected) => {
                                                                                                        updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => {
                                                                                                            if(!buttonToBeAddedForAddCarouselNode) buttonToBeAddedForAddCarouselNode = {}

                                                                                                            return {
                                                                                                                ...buttonToBeAddedForAddCarouselNode,
                                                                                                                type: selected.value
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    buttonTypeOptions && Object.keys(buttonTypeOptions).map(key => <Select.Option key={ key } value={ key }>{ buttonTypeOptions[key] }</Select.Option>)
                                                                                                }
                                                                                            </Select>

                                                                                            <Input 
                                                                                                placeholder="Enter a label for button..." 
                                                                                                className="box-shadow-none"
                                                                                                style={{ background: '#EBECFF', border: '1px solid #F0F0F0', borderRadius: 0, lineHeight: '32px' }} 
                                                                                                defaultValue={
                                                                                                    (carouselItemsButtons && Array.isArray(carouselItemsButtons) && carouselItemsButtons.length > 0) && (
                                                                                                        (carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem] && Array.isArray(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem]) && carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem].length > 0) && (
                                                                                                            (carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex]) && (
                                                                                                                carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex].label
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                }
                                                                                                onChange={ 
                                                                                                    (event) => {
                                                                                                        let label = event.target.value;
                                                                                                        updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => {
                                                                                                            if(!buttonToBeAddedForAddCarouselNode) buttonToBeAddedForAddCarouselNode = {}

                                                                                                            return {
                                                                                                                ...buttonToBeAddedForAddCarouselNode,
                                                                                                                label: label
                                                                                                            }
                                                                                                        })
                                                                                                    } 
                                                                                                }    
                                                                                            />

                                                                                            {
                                                                                                (buttonToBeAddedForAddCarouselNode && buttonToBeAddedForAddCarouselNode.type && buttonTypeOptions && buttonTypeOptions[buttonToBeAddedForAddCarouselNode.type] && buttonTypeOptions[buttonToBeAddedForAddCarouselNode.type] === 'Link') && (
                                                                                                    <Input 
                                                                                                        placeholder="Enter a URL for button..." 
                                                                                                        className="box-shadow-none"
                                                                                                        style={{ background: '#EBECFF', border: '1px solid #F0F0F0', borderRadius: 0, lineHeight: '32px' }} 
                                                                                                        defaultValue={
                                                                                                            () => {
                                                                                                                if(carouselItemsButtons && Array.isArray(carouselItemsButtons) && carouselItemsButtons.length > 0) {
                                                                                                                    if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem] && Array.isArray(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem]) && carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem].length > 0) {
                                                                                                                        if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex]) {
                                                                                                                            return carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex].url;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
            
                                                                                                                return '';
                                                                                                            }
                                                                                                        }
                                                                                                        onChange={ 
                                                                                                            (event) => {
                                                                                                                let url = event.target.value;
                                                                                                                updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => {
                                                                                                                    if(!buttonToBeAddedForAddCarouselNode) buttonToBeAddedForAddCarouselNode = {}

                                                                                                                    return {
                                                                                                                        ...buttonToBeAddedForAddCarouselNode,
                                                                                                                        url: url
                                                                                                                    }
                                                                                                                })
                                                                                                            } 
                                                                                                        }    
                                                                                                    />
                                                                                                )
                                                                                            }

                                                                                            <Row>
                                                                                                <Col span={ 8 }>
                                                                                                    <Button 
                                                                                                        className="btnDelete" 
                                                                                                        style={{ border: '1px solid #EBECFF', borderRadius: '0px' }} 
                                                                                                        block
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForEditCarouselNode => null);

                                                                                                                updateCarouselItemsButtons(carouselItemsButtons => {
                                                                                                                    if(carouselItemsButtons && Array.isArray(carouselItemsButtons) && carouselItemsButtons.length > 0) {
                                                                                                                        if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem] && Array.isArray(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem]) && carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem].length > 0) {
                                                                                                                            if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex]) {
                                                                                                                                carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem].splice(editCarouselButtonFormVisibleFor.buttonIndex, 1);
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    return carouselItemsButtons;
                                                                                                                })

                                                                                                                setEditCarouselButtonFormVisibleFor(null);
                                                                                                            }
                                                                                                        }
                                                                                                    >
                                                                                                        Delete
                                                                                                    </Button>
                                                                                                </Col>

                                                                                                <Col span={ 8 }>
                                                                                                    <Button 
                                                                                                        className="btnDefault" 
                                                                                                        style={{ border: '1px solid #EBECFF', borderRadius: '0px' }} 
                                                                                                        block
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => null);
                                                                                                                setEditCarouselButtonFormVisibleFor(null);
                                                                                                            }
                                                                                                        }
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                </Col>

                                                                                                <Col span={ 8 }>
                                                                                                    <Button 
                                                                                                        className="btnAdd" 
                                                                                                        style={{ border: '1px solid #EBECFF', borderRadius: '0px' }} 
                                                                                                        block 
                                                                                                        icon={<SaveOutlined />}
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                if(!buttonToBeAddedForAddCarouselNode || !buttonToBeAddedForAddCarouselNode.type || !buttonToBeAddedForAddCarouselNode.label) {
                                                                                                                    message.error('You must enter a valid type and label for the button.');
                                                                                                                    return;
                                                                                                                }   

                                                                                                                updateCarouselItemsButtons(carouselItemsButtons => {
                                                                                                                    if(carouselItemsButtons && Array.isArray(carouselItemsButtons) && carouselItemsButtons.length > 0) {
                                                                                                                        if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem] && Array.isArray(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem]) && carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem].length > 0) {
                                                                                                                            if(carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex]) {
                                                                                                                                carouselItemsButtons[editCarouselButtonFormVisibleFor.carouselItem][editCarouselButtonFormVisibleFor.buttonIndex] = buttonToBeAddedForAddCarouselNode;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    return carouselItemsButtons;
                                                                                                                })

                                                                                                                setEditCarouselButtonFormVisibleFor(null);
                                                                                                            }
                                                                                                        }    
                                                                                                    >
                                                                                                        Save
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    (carouselItemField.name === addCarouselButtonFormVisibleFor) ? (
                                                                                        <>
                                                                                            <Select
                                                                                                labelInValue
                                                                                                placeholder="Select a button type..."
                                                                                                className="addNodeModal_addCarouselBlock_select box-shadow-none"
                                                                                                style={{ width: '100%', border: '0 none', borderRadius: 0 }}
                                                                                                onChange={
                                                                                                    (selected) => {
                                                                                                        updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => {
                                                                                                            if(!buttonToBeAddedForAddCarouselNode) buttonToBeAddedForAddCarouselNode = {}

                                                                                                            return {
                                                                                                                ...buttonToBeAddedForAddCarouselNode,
                                                                                                                type: selected.value
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    buttonTypeOptions && Object.keys(buttonTypeOptions).map(key => <Select.Option key={ key } value={ key }>{ buttonTypeOptions[key] }</Select.Option>)
                                                                                                }
                                                                                            </Select>

                                                                                            <Input 
                                                                                                className="box-shadow-none"
                                                                                                placeholder="Enter a label for button..." 
                                                                                                style={{ background: '#EBECFF', border: '1px solid #F0F0F0', borderRadius: 0, lineHeight: '32px' }} 
                                                                                                onChange={ 
                                                                                                    (event) => {
                                                                                                        let label = event.target.value;
                                                                                                        updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => {
                                                                                                            if(!buttonToBeAddedForAddCarouselNode) buttonToBeAddedForAddCarouselNode = {}

                                                                                                            return {
                                                                                                                ...buttonToBeAddedForAddCarouselNode,
                                                                                                                label: label
                                                                                                            }
                                                                                                        })
                                                                                                    } 
                                                                                                }    
                                                                                            />

                                                                                            {
                                                                                                (buttonToBeAddedForAddCarouselNode && buttonToBeAddedForAddCarouselNode.type && buttonTypeOptions && buttonTypeOptions[buttonToBeAddedForAddCarouselNode.type] && buttonTypeOptions[buttonToBeAddedForAddCarouselNode.type] === 'Link') && (
                                                                                                    <Input 
                                                                                                        className="box-shadow-none"
                                                                                                        placeholder="Enter a URL for button..." 
                                                                                                        style={{ background: '#EBECFF', border: '1px solid #F0F0F0', borderRadius: 0, lineHeight: '32px' }} 
                                                                                                        onChange={ 
                                                                                                            (event) => {
                                                                                                                let url = event.target.value;
                                                                                                                updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => {
                                                                                                                    if(!buttonToBeAddedForAddCarouselNode) buttonToBeAddedForAddCarouselNode = {}

                                                                                                                    return {
                                                                                                                        ...buttonToBeAddedForAddCarouselNode,
                                                                                                                        url: url
                                                                                                                    }
                                                                                                                })
                                                                                                            } 
                                                                                                        }    
                                                                                                    />
                                                                                                )
                                                                                            }

                                                                                            <Row>
                                                                                                <Col span={ 12 }>
                                                                                                    <Button 
                                                                                                        className="btnDefault" 
                                                                                                        style={{ border: '1px solid #EBECFF', borderRadius: '0px' }} 
                                                                                                        block
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                updateButtonToBeAddedForAddCarouselNode(buttonToBeAddedForAddCarouselNode => null);
                                                                                                                setAddCarouselButtonFormVisibleFor(null);
                                                                                                            }
                                                                                                        }
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                </Col>

                                                                                                <Col span={ 12 }>
                                                                                                    <Button 
                                                                                                        className="btnAdd" 
                                                                                                        style={{ border: '1px solid #EBECFF', borderRadius: '0px' }} 
                                                                                                        block 
                                                                                                        icon={ <SaveOutlined /> }
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                if(!buttonToBeAddedForAddCarouselNode || !buttonToBeAddedForAddCarouselNode.type || !buttonToBeAddedForAddCarouselNode.label) {
                                                                                                                    message.error('You must enter a valid type and label for the button.');
                                                                                                                    return;
                                                                                                                }

                                                                                                                updateCarouselItemsButtons(carouselItemsButtons => {
                                                                                                                    if(!carouselItemsButtons || !Array.isArray(carouselItemsButtons) || carouselItemsButtons.length <= 0) carouselItemsButtons = [];

                                                                                                                    for(let i = 0; i <= carouselItemField.name; i++) {
                                                                                                                        if(!carouselItemsButtons[i] || !Array.isArray(carouselItemsButtons[i])) carouselItemsButtons[i] = [];
                                                                                                                    }

                                                                                                                    carouselItemsButtons[carouselItemField.name].push(buttonToBeAddedForAddCarouselNode);

                                                                                                                    return carouselItemsButtons;
                                                                                                                })

                                                                                                                setAddCarouselButtonFormVisibleFor(null);
                                                                                                            }
                                                                                                        }    
                                                                                                    >
                                                                                                        Save
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : (
                                                                                        <Form.Item className="m-0">
                                                                                            <Button className="btnPlain" style={{ border: '2px solid #EBECFF' }} onClick={ () => { setEditCarouselButtonFormVisibleFor(null); setAddCarouselButtonFormVisibleFor(carouselItemField.name) } } block icon={<PlusOutlined />}>
                                                                                                Add button
                                                                                            </Button>
                                                                                        </Form.Item>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                    
                                                                    {
                                                                        (fields.length > 1) && (
                                                                            <Button 
                                                                                style={{ position: 'absolute', top: '-10px', right: '-10px', background: '#F0F0FB', borderRadius: '100px', height: '50px', padding: '15px 15px 15px 15px' }}
                                                                                onClick={() => { 
                                                                                    remove(carouselItemField.name);

                                                                                    updateCarouselItemsImages(carouselItemsImages => {
                                                                                        if(carouselItemsImages && Array.isArray(carouselItemsImages)) carouselItemsImages.splice(carouselItemField.name, 1);
                                                                                        return carouselItemsImages;
                                                                                    });

                                                                                    updateCarouselItemsButtons(carouselItemsButtons => {
                                                                                        if(carouselItemsButtons && Array.isArray(carouselItemsButtons)) carouselItemsButtons.splice(carouselItemField.name, 1);
                                                                                        return carouselItemsButtons;
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <DeleteOutlined style={{ fontSize: '16px', color: '#40456B' }} />
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <Form.Item style={{ textAlign: 'right' }}>
                                                        <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { onCancel(); } }>Cancel</Button>
                                                        <Button className="btnSecondary mr-2" style={{ background: '#FFFFFF' }} onClick={ () => { setAddCarouselNodeStep(addCarouselNodeStep - 1) } }>Back</Button>
                                                        <Button className="btnAdd" type="primary" htmlType="submit">Save</Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </>
                                ) : (
                                    null
                                )
                            ) : (
                                null
                            )
                        )
                    }
                </div>
            </Form>
        </Modal>
    )
};

export default AddNodeModal;