import React from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';


import string2Hex from '../../utils/String2Hex';

import moment from 'moment';

import { Link } from 'react-router-dom';
import { Layout, Menu, Dropdown, Badge, Avatar, Switch } from 'antd';
import { DownOutlined, KeyOutlined, LogoutOutlined, InfoCircleOutlined, AppstoreOutlined } from '@ant-design/icons';

import './Navbar.css';
import classes from './Navbar.module.css';

const Navbar = (props) => {   
    const {
        isSocketConnected,
        currentUser, updateCurrentUser, updateCurrentUserParameter,
        systemVariables,
        client,
        operators,
        notificationsDataset, openNotification
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const availabilityOptions = (systemVariables && systemVariables.user && systemVariables.user.availabilityOptions ? systemVariables.user.availabilityOptions : null);

    const handleToggleAutomaticallyObserveAllChats = (isActive) => {
        updateCurrentUserParameter('automaticallyObserveAllChats', (isActive ? 1 : 0), () => {
            socket.emit('operator.connect');
        });
    }

    const handleToggleAutomaticallyAcceptChats = (isActive) => {
        updateCurrentUserParameter('automaticallyAcceptChats', (isActive ? 1 : 0), () => {
            socket.emit('operator.connect');
        });
    }

    const handleUpdateAvailability = (availability) => {
        if(!availability) return;
        if(!availabilityOptions) return;
        
        let availabilityKey = Object.keys(availabilityOptions).find(key  => availabilityOptions[key] === availability);
        if(!availabilityKey) return;

        socket.emit('operator.availability.update', { availability: availabilityKey }, (ack) => {
            if(ack && ack.result) {
                if(ack.operator && ack.operator.availability) {
                    updateCurrentUser(currentUser => {
                        currentUser.availability = ack.operator.availability;
                    });

                    if(availability === 'Online') {
                        socket.emit('operator.connect');
                    }
                }
            }
        });
    };

    const logout = () => {
        socket.emit('operator.logout');
        
        localStorage.removeItem('token');
        window.location.href = '/login';
        return;
    };

    return (
        <>
            {
                ((currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'GOD')].includes(''+currentUser.role)) || (currentUser && client && operators)) && (
                    <>
                        <Layout.Header className={ classes.header }>
                            <div className={ classes.actions }>
                                <span style={{ margin: '0 45px 0 0' }}>
                                    { isSocketConnected ? <span style={{ background: '#52c41a', padding: '5px 15px 5px 15px' }}>Connection Established</span> : <span style={{ background: '#DC143C', color: '#FFFFFF', padding: '5px 15px 5px 15px' }}>Connection lost</span> }
                                </span>


                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                        <span style={{ color: '#ffffff', margin: '0 45px 0 0' }}>
                                            <Switch defaultChecked={ (currentUser && currentUser.parameters && currentUser.parameters.automaticallyObserveAllChats) } onChange={ (checked) => { handleToggleAutomaticallyObserveAllChats(checked) } } /> Automatically Observe All Chats
                                        </span>
                                    )
                                }

                                <span style={{ color: '#ffffff', margin: '0 15px 0 0' }}>
                                    <Switch defaultChecked={ (currentUser && currentUser.parameters && currentUser.parameters.automaticallyAcceptChats) } onChange={ (checked) => { handleToggleAutomaticallyAcceptChats(checked) } } /> Automatically Accept Chats
                                </span>
                                
                                <Dropdown className="notificationsMenu" trigger={ ['click'] } overlay={ 
                                    <Menu { ...props } style={{ maxWidth: '400px' }}>
                                        {
                                            (operators && notificationsDataset && notificationsDataset.notifications) ? (
                                                <>
                                                    {
                                                        Object.values(notificationsDataset.notifications).map(notification => {
                                                            return (
                                                                <Menu.Item onItemHover={ () => {} } onClick={ () => openNotification(notification) } key={ notification._id }>
                                                                    <div className={ classes.notificationC }>
                                                                        <div className={ classes.notificationBody }>
                                                                            <div className={ classes.flexRow }>
                                                                                <div className={ classes.colContentPaneLeft }>
                                                                                    {
                                                                                        (notification.notifiedBy && operators && operators[notification.notifiedBy] && operators[notification.notifiedBy].displayName) ? (
                                                                                            <Avatar style={ 
                                                                                                (currentUser.displayName === operators[notification.notifiedBy].displayName) ? (
                                                                                                    { margin: '4px 0 0 0' }
                                                                                                ) : (
                                                                                                    { background: string2Hex(operators[notification.notifiedBy].displayName), margin: '4px 0 0 0' }
                                                                                                )
                                                                                            }>
                                                                                                { operators[notification.notifiedBy].displayName && operators[notification.notifiedBy].displayName[0] }
                                                                                            </Avatar>
                                                                                        ) : (
                                                                                            <Avatar style={{ background: string2Hex('system'), margin: '4px 0 0 0' }}>S</Avatar>
                                                                                        )
                                                                                    }
                                                                                </div>

                                                                                <div className={ classes.colContentPaneRight }>
                                                                                    <div className={ classes.notifiedBy }>{ (notification.notifiedBy && operators && operators[notification.notifiedBy] ? operators[notification.notifiedBy].displayName : 'System Notification') }{ !notification.seen ? <span className={ classes.seenIndicator }>&#8226;</span> : null }</div>
                                                                                    <div className={ classes.message }>{ (notification.message ? notification.message : 'Notification') }</div>
                                                                                    <div className={ classes.dateAndTime }>{ moment(notification.createdAt).format('DD.MM.YYYY - hh:mm:ss') }</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Menu.Item>
                                                            );
                                                        })
                                                    }

                                                    <Menu.Item>
                                                        <Link className={ classes.seeAllBtn } to="/notifications"><div className="text-center py-1">See all notifications</div></Link>
                                                    </Menu.Item>
                                                </>
                                            ) : (
                                                <Menu.Item> You don't have any notifications. </Menu.Item>
                                            )
                                        }
                                    </Menu>
                                }>
                                    <Badge count={ (notificationsDataset && notificationsDataset.unseenCount ? notificationsDataset.unseenCount : 0) } size="small">
                                        <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()}><InfoCircleOutlined style={{ fontSize: '20px', margin: '0 8px 0 8px' }} /></a>
                                    </Badge>
                                </Dropdown>

                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                        <Link to='/control-panel'><AppstoreOutlined style={{ fontSize: '20px', margin: '0 8px 0 8px' }} /></Link>
                                    )
                                }

                                <Dropdown style={{ margin: '0 8px 0 8px' }} trigger={ ['click'] } overlay={ 
                                    <Menu>
                                        <Menu.Item onClick={ () => { handleUpdateAvailability('Online') } } className={ 'status online' + (currentUser && availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Online' ? ' active' : '') }>
                                            Online <Badge style={{ float: 'right' }} dot status="success"></Badge>
                                        </Menu.Item>

                                        <Menu.Item onClick={ () => { handleUpdateAvailability('Away') } } className={ 'status away' + (currentUser && availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Away' ? ' active' : '') }>
                                            Away <Badge style={{ float: 'right' }} dot status="warning"></Badge>
                                        </Menu.Item>

                                        <Menu.Item onClick={ () => { handleUpdateAvailability('Offline') } } className={ 'status offline' + (currentUser && availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Offline' ? ' active' : '') }>
                                            Offline <Badge style={{ float: 'right' }} dot status="error"></Badge>
                                        </Menu.Item>

                                        <Menu.Item>
                                            <Link to="/change-password"><KeyOutlined /> Change password</Link>
                                        </Menu.Item>

                                        <Menu.Item>
                                            <a href="#/" onClick={ logout }><LogoutOutlined /> Logout</a>
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    
                                    { 
                                        (currentUser) && (
                                            <a href="/#" className="ant-dropdown-link" onClick={ e => e.preventDefault() }>
                                                <Badge dot style={{ top: '46px', right: '6px' }} status={ 
                                                    (availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Online') ? (
                                                        'success'
                                                    ) : (availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Away') ? (
                                                        'warning'
                                                    ) : (
                                                        'error'
                                                    )
                                                }>
                                                    <Avatar style={{ margin: '0 0 0 8px'}} size={ 36 }>{ currentUser.displayName[0] }</Avatar>
                                                </Badge>
                                                <span className="ml-2">{ currentUser.displayName }</span> <DownOutlined />
                                            </a>
                                        )
                                    }
                                </Dropdown>
                            </div>
                        </Layout.Header>
                    </>
                )
            }
        </>
    );
}

export default Navbar;
