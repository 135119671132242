import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation, Switch, Route, withRouter, Redirect } from 'react-router-dom';

import { SystemContextProvider } from './Context/SystemContext';

import { socket } from './service/socket';

import debounce from 'lodash.debounce';

import { GlobalHotKeys } from 'react-hotkeys';

import ProtectedRoute from './utils/ProtectedRoute';

import Login from './Containers/Auth/Login/Login';
import ChangePassword from './Containers/Auth/ChangePassword/ChangePassword';
import Home from './Containers/Home/Home';

import GOD_LEVEL_Clients from './Containers/Clients/Clients';
import GOD_LEVEL_EditClient from './Containers/Clients/Client/Edit/Edit';
import GOD_LEVEL_Members from './Containers/Clients/Client/Members/Members';
import GOD_LEVEL_EditMember from './Containers/Clients/Client/Members/Member/Edit/Edit';

import ControlPanel from './Containers/ControlPanel/ControlPanel';
import ControlPanel_Operators from './Containers/ControlPanel/Operators/Operators';
import ControlPanel_Operators_Add from './Containers/ControlPanel/Operators/Operator/Add/Add';
import ControlPanel_Operators_Edit from './Containers/ControlPanel/Operators/Operator/Edit/Edit';
import Departments from './Containers/ControlPanel/Departments/Departments';
import EditDepartment from './Containers/ControlPanel/Departments/Department/Edit/Edit';
import DepartmentMembers from './Containers/ControlPanel/Departments/Department/Members/Members';
import Shortcuts from './Containers/ControlPanel/Shortcuts/Shortcuts';
import Settings from './Containers/ControlPanel/Settings/Settings';
import Widget from './Containers/ControlPanel/Widget/Widget';
import Notifications from './Containers/Notifications/Notifications';

import Monitor from './Containers/Monitor/Monitor';
import Visitors from './Containers/Visitors/Visitors';
import ChatQueuePendingChats from './Containers/ChatQueue/PendingChats';
import ChatQueueActiveChats from './Containers/ChatQueue/ActiveChats';
import MyChats from './Containers/MyChats/MyChats';
import PreviousChats from './Containers/PreviousChats/PreviousChats';
import ChatHistory from './Containers/PreviousChats/ChatHistory/ChatHistory';
import ArchivedPreviousChats from './Containers/PreviousChats/ArchivedPreviousChats';
import ArchivedChatHistory from './Containers/PreviousChats/ChatHistory/ArchivedChatHistory';
import Operators from './Containers/Operators/Operators';
import Channels from './Containers/Channels/Channels';
import Tickets from './Containers/Tickets/Tickets';
import Ticket from './Containers/Tickets/Ticket/Ticket';
import PersonalShortcuts from './Containers/PersonalShortcuts/PersonalShortcuts';
import BanList from './Containers/BanList/BanList';
import Chatbots from './Containers/Chatbots/Chatbots';
import Chatbot from './Containers/Chatbots/Chatbot/Chatbot';
import ChatVolume from './Containers/Reports/ChatVolume';
import OperatorPerformance from './Containers/Reports/OperatorPerformance';
import RealTime from './Containers/Reports/RealTime';

import Error from './Containers/Error/Error';
import { message } from 'antd';
import './App.less';

const App = (props) => {
	const history = useHistory();
	const location = useLocation();

	const hotKeysKeyMap = {
		TAKE_NEW_CHAT: "shift+w"
	}

	const hotKeysHandlers = {
		TAKE_NEW_CHAT: debounce((event) => {
			let takeChatCallback = (ack) => {
				if(!ack) return;

				if(ack.result && ack.chat) {
					if(location.pathname && location.pathname.startsWith('/my-chats')) {
						socket.emit('operator.chats.myChats.get', null, (ack2) => {
							if(ack2 && ack2.result) {
								history.push('/my-chats/' + ack.chat._id);
								return;
							}
						});

						return;
					}

					history.push('/my-chats/' + ack.chat._id);
					return;
				} else if(!ack.result) {
					if(ack.retry) {
						socket.emit('operator.chats.take', { force: true }, takeChatCallback);
						return;
					}
				}
	
				message.error('There are no pending chats that you can take from!');
				return;
			};

			socket.emit('operator.chats.take', { force: true }, takeChatCallback);
		}, 250)
	};

	return (
		<SystemContextProvider>
			<GlobalHotKeys allowChanges={ true } keyMap={ hotKeysKeyMap } handlers={ hotKeysHandlers }>
				<Switch>
					<Route exact path='/login'>
						{ localStorage.getItem('token') ? <Redirect to="/" /> : <Login /> }
					</Route>

					<ProtectedRoute exact path='/' component={ Home } />

					<ProtectedRoute exact path='/change-password' component={ ChangePassword } />

					<ProtectedRoute exact path='/clients' component={ GOD_LEVEL_Clients } />
					<ProtectedRoute exact path='/clients/:clientId/edit' roles={ [999] } component={ GOD_LEVEL_EditClient } />
					<ProtectedRoute exact path='/clients/:clientId/members' roles={ [999] } component={ GOD_LEVEL_Members } />
					<ProtectedRoute exact path='/clients/:clientId/members/:memberId/edit' roles={ [999] } component={ GOD_LEVEL_EditMember } />

					<ProtectedRoute exact path='/control-panel' roles={ [20, 99] } component={ ControlPanel } />
					<ProtectedRoute exact path='/control-panel/operators' roles={ [20, 99] } component={ ControlPanel_Operators } />
					<ProtectedRoute exact path='/control-panel/operators/add' roles={ [99] } component={ ControlPanel_Operators_Add } />
					<ProtectedRoute exact path='/control-panel/operators/:operatorId/edit' roles={ [20, 99] } component={ ControlPanel_Operators_Edit } />
					<ProtectedRoute exact path='/control-panel/departments' roles={ [20, 99] } component={ Departments } />
					<ProtectedRoute exact path='/control-panel/departments/:departmentId/edit' roles={ [20, 99] } component={ EditDepartment } />
					<ProtectedRoute exact path='/control-panel/departments/:departmentId/members' roles={ [20, 99] } component={ DepartmentMembers } />
					<ProtectedRoute exact path='/control-panel/shortcuts' roles={ [20, 99] } component={ Shortcuts } />
					<ProtectedRoute exact path='/control-panel/settings' roles={ [20, 99] } component={ Settings } />
					<ProtectedRoute exact path='/control-panel/widget' roles={ [20, 99] } component={ Widget } />

					<ProtectedRoute exact path='/monitor' component={ Monitor } />

					<ProtectedRoute exact path='/visitors' component={ Visitors } />
					
					<ProtectedRoute exact path='/chat-queue' render={ () => <Redirect to="/chat-queue/pending-chats" /> } />
					<ProtectedRoute exact path='/chat-queue/pending-chats' component={ ChatQueuePendingChats } />
					<ProtectedRoute exact path='/chat-queue/active-chats' component={ ChatQueueActiveChats } />
					
					<ProtectedRoute exact path='/my-chats' component={ MyChats } />
					<ProtectedRoute exact path='/my-chats/:activeChatId' component={ MyChats } />

					<ProtectedRoute exact path='/chat-history' component={ PreviousChats } />
					<ProtectedRoute exact path='/chat-history/:chatId' component={ ChatHistory } />
					<ProtectedRoute exact path='/archived-chat-history' component={ ArchivedPreviousChats } />
					<ProtectedRoute exact path='/archived-chat-history/:chatId' component={ ArchivedChatHistory } />
					

					<ProtectedRoute exact path='/operators' component={ Operators } />
					<ProtectedRoute exact path='/operators/chats' component={ Operators } />
					<ProtectedRoute exact path='/operators/chats/:activeChatId' component={ Operators } />
					<ProtectedRoute exact path='/operators/channels' component={ Channels } />
					<ProtectedRoute exact path='/operators/channels/:activeChatId' component={ Channels } />

					<ProtectedRoute exact path='/tickets' component={ Tickets } />
					<ProtectedRoute exact path='/tickets/:ticketId' component={ Ticket } />

					<ProtectedRoute exact path='/personal-shortcuts' component={ PersonalShortcuts } />

					<ProtectedRoute exact path='/ban-list' roles={ [20, 99] } component={ BanList } />

					<ProtectedRoute exact path='/chatbots' roles={ [20, 99] } component={ Chatbots } />
					<ProtectedRoute exact path='/chatbots/:chatbotId' roles={ [20, 99] } component={ Chatbot } />

					<ProtectedRoute exact path='/reports/chat-volume' roles={ [20, 99] } component={ ChatVolume } />
					<ProtectedRoute exact path='/reports/operator-performance' roles={ [20, 99] } component={ OperatorPerformance } />
					<ProtectedRoute exact path='/reports/real-time' roles={ [20, 99] } component={ RealTime } />

					<ProtectedRoute exact path='/notifications' component={ Notifications } />

					<ProtectedRoute exact path='/bad-request' render={ () => <Error status="400" message="Bad request" /> } />
					<ProtectedRoute exact path='/unauthorized' render={ () => <Error status="403" message="Unauthorized" /> } />
					<ProtectedRoute exact path='/file-not-found' render={ () => <Error status="404" message="File not found" /> } />
					<ProtectedRoute exact path='/internal-server-error' render={ () => <Error status="500" message="Internal Server Error" /> } />

					<Route render={ () => <Error status="404" message="File not found" /> } />
				</Switch>
			</GlobalHotKeys>
		</SystemContextProvider>
	);
}

export default withRouter(App);
