import React from 'react';
import { useSystemContext } from '../../../../../Context/SystemContext';

import { Handle } from 'react-flow-renderer';

import { Button } from 'antd';

const ButtonNode = ({ data, isConnectable, ...restProps }) => {
    const {
        systemVariables
    } = useSystemContext();
    
    const buttonTypeStyles = (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeStyles) || null;

    return (
        <div className="px-1 py-1" style={ data.node.withError && { background: 'rgba(255, 0, 0, 0.25)', border: '1px solid rgb(255, 0, 0)', borderRadius: '5px' } }>
            <Handle
                type="target"
                position="top"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            <Handle
                type="source"
                position="bottom"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            {
                (data && data.node && data.node.parameters && data.node.parameters.buttons && Array.isArray(data.node.parameters.buttons) && data.node.parameters.buttons.length > 0) && (
                    data.node.parameters.buttons.map((button, index) => {
                        return (
                            <Button 
                                key={ index }
                                onClick={ () => {} }
                                className="mx-1 my-1"
                                style={ 
                                    button.type && buttonTypeStyles && buttonTypeStyles[button.type] ? buttonTypeStyles[button.type] : (
                                        {
                                            display: 'inline-block',
                                            background: '#EBECFF',
                                            border: '1px solid #EBECFF',
                                            borderRadius: '100px',
                                            padding: '4px 16px',
                                            fontSize: '11px',
                                            lineHeight: '14px',
                                            color: '#40456B'
                                        }
                                    )
                                }
                            >
                                { button.label }
                            </Button>
                        )
                    })
                )
            }
        </div>
    );
};

export default ButtonNode;