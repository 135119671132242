import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../../Context/SystemContext';

import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';
import MainLayout from '../../../Components/Layouts/MainLayout';

import Error from '../../Error/Error';

import ChatlineAPI from '../../../utils/ChatlineAPI';

import { Table, Button, Modal, Form, Input, message, Tag, Spin, Row, Col, Select } from 'antd';
import { PlusCircleOutlined, EditOutlined, TeamOutlined, DeleteOutlined, ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';

const Departments = (props) => {
    const { 
        systemVariables
    } = useSystemContext();
    
    const priorityOptions = (systemVariables && systemVariables.department && systemVariables.department.priorityOptions ? systemVariables.department.priorityOptions : null);
    const visibilityOptions = (systemVariables && systemVariables.department && systemVariables.department.visibilityOptions ? systemVariables.department.visibilityOptions : null);
    const statusOptions = (systemVariables && systemVariables.department && systemVariables.department.statusOptions ? systemVariables.department.statusOptions : null);

    const [departmentsDataset, setDepartmentsDataset] = useState(null);
    const [departmentsParameters, updateDepartmentsParameters] = useImmer(null);

    const [addDepartmentModalIsVisible, setAddDepartmentModalVisibility] = useState(false);
    const [addDepartmentForm] = Form.useForm();
    const [addDepartmentErrors, setAddDepartmentErrors] = useState(null);

    const [filtersForm] = Form.useForm();

    const [error, setError] = useState(null);

    const getDepartments = useCallback((page = 1, filters = null) => {
        filters = (filters && Object.keys(filters).length > 0 ? Object.keys(filters).map(key => key + '=' + filters[key]).join('&') : '');

        ChatlineAPI.HttpGetRequest('departments?page=' + page + (filters ? ('&'+filters) : ''), (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setDepartmentsDataset(res.data); }
        });
    }, []);

    useEffect(() => {
        if(departmentsParameters) getDepartments((departmentsParameters.page || 1), (departmentsParameters.filters || null));
    }, [getDepartments, departmentsParameters]);

    useEffect(() => {
        updateDepartmentsParameters(departmentsParameters => {
            return { page: 1, filters: null };
        });
    }, [updateDepartmentsParameters]);

    const handleDepartmentsTableChange = (pagination, filters, sorter) => {
        updateDepartmentsParameters(departmentsParameters => {
            departmentsParameters.page = 1;
        });
    }

    const onApplyFilter = (values) => {
        if(!values) {
            updateDepartmentsParameters(departmentsParameters => {
                departmentsParameters.page = 1;
                departmentsParameters.filters = null;
            });
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            if(values[key]) filters[key] = (''+values[key]).trim();
        }
        
        updateDepartmentsParameters(departmentsParameters => {
            departmentsParameters.page = 1;
            departmentsParameters.filters = filters;
        });
    }

    const onResetFields = () => {
        filtersForm.resetFields();

        updateDepartmentsParameters(departmentsParameters => {
            departmentsParameters.page = 1;
            departmentsParameters.filters = null;
        });
    }

    const handleAddDepartment = values => {
        ChatlineAPI.HttpPostRequest('departments/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }
                    setAddDepartmentErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.'); return;
            }

            if(res.data && res.data.result === true) {
                setAddDepartmentModalVisibility(false);

                addDepartmentForm.resetFields();

                updateDepartmentsParameters(departmentsParameters => {
                    return { ...departmentsParameters };
                });
            }
        });
    };

    const handleDeleteDepartment = (departmentId, displayName) => {
        Modal.confirm({
            title: 'Department: ' + displayName,
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('departments/' + departmentId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }
        
                    if(res.data && res.data.result === true) {
                        message.success('Selected record has successfully been deleted.');
                        
                        updateDepartmentsParameters(departmentsParameters => {
                            return { ...departmentsParameters };
                        });

                        return;
                    }
                });
            },
            onCancel() {
            },
        });
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            width: '120px',
            render: (priority) => (priorityOptions && priorityOptions[priority])
        },
        {
            title: 'Visibility',
            dataIndex: 'isVisibleToClient',
            key: 'isVisibleToClient',
            width: '160px',
            render: (isVisibleToClient) => (visibilityOptions && visibilityOptions[isVisibleToClient])
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (status) => {
                if(!statusOptions || !statusOptions[status]) return <Tag>Unknown</Tag>;

                switch(parseInt(status)) {
                    case 1: return <Tag color="green">{ statusOptions[status] }</Tag>;
                    case 0: return <Tag color="magenta">{ statusOptions[status] }</Tag>;
                    case -1: return <Tag color="red">{ statusOptions[status] }</Tag>;
                    default: return <Tag>{ statusOptions[status] }</Tag>;
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '360px',
            render: (val, row) => {
                return (
                    <>
                        <Link to={ '/control-panel/departments/' + row._id + '/members' } className="btnActions edit"><Button className="btnEdit btnInline"><TeamOutlined /> Members</Button></Link>
                        <Link to={ '/control-panel/departments/' + row._id + '/edit' } className="btnActions edit"><Button className="btnEdit btnInline"><EditOutlined /> Edit</Button></Link>
                        { (row.status !== -1 ? <Button className="btnDelete btnInline" onClick={ () => handleDeleteDepartment(row._id, row.name) }><DeleteOutlined /> Delete</Button> : null) }
                    </>
                );
            }
        }
    ];

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Departments | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>
            
            <MainLayout>
                <>
                    { 
                        (departmentsDataset && departmentsDataset.departments) ? (
                            <>
                                <div style={{ margin: '0 0 16px 0' }}>
                                    <Button className="btnAdd" size="medium" onClick={ () => { setAddDepartmentModalVisibility(true) } } style={{ float: 'right' }}><PlusCircleOutlined /> Add Department</Button>
                                    <Link to="/control-panel"><Button className="btnBack" size="medium" onClick={ () => {} }><ArrowLeftOutlined /> Control Panel</Button></Link>
                                </div>

                                <Form form={ filtersForm } initialValues={{}} onFinish={ onApplyFilter } layout="vertical" hideRequiredMark={ true }>
                                    <Row gutter={ 16 }>
                                        <Col xs={ 24 } sm={ 8 } xl={ 4 }>
                                            <Form.Item 
                                                name="status"
                                                label="Status"
                                                initialValue="All"
                                            >
                                                <Select className="filterOptions">
                                                    {
                                                        statusOptions ?
                                                        Object.keys(statusOptions).map(statusId => {
                                                            return (
                                                                <Select.Option key={ statusId } value={ statusId }>{ (statusOptions && statusOptions[statusId] ? statusOptions[statusId] : 'Unknown') }</Select.Option>
                                                            );
                                                        })
                                                        : null }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                                            <Form.Item 
                                                name="limit"
                                                label="Show"
                                                initialValue={ departmentsDataset && departmentsDataset.pagination && departmentsDataset.pagination.pageSize ? departmentsDataset.pagination.pageSize : null }
                                            >
                                                <Select className="filterOptions">
                                                    <Select.Option value={ 10 }>10</Select.Option>
                                                    <Select.Option value={ 25 }>25</Select.Option>
                                                    <Select.Option value={ 50 }>50</Select.Option>
                                                    <Select.Option value={ 100 }>100</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                                            <Form.Item>
                                                <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                <Button className="btnBack" onClick={ onResetFields }>Reset</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Table rowKey="_id" columns={ columns } pagination={ (departmentsDataset && departmentsDataset.pagination ? departmentsDataset.pagination : false) } dataSource={ (departmentsDataset && departmentsDataset.departments ? Object.values(departmentsDataset.departments) : []) } onChange={ handleDepartmentsTableChange } />

                                <Modal
                                    visible={ addDepartmentModalIsVisible }
                                    onCancel={ () => { setAddDepartmentModalVisibility(false) } }
                                    footer={ null }
                                >
                                    <h1 className="mb-3">Add Department</h1>

                                    <Form form={ addDepartmentForm } onFinish={ handleAddDepartment } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                        <Form.Item 
                                            name="name"
                                            label="Name"
                                            validateStatus={ addDepartmentErrors && addDepartmentErrors.name ? "error" : null }
                                            help={ addDepartmentErrors && addDepartmentErrors.name ? addDepartmentErrors.name : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="priority"
                                            label="Priority"
                                            initialValue={{ value: '1' }}
                                        >
                                            <Select labelInValue>
                                                {
                                                    priorityOptions && Object.keys(priorityOptions).map(pKey => {
                                                        return (
                                                            <Select.Option key={ pKey } value={ pKey }>{ priorityOptions[pKey] }</Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="isVisibleToClient"
                                            label="Visible to customers?"
                                            initialValue={{ value: '1' }}
                                        >
                                            <Select labelInValue>
                                                {
                                                    visibilityOptions && Object.keys(visibilityOptions).map(pKey => {
                                                        return (
                                                            <Select.Option key={ pKey } value={ pKey }>{ visibilityOptions[pKey] }</Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Departments;