import React from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';

const Home = () => {
    const { 
        currentUser
    } = useSystemContext();

    if(!currentUser) {
        return (
            <div className="spin-wrapper"><Spin /></div>
        );
    }

    return (<Redirect to={ currentUser.role === 999 ? '/clients' : '/monitor'} />);
}

export default Home;