import { useRef, useEffect } from 'react';

export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export const useHasChanged = (value) => {
    let previousValue = usePrevious(value);
    return (previousValue !== value);
}