import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import { Link } from 'react-router-dom';

import { socket } from '../../service/socket';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Row, Col, Modal, Form, Input, Select, Button, Empty, message } from 'antd';
import { RobotOutlined, PlusOutlined } from '@ant-design/icons';

import './Chatbots.less';

import ChatbotIMG from '../../assets/images/chatbot.png';

const Chatbots = () => {
    const { 
        systemVariables,
        isSocketConnected
    } = useSystemContext();

    const userGroupOptions = (systemVariables && systemVariables.chatbot && systemVariables.chatbot.userGroupOptions ? systemVariables.chatbot.userGroupOptions : null);
    const conditionOptions = (systemVariables && systemVariables.chatbot && systemVariables.chatbot.conditionOptions ? systemVariables.chatbot.conditionOptions : null);
    const statusOptions = (systemVariables && systemVariables.chatbot && systemVariables.chatbot.statusOptions ? systemVariables.chatbot.statusOptions : null);

    const [chatbots, updateChatbots] = useImmer(null);

    const [createChatbotModalVisible, setCreateChatbotModalVisible] = useState(false);
    const [createChatbotForm] = Form.useForm();

    const fetchChatbots = useCallback(() => {
        socket.emit('operator.chatbots.get', null, (ack) => {
            updateChatbots(chatbots => (ack && ack.result && ack.chatbots) || []);
        });
    }, [updateChatbots]);

    useEffect(() => {
        if(isSocketConnected) {
            fetchChatbots();
        }
    }, [isSocketConnected, fetchChatbots]);

    const handleCreateChatbotFinish = (values) => {
        socket.emit('operator.chatbots.chatbot.add', { ...values }, (ack) => {
            if(ack && ack.result) {
                setCreateChatbotModalVisible(false);
                createChatbotForm.resetFields();
                fetchChatbots();
            }
        });
    }

    const handlePublishChatbot = (chatbotId, displayName) => {
        Modal.confirm({
            title: 'Chatbot: ' + displayName,
            content: 'Are you sure you want to publish this chatbot?',
            onOk() {
                socket.emit('operator.chatbots.chatbot.publish', { chatbotId: chatbotId }, (ack) => {
                    if(ack && ack.result) {
                        fetchChatbots();
                        return;
                    }

                    if(ack.error && ack.error.message) message.error(ack.error.message);
                })
            },
            onCancel() {
            },
        });
    }

    const handleUnpublishChatbot = (chatbotId, displayName) => {
        Modal.confirm({
            title: 'Chatbot: ' + displayName,
            content: 'Are you sure you want to unpublish this chatbot?',
            onOk() {
                socket.emit('operator.chatbots.chatbot.unpublish', { chatbotId: chatbotId }, (ack) => {
                    if(ack && ack.result) {
                        fetchChatbots();
                    }
                })
            },
            onCancel() {
            },
        });
    }

    return (
        <>
            <Helmet>
                <title>Chatbot | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    <div className="mb-4" style={{ float: 'right' }}>
                        <Button className={ "btnAdd mx-1" } onClick={ () => { setCreateChatbotModalVisible(true) } }><PlusOutlined /> Create a new chatbot</Button>
                    </div>

                    <h2 className="mb-2"><RobotOutlined className="mr-1" /> Chatbot</h2>
                    <div className="mb-4" style={{ background: '#EBECFF', width: '100%', height: '1px' }} />

                    <Row gutter={ 16 }>
                        {
                            chatbots && Object.keys(chatbots).length > 0 ? (
                                Object.values(chatbots).map(chatbot => {
                                    return (
                                        <Col key={ chatbot._id+'' } span={ 8 }>
                                            <div className={ 'chatbot-C my-2' + (statusOptions[chatbot.status] === 'Active' ? ' active' : '') + (statusOptions[chatbot.status] === 'Inactive' ? ' inactive' : '') }>
                                                <div style={{ textAlign: 'right' }}>
                                                    <div className={ 'status mt-2 mr-2' + (statusOptions[chatbot.status] === 'Active' ? ' active' : '') + (statusOptions[chatbot.status] === 'Inactive' ? ' inactive' : '') }>{ statusOptions[chatbot.status] }</div>
                                                </div>

                                                <img src={ ChatbotIMG } alt="Chatbot" />

                                                <div className="name mt-2">{ chatbot.name }</div>
                                                <div className="helper">Add, remove or edit chatbot...</div>

                                                <div className="actions-C mt-4 mb-4">
                                                    {
                                                        statusOptions[chatbot.status] === 'Inactive' && (
                                                            <Button className="chatbot_btnPublish mx-1" onClick={ () => { handlePublishChatbot(chatbot._id+'', chatbot.name+'') } }>Publish</Button>
                                                        )
                                                    }

                                                    {
                                                        statusOptions[chatbot.status] === 'Active' && (
                                                            <Button className="chatbot_btnUnpublish mx-1" onClick={ () => { handleUnpublishChatbot(chatbot._id+'', chatbot.name+'') } }>Unpublish</Button>
                                                        )
                                                    }

                                                    <Link to={ '/chatbots/' + chatbot._id+'' } className="chatbot_btnEdit mx-1">Edit</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                            ) : (
                                <Col span={ 24 }>
                                    <div className="empty-W mb-4">
                                        <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </>
            </MainLayout>

            {
                (createChatbotModalVisible) && (
                    <Modal
                        visible={ true }
                        onCancel={ () => { setCreateChatbotModalVisible(false); createChatbotForm.resetFields(); } }
                        footer={ null }
                    >
                        <h1 style={{ marginBottom: '20px' }}>Create a new chatbot</h1>

                        <Form form={ createChatbotForm } onFinish={ handleCreateChatbotFinish } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                            <Form.Item 
                                name="name"
                                label="Name"
                                rules={ [{ required: true, message: 'Please enter a name for the chatbot.' }] }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item 
                                name="displayName"
                                label="Display name"
                                rules={ [{ required: true, message: 'Please enter a display name for the chatbot.' }] }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item 
                                name="language"
                                label="Language"
                                rules={ [{ required: true, message: 'Please select the language for the chatbot.' }] }
                                initialValue={{ value: 'en' }}
                            >
                                <Select labelInValue>
                                    <Select.Option key={ 'en' } value={ 'en' }>[EN] English</Select.Option>
                                    <Select.Option key={ 'tr' } value={ 'tr' }>[TR] Turkish</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item 
                                name="userGroup"
                                label="Show to"
                                initialValue={ { value: Object.keys(userGroupOptions)[0] } }
                            >
                                <Select labelInValue>
                                    {
                                        userGroupOptions && Object.keys(userGroupOptions).map(key => <Select.Option key={ key } value={ key }>{ userGroupOptions[key] }</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item 
                                name="condition"
                                label="Condition"
                                initialValue={ { value: Object.keys(conditionOptions)[0] } }
                            >
                                <Select labelInValue>
                                    {
                                        conditionOptions && Object.keys(conditionOptions).map(key => <Select.Option key={ key } value={ key }>{ conditionOptions[key] }</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                )
            }
        </>
    )
}

export default Chatbots;