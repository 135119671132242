import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../../../../Context/SystemContext';

import { Link, useHistory, useParams } from 'react-router-dom';

import Helmet from 'react-helmet';
import MainLayout from '../../../../../Components/Layouts/MainLayout';

import Error from '../../../../Error/Error';

import ChatlineAPI from '../../../../../utils/ChatlineAPI';

import { Button, Form, Input, Select, message, Spin } from 'antd';

const Edit = (props) => {
    const { 
        systemVariables
    } = useSystemContext();

    const priorityOptions = (systemVariables && systemVariables.department && systemVariables.department.priorityOptions ? systemVariables.department.priorityOptions : null);
    const visibilityOptions = (systemVariables && systemVariables.department && systemVariables.department.visibilityOptions ? systemVariables.department.visibilityOptions : null);
    const statusOptions = (systemVariables && systemVariables.user && systemVariables.user.statusOptions ? systemVariables.user.statusOptions : null);

    const { departmentId } = useParams();
    const [department, setDepartment] = useState(null);

    const [editDepartmentErrors, setEditDepartmentErrors] = useState([]);

    const [error, setError] = useState(null);

    const history = useHistory();

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('departments/' + departmentId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data && res.data.department) { setDepartment(res.data.department); }
        });
    }, [departmentId])

    
    const handleEditDepartment = values => {
        ChatlineAPI.HttpPostRequest('departments/' + departmentId +  '/edit', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }
                    setEditDepartmentErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                history.push('/control-panel/departments');
                return;
            }
        });
    };

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Edit Department | Departments | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (department) ? (
                            <>
                                <div className="pageBody">
                                    <h1 className="mb-3">Edit Department</h1>

                                    <Form initialValues={{}} name="editDepartment" onFinish={ handleEditDepartment } layout="vertical" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="name"
                                            label="Name"
                                            initialValue={ (department && department.name ? department.name : '') }
                                            validateStatus={ editDepartmentErrors && editDepartmentErrors.name ? "error" : null }
                                            help={ editDepartmentErrors && editDepartmentErrors.name ? editDepartmentErrors.name : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="priority"
                                            label="Priority"
                                            initialValue={{ value: (department && department.priority !== null && department.priority+'') }}
                                        >
                                            <Select labelInValue>
                                                {
                                                    priorityOptions && Object.keys(priorityOptions).map(pKey => {
                                                        return (
                                                            <Select.Option key={ pKey } value={ pKey }>{ priorityOptions[pKey] }</Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="isVisibleToClient"
                                            label="Is visible to customers?"
                                            initialValue={{ value: (department && department.isVisibleToClient !== null && department.isVisibleToClient+'') }}
                                        >
                                            <Select labelInValue>
                                                {
                                                    visibilityOptions && Object.keys(visibilityOptions).map(pKey => {
                                                        return (
                                                            <Select.Option key={ pKey } value={ pKey }>{ visibilityOptions[pKey] }</Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="status"
                                            label="Status"
                                            initialValue={ (department && department.status+'' ? department.status+'' : '') }
                                        >
                                            <Select>
                                                { statusOptions && Object.keys(statusOptions).map(statusId => <Select.Option key={ statusId } value={ statusId }>{ (statusOptions && statusOptions[statusId] ? statusOptions[statusId] : 'Unknown') }</Select.Option>) }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Link to="/control-panel/departments" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Edit;