import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { useParams} from 'react-router';
import { useImmer } from "use-immer";
import { usePrevious } from '../../hooks/custom';
import { useSystemContext } from '../../Context/SystemContext';
import { Link, useHistory } from 'react-router-dom';
import { GlobalHotKeys } from 'react-hotkeys';

import { socket } from '../../service/socket';

import string2Hex from '../../utils/String2Hex';
import ChatlineAPI from '../../utils/ChatlineAPI';

import { v4 as uuid } from 'uuid';
import debounce from 'lodash.debounce';
import nl2br from 'react-nl2br';
import Linkify from 'react-linkify';
import Helmet from 'react-helmet';

import MainLayout from '../../Components/Layouts/MainLayout';
import CardWithShadow from '../../Components/Cards/CardWithShadow';

import { Spin, Typography, Row, Col, Avatar, Badge, Tabs, Form, Input, Button, Carousel, Mentions, Upload, Progress, message, Modal, Pagination, Empty, Dropdown, Menu, Select } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, SendOutlined, SmileOutlined, FilterOutlined, SearchOutlined, PaperClipOutlined, LoadingOutlined, EyeOutlined, DownloadOutlined, FileOutlined, UserOutlined, ExclamationCircleOutlined, HistoryOutlined, MessageOutlined, DeleteOutlined, EditOutlined, StopOutlined, FontSizeOutlined, UpOutlined, DownOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal';
import 'ant-design-draggable-modal/dist/index.css';

import ReactCountryFlag from 'react-country-flag';

import './MyChats.less';
import './ChatHistoryModal.less';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import newMessageAudioAsset from '../../assets/audio/new-message.mp3';

var moment = require('moment');
const { Text } = Typography;

const MyChats = (props) => {
    const { 
        currentUser, updateCurrentUserParameter,
        systemVariables,
        isSocketConnected,
        updateMyChatsBadgeCountChatIds
    } = useSystemContext();

    const availabilityOptions = (systemVariables && systemVariables.user && systemVariables.user.availabilityOptions ? systemVariables.user.availabilityOptions : null);
    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const chatStatusOptions = (systemVariables && systemVariables.chat && systemVariables.chat.statusOptions ? systemVariables.chat.statusOptions : null);
    const chatRoomFontSizeOptions = (systemVariables && systemVariables.user && systemVariables.user.chatRoomFontSizeOptions ? systemVariables.user.chatRoomFontSizeOptions : null);
    
    const [operators, setOperators] = useState(null);
    const [onlineOperators, updateOnlineOperators] = useImmer(null);
    const [chats, updateChats] = useImmer(null);

    const [initialised, setInitialised] = useState(false);

    const { activeChatId } = useParams();
    const prevActiveChatId = usePrevious(activeChatId);
    
    const [scrollChatLogToBottomActivated, setScrollChatLogToBottomActivated] = useState(true);
    const bottomOfChatLog = useRef(null);
    const [chatMessagesOnKeyDown, updateChatMessagesOnKeyDown] = useImmer(null);
    const messageDraftsByChatId = useRef({});
    const latestChatHistoryRequestTimestamp = useRef(null);
    const [messagesPagination, setMessagesPagination] = useState(null);
    const [messages, updateMessages] = useImmer(null);
    const [messageToBeEdited, setMessageToBeEdited] = useState(null);
    const [sendMessageFormEmojiPickerVisible, setSendMessageFormEmojiPickerVisible] = useState(false);
    const [editMessageFormEmojiPickerVisible, setEditMessageFormEmojiPickerVisible] = useState(false);
    const [mentionsOnSearch, setMentionsOnSearch] = useState(false);
    const [uploads, updateUploads] = useImmer(null);
    const [attachmentToDisplay, setAttachmentToDisplay] = useState(null);

    const [previousChats, setPreviousChats] = useState(null);
    const [selectedPreviousChat, setSelectedPreviousChat] = useState(null);
    const [selectedPreviousChatMessages, setSelectedPreviousChatMessages] = useState(null);

    const [shortcutsContainerIsVisible, setShortcutsContainerIsVisible] = useState(false);
    const [shortcutsActiveTab, setShortcutsActiveTab] = useState(null);
    const [shortcutsQueryParams, setShortcutsQueryParams] = useState(null);
    const [shortcutGroups, setShortcutGroups] = useState(null);

    const [fontSizeSelection, setFontSizeSelection] = useState(null);
    const [sendMessageForm] = Form.useForm();
    const sendMessage_mentionsRef = useRef(null);
    const [editMessageForm] = Form.useForm();
    const editMessage_mentionsRef = useRef(null);
    const [searchShortcutForm] = Form.useForm();

    const [previousChatModalIsVisible, setPreviousChatModalIsVisible] = useState(false);
    
    let newMessageAudioRef = useRef(null);

    const history = useHistory();

    const updateLastSeenBy = useCallback((chatId) => {
        socket.emit('operator.chat.updateLastSeenBy', chatId);

        updateChats(chats => {
            if(chats && chats[chatId]) {
                chats[chatId].unseenMessagesCount = 0;
            }
        });
    }, [updateChats]);

    const handleChatHistoryCallback = useCallback((response) => {
        if(response.chat && response.chat._id && ''+response.chat._id !== ''+activeChatId) return;

        if(response.requestTimestamp && latestChatHistoryRequestTimestamp.current && response.requestTimestamp < latestChatHistoryRequestTimestamp.current) {
            return;
        }

        if(response.pagination) {
            setMessagesPagination(response.pagination);
        }

        if(response.pagination && response.pagination.current && parseInt(response.pagination.current) === 1) {
            updateMessages(messages => (response.history ? response.history : {}));
            updateLastSeenBy(activeChatId);

            if(response.chat) {
                socket.emit('operator.visitors.visitor.chats', { sessionId: response.chat.sessionId, visitorUsername: ((response.chat.visitor && response.chat.visitor.username) || null) }, (ack2) => {
                    setPreviousChats((ack2 && ack2.response) || null);
                });
            }
        } else {
            if(response.history) {
                updateMessages(messages => (messages ? { ...response.history, ...messages } : { ...response.history }));
                setTimeout(setScrollChatLogToBottomActivated.bind(null, true), 1000);
            }
        }
    }, [activeChatId, latestChatHistoryRequestTimestamp, updateMessages, updateLastSeenBy]);

    const scrollChatLogToBottom = useCallback(() => {
        if(!scrollChatLogToBottomActivated) return;

        bottomOfChatLog && bottomOfChatLog.current && bottomOfChatLog.current.scrollIntoView(); // bottomOfChatLog.current.scrollIntoView({ behavior: "smooth" });
    }, [scrollChatLogToBottomActivated]);


    useEffect(() => {
        if(activeChatId && (messages || (chatMessagesOnKeyDown && chatMessagesOnKeyDown[activeChatId] && chatMessagesOnKeyDown[activeChatId].message))) {
            setTimeout(scrollChatLogToBottom, 100);
        }
    }, [activeChatId, messages, chatMessagesOnKeyDown, scrollChatLogToBottom]);

    useEffect(() => {
        if(activeChatId) {
            setTimeout(() => {
                if(sendMessage_mentionsRef && sendMessage_mentionsRef.current) sendMessage_mentionsRef.current.focus();
            }, 100);
        }
    }, [activeChatId]);

    useEffect(() => {
        if(isSocketConnected) {
            // socket.emit('operator.chats.myChatsBadgeCountChatIds.get');

            socket.emit('operator.operators.all.get', null, (ack) => {
                setOperators((ack && ack.result && ack.operators) || {});
            });
            
            socket.emit('operator.operators.online.get', null, (ack) => {
                updateOnlineOperators(onlineOperators => (ack && ack.result && ack.onlineOperators) || {});
            });

            socket.off('operator.operators.operator.connected').on('operator.operators.operator.connected', (pOperator) => {
                updateOnlineOperators(onlineOperators => {
                    if(!onlineOperators) onlineOperators = {};
                    onlineOperators[pOperator.userId] = { ...pOperator };
                });
            });

            socket.off('operator.operators.operator.away').on('operator.operators.operator.away', (pOperator) => {
                updateOnlineOperators(onlineOperators => {
                    if(!onlineOperators) onlineOperators = {};
                    onlineOperators[pOperator.userId] = { ...pOperator };
                });
            });

            socket.off('operator.operators.operator.disconnected').on('operator.operators.operator.disconnected', (pOperator) => {
                updateOnlineOperators(onlineOperators => {
                    if(onlineOperators && Object.keys(onlineOperators).length > 0) {
                        if(onlineOperators[pOperator.userId]) {
                            delete onlineOperators[pOperator.userId];
                            if(!onlineOperators) onlineOperators = {};
                        }
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('operator.operators.operator.connected');
            socket.off('operator.operators.operator.away');
            socket.off('operator.operators.operator.disconnected');
        }
    }, [isSocketConnected, updateOnlineOperators]);

    const loadMyChats = useCallback(() => {
        socket.emit('operator.chats.myChats.get', null, (ack) => {
            updateChats(chats => (ack && ack.result && ack.chats) || {});
        });
    }, [updateChats]);

    useEffect(() => {
        if(isSocketConnected) {
            loadMyChats();
        }
    }, [isSocketConnected, loadMyChats]);

    useEffect(() => {
        if(!initialised && operators && onlineOperators && chats) {
            setInitialised(true);
        }
    }, [initialised, operators, onlineOperators, chats]);

    useEffect(() => {
        if(isSocketConnected && initialised && activeChatId) {
            updateMessages(messages => null);

            let requestTimestamp = moment().format('x');
            latestChatHistoryRequestTimestamp.current = requestTimestamp;

            socket.emit('operator.chat.history', { chatId: activeChatId, page: 1, timestamp: requestTimestamp }, (ack) => {
                if(ack && ack.result && ack.response) {
                    handleChatHistoryCallback(ack.response);
                }
            });
        }
    }, [isSocketConnected, initialised, activeChatId, updateMessages, handleChatHistoryCallback]);

    useEffect(() => {
        if(isSocketConnected) {
            if(prevActiveChatId && prevActiveChatId !== activeChatId) {
                updateLastSeenBy(prevActiveChatId);

                updateMessages(messages => null);
            }

            /*
            // Handled in handleChatHistoryCallback
            if(activeChatId && prevActiveChatId !== activeChatId) {
                updateLastSeenBy(activeChatId);
            }
            */
        }
    });

    useEffect(() => {
        if(chats) {
            updateMyChatsBadgeCountChatIds(myChatsBadgeCountChatIds => {
                if(myChatsBadgeCountChatIds && Object.keys(myChatsBadgeCountChatIds).length > 0) {
                    let nChatIds = {};

                    for(let iChatId of Object.keys(myChatsBadgeCountChatIds)) {
                        if(chats && chats[iChatId] && chats[iChatId].unseenMessagesCount && parseInt(chats[iChatId].unseenMessagesCount) > 0) {
                            nChatIds[''+iChatId] = 1;
                        }
                    }

                    return nChatIds;
                }
            });
        }
    }, [chats, updateMyChatsBadgeCountChatIds]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('operator.chats.chat.new').on('operator.chats.chat.new', (chat) => {
                if(!chat || !chat._id) return;

                updateChats(chats => {
                    let nChats = (chats ? { ...chats } : {});
                    if(!nChats[chat._id]) {
                        let nChat = {};
                        nChat[chat._id] = chat;

                        nChats = { ...nChat, ...nChats };
                        return nChats;
                    }
                });
            });

            socket.off('operator.chats.chat.update').on('operator.chats.chat.update', (chat) => {
                if(!chat || !chat._id) return;

                updateChats(chats => {
                    if(chats && chats[chat._id]) {
                        chats[chat._id] = chat;
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('operator.chats.chat.new');
            socket.off('operator.chats.chat.update');
        }
    }, [isSocketConnected, updateChats]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('operator.visitors.visitor.update').on('operator.visitors.visitor.update', (pVisitor) => {
                updateChats(chats => {
                    if(activeChatId && chats && chats[activeChatId] && chats[activeChatId].visitor && chats[activeChatId].visitor.sessionId && ''+chats[activeChatId].visitor.sessionId === ''+pVisitor.sessionId) {
                        chats[activeChatId].visitor = { ...pVisitor };
                    }
                });
            });

            socket.off('chat.message').on('chat.message', (chatMessage) => {
                if(chatMessage && chatMessage.messageBy && !(chatMessage.messageBy.userType === 'operator' && chatMessage.messageBy.userId === currentUser._id)) {
                    newMessageAudioRef && newMessageAudioRef.current && newMessageAudioRef.current.play().catch(err => {});
                }

                if(activeChatId && ''+chatMessage.chatId === ''+activeChatId) {
                    updateMessages(messages => {
                        if(!messages) return { [chatMessage._id]: chatMessage };
                        messages[chatMessage._id] = chatMessage;
                    });
                }
                
                updateChats(chats => {
                    if(!chats || !chats[chatMessage.chatId]) {
                        loadMyChats(); // causes reload!!!
                    } else {
                        if(chats && chats[chatMessage.chatId]) {
                            if(!activeChatId || ''+chatMessage.chatId !== ''+activeChatId) {
                                if(!chats[chatMessage.chatId].unseenMessagesCount) chats[chatMessage.chatId].unseenMessagesCount = 0;
                                chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                            }
                            chats[chatMessage.chatId].latestMessage = chatMessage;
                            chats[chatMessage.chatId].updatedAt = Date.now();
                        }
                    }
                });

                if(!activeChatId || ''+chatMessage.chatId !== ''+activeChatId) {
                    updateMyChatsBadgeCountChatIds(myChatsBadgeCountChatIds => {
                        if(!myChatsBadgeCountChatIds) {
                            let nMyChatsBadgeCountChatIds = {};
                            nMyChatsBadgeCountChatIds[''+chatMessage.chatId] = 1;
                            return nMyChatsBadgeCountChatIds;
                        }
        
                        if(!myChatsBadgeCountChatIds[''+chatMessage.chatId]) myChatsBadgeCountChatIds[''+chatMessage.chatId] = 1;
                    });
                }
            });
        }

        return function cleanup() {
            socket.off('operator.visitors.visitor.update');
            socket.off('chat.message');
        }
    }, [isSocketConnected, activeChatId, currentUser, loadMyChats, updateChats, updateMessages, updateMyChatsBadgeCountChatIds]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('chat.visitor.onKeyDown').on('chat.visitor.onKeyDown', (chatMessage) => {
                updateChatMessagesOnKeyDown(chatMessagesOnKeyDown => {
                    if(!chatMessagesOnKeyDown) chatMessagesOnKeyDown = {};
                    chatMessagesOnKeyDown[chatMessage.chatId] = chatMessage;
                    return chatMessagesOnKeyDown;
                });
            });
        }

        return function cleanup() {
            socket.off('chat.visitor.onKeyDown');
        }
    }, [isSocketConnected, updateChatMessagesOnKeyDown]);


    useEffect(() => {
        if(isSocketConnected) {
            socket.off('chat.leave').on('chat.leave', (pChatId) => {
                if(activeChatId && activeChatId === pChatId) history.push('/my-chats');

                updateChats(chats => {
                    if(chats && chats[pChatId]) {
                        delete chats[pChatId];
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('chat.leave');
        }
    }, [isSocketConnected, activeChatId, updateChats, history]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('chat.end').on('chat.end', (pData) => {
                if(!pData || !pData.chatId) return;

                if(!pData.endedBy || pData.endedBy !== 'operator') {
                    socket.emit('operator.chats.chat.leave', pData.chatId);

                    updateChats(chats => {
                        if(chats && chats[pData.chatId]) {
                            chats[pData.chatId].status = Object.keys(chatStatusOptions).find(key => chatStatusOptions[key] === 'Ended');
                        }
                    });
                } else {
                    if(activeChatId && activeChatId === pData.chatId) history.push('/my-chats');

                    updateChats(chats => {
                        if(chats && chats[pData.chatId]) {
                            delete chats[pData.chatId];
                        }
                    });
                }

                if(currentUser && currentUser.parameters && currentUser.parameters.automaticallyAcceptChats && parseInt(currentUser.parameters.automaticallyAcceptChats) === 1) {
                    if(availabilityOptions && availabilityOptions[currentUser.availability] && availabilityOptions[currentUser.availability] === 'Online') {
                        let takeChatCallback = (ack) => {
                            if(!ack) return;

                            if(ack.result) {
                                updateChats(chats => {
                                    let nChats = (chats ? { ...chats } : {});
                                    if(!nChats[ack.chat._id]) {
                                        let nChat = {};
                                        nChat[ack.chat._id] = ack.chat;

                                        nChats = { ...nChat, ...nChats };
                                        return nChats;
                                    }
                                });
                            } else {
                                if(ack.retry) {
                                    socket.emit('operator.chats.take', { force: false }, takeChatCallback);
                                    return;
                                }
                            }
                        };

                        socket.emit('operator.chats.take', { force: false }, takeChatCallback);
                    }
                }
            });
        }

        return function cleanup() {
            socket.off('chat.end');
        }
    }, [isSocketConnected, activeChatId, currentUser, chatStatusOptions, roleOptions, availabilityOptions, history, updateChats]);

    useEffect(() => {
        if(chats && activeChatId) {
            if(!chats[activeChatId]) {
                history.push('/my-chats');
            }
        }
    }, [chats, activeChatId, history]);

    useEffect(() => {
        if(sendMessageForm && sendMessageForm.__INTERNAL__ && sendMessageForm.__INTERNAL__.name) {
            if(activeChatId) sendMessageForm.setFieldsValue({ message: (messageDraftsByChatId && messageDraftsByChatId.current && messageDraftsByChatId.current[activeChatId] ? messageDraftsByChatId.current[activeChatId] : '') });
        }
    }, [sendMessageForm, activeChatId, messageDraftsByChatId]);

    const loadMoreMessages = useCallback((page) => {
        setScrollChatLogToBottomActivated(false);

        let requestTimestamp = moment().format('x');
        latestChatHistoryRequestTimestamp.current = requestTimestamp;
        
        socket.emit('operator.chat.history', { chatId: activeChatId, page: page, timestamp: requestTimestamp }, (ack) => {
            if(ack && ack.result && ack.response) {
                handleChatHistoryCallback(ack.response);
            }
        });
    }, [activeChatId, handleChatHistoryCallback]);

    const createdAt2When = (createdAt) => {
        createdAt = moment(createdAt);
        let when = createdAt.format('HH:mm');

        if(moment().startOf('day') > createdAt) {
            when = createdAt.fromNow();
            if(when === '1 day ago') when = 'Yesterday';
        }

        return when;
    }

    const editMessageForm_addEmoji = (emoji) => {
        editMessageForm.setFieldsValue({
            message: (editMessageForm.getFieldValue('message') ? editMessageForm.getFieldValue('message') : '') + emoji.native
        });
    }

    const sendMessageForm_addEmoji = (emoji) => {
        sendMessageForm.setFieldsValue({
            message: (sendMessageForm.getFieldValue('message') ? sendMessageForm.getFieldValue('message') : '') + emoji.native
        });
    }

    const beforeUpload = (file, fileList) => {
        updateUploads(uploads => {
            if(!uploads) uploads = {};
            uploads[activeChatId] = 0;
            return uploads;
        });

        if(['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) {
            // Notify the admins if an operator tries to upload word/excel documents.
            socket.emit('admin.notifications.new', { chatId: activeChatId });
            return false;
        }

        return true;
    }

    const viewFile = (attachment) => {
        setAttachmentToDisplay(attachment);
    }

    const downloadChatbotFile = async (clientId, chatbotId, fileName, type, originalFileName) => {
        ChatlineAPI.FileReader(clientId, 'chatbots/' + chatbotId + '/nodes/files/view-image/' + fileName, type, originalFileName, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
        });
    }

    const downloadFile = async (clientId, chatId, fileName, type, originalFileName) => {
        ChatlineAPI.FileReader(clientId, 'chats/' + chatId + '/attachments/' + fileName, type, originalFileName, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
        });
    }

    const sendMessage_onKeyDown = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if(mentionsOnSearch) return;
            sendMessageForm.submit();
        } else if(e.keyCode === 27) {
            if(sendMessage_mentionsRef && sendMessage_mentionsRef.current) sendMessage_mentionsRef.current.blur();
        }
    }

    const ackWithTimeout = (onSuccess, onTimeout, timeout) => {
        let called = false;

        const timer = setTimeout(() => {
            if(called) return;
            called = true;
            onTimeout();
        }, timeout);

        return (...args) => {
            if(called) return;
            called = true;
            clearTimeout(timer);
            onSuccess.apply(this, args);
        }
    }

    const emitOperatorChatMessage = (pData, timeout = false) => {
        if(timeout && parseInt(timeout) > 30000) {
            updateMessages(messages => {
                if(messages && messages[pData.tempChatMessageId]) {
                    messages[pData.tempChatMessageId].isFailed = true;
                }
            });

            return;
        }

        const successCallback = (ack) => {
            if(ack && ack.result && ack.chatMessageId && ack.tempChatMessageId) {
                updateMessages(messages => {
                    if(messages && messages[ack.tempChatMessageId]) {
                        messages[ack.tempChatMessageId].isSent = true;
                        messages[ack.chatMessageId] = { ...messages[ack.tempChatMessageId], _id: ack.chatMessageId };
                        delete messages[ack.tempChatMessageId];
                    }
                });
            }
            
            setIsFormSubmitting(false);
        };

        if(!timeout || parseInt(timeout) <= 0) {
            socket.emit('operator.chat.message', pData, successCallback);
            return;
        }

        const timeoutCallback = () => {
            socket.connect();
            emitOperatorChatMessage(pData, parseInt(timeout * 1.25));
        }

        socket.emit('operator.chat.message', pData, ackWithTimeout(successCallback, timeoutCallback, timeout));
    }

    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const onSendMessage = (values) => {
        if(isFormSubmitting) return;
        setIsFormSubmitting(true);

        let message = sendMessageForm.getFieldValue('message');
        if(!message) { setIsFormSubmitting(false); return; }

        message = message.trim();
        if(!message) { setIsFormSubmitting(false); return; }

        if(messageDraftsByChatId && messageDraftsByChatId.current && messageDraftsByChatId.current[activeChatId]) messageDraftsByChatId.current[activeChatId] = '';
        sendMessageForm.setFieldsValue({ message: '' });

        let chatMessage = {
            _id: uuid(),
            chatId: activeChatId,
            messageType: (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && Object.keys(systemVariables.chat.messageTypes).find(key => systemVariables.chat.messageTypes[key] === 'Text')),
            messageBy: { userType: 'operator', userId: currentUser._id, name: currentUser.displayName },
            message: message,
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
        };

        updateMessages(messages => {
            if(!messages) return { [chatMessage._id]: chatMessage };
            messages[chatMessage._id] = chatMessage;
        });

        updateChats(chats => {
            if(chats && chats[chatMessage.chatId]) {
                if(''+chatMessage.chatId !== ''+activeChatId) chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                chats[chatMessage.chatId].latestMessage = chatMessage;
                chats[chatMessage.chatId].updatedAt = Date.now();
            }
        });

        emitOperatorChatMessage({ chatId: activeChatId, message: chatMessage.message, tempChatMessageId: chatMessage._id }, 5000);
    }

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.client.shortcuts.get', null, (ack) => {
                if(ack && ack.result && ack.shortcutGroups) setShortcutGroups(ack.shortcutGroups);
            });
        }
    }, [isSocketConnected]);

    useEffect(() => {
        if(shortcutGroups && Object.values(shortcutGroups)) {
            onShortcutsTabChange('ALL');
        }
    }, [shortcutGroups]);

    const onShortcutsTabChange = (key) => {
        setShortcutsActiveTab(key);
    }

    const onShortcutsApplyFilter = (values) => {
        let search = (values.search && values.search.trim() ? values.search.trim() : null);
        setShortcutsQueryParams((shortcutsQueryParams ? { ...shortcutsQueryParams, search } : { search }));
    }

    const onShortcutsResetFields = () => {
        setShortcutsQueryParams(null);
        searchShortcutForm.resetFields();
    }

    const onShortcutSelect = (message) => {
        sendMessageForm.setFieldsValue({ 
            message: (sendMessageForm.getFieldValue('message') ? sendMessageForm.getFieldValue('message') : '') + message
        });

        setShortcutsContainerIsVisible(false);

        const selector = document.getElementById('sendMessage_message');
        setTimeout(function() {
            selector.focus();
            selector.setSelectionRange(selector.value.length, selector.value.length);
        }, 1);
    }

    const handleJoinChatClick = (chatId) => {
        socket.emit('operator.chat.join', { chatId: chatId }, (ack) => {
            if(ack && ack.result && ack.chat) {
                updateChats(chats => {
                    let nChats = (chats ? { ...chats } : {});
                    if(!nChats[ack.chat._id]) {
                        let nChat = {};
                        nChat[ack.chat._id] = ack.chat;

                        nChats = { ...nChat, ...nChats };
                        return nChats;
                    }
                });
            }
        });
    };

    const handleCloseChatClick = (pChatId) => {
        updateChats(chats => {
            if(chats && chats[pChatId]) {
                delete chats[pChatId];
            }
        });
    }

    const handleEndChatClick = (pChatId) => {
        socket.emit('operator.chats.chat.end', pChatId, (ack) => {
            if(ack && ack.result && ack.chatId) {
                // nothing-to-do    
            }
        });
    }

    const handleLeaveChatClick = (pChatId) => {
        socket.emit('operator.chats.chat.leave', pChatId);
    }

    const hotKeysKeyMap = {
		GO_TO_NEXT_CHAT: "shift+e",
		GO_TO_NEXT_UNREAD_CHAT: "shift+r",
	}

	const hotKeysHandlers = {
		GO_TO_NEXT_CHAT: debounce((event) => {
            if(!props.location.pathname || !props.location.pathname.startsWith('/my-chats')) return;
            
            if(!chats) return;

            let chatIds = Object.keys(chats);
            if(chatIds && Array.isArray(chatIds) && chatIds.length > 0) {
                if(activeChatId && chatIds.includes(activeChatId)) {
                    let nIndex = chatIds.indexOf(activeChatId) + 1;
                    if(nIndex >= chatIds.length) nIndex = 0;
                    let newActiveChatId = chatIds[nIndex];

                    history.push('/my-chats/' + newActiveChatId);
                    return;
                } else {
                    let newActiveChatId = chatIds[0];
                    history.push('/my-chats/' + newActiveChatId);
                    return;
                }
            }
		}, 250),
		GO_TO_NEXT_UNREAD_CHAT: debounce((event) => {
            if(!props.location.pathname || !props.location.pathname.startsWith('/my-chats')) return;
            
            if(!chats) return;

            let chatIds = [];
            for(let cChat of Object.values(chats)) {
                if(cChat.unseenMessagesCount && cChat.unseenMessagesCount > 0) chatIds.push(cChat._id);
            }

            if(chatIds && Array.isArray(chatIds) && chatIds.length > 0) {
                if(activeChatId && chatIds.includes(activeChatId)) {
                    let nIndex = chatIds.indexOf(activeChatId) + 1;
                    if(nIndex >= chatIds.length) nIndex = 0;
                    let newActiveChatId = chatIds[nIndex];

                    history.push('/my-chats/' + newActiveChatId);
                    return;
                } else {
                    let newActiveChatId = chatIds[0];
                    history.push('/my-chats/' + newActiveChatId);
                    return;
                }
            }
		}, 250),
    };

    const handleEditMessageButtonClicked = (chatId, messageId, message) => {
        setMessageToBeEdited(messageId);

        editMessageForm.setFieldsValue({ message: message });
    }

    useLayoutEffect(() => {
        setTimeout(function() {
            const selector = document.getElementById('editMessage_message');
            if(selector) selector.setSelectionRange(selector.value.length, selector.value.length);
        }, 1);
    }, [messageToBeEdited])

    const editMessage_onKeyDown = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if(mentionsOnSearch) return;
            editMessageForm.submit();
        } else if(e.keyCode === 27) {
            if(editMessage_mentionsRef && editMessage_mentionsRef.current) editMessage_mentionsRef.current.blur();
        }
    }

    const handleEditMessage = (chatId, messageId) => {
        socket.emit('operator.chat.message.edit', { chatId: chatId, messageId: messageId, message: editMessageForm.getFieldValue("message") }, (ack) => {
            if(ack && ack.result === true && ack.chatMessage) {
                let chatMessage = ack.chatMessage;

                updateMessages(messages => {
                    if(!messages) return { [chatMessage._id]: chatMessage };
                    messages[chatMessage._id] = chatMessage;
                });
            }
        })

        setMessageToBeEdited(null);
    }

    const handleDeleteMessage = (chatId, messageId) => {
        Modal.confirm({
            title: 'Delete message',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure that you want to delete this record?',
            onOk() {
                socket.emit('operator.chat.message.delete', { chatId: chatId, messageId: messageId }, (ack) => {
                    if(ack && ack.result === true && ack.chatMessage) {
                        let chatMessage = ack.chatMessage;

                        updateMessages(messages => {
                            if(!messages) return { [chatMessage._id]: chatMessage };
                            messages[chatMessage._id] = chatMessage;
                        });
                    }
                })
            },
            onCancel() {},
        });
    }

    const handlePreviousChatsPagination = (page) => {
        if(activeChatId && chats && chats[activeChatId]) {
            socket.emit('operator.visitors.visitor.chats', { sessionId: chats[activeChatId].sessionId, visitorUsername: ((chats[activeChatId].visitor && chats[activeChatId].visitor.username) || null), page: page }, (ack) => {
                setPreviousChats((ack && ack.response) || null);
            });
        }
    }

    const handleFetchPreviousChat = (chatId) => {
        socket.emit('operator.visitors.visitor.chats.chat', { chatId: chatId }, (ack) => {
            if(ack && ack.response) {
                if(ack.response.chat) setSelectedPreviousChat(ack.response.chat);

                if(ack.response.chatMessagesData) {
                    if(ack.response.chatMessagesData.chatMessages) setSelectedPreviousChatMessages(ack.response.chatMessagesData.chatMessages);
                }
            }
        })

        setPreviousChatModalIsVisible(true);
    }

    useEffect(() => {
        if(currentUser) {
            if(currentUser.parameters && currentUser.parameters.preferredFontSizeForChatRoom) {
                setFontSizeSelection(currentUser.parameters.preferredFontSizeForChatRoom);
            }
        }
    }, [currentUser]);

    const handleFontSizeSelection = (fontSizeOption) => {
        updateCurrentUserParameter('preferredFontSizeForChatRoom', fontSizeOption, () => {
            setFontSizeSelection(fontSizeOption);
        });
    }

    const handleBanUser = (duration) => {
        Modal.confirm({
            title: 'Ban User',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure that you want to ban this user for ' + duration + '?',
            onOk() {
                socket.emit('operator.chat.ban', { chatId: activeChatId, duration: duration }, (ack) => {
                    if(ack && ack.result && ack.chat) {
                        socket.emit('operator.chats.chat.end', ack.chat._id);
                    }
                });
            },
            onCancel() {},
        });
    }

    const [transferChatModalVisible, setTransferChatModalVisible] = useState(false);
    const onTransferChatFinish = (values) => {
        if(!activeChatId) return;
        if(!values || !values.assignTo) return;

        socket.emit('operator.chat.transfer', { chatId: activeChatId, operatorId: values.assignTo }, (ack) => {
            if(ack && ack.result && ack.chat) {
                if(!ack.chat.operatorsJoined || !Array.isArray(ack.chat.operatorsJoined) || !ack.chat.operatorsJoined.includes(''+currentUser._id)) {
                    socket.emit('operator.chats.chat.leave', ack.chat._id);
                }
            }

            setTransferChatModalVisible(false);
        });
    };

    const formatAvatar = (userType, userName) => {
        let avatarSize = 35;

        if(userType === 'chatbot') {
            return (
                <Avatar size={ avatarSize } style={{ background: '#FFD967', color: '#000' }} className="avatar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-robot" viewBox="0 0 16 16">
                        <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z" />
                        <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z" />
                    </svg>
                </Avatar>
            );  
        }

        if(!userName) userName = '?';

        return (
            <Avatar size={ avatarSize } style={{ background: string2Hex(userName), color: '#000' }} className="avatar">{ userName.substring(0, 1).toUpperCase() }</Avatar>
        );
    }

    const formatChatMessage = (message, i, cDate) => {
        // if(message.status !== null && systemVariables && systemVariables.chatMessage && systemVariables.chatMessage.statusOptions && systemVariables.chatMessage.statusOptions[message.status] && systemVariables.chatMessage.statusOptions[message.status] === 'Deleted') return;

        if(message.visibleToSenderOnly && (!message.messageBy.userId || message.messageBy.userId !== currentUser._id)) return;

        if(systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Info') {
            return (
                <div key={ 'chat-log-message-wrapper-' + i } className={ 'chat-log-message-wrapper chat-log-message-wrapper--center mt-3' + ('font-size--' + fontSizeSelection || '20') }>
                    { message.messageBy && message.messageBy.userType && message.messageBy.userId && message.messageBy.name && formatAvatar(message.messageBy.userType, message.messageBy.name) }
                    <div className="message-text mt-3">{ nl2br(message.message) }</div>
                    <div className="message-details">{ moment(message.createdAt).format('HH:mm:ss') }</div>
                </div>
            );
        }

        let isMessageSentByCurrentUser = (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' && message.messageBy.userId && ''+message.messageBy.userId === ''+currentUser._id ? true : false);

        return (
            <React.Fragment key={ 'chat-log-message' + i }>
                {
                    (message.previousVersions && Array.isArray(message.previousVersions) && message.previousVersions.length > 0 && message.previousVersions.map((previousMessage, j) => {
                        if(!systemVariables || !systemVariables.chat || !systemVariables.chat.messageTypes) return null;
                        
                        return (
                            <React.Fragment key={ 'chat-log-message--previous' + i + '-' + j }>
                                {
                                    formatChatMessage({
                                        chatId: message.chatId,
                                        messageType: Object.keys(systemVariables.chat.messageTypes).find(key => systemVariables.chat.messageTypes[key] === 'Error'),
                                        messageBy: message.messageBy,
                                        message: previousMessage,
                                        visibleToOperatorsOnly: true,
                                        createdAt: message.createdAt,
                                        updatedAt: message.updatedAt,
                                        status: Object.keys(systemVariables.chat.statusOptions).find(key => systemVariables.chat.statusOptions[key] === 'Active')
                                    }, (i+'-'+j), null)
                                }

                                <div style={{ float: 'right', clear: 'both', background: '#dfdfdf', width: '2px', height: '16px', margin: '-4px 16px 8px auto' }}>&nbsp;</div>
                            </React.Fragment>
                        )
                    }))
                }

                <div key={ 'chat-log-message-wrapper' + i } className={ 'chat-log-message-wrapper ' + (isMessageSentByCurrentUser ? 'chat-log-message-wrapper--right' : 'chat-log-message-wrapper--left') + ' font-size--' + (fontSizeSelection || '20') }>
                    <div className="chat-log-message-container">
                        { !isMessageSentByCurrentUser && <div className="message-by--avatar">{ formatAvatar(message.messageBy.userType, message.messageBy.name) }</div> }

                        {
                            (systemVariables && systemVariables.chatMessage && systemVariables.chatMessage.statusOptions && systemVariables.chatMessage.statusOptions[message.status] && systemVariables.chatMessage.statusOptions[message.status] === 'Deleted') ? (
                                <div className="message-text message-text-error"><StopOutlined style={{ fontSize: '11px' }} /> This message has been deleted.</div>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Error') ? (
                                <div className="message-text message-text-error">
                                    <Linkify componentDecorator={ (href, text, key) => (<a href={ href}  key={ key } target="_blank" rel="noopener noreferrer">{ text }</a>) }>{ nl2br(message.message) }</Linkify>
                                </div>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'File') ? (
                                <>
                                    {
                                        /*
                                        <div className="message-file">
                                            {
                                                ['image/jpeg', 'image/png', 'image/gif'].includes(message.attachment.mime) ? (
                                                    <>
                                                        <img className="message-file--image" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + message.attachment.filePath.replace(/^public\//, '') } alt={ message.attachment.originalFileName } onClick={ () => {
                                                            if(!message.parameters || !message.parameters.linkTo) return false;

                                                            if(message.parameters.target) window.open(message.parameters.linkTo, message.parameters.target);
                                                            else window.open(message.parameters.linkTo, '_blank');
                                                            return true;
                                                        }} style={ ((message.parameters && message.parameters.linkTo) ? { cursor: 'pointer' } : {}) } />

                                                        <div className="message-file---actions">
                                                            <Button onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? downloadChatbotFile(currentUser.clientId, message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) }} className="action"><DownloadOutlined /></Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? downloadChatbotFile(currentUser.clientId, message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) }} className="file"><FileOutlined /> { message.attachment.originalFileName } <DownloadOutlined /></div>
                                                )
                                            }
                                        </div>
                                        */
                                    }

                                    <div className={ 'message-text' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                        <FileOutlined /> <span style={{ marginRight: '8px' }}>{ message.attachment.originalFileName }</span>
                                        { ['image/jpeg', 'image/png', 'image/gif'].includes(message.attachment.mime) && <Button onClick={ () => viewFile(message.attachment) } className="action" style={{ marginLeft: '2px' }}><EyeOutlined /></Button> }
                                        <Button onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? downloadChatbotFile(currentUser.clientId, message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) } } className="action" style={{ marginLeft: '2px' }}><DownloadOutlined /></Button>
                                    </div>
                                </>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Button Group') ? (
                                <div className={ 'message-button-group' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                    {
                                        (message.parameters && message.parameters.buttons && Array.isArray(message.parameters.buttons) && message.parameters.buttons.map((button, buttonIndex) => {
                                            if(!button.label) return null;

                                            return (    
                                                <Button
                                                    key={ 'btn-action-' + buttonIndex }
                                                    className={ 'btn-action type-'+(button.type || (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'User response'))) }
                                                    onClick={ () => { return false; } }
                                                >
                                                    { button.label }
                                                </Button>
                                            );
                                        }))
                                    }
                                </div>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Carousel') ? (
                                <div className={ 'message-carousel' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                    {
                                        (message.parameters && message.parameters.fields && message.parameters.carouselItems && Array.isArray(message.parameters.carouselItems) && (
                                            <Carousel effect="scrollx" dotPosition="top" arrows={ true } prevArrow={ <ArrowLeftOutlined /> } nextArrow={ <ArrowRightOutlined /> }>
                                                {
                                                    (message.parameters.carouselItems.map((carouselItem, carouselItemIndex) => {
                                                        if(!carouselItem.image) return null;

                                                        return (
                                                            <div key={ 'carousel-item-' + carouselItemIndex } className="carousel-item">
                                                                <img className="carousel-item--image" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + carouselItem.image.filePath.replace(/^public\//, '') } alt={ carouselItem.title || carouselItem.image.fileName || null } />

                                                                { (carouselItem.title) && <div className="carousel-item--title">{ carouselItem.title }</div> }
                                                                { (carouselItem.description) && <div className="carousel-item--description">{ carouselItem.description }</div> }

                                                                {
                                                                    (carouselItem.buttons && Array.isArray(carouselItem.buttons) && carouselItem.buttons.length > 0) && (
                                                                        <div className="carousel-item--button-group">
                                                                            {
                                                                                (carouselItem.buttons.map((button, buttonIndex) => {
                                                                                    if(!button.label) return null;

                                                                                    return (    
                                                                                        <Button
                                                                                            key={ 'carousel-item--button-group--button-' + buttonIndex }
                                                                                            className={ 'btn-action type-'+(button.type || (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'User response'))) }
                                                                                            onClick={ () => false }
                                                                                        >
                                                                                            { button.label }
                                                                                        </Button>
                                                                                    );
                                                                                }))
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        );
                                                    }))
                                                }
                                            </Carousel>
                                        ))
                                    }
                                </div>
                            ) : (
                                <>
                                    {
                                        (messageToBeEdited && messageToBeEdited+'' === message._id+'') ? (
                                            <>
                                                <div className="container-edit-message-form p-2">
                                                    <Button onClick={ () => { setMessageToBeEdited(null) } } className="btnSecondary px-2 mr-2" style={{ float: 'right', fontSize: '12px' }}>Cancel</Button>

                                                    <Form form={ editMessageForm } className="edit-message-form" name="editMessage" onFinish={ () => { handleEditMessage(message.chatId, message._id) } } layout="vertical" hideRequiredMark={ true }>
                                                        <Form.Item className="edit-message-form-emoji-picker-container">
                                                            <span>
                                                                <span className="icon mr-1" onClick={ () => { setEditMessageFormEmojiPickerVisible(!editMessageFormEmojiPickerVisible) } }><SmileOutlined /></span>
                                                                <span className="picker" style={{ display: (editMessageFormEmojiPickerVisible ? 'block' : 'none'), bottom: 'auto' }}><Picker set="apple" title={ '' } showPreview={ false } showSkinTones={ false } onSelect={ editMessageForm_addEmoji } /></span>
                                                            </span> 
                                                        </Form.Item>

                                                        <div className="edit-message-form-input-C" onKeyDown={ editMessage_onKeyDown }>
                                                            <Form.Item
                                                                name="message"
                                                            >
                                                                <Mentions 
                                                                    ref={ editMessage_mentionsRef }
                                                                    autoSize={ { minRows: 1, maxRows: 3} }
                                                                    autoFocus={ true }
                                                                    className={ 'edit-message-form-input'+(chatRoomFontSizeOptions && fontSizeSelection && chatRoomFontSizeOptions[fontSizeSelection] ? ' '+chatRoomFontSizeOptions[fontSizeSelection].toLowerCase() : '') }
                                                                    prefix="#"
                                                                    filterOption={ (input, option) => { return (!input || (option && option.value.startsWith(input))); } }
                                                                    notFoundContent="No matching shortcut!"
                                                                    onSearch={ (text, prefix) => { !mentionsOnSearch && setMentionsOnSearch(true) } }
                                                                    onBlur= { () => { mentionsOnSearch && setMentionsOnSearch(false) } }
                                                                    onChange= { (text) => {
                                                                        /*
                                                                            if(messageDraftsByChatId && messageDraftsByChatId.current) messageDraftsByChatId.current[activeChatId] = text;
                                                                            mentionsOnSearch && setMentionsOnSearch(false) 
                                                                        */
                                                                    } }
                                                                    onSelect={ (option, prefix) => {
                                                                        if(!shortcutGroups) return;
                                                                        
                                                                        let shortcut = null;
                                                                        if(shortcutGroups['Personal'] && shortcutGroups['Personal'].shortcuts) {
                                                                            shortcut = Object.values(shortcutGroups['Personal'].shortcuts).find(shortcut => shortcut.key === option.value);
                                                                        }
                                                                        
                                                                        if(!shortcut && shortcutGroups['ALL'] && shortcutGroups['ALL'].shortcuts) {
                                                                            shortcut = Object.values(shortcutGroups['ALL'].shortcuts).find(shortcut => shortcut.key === option.value);
                                                                        }

                                                                        if(!shortcut) return;

                                                                        let xMessage = editMessageForm.getFieldValue('message').replace(prefix + option.value, shortcut.message);
                                                                        editMessageForm.setFieldsValue({ message: xMessage });
                                                                        mentionsOnSearch && setMentionsOnSearch(false);
                                                                        
                                                                        const selector = document.getElementById('editMessage_message');
                                                                        setTimeout(function() {
                                                                            selector.focus();
                                                                            selector.setSelectionRange(selector.value.length, selector.value.length);
                                                                        }, 1);
                                                                    } }
                                                                >
                                                                    <>
                                                                        {
                                                                            (shortcutGroups && shortcutGroups['ALL'] && shortcutGroups['ALL'].shortcuts && Object.values(shortcutGroups['ALL'].shortcuts).map(shortcut => {
                                                                                return (
                                                                                    <Mentions.Option key={ shortcut._id } value={ shortcut.key }>{ shortcut.key }</Mentions.Option>
                                                                                );
                                                                            }))
                                                                        }
                                                                    </>
                                                                </Mentions>
                                                            </Form.Item>
                                                        </div>

                                                        <Form.Item className="edit-message-form-buttons">
                                                            <div>
                                                                {
                                                                    (isFormSubmitting) ? (
                                                                        <Button htmlType="submit" className="edit-message-form-submit-button" disabled="disabled"><LoadingOutlined /></Button>
                                                                    ) : (
                                                                        <Button htmlType="submit" className="edit-message-form-submit-button"><SendOutlined /></Button>
                                                                    )
                                                                }
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={ 'message-text' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                                    <Linkify componentDecorator={ (href, text, key) => (<a href={ href}  key={ key } target="_blank" rel="noopener noreferrer">{ text }</a>) }>{ nl2br(message.message) }</Linkify>
                                                </div>

                                                {
                                                    (isMessageSentByCurrentUser) && (
                                                        <div style={{ float: 'right', marginTop: '12px' }}>
                                                            <DeleteOutlined onClick={ () => { handleDeleteMessage(message.chatId, message._id) } } className="message-action mr-1" />
                                                            <EditOutlined onClick={ () => { handleEditMessageButtonClicked(message.chatId, message._id, message.message) } } className="message-action mr-2" />
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    
                        <div className="message-details">{ !isMessageSentByCurrentUser && ((message.messageBy && message.messageBy.name) || 'Bot') + ' - ' }{ moment(message.createdAt).format('HH:mm:ss') }</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const onFileUploadSuccess = (response) => {
        let chatMessage = response.chatMessage || null;
        if(!chatMessage) return;

        chatMessage.messageBy = { userType: 'operator', userId: currentUser._id, name: currentUser.displayName };

        updateMessages(messages => {
            if(!messages) return { [chatMessage._id]: chatMessage };
            messages[chatMessage._id] = chatMessage;
        });

        updateChats(chats => {
            if(chats && chats[chatMessage.chatId]) {
                if(''+chatMessage.chatId !== ''+activeChatId) chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                chats[chatMessage.chatId].latestMessage = chatMessage;
                chats[chatMessage.chatId].updatedAt = Date.now();
            }
        });

        socket.emit('operator.chat.message', { ...chatMessage }, (ack) => {
            if(ack && ack.result && ack.tempChatMessageId) {
                updateMessages(messages => {
                    if(messages && messages[ack.tempChatMessageId]) {
                        messages[ack.tempChatMessageId].isSent = true;
                    }
                });
            }
        });
    }

    const onFileUploadError = (err = null) => {
        let chatMessage = {
            _id: uuid(),
            chatId: activeChatId,
            messageType: (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && Object.keys(systemVariables.chat.messageTypes).find(key => systemVariables.chat.messageTypes[key] === 'Error')),
            messageBy: { userType: 'operator', userId: currentUser._id, name: currentUser.displayName },
            message: (err && err.message ? err.message : 'An unknown error occured while uploading the file. Please try again.'),
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
        };

        updateMessages(messages => {
            if(!messages) return { [chatMessage._id]: chatMessage };
            messages[chatMessage._id] = chatMessage;
        });

        updateChats(chats => {
            if(chats && chats[chatMessage.chatId]) {
                if(''+chatMessage.chatId !== ''+activeChatId) chats[chatMessage.chatId].unseenMessagesCount = chats[chatMessage.chatId].unseenMessagesCount + 1;
                chats[chatMessage.chatId].latestMessage = chatMessage;
                chats[chatMessage.chatId].updatedAt = Date.now();
            }
        });

        socket.emit('operator.chat.message', { chatId: chatMessage.chatId, messageType: chatMessage.messageType, message: chatMessage.message, tempChatMessageId: chatMessage._id }, (ack) => {
            if(ack && ack.result && ack.tempChatMessageId) {
                updateMessages(messages => {
                    if(messages && messages[ack.tempChatMessageId]) {
                        messages[ack.tempChatMessageId].isSent = true;
                    }
                });
            }
        });
    }

    return (
        <GlobalHotKeys allowChanges={ true } keyMap={ hotKeysKeyMap } handlers={ hotKeysHandlers }>
            <Helmet>
                <title>My Chats | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (currentUser && chats) ? (
                            <DraggableModalProvider>
                                <div className="page-MyChats">
                                    <Row type="flex" gutter={ 8 }>
                                        <Col span={ 6 } style={{ height: 'calc(100vh - 160px)' }}>
                                            <CardWithShadow style={{ overflow: 'auto', height: '100%' }}>
                                                <section className="chat-list">
                                                    <div className="section-title py-2 px-3">
                                                        <h4 style={{ color: '#6800D0' }}>Pending Chats</h4>
                                                    </div>

                                                    <div className="px-3">
                                                        {
                                                            (chats && Object.keys(chats).length > 0) ? (
                                                                Object.keys(chats).map((chatId) => {
                                                                    let chat = chats[chatId];

                                                                    return (
                                                                        <Link key={ chat._id } to={ '/my-chats/' + chat._id }>
                                                                            <div className={ 'chat-list-item'+(chat._id === activeChatId ? ' active' : '') }>
                                                                                <Avatar style={{ background: string2Hex(chat.visitor.name ? chat.visitor.name : chat.visitor.ip), color: '#000' }} size={ 35 } className="avatar">{ chat.visitor.name ? chat.visitor.name.substring(0, 1).toUpperCase() : chat.visitor.ip.substring(0, 1).toUpperCase() }</Avatar>
                                                                
                                                                                <div className="container">
                                                                                    <div className="row1">
                                                                                        <Text ellipsis className="username">{ (chat.visitor.name ? chat.visitor.name : chat.visitor.ip) }</Text>
                                                                                        <Text className="time">{ chat.latestMessage && createdAt2When(chat.latestMessage.createdAt) }</Text>
                                                                                    </div>
                                                                                    <div className="row2">
                                                                                        <Text ellipsis className="message">{ chat.latestMessage && chat.latestMessage.message ? chat.latestMessage.message : (chat.latestMessage && chat.latestMessage.attachment && chat.latestMessage.attachment.originalFileName ? chat.latestMessage.attachment.originalFileName : '') }</Text>
                                                                                        <div className="badge-container"><Badge className="badge" count={ (chat._id !== activeChatId && chat.unseenMessagesCount ? chat.unseenMessagesCount : 0) } style={{ backgroundColor: 'rgba(98,70,234, 1)', color: '#ffffff' }} /></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ clear: 'both' }}></div>
                                                                            </div>
                                                                        </Link>
                                                                    );
                                                                })
                                                            ) : (
                                                                <div className="py-3"><Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } description="No active chats" /></div>
                                                            )
                                                        }
                                                    </div>
                                                </section>
                                            </CardWithShadow>
                                        </Col>

                                        {
                                            (systemVariables && activeChatId && chats && chats[activeChatId]) && (
                                                <>
                                                    <Col span={ 13 } style={{ height: 'calc(100vh - 160px)' }}>
                                                        <CardWithShadow>
                                                            {
                                                                (systemVariables && activeChatId && chats && chats[activeChatId]) ? (
                                                                    <section className="chat-container">
                                                                        <div className="chat-container-header py-2 px-3">
                                                                            <div className="actions mt-1" style={{ float: 'right' }}>
                                                                                {
                                                                                    (chats[activeChatId].status === Object.keys(chatStatusOptions).find(key => chatStatusOptions[key] === 'Ended')) ? (
                                                                                        <Button className="btnAdd" onClick={ () => handleCloseChatClick(activeChatId) }>Close Chat</Button>
                                                                                    ) : (
                                                                                        (activeChatId && chats && chats[activeChatId] && chats[activeChatId].operatorId === currentUser._id) ? (
                                                                                            <>
                                                                                                <Button className="btnSecondary mr-1" onClick={ () => { setTransferChatModalVisible(true) } }><UserSwitchOutlined /> Transfer</Button>

                                                                                                {
                                                                                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                                                                                        <Dropdown 
                                                                                                            trigger={['click']}
                                                                                                            overlay={
                                                                                                                <Menu>
                                                                                                                    <Menu.Item onClick={ () => { handleBanUser('8h') } } key="8h">8 hours</Menu.Item>
                                                                                                                    <Menu.Item onClick={ () => { handleBanUser('1d') } } key="1d">1 day</Menu.Item>
                                                                                                                    <Menu.Item onClick={ () => { handleBanUser('3d') } } key="3d">3 days</Menu.Item>
                                                                                                                </Menu>
                                                                                                            }
                                                                                                        >
                                                                                                            <Button className="btnSecondary mr-1"><StopOutlined /> Ban <DownOutlined /></Button>
                                                                                                        </Dropdown>
                                                                                                    )
                                                                                                }

                                                                                                <Button className="btnAdd" onClick={ () => handleEndChatClick(activeChatId) }>End Chat</Button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <Button className="btnSecondary mr-1" onClick={ () => { setTransferChatModalVisible(true) } }><UserSwitchOutlined /> Transfer</Button>

                                                                                                {
                                                                                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                                                                                        <Dropdown 
                                                                                                            trigger={['click']}
                                                                                                            overlay={
                                                                                                                <Menu>
                                                                                                                    <Menu.Item onClick={ () => { handleBanUser('8h') } } key="8h">8 hours</Menu.Item>
                                                                                                                    <Menu.Item onClick={ () => { handleBanUser('1d') } } key="1d">1 day</Menu.Item>
                                                                                                                    <Menu.Item onClick={ () => { handleBanUser('3d') } } key="3d">3 days</Menu.Item>
                                                                                                                </Menu>
                                                                                                            }
                                                                                                        >
                                                                                                            <Button className="btnSecondary mr-1"><StopOutlined /> Ban <DownOutlined /></Button>
                                                                                                        </Dropdown>
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                                {
                                                                                                    (activeChatId && chats && chats[activeChatId] && chats[activeChatId].operatorsJoined && Array.isArray(chats[activeChatId].operatorsJoined) && chats[activeChatId].operatorsJoined.includes(''+currentUser._id)) ? (
                                                                                                        null
                                                                                                    ) : (
                                                                                                        <Button className="btnSecondary mr-1" onClick={ () => { handleJoinChatClick(activeChatId) } }>Join</Button>
                                                                                                    )
                                                                                                }

                                                                                                <Button className="btnAdd" onClick={ () => handleLeaveChatClick(activeChatId) }>Leave Chat</Button>
                                                                                            </>
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <h4>{ (chats[activeChatId].visitor.name ? chats[activeChatId].visitor.name : chats[activeChatId].visitor.ip) }</h4>
                                                                        </div>

                                                                        <div className="chat-log-wrapper">
                                                                            {
                                                                                (shortcutGroups && Object.values(shortcutGroups)) && (
                                                                                    <div className="shortcutsC" style={ shortcutsContainerIsVisible ? { display: 'block' } : { display: 'none' } }>
                                                                                        <div className="shortcutsH">
                                                                                            <Tabs className="mt-2" activeKey={ shortcutsActiveTab } onChange={ onShortcutsTabChange }>
                                                                                                { 
                                                                                                    (shortcutGroups && Object.values(shortcutGroups) && Object.values(shortcutGroups).map(shortcutGroup => {
                                                                                                        return (
                                                                                                            <Tabs.TabPane tab={ shortcutGroup.name } key={ shortcutGroup._id }>
                                                                                                                <Form form={ searchShortcutForm } initialValues={{}} name="searchShortcut" onFinish={ onShortcutsApplyFilter } layout="horizontal" hideRequiredMark={ true }>
                                                                                                                    <Row gutter={ 8 }>
                                                                                                                        <Col span={ 8 }>
                                                                                                                            <Form.Item name="search">
                                                                                                                                <Input className="filterOptions searchBar" prefix={ <SearchOutlined /> } />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                
                                                                                                                        <Col span={ 8 }>
                                                                                                                            <Form.Item>
                                                                                                                                <Button className="btnAdd mr-1" type="primary" htmlType="submit"><FilterOutlined /> Apply</Button>
                                                                                                                                <Button className="btnBack" onClick={ onShortcutsResetFields }>Reset</Button>
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </Form>
                                                                                                                
                                                                                                                {
                                                                                                                    (shortcutGroup.shortcuts && Object.values(shortcutGroup.shortcuts) && Object.values(shortcutGroup.shortcuts).map(shortcut => {
                                                                                                                        if(shortcutsQueryParams && shortcutsQueryParams.search && !shortcut.key.startsWith(shortcutsQueryParams.search)) return null;

                                                                                                                        return (
                                                                                                                            <div onClick={ () => onShortcutSelect(shortcut.message) } key={ shortcut.key } className="shortcut mt-1"><span className="mr-2" style={{ fontWeight: 700, fontStyle: 'italic' }}>#{ shortcut.key }</span>{ shortcut.message }</div>
                                                                                                                        );
                                                                                                                    }))
                                                                                                                }
                                                                                                            </Tabs.TabPane>
                                                                                                        );
                                                                                                    }))
                                                                                                }
                                                                                            </Tabs>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <div id="chat-log-container" className="chat-log-container">
                                                                                <>
                                                                                    { messagesPagination && messagesPagination.current < Math.ceil(messagesPagination.total / messagesPagination.pageSize) && <Button className="btnSecondary mr-1" onClick={ () => { loadMoreMessages(((messagesPagination && messagesPagination.current + 1) || 1)); } }><UpOutlined /> Load Previous Messages</Button> }

                                                                                    {
                                                                                        activeChatId && messages && Object.values(messages) && Object.values(messages).length > 0 && Object.values(messages).map((message, i) => {
                                                                                            let cDate = null;

                                                                                            if(i === 0) {
                                                                                                cDate = moment(message.createdAt).format('LL');
                                                                                            } else if(i > 0) {
                                                                                                let cPreviousMessage = Object.values(messages)[i-1];
                                                                                                if(cPreviousMessage) {
                                                                                                    if(moment(cPreviousMessage.createdAt).startOf('day').format('LL') !== moment(message.createdAt).startOf('day').format('LL')) {
                                                                                                        cDate = moment(message.createdAt).format('LL');
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        
                                                                                            return formatChatMessage(message, i, cDate);
                                                                                        })
                                                                                    }

                                                                                    { 
                                                                                        (activeChatId && uploads && uploads[activeChatId] && uploads[activeChatId] !== 0) && (
                                                                                            
                                                                                            <div key={ 'chat-log-message-wrapper--uploading' } className={ 'chat-log-message-wrapper chat-log-message-wrapper--center' }>
                                                                                                <div className="chat-log-message-container">
                                                                                                    <Progress className="mx-2" percent={ parseInt(uploads[activeChatId]) } status={ (uploads[activeChatId] > 0 ? 'active' : 'exception') } />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        chatMessagesOnKeyDown && chatMessagesOnKeyDown[activeChatId] && chatMessagesOnKeyDown[activeChatId].message && (
                                                                                            <div className={ 'chat-message' }>
                                                                                                <div className="row1">
                                                                                                    <span className="chat-message-by">{ chatMessagesOnKeyDown[activeChatId].messageBy.name } | typing...</span>
                                                                                                </div>

                                                                                                <div className="row2">
                                                                                                    <Avatar style={{ background: (chatMessagesOnKeyDown[activeChatId].messageBy.userId === currentUser._id ? '#FFD967' : string2Hex(chatMessagesOnKeyDown[activeChatId].messageBy.name)) }} size={ 35 } className="chat-message-avatar">{ chatMessagesOnKeyDown[activeChatId].messageBy.name.substring(0, 1).toUpperCase() }</Avatar>
                                                                                    
                                                                                                    <div className="chat-message-text">
                                                                                                        <p>{ nl2br(chatMessagesOnKeyDown[activeChatId].message) }</p>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row3">
                                                                                                    <span className="chat-message-datetime">{ moment(chatMessagesOnKeyDown[activeChatId].createdAt).format('HH:mm:ss') }</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                    <div ref={ bottomOfChatLog } className="chat-log-container--bottom"></div>
                                                                                </>
                                                                            </div>
                                                                        </div>

                                                                        <div className="chat-form-emoji-picker-overlay" style={{ display: (sendMessageFormEmojiPickerVisible ? 'block' : 'none') }} onClick={ () => { setSendMessageFormEmojiPickerVisible(false) } }></div>
                                                                        
                                                                        {
                                                                            (activeChatId && chats && chats[activeChatId] && (chats[activeChatId].status && chatStatusOptions && chatStatusOptions[chats[activeChatId].status] && ['Active'].includes(chatStatusOptions[chats[activeChatId].status])) && (chats[activeChatId].operatorId === currentUser._id || (chats[activeChatId].operatorsJoined && Array.isArray(chats[activeChatId].operatorsJoined) && chats[activeChatId].operatorsJoined.includes(currentUser._id)))) && (
                                                                                <div className="container-chat-form p-3">
                                                                                    <Form form={ sendMessageForm } className="chat-form" name="sendMessage" onFinish={ onSendMessage } layout="vertical" hideRequiredMark={ true }>
                                                                                        <Form.Item className="chat-form-font-size-selector-container">
                                                                                            <span>
                                                                                                <Dropdown 
                                                                                                    overlay={
                                                                                                        <Menu className="mb-3" selectedKeys={ fontSizeSelection ? [fontSizeSelection+''] : [] }>
                                                                                                            { 
                                                                                                                chatRoomFontSizeOptions && Object.keys(chatRoomFontSizeOptions).map(key => {
                                                                                                                    return (
                                                                                                                        <Menu.Item key={ key+'' } onClick={ () => { handleFontSizeSelection(key) } }>
                                                                                                                            <span className="px-1 font-size-selector-menu-item" style={{ fontWeight: '500', color: '#767990' }}>{ chatRoomFontSizeOptions[key] }</span>
                                                                                                                        </Menu.Item>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </Menu>
                                                                                                    } 
                                                                                                    trigger={['click']}
                                                                                                    placement="topLeft"
                                                                                                >
                                                                                                    <Button className="ant-dropdown-link" style={{ outline: '0', border: '0 none', padding: '0 0 0 0' }}>
                                                                                                        <span className="icon"><FontSizeOutlined /></span>
                                                                                                    </Button>
                                                                                                </Dropdown>
                                                                                            </span> 
                                                                                        </Form.Item>

                                                                                        <Form.Item className="chat-form-emoji-picker-container">
                                                                                            <span>
                                                                                                <span className="icon mr-1" onClick={ () => { setSendMessageFormEmojiPickerVisible(!sendMessageFormEmojiPickerVisible) } }><SmileOutlined /></span>
                                                                                                <span className="picker" style={{ display: (sendMessageFormEmojiPickerVisible ? 'block' : 'none') }}><Picker set="apple" title={ '' } showPreview={ false } showSkinTones={ false } onSelect={ sendMessageForm_addEmoji } /></span>
                                                                                            </span> 
                                                                                        </Form.Item>

                                                                                        <div className="chat-form-input-C" onKeyDown={ sendMessage_onKeyDown }>
                                                                                            <Form.Item name="message">
                                                                                                <Mentions 
                                                                                                    ref={ sendMessage_mentionsRef }
                                                                                                    autoSize={ { minRows: 1, maxRows: 3} }
                                                                                                    autoFocus={ true }
                                                                                                    className={ 'chat-form-input'+(chatRoomFontSizeOptions && fontSizeSelection && chatRoomFontSizeOptions[fontSizeSelection] ? ' '+chatRoomFontSizeOptions[fontSizeSelection].toLowerCase() : '') }
                                                                                                    prefix="#"
                                                                                                    filterOption={ (input, option) => { return (!input || (option && option.value.startsWith(input))); } }
                                                                                                    notFoundContent="No matching shortcut!"
                                                                                                    onSearch={ (text, prefix) => { !mentionsOnSearch && setMentionsOnSearch(true) } }
                                                                                                    onBlur= { () => { mentionsOnSearch && setMentionsOnSearch(false) } }
                                                                                                    onChange= { (text) => {
                                                                                                        if(messageDraftsByChatId && messageDraftsByChatId.current) messageDraftsByChatId.current[activeChatId] = text;
                                                                                                        mentionsOnSearch && setMentionsOnSearch(false) 
                                                                                                    } }
                                                                                                    onSelect={ (option, prefix) => {
                                                                                                        if(!shortcutGroups) return;
                                                                                                        
                                                                                                        let shortcut = null;
                                                                                                        if(shortcutGroups['Personal'] && shortcutGroups['Personal'].shortcuts) {
                                                                                                            shortcut = Object.values(shortcutGroups['Personal'].shortcuts).find(shortcut => shortcut.key === option.value);
                                                                                                        }
                                                                                                        
                                                                                                        if(!shortcut && shortcutGroups['ALL'] && shortcutGroups['ALL'].shortcuts) {
                                                                                                            shortcut = Object.values(shortcutGroups['ALL'].shortcuts).find(shortcut => shortcut.key === option.value);
                                                                                                        }

                                                                                                        if(!shortcut) return;

                                                                                                        let xMessage = sendMessageForm.getFieldValue('message').replace(prefix + option.value, shortcut.message);
                                                                                                        sendMessageForm.setFieldsValue({ message: xMessage });
                                                                                                        mentionsOnSearch && setMentionsOnSearch(false);
                                                                                                        
                                                                                                        const selector = document.getElementById('sendMessage_message');
                                                                                                        setTimeout(function() {
                                                                                                            selector.focus();
                                                                                                            selector.setSelectionRange(selector.value.length, selector.value.length);
                                                                                                        }, 1);
                                                                                                    } }
                                                                                                >
                                                                                                    <>
                                                                                                        {
                                                                                                            (shortcutGroups && shortcutGroups['ALL'] && shortcutGroups['ALL'].shortcuts && Object.values(shortcutGroups['ALL'].shortcuts).map(shortcut => {
                                                                                                                return (
                                                                                                                    <Mentions.Option key={ shortcut._id } value={ shortcut.key }>{ shortcut.key }</Mentions.Option>
                                                                                                                );
                                                                                                            }))
                                                                                                        }
                                                                                                    </>
                                                                                                </Mentions>
                                                                                            </Form.Item>
                                                                                        </div>

                                                                                        <Form.Item className="chat-form-buttons">
                                                                                            <div>
                                                                                                {
                                                                                                    (!shortcutsContainerIsVisible) ? (
                                                                                                        <Button className="chat-form-shortcuts-button" onClick={ () => { setShortcutsContainerIsVisible(true) } }>Shortcuts</Button>
                                                                                                    ) : (
                                                                                                        <Button className="chat-form-shortcuts-button" onClick={ () => { setShortcutsContainerIsVisible(false) } }>Chat</Button>
                                                                                                    )
                                                                                                }

                                                                                                <Upload
                                                                                                    name="file"
                                                                                                    className="chat-form-upload-button" 
                                                                                                    action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chats/' + activeChatId + '/upload' }
                                                                                                    headers={{ authorization: localStorage.getItem('token'), clientId: currentUser.clientId }}
                                                                                                    showUploadList={ false } 
                                                                                                    beforeUpload={ beforeUpload }
                                                                                                    onChange={ 
                                                                                                        (info) => {
                                                                                                            if(info.file.status === 'uploading') {
                                                                                                                updateUploads(uploads => {
                                                                                                                    if(!uploads) uploads = {};
                                                                                                                    uploads[activeChatId] = info.file.percent || 0;
                                                                                                                    return uploads;
                                                                                                                });
                                                                                                            } else if(info.file.status === 'done') {
                                                                                                                updateUploads(uploads => {
                                                                                                                    if(uploads && uploads[activeChatId]) delete uploads[activeChatId];
                                                                                                                    return uploads;
                                                                                                                });

                                                                                                                onFileUploadSuccess((info && info.file && info.file.response) || null);
                                                                                                            } else if (info.file.status === 'error') {
                                                                                                                updateUploads(uploads => {
                                                                                                                    if(uploads && uploads[activeChatId]) uploads[activeChatId] = -1;
                                                                                                                    return uploads;
                                                                                                                });

                                                                                                                onFileUploadError((info && info.error) || null);
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    <Button><PaperClipOutlined /></Button>
                                                                                                </Upload>

                                                                                                {
                                                                                                    (isFormSubmitting) ? (
                                                                                                        <Button htmlType="submit" className="chat-form-submit-button" disabled="disabled"><LoadingOutlined /></Button>
                                                                                                    ) : (
                                                                                                        <Button htmlType="submit" className="chat-form-submit-button"><SendOutlined /></Button>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </Form.Item>
                                                                                    </Form>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </section>
                                                                ) : (
                                                                    <section className="chat-container-empty"></section>
                                                                )
                                                            }
                                                        </CardWithShadow>
                                                    </Col>

                                                    <Col span={ 5 }>
                                                        <CardWithShadow style={{ height: 'auto' }}>
                                                            <section className="chat-details">
                                                                <div className="section-title py-2 px-3">
                                                                    <h4 style={{ color: '#6800D0' }}><span style={{ float: 'right' }}><UserOutlined style={{ float: 'right', margin: '6px 0 0 0', fontSize: '24px', color: '#6800D0' }} /></span> User Details</h4>
                                                                </div>

                                                                <div className="user-details py-2 px-3">
                                                                    <Row>
                                                                        <Col span={ 6 }><b>Email</b></Col><Col style={{ textAlign: 'right' }} span={ 18 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.email ? chats[activeChatId].visitor.email : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>Username</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.username ? chats[activeChatId].visitor.username : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>Name</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.name ? chats[activeChatId].visitor.name : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>IP</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.ip ? <span> { chats[activeChatId].visitor.ip } { (chats[activeChatId].visitor.countryCode && typeof(chats[activeChatId].visitor.countryCode) === 'string' && <ReactCountryFlag countryCode={ chats[activeChatId].visitor.countryCode } style={{ fontSize: '18px'}} />) } </span> : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>User-Agent</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.userAgent ? chats[activeChatId].visitor.userAgent : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>Reg. Date</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.registeredAt ? moment.unix(chats[activeChatId].visitor.registeredAt+'').format('DD/MM/YYYY') : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>Page</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.currentPage ? chats[activeChatId].visitor.currentPage.title : 'N/A') } </Col>
                                                                        <Col className="mt-1" span={ 8 }><b>Duration</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chats[activeChatId].visitor && chats[activeChatId].visitor.sessionStartedAt ? parseInt(moment.duration(moment().diff(moment.unix(chats[activeChatId].visitor.sessionStartedAt))).asMinutes()) + ' minutes' : 'N/A') } </Col>
                                                                    </Row>
                                                                </div>

                                                                {
                                                                    (previousChats && previousChats.chats && Array.isArray(previousChats.chats) && previousChats.chats.length > 0) && (
                                                                        <>
                                                                            <div className="my-3"></div>

                                                                            <div className="section-title py-2 px-3">
                                                                                <h4 style={{ color: '#6800D0' }}><span style={{ float: 'right' }}><HistoryOutlined style={{ float: 'right', margin: '6px 0 0 0', fontSize: '24px', color: '#6800D0' }} /></span> Chat History</h4>
                                                                            </div>
                
                                                                            <div className="user-details">
                                                                                {
                                                                                    Array.isArray(previousChats.chats) && previousChats.chats.length > 0 && previousChats.chats.map((previousChat) => {
                                                                                        return (
                                                                                            <div key={ previousChat._id }>
                                                                                                { previousChat.operatorId &&
                                                                                                    <div className="previousChatC py-3 px-3" onClick={ () => { handleFetchPreviousChat(previousChat._id) } }>
                                                                                                        <MessageOutlined style={{ float: 'right', margin: '6px 0 0 0', fontSize: '24px', color: '#6800D0' }} />

                                                                                                        <>
                                                                                                            <div><b>{ operators && operators[previousChat.operatorId] ? operators[previousChat.operatorId].displayName : previousChat.operatorId }</b></div>
                                                                                                            <div className="date-time mt-1">{ moment(previousChat.createdAt).format('LL') } - { moment(previousChat.createdAt).format('HH:mm:ss') }</div>
                                                                                                        </>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                    
                                                                                { previousChats && previousChats.chats && previousChats.pagination && <Pagination simple style={{ textAlign: 'right' }} className="py-2 pr-3" current={ previousChats.pagination.current } onChange={ (page, pageSize) => { handlePreviousChatsPagination(page) } } total={ previousChats.pagination.total } /> }
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }

                                                                <div className="pb-2"></div>
                                                            </section>
                                                        </CardWithShadow>
                                                    </Col>
                                                </>
                                            )
                                        }
                                    </Row>
                                </div>

                                <DraggableModal title={ selectedPreviousChat && selectedPreviousChat.visitor ? (selectedPreviousChat.visitor.name ? selectedPreviousChat.visitor.name : selectedPreviousChat.visitor.ip) : 'Chat History' } className="previousChatDraggableModal" initialWidth={ 540 } initialHeight={ 480 } visible={ previousChatModalIsVisible } onCancel={ () => { setPreviousChatModalIsVisible(false) } } footer={ null } bodyStyle={{ padding: '0 0 0 0 !important' }}>
                                    <section className="chat-container">
                                        <div className="chat-log-wrapper">
                                            <div className="chat-log-container">
                                                {
                                                    selectedPreviousChatMessages && Array.isArray(selectedPreviousChatMessages) && selectedPreviousChatMessages.length > 0 && selectedPreviousChatMessages.map((message, i) => {
                                                        let cDate = null;

                                                        if(i === 0) {
                                                            cDate = moment(message.createdAt).format('LL');
                                                        } else if(i > 0) {
                                                            let cPreviousMessage = selectedPreviousChatMessages[i-1];
                                                            if(cPreviousMessage) {
                                                                if(moment(cPreviousMessage.createdAt).startOf('day').format('LL') !== moment(message.createdAt).startOf('day').format('LL')) {
                                                                    cDate = moment(message.createdAt).format('LL');
                                                                }
                                                            }
                                                        }

                                                        return formatChatMessage(message, i, cDate);
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </section>
                                </DraggableModal>

                                <Modal onCancel={ () => setAttachmentToDisplay(null) } className="imgModal" visible={ attachmentToDisplay ? true : false } footer={ null }>
                                    {  attachmentToDisplay && attachmentToDisplay.filePath ? <img style={{ width: '100%' }} src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + attachmentToDisplay.filePath.replace(/^public\//, '') } alt={ attachmentToDisplay.originalFileName ? attachmentToDisplay.originalFileName : 'Image' } /> : null }
                                </Modal>

                                <audio ref={ newMessageAudioRef }><source src={ newMessageAudioAsset } type="audio/mpeg" /></audio>
                            </DraggableModalProvider>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }

                    <Modal
                        visible={ transferChatModalVisible }
                        onCancel={ () => { setTransferChatModalVisible(false) } }
                        footer={ null }
                    >

                        <h1 className="mb-3">Transfer Chat</h1>
                        <Form name="transferChat" onFinish={ onTransferChatFinish } layout="horizontal" hideRequiredMark={ true }>
                            <Form.Item 
                                name="assignTo"
                                label="Assign to"
                            >
                                <Select>
                                    { onlineOperators && Object.values(onlineOperators).map(operator => <Select.Option key={ operator.userId } value={ operator.userId }>{ operator.displayName }</Select.Option>) }
                                </Select>
                            </Form.Item>

                            <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            </MainLayout>
        </GlobalHotKeys>
    );
};

export default MyChats;
