export const getDefaultStyleCode = () => {
    return (
`.widget-wrapper {
}

.widget-wrapper .widget-container {
}

.widget-wrapper .widget-container .widget-header {
}

.widget-wrapper .widget-container .widget-body {
}

.widget-wrapper .widget-container .widget-body .widget-body-container {
}

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 {
}

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 .prechat-greeting-container {
}

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 .channels {
}

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 .channels .channel {
    }

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 .channels .channel .channel-name {
    }

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 .channels .channel .channel-helper-text {
    }

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step1 .channels .channel .channel-button {
    }

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step2 {
}

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step2 .prechat-greeting-container {
}

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step2 button[type=submit] {
}

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step3 {
}

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step3 .box {
    }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step3 .box .btn-primary {
        }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step3 .box .btn-secondary {
        }

.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 {
}

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .box {
    }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .box .btn-primary {
        }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .box .btn-secondary {
        }

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container {
    }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper {
        }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left {
        }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container {
        }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-by--avatar {
            }
            
            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-by--avatar .avatar {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-text {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-file {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-button-group {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-button-group button {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel .carousel-item {
            }

                .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel .carousel-item {
                }

                .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel .carousel-item .carousel-item--image {
                }

                .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel .carousel-item .carousel-item--description {
                }

                .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel .carousel-item .carousel-item--button-group {
                }

                    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-carousel .carousel-item .carousel-item--button-group button {
                    }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--left .chat-log-message-container .message-details {
            }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--right {
        }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--right .chat-log-message-container {
        }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--right .chat-log-message-container .message-text {
            }

            .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step4 .chat-log-container .chat-log-message-wrapper.chat-log-message-wrapper--right .chat-log-message-container .message-file {
            }
        
.widget-wrapper .widget-container .widget-body .widget-body-container #widget-step5 {
}

    .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step5 .box {
    }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step5 .box .btn-primary {
        }

        .widget-wrapper .widget-container .widget-body .widget-body-container #widget-step5 .box .btn-secondary {
        }`
    );
}