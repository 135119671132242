import React from 'react';
import { useSystemContext } from '../Context/SystemContext';

import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = (props) => {
    const { currentUser } = useSystemContext();

    if(!localStorage.getItem('token')) {
        return (
            <Route { ...props } component={ () => <Redirect to={{ pathname: '/login' }} /> } />
        );
    }

    if(currentUser && props.roles && Array.isArray(props.roles) && props.roles.length > 0) {
        if(!props.roles.includes(currentUser.role)) window.location.href = '/unauthorized';
    }

    return (
        <Route { ...props } />
    );
}

export default ProtectedRoute;