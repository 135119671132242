export const getDefaultExternalStyleCode = () => {
    return (
`.${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME}-launcher-container {
    z-index: 9999998;
    overflow: hidden;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background: #6800D0;
    box-shadow: rgb(0 0 0 / 6%) 0px 1px 6px 0px, rgb(0 0 0 / 16%) 0px 2px 32px 0px;
    border-radius: 50%;
}

.${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME}-messenger-container {
    z-index: 9999999;
    overflow: hidden;
    position: fixed;
    bottom: 100px;
    right: 20px;
    height: calc(100% - 120px);
    width: 376px;
    min-height: 250px;
    max-height: 704px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
    opacity: 1;
    border-radius: 8px;
    transition: width 200ms ease 0s, height ease 0s, max-height ease 0s;
}

@media (max-width: 720px) {
    body.${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME}--expanded {
        overflow: hidden;
    }

    .${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME}-messenger-container {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
    }
}`
    );
}