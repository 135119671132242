import io from 'socket.io-client';

export const socket = io(process.env.REACT_APP_CHATLINE_SOCKET_ADDRESS.replace(/^\/|\/$/g, '')+'/', {
    path: '/chat/socket',
    transports: ['websocket'],
    secure: (process.env.REACT_APP_CHATLINE_SOCKET_ADDRESS.startsWith('https://') ? true : false),
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    query: { userType: 'operator' }
});