import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useImmer } from "use-immer";
import { usePrevious } from '../hooks/custom';
import { useHistory, useLocation } from 'react-router-dom';

import { socket } from '../service/socket';

import ChatlineAPI from '../utils/ChatlineAPI';

export const SystemContext = createContext();

export const SystemContextProvider = ({ children }) => {
    const [currentUser, updateCurrentUser] = useImmer(null);
    const [systemVariables, setSystemVariables] = useState(null);
    const [client, updateClient] = useImmer(null);
    const [operators, updateOperators] = useImmer(null);

	const [isSocketReadyToConnect, setIsSocketReadyToConnect] = useState(false);
	const [isSocketConnected, setIsSocketConnected] = useState(false);

    const [notificationsDataset, updateNotificationsDataset] = useImmer(null);

    const [chatQueueBadgeCount, updateChatQueueBadgeCount] = useImmer(0);
    const [myChatsBadgeCountChatIds, updateMyChatsBadgeCountChatIds] = useImmer(null);
    const [operatorsBadgeCountChatIds, updateOperatorsBadgeCountChatIds] = useImmer(null);

    const history = useHistory();
    const location = useLocation();
    const previousLocation = usePrevious(location);

    const fetchCurrentUser = useCallback(async () => {
        if(localStorage.getItem('token')) {
            ChatlineAPI.HttpGetRequest('auth/user', (err, res) => {
                if(err || !res || !res.data) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    return;
                }
                
                updateCurrentUser(currentUser => res.data);
            });
        }
    }, [updateCurrentUser]);

    const updateCurrentUserParameter = useCallback((parameterKey, parameterValue, callback) => {
        let pData = {};
        pData[parameterKey] = parameterValue;

        socket.emit('operator.parameters.update', pData, (ack) => {
            if(ack && ack.result) {
                if(ack.operator && ack.operator.parameters) {
                    updateCurrentUser(currentUser => {
                        currentUser.parameters = ack.operator.parameters;
                    });

                    if(callback && typeof(callback) === 'function') callback();
                }
            }
        });
    }, [updateCurrentUser]);

	useEffect(() => {
		fetchCurrentUser();
	}, [fetchCurrentUser]);


    const fetchSystemVariables = useCallback(async () => {
        ChatlineAPI.HttpGetRequest('system/variables', (err, res) => {
            if(err || !res || !res.data) {
                alert('System variables could not be fetched!');
                return;
            }

            setSystemVariables(res.data);
        });
    }, []);

	useEffect(() => {
		if(currentUser) {
			fetchSystemVariables();
		}
	}, [fetchSystemVariables, currentUser]);

    
    const fetchClient = useCallback(async () => {
        ChatlineAPI.HttpGetRequest('client', (err, res) => {
            if(err || !res || !res.data) {
                alert('Unable to fetch the Client!');
                return;
            }

            updateClient(client => ((res && res.data && res.data.client) || null));
		});
    }, [updateClient]);

    useEffect(() => {
		if(currentUser) {
			fetchClient();
		}
	}, [fetchClient, currentUser]);


    useEffect(() => {
        setIsSocketReadyToConnect(currentUser && systemVariables && client);
	}, [currentUser, systemVariables, client]);


	const onSocketConnect = useCallback(() => {
		socket.emit('operator.connect', null, (ack) => {
			if(ack && ack.result) {
				setIsSocketConnected(true);
			}
		});
	}, []);

    useEffect(() => {
		if(isSocketReadyToConnect) {
			socket.off('connect').on('connect', () => { // Fired upon connection (including a successful reconnection)
				console.log('App.js | socket.connect()');
                onSocketConnect();
            });

			socket.off('disconnect').on('disconnect', () => {
				console.log('App.js | socket.disconnect()');
                setIsSocketConnected(false);
			});

			if(localStorage.getItem('token', null) !== null) {
				socket.auth = { token: localStorage.getItem('token', null) };
			}

			if(!socket.connected) socket.connect();
		}

        return function cleanup() {
			socket.off('connect');
			socket.off('disconnect');
        }
	}, [isSocketReadyToConnect, onSocketConnect]);

    const fetchOperators = useCallback(() => {
        socket.emit('operator.operators.all.get', null, (ack) => {
            updateOperators(operators => ((ack && ack.result && ack.operators) || null));
        });
    }, [updateOperators]);

    useEffect(() => {
        if(isSocketConnected) {
            fetchOperators();
        }
	}, [fetchOperators, isSocketConnected]);

    const fetchNotifications = useCallback(() => {
        socket.emit('operator.notifications.get', { limit: 4 }, (ack) => {
            updateNotificationsDataset(notificationsDataset => ((ack && ack.result && ack.response) || {}));
        });
    }, [updateNotificationsDataset]);

    const openNotification = useCallback((notification) => {
        if(!notification.seen) {
            socket.emit('operator.notifications.notification.markAsSeen', { notificationId: notification._id }, (ack) => {
                if(ack && ack.result) {
                    updateNotificationsDataset(notificationsDataset => {
                        notificationsDataset.unseenCount = (notificationsDataset && notificationsDataset.unseenCount && notificationsDataset.unseenCount > 0 ? (notificationsDataset.unseenCount - 1) : 0);
                    });
                }
            });
        }

        if(notification.link && notification.link.substring(0, 6) === '$chat/') {
            let chatId = (notification.parameters && notification.parameters.chatId ? notification.parameters.chatId : null);
            if(!chatId) return;

            socket.emit('operator.chats.chat.get', { chatId: chatId }, (ack) => {
                if(ack && ack.result) {
                    if(ack.chat) {
                        if(ack.chat.status === 2) { // Chat.STATUS_ENDED
                            history.push('/chat-history/' + ack.chat._id);
                            return;
                        } else if(ack.chat.status === 1) { // Chat.STATUS_ACTIVE
                            socket.emit('operator.chat.observe', { chatId: ack.chat._id }, (ack2) => {
                                if(ack2 && ack2.result && ack2.chat) {
                                    setTimeout(() => {
                                        history.push('/my-chats/' + ack2.chat._id);
                                    }, 1000);
                                }
                            });
                            return;
                        }
                    }
                }
            });

            return;
        }

        if(notification.link) {
            history.push(notification.link);
            return;
        }
    }, [updateNotificationsDataset, history]);

    useEffect(() => {
        if(isSocketConnected) {
            fetchNotifications();
        }
	}, [fetchNotifications, isSocketConnected]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('operator.notifications.new').on('operator.notifications.new', (notification) => {
                fetchNotifications();
            });
        }

        return function cleanup() {
			socket.off('operator.notifications.new');
        }
	}, [isSocketConnected, fetchNotifications]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.off('operator.availability.update').on('operator.availability.update', (pOperator) => {
                updateCurrentUser(currentUser => { 
                    if(currentUser._id+'' === pOperator.userId+'') currentUser.availability = pOperator.availability;
                });
            });
        }

        return function cleanup() {
            socket.off('operator.availability.update');
        }
    }, [isSocketConnected, updateCurrentUser]);

    useEffect(() => {
        if(isSocketConnected) {
			socket.off('operator.room.join').on('operator.room.join', (room) => {
				if(room) socket.emit('operator.room.join', room);
			});

			socket.off('operator.room.leave').on('operator.room.leave', (room) => {
				if(room) socket.emit('operator.room.leave', room);
			});
        }

        return function cleanup() {
			socket.off('operator.room.join');
			socket.off('operator.room.leave');
        }
	}, [isSocketConnected]);
	
	useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.chats.chatQueueBadgeCount.get');

            socket.emit('operator.chats.myChatsBadgeCountChatIds.get');

            socket.emit('operator.chats.operatorsBadgeCountChatIds.get');
        }
    }, [isSocketConnected]);

	useEffect(() => {
        if(location && location.pathname && previousLocation && previousLocation.pathname && location.pathname !== previousLocation.pathname) {
            if(previousLocation.pathname.startsWith('/my-chats') && !location.pathname.startsWith('/my-chats')) {
                let previousActiveChatId = (previousLocation.pathname.startsWith('/my-chats/') && previousLocation.pathname.substring(previousLocation.pathname.lastIndexOf('/') + 1)) || null;
                if(previousActiveChatId) {
                    if(isSocketConnected) {
                        socket.emit('operator.chat.updateLastSeenBy', previousActiveChatId);
                    }
                }
            } else if(previousLocation.pathname.startsWith('/operators/chats') && !location.pathname.startsWith('/operators/chats')) {
                let previousActiveChatId = (previousLocation.pathname.startsWith('/operators/chats/') && previousLocation.pathname.substring(previousLocation.pathname.lastIndexOf('/') + 1)) || null;
                if(previousActiveChatId) {
                    if(isSocketConnected) {
                        socket.emit('operator.internalChats.chat.updateLastSeenBy', previousActiveChatId);
                    }
                }
            } else if(previousLocation.pathname.startsWith('/operators/channels') && !location.pathname.startsWith('/operators/channels')) {
                let previousActiveChatId = (previousLocation.pathname.startsWith('/operators/channels/') && previousLocation.pathname.substring(previousLocation.pathname.lastIndexOf('/') + 1)) || null;
                if(previousActiveChatId) {
                    if(isSocketConnected) {
                        socket.emit('operator.groupChats.chat.updateLastSeenBy', previousActiveChatId);
                    }
                }
            }
        }
    });

    return (
        <SystemContext.Provider value={{ 
            currentUser, updateCurrentUser, fetchCurrentUser, updateCurrentUserParameter,
            systemVariables, fetchSystemVariables,
            client, fetchClient,
            operators, fetchOperators,
            isSocketReadyToConnect, setIsSocketReadyToConnect,
            isSocketConnected, setIsSocketConnected,
            notificationsDataset, fetchNotifications, openNotification,
            chatQueueBadgeCount, updateChatQueueBadgeCount,
            myChatsBadgeCountChatIds, updateMyChatsBadgeCountChatIds,
            operatorsBadgeCountChatIds, updateOperatorsBadgeCountChatIds
        }}>
            { children }
        </SystemContext.Provider>
    );
}

export const useSystemContext = () => useContext(SystemContext);