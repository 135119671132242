import React, { useState, useEffect, useCallback } from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import ChatlineAPI from '../../utils/ChatlineAPI';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';
import Error from '../Error/Error';
import { Link, useHistory } from 'react-router-dom';

import string2Hex from '../../utils/String2Hex';

import moment from 'moment';

import { Table, Tabs, Spin, message, Typography, Tag, Form, Select, Row, Col, Button, Avatar } from 'antd';
import { RightOutlined, FilterOutlined, EnterOutlined } from '@ant-design/icons';

import './Tickets.css';
import classes from './Tickets.module.css';

const Tickets = (props) => {
    const {
        systemVariables
    } = useSystemContext();
    
    const statusOptions = (systemVariables && systemVariables.ticket && systemVariables.ticket.statusOptions ? systemVariables.ticket.statusOptions : null);

    const [form] = Form.useForm();
    const [assignTasksForm] = Form.useForm();
    
    const [ticketsData, setTicketsData] = useState(null);
    const [ticketsById, setTicketsById] = useState(null);
    const [ticketsPagination, setTicketsPagination] = useState(null);
    const [ticketsFilters, setTicketsFilters] = useState(null);

    const [departmentsDataset, setDepartmentsDataset] = useState(null);

    const [membersData, setMembersData] = useState(null);
    const [membersById, setMembersById] = useState(null);

    const [error, setError] = useState(null);

    const history = useHistory();

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('departments', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            setDepartmentsDataset((res && res.data) || {});
        });

        ChatlineAPI.HttpGetRequest('members', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { 
                setMembersData(res.data);

                if(res.data.members && Array.isArray(res.data.members) && res.data.members.length > 0) {
                    let xMembersById = [];
                    for(let member of res.data.members) {
                        xMembersById[member._id] = member;
                    }

                    setMembersById(xMembersById);
                }
            }
        });
    }, []);

    const getTickets = useCallback((page = 1, filters = null) => {
        filters = (filters && Object.keys(filters).length > 0 ? Object.keys(filters).map(key => key + '=' + filters[key]).join('&') : '');

        ChatlineAPI.HttpGetRequest('tickets?page=' + page + (filters ? ('&'+filters) : ''), (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) {
                setTicketsData(res.data);

                if(res.data.tickets && Array.isArray(res.data.tickets) && res.data.tickets.length > 0) {
                    let xTicketsById = [];
                    for(let ticket of res.data.tickets) {
                        xTicketsById[ticket._id] = ticket;
                    }
                    setTicketsById(xTicketsById);
                }
            }
        });
    }, []);

    useEffect(() => {
        getTickets(((ticketsPagination && ticketsPagination.current) || 1), ticketsFilters);
    }, [getTickets, ticketsPagination, ticketsFilters]);

    const handleticketsTableChange = (pagination, filters, sorter) => {
        setTicketsPagination(pagination);
    }

    const onTabChange = (key) => {
        let filters = { ...ticketsFilters };
        if(statusOptions && Object.keys(statusOptions).length > 0) {
            let status = Object.keys(statusOptions).find(xKey => statusOptions[xKey]+'' === key+'');
            if(status) {
                filters.status = status;
            } else {
                delete filters.status;
            }
        }

        setTicketsFilters(filters);
    }

    const onApplyFilter = (values) => {
        if(!values) {
            setTicketsPagination(null);
            setTicketsFilters(null);
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            if(values[key]) filters[key] = (''+values[key]).trim();
        }
        
        setTicketsPagination(null);
        setTicketsFilters(filters);
    }

    const onResetFields = () => {
        form.resetFields();
        setTicketsPagination(null);
        setTicketsFilters(null);
    }

    const columns = [
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (val, row) => {
                return (
                    <>
                        <Avatar style={{ float: 'left', background: string2Hex(row.visitor && row.visitor.name ? row.visitor.name[0] : '?'), margin: '0 12px 0 0' }}>{ row.visitor && row.visitor.name ? row.visitor.name[0] : '?' }</Avatar>

                        <div>
                            <div><b><Typography.Paragraph style={{ margin: '0 0 0 0' }} ellipsis>{ row.subject ? row.subject : 'Subject' }</Typography.Paragraph></b></div>
                            <div className="mt-1" style={{ maxWidth: '280px' }}><Typography.Paragraph style={{ margin: '0 0 0 0' }} ellipsis>{ row.description ? row.description : 'Description' }</Typography.Paragraph></div>
                        </div>
                    </>
                )
            }
        },
        {
            title: 'Sender',
            dataIndex: 'visitorName',
            key: 'visitorName',
            width: 180,
            render: (val, row) => {
                return (
                    <>
                        <div><b>{ row.visitor && row.visitor.name ? row.visitor.name : 'Full name' }</b></div>
                        <div className="mt-1">{ row.visitor && row.visitor.email ? row.visitor.email : 'Email address' }</div>
                    </>
                )
            }
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            width: 180,
            render: (val, row) => {
                return (
                    (membersById && membersById[row.assignedTo] ? <b>{ membersById[row.assignedTo].displayName }</b> : 'N/A')
                );
            }
        },
        {
            title: 'Department',
            dataIndex: 'departmentId',
            key: 'departmentId',
            width: 240,
            render: (val, row) => {
                return (
                    departmentsDataset && departmentsDataset.departments && departmentsDataset.departments[row.departmentId] ? <b>{ departmentsDataset.departments[row.departmentId].name }</b> : ''
                );
            }
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            render: (val, row) => {
                return (
                    row.createdAt ? <b>{ moment(row.createdAt).format('DD.MM.YYYY') }</b> : '-'
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 125,
            render: (val, row) => {
                return (
                    <div>{ statusValue2Tag(row.status) }</div>
                );
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 45,
            className: 'actionsC',
            render: (val, row) => {
                return <Link to={ '/tickets/' + row._id } style={{ color: '#000000' }}><RightOutlined style={{ fontSize: '16px' }} /></Link>;
            }
        }
    ];

    const [selectedTicketIds, setSelectedTicketIds] = useState([]);
    const [assignTasksFormIsVisible, setAssignTasksFormIsVisible] = useState(false);
    const rowSelection = {
        selectedRowKeys: selectedTicketIds,
        onChange: (selectedRowKeys, selectedRows) => {
            if(selectedRowKeys && Array.isArray(selectedRowKeys) && selectedRowKeys.length > 0) setAssignTasksFormIsVisible(true)
            else setAssignTasksFormIsVisible(false);

            setSelectedTicketIds(selectedRowKeys);
        }
    };

    const [assignTaskErrors, setAssignTaskErrors] = useState([]);
    const onAssignTask = (values) => {
        let xValues = {
            ...values,
            ticketIds: selectedTicketIds
        }

        ChatlineAPI.HttpPostRequest('tickets/assign', xValues, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formErr of err.data.errors) {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    }
                    setAssignTaskErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res && res.status && res.status === 200) {
                getTickets(((ticketsPagination && ticketsPagination.current) || 1), ticketsFilters);
            }
        });
    }

    const statusValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 2: return <Tag color="green">Resolved</Tag>;
            case 1: return <Tag color="gold">Assigned</Tag>;
            case 0: return <Tag color="magenta">Pending</Tag>;
            case -1: return <Tag color="red">Deleted</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };

    const formFilterOptions = (
        <Form form={ form } initialValues={{}} name="editMember" onFinish={ onApplyFilter } layout="vertical" hideRequiredMark={ true }>
            <Row gutter={ 16 }>
                <Col xs={ 24 } sm={ 8 } xl={ 4 }>
                    <Form.Item 
                        name="assignedTo"
                        label="Assigned to"
                    >
                        <Select className="filterOptions">
                            {
                                membersData && membersData.members && Array.isArray(membersData.members) && membersData.members.length > 0 ?
                                membersData.members.map(member => {
                                    return (
                                        <Select.Option key={ member._id } value={ member._id }>{ member.displayName }</Select.Option>
                                    );
                                })
                                : null }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={ 24 } sm={ 8 } xl={ 4 }>
                    <Form.Item 
                        name="department"
                        label="Department"
                    >
                        <Select className="filterOptions">
                            {
                                departmentsDataset && departmentsDataset.departments && Object.values(departmentsDataset.departments).map(department => {
                                    return (
                                        <Select.Option key={ department._id } value={ department._id }>{ department.name }</Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                    <Form.Item 
                        name="limit"
                        label="Show"
                        initialValue={ ticketsData && ticketsData.pagination && ticketsData.pagination.pageSize ? ticketsData.pagination.pageSize : null }
                    >
                        <Select className="filterOptions">
                            <Select.Option value={ 10 }>10</Select.Option>
                            <Select.Option value={ 25 }>25</Select.Option>
                            <Select.Option value={ 50 }>50</Select.Option>
                            <Select.Option value={ 100 }>100</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                    <Form.Item>
                        <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                        <Button className="btnBack" onClick={ onResetFields }>Reset</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );


    const [commonMemberIdsForSelectedTickets, setCommonMemberIdsForSelectedTickets] = useState(null);

    useEffect(() => {
        if(ticketsById && departmentsDataset && membersById) {
            let uDepartmentIds = [];

            if(selectedTicketIds && Array.isArray(selectedTicketIds) && selectedTicketIds.length > 0) {
                for(let ticketId of selectedTicketIds) {
                    let ticket = ticketsById[ticketId];
                    if(!ticket || !ticket.departmentId) continue;

                    if(!uDepartmentIds.includes(ticket.departmentId)) uDepartmentIds.push(ticket.departmentId);
                }
            }

            let commonMemberIds = Object.keys(membersById);

            if(uDepartmentIds && Array.isArray(uDepartmentIds) && uDepartmentIds.length > 0) {
                for(let departmentId of uDepartmentIds) {
                    let department = (departmentsDataset && departmentsDataset.departments && departmentsDataset.departments[departmentId]) || null;
                    if(!department || !department.memberIds) continue;

                    commonMemberIds = commonMemberIds.filter(value => department.memberIds.includes(value));
                }
            }

            setCommonMemberIdsForSelectedTickets(commonMemberIds);
        }
    }, [ticketsById, departmentsDataset, membersById, selectedTicketIds]);

    const assignTaskFormC = (
        assignTasksFormIsVisible && selectedTicketIds && selectedTicketIds.length > 0 && (
            <div className="assignTasksFormC">
                { assignTaskErrors && assignTaskErrors.assignedTo ? <div className="error">{ assignTaskErrors.assignedTo }</div> : null }
                <Form form={ assignTasksForm } initialValues={{}} name="assignTask" onFinish={ onAssignTask } layout="inline" hideRequiredMark={ true } style={{ display: 'inline' }}>
                    <Form.Item 
                        className={ classes.formInput }
                        name="assignedTo"
                        label={ ('Assign ' + selectedTicketIds.length + ' ticket(s) to: ') }
                    >
                        <Select style={{ width: '360px' }} className="filterOptions">
                            {
                                commonMemberIdsForSelectedTickets && Array.isArray(commonMemberIdsForSelectedTickets) && commonMemberIdsForSelectedTickets.length > 0 && commonMemberIdsForSelectedTickets.map(memberId => {
                                    return (
                                        membersById && membersById[memberId] && <Select.Option key={ membersById[memberId]._id } value={ membersById[memberId]._id }>{ membersById[memberId].displayName }</Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </Form.Item>
                
                    <Form.Item>
                        <Button style={{  margin: '0 0 0 12px' }} className="btnAdd" type="primary" htmlType="submit"><EnterOutlined /> Assign</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    )

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Tickets | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        ticketsData && ticketsData.tickets && membersData && membersData.members && departmentsDataset && departmentsDataset.departments ? (
                            <Tabs defaultActiveKey="1" onChange={ onTabChange }>
                                <Tabs.TabPane tab="All Tickets" key="All">
                                    { formFilterOptions }
                                    <Table rowSelection={{ type: "checkbox", ...rowSelection }} id="ticketsTHead" className="ticketsT" rowKey="_id" columns={ columns } pagination={ false } dataSource={ [] } locale={{ emptyText: '' }} />
                                    { assignTaskFormC }
                                    <Table showHeader={ false } rowSelection={{ type: "checkbox", ...rowSelection }} className="ticketsT" rowKey="_id" columns={ columns } pagination={ (ticketsData && ticketsData.pagination ? { ...ticketsData.pagination, showSizeChanger: false } : {}) } dataSource={ (ticketsData && ticketsData.tickets ? ticketsData.tickets : []) } onChange={ handleticketsTableChange } onRow={ (record, rowIndex) => { return { onClick: event => { history.push('/tickets/' + record._id) } } } } />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Pending" key="Pending">
                                    { formFilterOptions }
                                    <Table rowSelection={{ type: "checkbox", ...rowSelection }} id="ticketsTHead" className="ticketsT" rowKey="_id" columns={ columns } pagination={ false } dataSource={ [] } locale={{ emptyText: '' }} />
                                    { assignTaskFormC }
                                    <Table showHeader={ false } rowSelection={{ type: "checkbox", ...rowSelection }} className="ticketsT" rowKey="_id" columns={ columns } pagination={ (ticketsData && ticketsData.pagination ? { ...ticketsData.pagination, showSizeChanger: false } : {}) } dataSource={ (ticketsData && ticketsData.tickets ? ticketsData.tickets : []) } onChange={ handleticketsTableChange } onRow={ (record, rowIndex) => { return { onClick: event => { history.push('/tickets/' + record._id) } } } } />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Assigned" key="Assigned">
                                    { formFilterOptions }
                                    <Table rowSelection={{ type: "checkbox", ...rowSelection }} id="ticketsTHead" className="ticketsT" rowKey="_id" columns={ columns } pagination={ false } dataSource={ [] } locale={{ emptyText: '' }} />
                                    { assignTaskFormC }
                                    <Table showHeader={ false } rowSelection={{ type: "checkbox", ...rowSelection }} className="ticketsT" rowKey="_id" columns={ columns } pagination={ (ticketsData && ticketsData.pagination ? { ...ticketsData.pagination, showSizeChanger: false } : {}) } dataSource={ (ticketsData && ticketsData.tickets ? ticketsData.tickets : []) } onChange={ handleticketsTableChange } onRow={ (record, rowIndex) => { return { onClick: event => { history.push('/tickets/' + record._id) } } } } />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Resolved" key="Resolved">
                                    { formFilterOptions }
                                    <Table rowSelection={{ type: "checkbox", ...rowSelection }} id="ticketsTHead" className="ticketsT" rowKey="_id" columns={ columns } pagination={ false } dataSource={ [] } locale={{ emptyText: '' }} />
                                    { assignTaskFormC }
                                    <Table showHeader={ false } rowSelection={{ type: "checkbox", ...rowSelection }} className="ticketsT" rowKey="_id" columns={ columns } pagination={ (ticketsData && ticketsData.pagination ? { ...ticketsData.pagination, showSizeChanger: false } : {}) } dataSource={ (ticketsData && ticketsData.tickets ? ticketsData.tickets : []) } onChange={ handleticketsTableChange } onRow={ (record, rowIndex) => { return { onClick: event => { history.push('/tickets/' + record._id) } } } } />
                                </Tabs.TabPane>
                            </Tabs>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
}

export default Tickets;
