import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import ChatlineAPI from '../../utils/ChatlineAPI';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import Error from '../Error/Error';

import { Table, Button, Modal, Form, Input, message, Tag, Spin } from 'antd';
import { PlusCircleOutlined, EditOutlined ,TeamOutlined, DeleteOutlined } from '@ant-design/icons';

const Clients = (props) => {
    const [error, setError] = useState(null);

    const [clientsData, setClientsData] = useState(null);
    const [clientsTableCurrentPage, setClientsTableCurrentPage] = useState(1);

    const getClients = (page = 1) => {
        ChatlineAPI.HttpGetRequest('clients?page=' + page, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) setClientsData(res.data);
        });
    }
    
    useEffect(getClients, []);

    const handleClientsTableChange = (pagination, filters, sorter) => {
        setClientsTableCurrentPage(pagination.current);
        getClients(pagination.current);
    }

    const statusValue2Tag = (status) => {
        switch(parseInt(status)) {
            case 1: return <Tag color="green">Active</Tag>;
            case 0: return <Tag color="magenta">Inactive</Tag>;
            case -1: return <Tag color="red">Deleted</Tag>;
            default: return <Tag>Unknown</Tag>;
        }
    };

    const handleDeleteClient = (clientId, businessName) => {
        Modal.confirm({
            title: 'Client: ' + businessName,
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('clients/' + clientId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.'); return;
                    }
        
                    if(res && res.status && res.status === 200) {
                        message.success('Selected record has successfully been deleted.');
                        getClients(clientsTableCurrentPage); return;
                    }
                });
            },
            onCancel() {
            },
        });
    }

    const columns = [
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'name',
        },
        {
            title: 'Business Email',
            dataIndex: 'businessEmail',
            key: 'email',
        },
        {
            title: 'Website URL',
            dataIndex: 'websiteURL',
            key: 'websiteURL',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (status) => statusValue2Tag(status),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '160px',
            render: (val, row) => {
                return (
                    <>
                        <Link to={ '/clients/' + row._id + '/edit' } className="btnActions edit"><EditOutlined /></Link>
                        <Link to={ '/clients/' + row._id + '/members' } className="btnActions members"><TeamOutlined /></Link>
                        { (row.status !== -1 ? <DeleteOutlined onClick={ () => handleDeleteClient(row._id, row.businessName) } style={{ color: '#6246EA' }} /> : null) }
                    </>
                );
            }
        }
    ];

    const [addClientErrors, setAddClientErrors] = useState([]);
    const [addClientModalIsVisible, setAddClientModalVisibility] = useState(false);
    const showAddClientModal = () => { setAddClientModalVisibility(true) };
    const handleAddClientModalCancel = e => { setAddClientModalVisibility(false) };
    const onAddClientFinish = values => {
        ChatlineAPI.HttpPostRequest('clients/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    // setAddClientErrors(err.data.errors);
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setAddClientErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setAddClientModalVisibility(false);
                getClients(); return;
            }
        });
    };

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Clients | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (clientsData && clientsData.clients) ? (
                            <>
                                
                                <div style={{ textAlign: 'right', margin: '0 0 8px 0' }}>
                                    <Button className="btnDefault" size="medium" onClick={ showAddClientModal }><PlusCircleOutlined /> Add</Button>
                                </div>

                                <Table rowKey="_id" columns={ columns } pagination={ (clientsData && clientsData.pagination ? clientsData.pagination : {}) } dataSource={ (clientsData && clientsData.clients ? clientsData.clients : []) } onChange={ handleClientsTableChange } />

                                <Modal
                                    title="Add Client"
                                    visible={ addClientModalIsVisible }
                                    onCancel={ handleAddClientModalCancel }
                                    footer={ null }
                                >
                                    <Form name="addClient" onFinish={ onAddClientFinish } layout="vertical" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="businessName"
                                            label="Business Name"
                                            validateStatus={ addClientErrors && addClientErrors.businessName ? "error" : null }
                                            help={ addClientErrors && addClientErrors.businessName ? addClientErrors.businessName : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="businessEmail"
                                            label="Business Email"
                                            validateStatus={ addClientErrors && addClientErrors.businessEmail ? "error" : null }
                                            help={ addClientErrors && addClientErrors.businessEmail ? addClientErrors.businessEmail : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="websiteURL"
                                            label="Website URL"
                                            validateStatus={ addClientErrors && addClientErrors.websiteURL ? "error" : null }
                                            help={ addClientErrors && addClientErrors.websiteURL ? addClientErrors.websiteURL : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Button type="default" onClick={ handleAddClientModalCancel } style={{ margin: '0 8px 0 0' }}>Cancel</Button>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Clients;