import React, { useState, useEffect, useCallback } from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import ChatlineAPI from '../../utils/ChatlineAPI';
import moment from 'moment';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';
import Error from '../Error/Error';

import { Spin, Table, Button, Modal, Form, Input, Select, message, Row, Col } from 'antd';
import { EditOutlined, FilterOutlined, PlusCircleOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import './PersonalShortcuts.css';

const PersonalShortcuts = (props) => {
    const { 
        systemVariables
    } = useSystemContext();

    const shortcutStatusOptions = (systemVariables && systemVariables.shortcut && systemVariables.shortcut.statusOptions ? systemVariables.shortcut.statusOptions : null);

    const [shortcuts, setShortcuts] = useState(null);
    const [shortcutsPagination, setShortcutsPagination] = useState(null);
    const [shortcutsFilters, setShortcutsFilters] = useState(null);
    const [shortcutsFiltersForm] = Form.useForm();

    const [addShortcutModalVisibility, setAddShortcutModalVisibility] = useState(false);
    const [addShortcutForm] = Form.useForm();
    const [addShortcutErrors, setAddShortcutErrors] = useState([]);
    const [shortcutInEdit, setShortcutInEdit] = useState(null);
    const [editShortcutForm] = Form.useForm();
    const [editShortcutErrors, setEditShortcutErrors] = useState([]);
    const [editShortcutModalIsVisible, setEditShortcutModalVisibility] = useState(false);

    const [error, setError] = useState(null);

    const getPersonalShortcuts = useCallback((filters = null) => {
        filters = (filters && Object.keys(filters).length > 0 ? Object.keys(filters).map(key => key + '=' + filters[key]).join('&') : '');

        ChatlineAPI.HttpGetRequest('personal-shortcuts' + (filters ? ('?'+filters) : ''), (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data && res.data.result) {
                if(res.data.shortcuts) setShortcuts(res.data.shortcuts);
                if(res.data.pagination) setShortcutsPagination(res.data.pagination);
                return;
            }

            setShortcuts({});
            setShortcutsPagination(null);
        });
    }, []);

    useEffect(() => {
        getPersonalShortcuts(shortcutsFilters);
    }, [getPersonalShortcuts, shortcutsFilters]);

    const handleAddShortcutFinish = values => {
        ChatlineAPI.HttpPostRequest('personal-shortcuts/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = {};
                    err.data.errors.forEach(formErr => { if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg; });
                    setAddShortcutErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setAddShortcutModalVisibility(false);
                addShortcutForm.resetFields();
                getPersonalShortcuts(values.groupId);
            }
        });
    };

    useEffect(() => {
        if(editShortcutForm) {
            if(!shortcutInEdit) {
                editShortcutForm.resetFields();
                return;
            }

            editShortcutForm.setFieldsValue({
                groupId: shortcutInEdit.groupId,
                key: shortcutInEdit.key,
                message: shortcutInEdit.message
            });
        }
    }, [shortcutInEdit, editShortcutForm]);

    const handleEditShortcutFinish = (values) => {
        if(shortcutInEdit && shortcutInEdit._id) {
            ChatlineAPI.HttpPostRequest('personal-shortcuts/' + shortcutInEdit._id + '/edit', values, (err, res) => {
                if(err || !res) {
                    if(err && err.data && err.data.errors) {
                        let formErrors = {};
                        err.data.errors.forEach(formErr => { if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg; });
                        setEditShortcutErrors(formErrors);
                        return;
                    }

                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                if(res.data && res.data.result === true) {
                    setEditShortcutModalVisibility(false);
                    editShortcutForm.resetFields();
                    getPersonalShortcuts(values.groupId);
                }
            });
        }
    };

    const handleDeleteShortcut = (shortcutGroupId, shortcutId) => {
        Modal.confirm({
            title: 'Delete Shortcut',
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('personal-shortcuts/' + shortcutId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }

                    if(res.data && res.data.result === true) {
                        getPersonalShortcuts(shortcutGroupId);
                    }
                });
            },
            onCancel() {

            },
        });
    }

    const columns = [
        {
            title: 'Shortcut Key',
            dataIndex: 'key',
            key: 'key',
            width: '160px',
            render: (val, row) => {
                return (
                    <b>#{ row.key }</b>
                );
            }
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Scope',
            dataIndex: 'scope',
            key: 'scope',
            width: '160px',
            render: (val, row) => {
                return (
                    <div>Everyone</div>
                );
            }
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '120px',
            render: (val, row) => {
                return (
                    row.createdAt ? <b>{ moment(row.createdAt).format('DD.MM.YYYY') }</b> : '-'
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (val, row) => {
                return (
                    <div>{ (row.status && shortcutStatusOptions && shortcutStatusOptions[row.status] ? shortcutStatusOptions[row.status] : 'N/A') }</div>
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '240px',
            render: (val, row) => {
                return (
                    <>
                        <Button className="btnEdit btnInline mr-2" onClick={ () => { setShortcutInEdit(row); setEditShortcutModalVisibility(true); } }><EditOutlined /> Edit</Button>
                        { (row.status !== -1 ? <Button className="btnDelete btnInline" onClick={ () => { handleDeleteShortcut(row.groupId, row._id) } }><DeleteOutlined /> Delete</Button> : null) }
                    </>
                );
            }
        }
    ];

    const onApplyShortcutsFilters = (values) => {
        if(!values) {
            setShortcutsPagination(null);
            setShortcutsFilters(null);
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            let value = (values[key] && (''+values[key]).trim()) || null;
            if(value) filters[key] = value;
        }
        
        setShortcutsPagination(null);
        setShortcutsFilters(filters);
    }

    const onResetShortcutsFilters = () => {
        shortcutsFiltersForm.resetFields();
        setShortcutsPagination(null);
        setShortcutsFilters(null);
    }

    if(error) return error;

    return (
        <>
            <Helmet>
                <title>Personal Shortcuts | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (shortcuts) ? (
                            <>
                                <div style={{ margin: '0 0 8px 0' }}>
                                    <div style={{ float: 'right' }}>
                                        <Button onClick={ () => { setAddShortcutModalVisibility(true) } } className={ "btnAdd mx-1" }><PlusCircleOutlined /> Create Shortcut</Button>
                                    </div>
                                </div>

                                <Form form={ shortcutsFiltersForm } name="applyFilter" onFinish={ onApplyShortcutsFilters } layout="vertical" hideRequiredMark={ true }>
                                    <Row gutter={ 16 }>
                                        <Col xs={ 24 } sm={ 12 } xl={ 6 }>
                                            <Form.Item 
                                                name="search"
                                                label="Search by"
                                            >
                                                <Input className="searchBar filterOptions" placeholder="Message..." prefix={ <SearchOutlined /> } />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                                            <Form.Item 
                                                name="limit"
                                                label="Show"
                                                initialValue={ shortcutsPagination && shortcutsPagination.pageSize ? shortcutsPagination.pageSize : null }
                                            >
                                                <Select className="filterOptions">
                                                    <Select.Option value={ 10 }>10</Select.Option>
                                                    <Select.Option value={ 25 }>25</Select.Option>
                                                    <Select.Option value={ 50 }>50</Select.Option>
                                                    <Select.Option value={ 100 }>100</Select.Option>
                                                    <Select.Option value={ 250 }>250</Select.Option>
                                                    <Select.Option value={ 500 }>500</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                                            <Form.Item>
                                                <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                <Button className="btnBack" onClick={ onResetShortcutsFilters }>Reset</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Table className="shortcutsT" columns={ columns } dataSource={ shortcuts && Object.keys(shortcuts) ? Object.values(shortcuts) : [] } pagination={ false } />

                                <Modal
                                    visible={ addShortcutModalVisibility }
                                    onCancel={ () => { setAddShortcutModalVisibility(false); addShortcutForm.resetFields(); } }
                                    footer={ null }
                                >
                                    <h1>Add Personal Shortcut</h1>
                                    <Form form={ addShortcutForm } onFinish={ handleAddShortcutFinish } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                        <Form.Item 
                                            name="key"
                                            label="Shortcut Key"
                                            validateStatus={ addShortcutErrors && addShortcutErrors.key ? "error" : null }
                                            help={ addShortcutErrors && addShortcutErrors.key ? addShortcutErrors.key : null }
                                            rules={[
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^[A-Za-z0-9]+$/i),
                                                    message: 'Shortcut key can contain alphanumeric characters only!'
                                                }
                                            ]}
                                        >
                                            <Input addonBefore="#" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="message"
                                            label="Message"
                                            validateStatus={ addShortcutErrors && addShortcutErrors.message ? "error" : null }
                                            help={ addShortcutErrors && addShortcutErrors.message ? addShortcutErrors.message : null }
                                        >
                                            <TextArea rows={ 3 } />
                                        </Form.Item>

                                        <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                <Modal
                                    visible={ editShortcutModalIsVisible }
                                    onCancel={ () => { setEditShortcutModalVisibility(false); editShortcutForm.resetFields(); } }
                                    footer={ null }
                                >
                                    <h1>Edit Personal Shortcut</h1>
                                    <Form form={ editShortcutForm } onFinish={ handleEditShortcutFinish } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                        <Form.Item 
                                            name="key"
                                            label="Shortcut Key"
                                            validateStatus={ editShortcutErrors && editShortcutErrors.key ? "error" : null }
                                            help={ editShortcutErrors && editShortcutErrors.key ? editShortcutErrors.key : null }
                                        >
                                            <Input addonBefore="#" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="message"
                                            label="Message"
                                            validateStatus={ editShortcutErrors && editShortcutErrors.message ? "error" : null }
                                            help={ editShortcutErrors && editShortcutErrors.message ? editShortcutErrors.message : null }
                                        >
                                            <TextArea rows={ 3 } />
                                        </Form.Item>

                                        <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default PersonalShortcuts;
