import React from 'react';

import { Handle } from 'react-flow-renderer';

const TextNode = ({ data, isConnectable, ...restProps }) => {
    return (
        <div className="px-1 py-2" style={ data.node.withError && { background: 'rgba(255, 0, 0, 0.25)', border: '1px solid rgb(255, 0, 0)', borderRadius: '5px' } }>
            <Handle
                type="target"
                position="top"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            <Handle
                type="source"
                position="bottom"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            { (data && data.node && data.node.parameters && data.node.parameters.message) || 'N/A' }
        </div>
    );
};

export default TextNode;