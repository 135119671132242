import React, { useEffect, useState, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useHistory } from 'react-router-dom';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import debounce from 'lodash.debounce';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';
import './Visitors.less';

import { Spin, Button, Table } from 'antd';

import ReactCountryFlag from 'react-country-flag';
import moment from 'moment';

const Visitors = () => {
    const {
        isSocketConnected
    } = useSystemContext();

    const [isVisitorsLoading, setIsVisitorsLoading] = useState(false);
    const [visitors, updateVisitors] = useImmer(null);
    const [visitorsPagination, setVisitorsPagination] = useState(null);

    const history = useHistory();
    
    const loadVisitors = useCallback((cursor = 0) => {
        setIsVisitorsLoading(true);

        socket.emit('operator.visitors.online.get', { cursor: cursor }, (ack) => {
            setIsVisitorsLoading(false);

            if(ack && ack.result && ack.response) {
                setVisitorsPagination(ack.response.pagination);

                updateVisitors(visitors => {
                    if(!visitors) return (ack.response && ack.response.visitors ? ack.response.visitors : {});

                    if(ack.response && ack.response.visitors && Object.values(ack.response.visitors) && Object.values(ack.response.visitors).length > 0) {
                        for(let visitor of Object.values(ack.response.visitors)) {
                            visitors[visitor.sessionId] = visitor;
                        }
                    }
                });
            }
        });
    }, [updateVisitors]);

    window.onscroll = debounce(() => {
        let hasMore = (visitors && visitorsPagination && visitorsPagination.total && Object.keys(visitors).length < visitorsPagination.total);

        if(isVisitorsLoading || !hasMore) return;

        // Checks that the page has scrolled to the bottom
        if(window.innerHeight + document.documentElement.scrollTop === document.getElementById('root').offsetHeight) {
            loadVisitors((visitorsPagination && visitorsPagination.cursor ? visitorsPagination.cursor : 0));
        }
    }, 100);

    useEffect(() => {
        if(isSocketConnected) {
            loadVisitors(0);
        }

    }, [isSocketConnected, loadVisitors]);

    useEffect(() => {        
        if(isSocketConnected) {
            socket.off('operator.visitors.visitor.connected').on('operator.visitors.visitor.connected', (pVisitor) => {
                updateVisitors(visitors => {
                    if(!visitors) visitors = {};
                    visitors[pVisitor.sessionId] = { ...pVisitor };
                });
            });

            socket.off('operator.visitors.visitor.update').on('operator.visitors.visitor.update', (pVisitor) => {
                updateVisitors(visitors => {
                    if(visitors && Object.keys(visitors).length > 0) {
                        if(visitors[pVisitor.sessionId]) {
                            visitors[pVisitor.sessionId] = { ...pVisitor };
                        }
                    }
                });
            });

            socket.off('operator.visitors.visitor.disconnected').on('operator.visitors.visitor.disconnected', (pVisitor) => {
                updateVisitors(visitors => {
                    if(visitors && Object.keys(visitors).length > 0) {
                        if(visitors[pVisitor.sessionId]) {
                            delete visitors[pVisitor.sessionId];
                            if(!visitors) visitors = {};
                        }
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('operator.visitors.visitor.connected');
            socket.off('operator.visitors.visitor.update');
            socket.off('operator.visitors.visitor.disconnected');
        }
    }, [isSocketConnected, updateVisitors]);

    const handleStartChat = (sessionId) => {
        socket.emit('operator.chat.start', sessionId, (ack) => {
            if(ack && ack.result && ack.chat) history.push('/my-chats/' + ack.chat._id);
        });
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (val, row) => { return (row.username ? row.username : 'N/A'); }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (val, row) => { return (row.name ? row.name : 'N/A'); }
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            render: (val, row) => { return (row.ip ? <span> { row.ip } { (row.countryCode && typeof(row.countryCode) === 'string' && <ReactCountryFlag countryCode={ row.countryCode } style={{ fontSize: '18px'}} />) } </span> : 'N/A'); }
        },
        {
            title: 'Registration Date',
            dataIndex: 'registeredAt',
            key: 'registeredAt',
            render: (val, row) => { return (row.registeredAt ? moment.unix(row.registeredAt+'').format('DD/MM/YYYY') : 'N/A'); }
        },
        {
            title: 'Current Page',
            dataIndex: 'currentPage',
            key: 'currentPage',
            className: 'currentPage',
            render: (val, row) => {
                let currentPage = null;
                if(row && row.currentPage) {
                    currentPage = (
                        !row.currentPage.title && !row.currentPage.URL ? (
                            <span>N/A</span>
                        ) : (
                            row.currentPage && row.currentPage.title && row.currentPage.URL && <div><a href={ row.currentPage.URL } target="_blank" rel="noopener noreferrer">{  row.currentPage.title }</a></div>
                        )
                    );
                }

                return currentPage;
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (val, row) => { return (row.sessionStartedAt ? moment.duration(moment().diff(moment.unix(row.sessionStartedAt))).humanize() : 'N/A'); }
        },
        {
            title: 'Last Deposit',
            dataIndex: 'lastDepositAmount',
            key: 'lastDepositAmount',
            render: (val, row) => { return (row.lastDepositAmount ? row.lastDepositAmount : 'N/A'); }
        },
        {
            title: 'Balance',
            dataIndex: 'currentBalance',
            key: 'currentBalance',
            render: (val, row) => { return (row.currentBalance ? row.currentBalance : 'N/A'); }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '180px',
            render: (val, row) => {
                return (
                    <>
                        {
                            (row.inActiveChat) ? (
                                <Button className="btnDisabled" disabled={ true }>In Chat</Button>
                            ) : (
                                <Button onClick={ () => { handleStartChat(row.sessionId) } } className="btnAdd">Chat</Button>
                            )
                        }
                    </>
                );
            }
        }
    ];

    return (
        <>
            <Helmet>
                <title>Visitors | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                {
                    (visitors) ? (
                        <>
                            <Table rowKey="sessionId" columns={ columns } dataSource={ (visitors ? Object.values(visitors) : []) } pagination={ false } />

                            { isVisitorsLoading && <div style={{ padding: '20px 0 20px 0', textAlign: 'center' }}><Spin /></div> }
                        </>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </MainLayout>
        </>
    );
};

export default Visitors;