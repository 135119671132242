import React from 'react';

import ChatlineAPI from '../../../utils/ChatlineAPI';

import PlainLayout from '../../../Components/Layouts/PlainLayout';
import './Login.css';
import classes from './Login.module.css';
import Helmet from 'react-helmet';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import welcome from './welcome.png';
import logo from '../../../logo.png'

const Login = () => {
    const onFinish = values => {
        ChatlineAPI.HttpPostRequest('auth/login', values, (err, res) => {
            if(err || !res) {
                if(err && err.hasOwnProperty('data') && err.data && err.data.hasOwnProperty('errors') && err.data.errors) {
                    Object.values(err.data.errors).map(formErr => {
                        if(formErr.msg) {
                            message.error(formErr.msg);
                            return false;
                        }
                        return true;
                    });

                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data.result === true && res.data.token) {
                localStorage.setItem('token', res.data.token);
                window.location.href = '/';
                return;
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Login | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <PlainLayout>
                <div className={ classes.loginC }>
                    <div className={ classes.flexRow }>
                        <div className={ classes.colContentPaneLeft }>
                            <div className={ classes.welcomeC }>
                                <img src={ welcome } alt="" style={{ width: '100%', height: '483px', borderRadius: '10px 0 0 10px' }} />
                                <img className={ classes.logo } src={ logo } alt="Chatline Logo" />
                                <div className={ classes.helper }>Operations Panel</div>
                            </div>
                        </div>

                        <div className={ classes.colContentPaneRight }>
                            <div className={ classes.loginFormWrapper }>
                                <div>
                                    <Form name="login" className={ classes.loginForm } initialValues={{ remember: true }} onFinish={ onFinish }>
                                        <h2 style={{ textAlign: 'left' }}>Welcome</h2>

                                        <Form.Item 
                                            name="email"
                                            rules={[{ required: true, message: 'You must enter your email address!' }]}
                                        >
                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email address" />
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            rules={[{ required: true, message: "You must enter your password!" }]}
                                        >
                                            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                                        </Form.Item>

                                        <Form.Item>
                                            <Button className="btnAdd" style={{ display: 'block', width: '100%' }} type="primary" htmlType="submit">Login</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PlainLayout>
        </>
    )
}

export default Login;