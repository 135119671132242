import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useImmer } from 'use-immer';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Row, Col, Table, Button, Spin } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

import classes from './Monitor.module.css';

const Monitor = (props) => {
    const { 
        currentUser,
        systemVariables,
        isSocketConnected
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const availabilityOptions = (systemVariables && systemVariables.user && systemVariables.user.availabilityOptions ? systemVariables.user.availabilityOptions : null);

    const [operatorsByAvailability, updateOperatorsByAvailability] = useImmer(null);

    const history = useHistory();

    const getOperatorsByAvailability = useCallback(() => {
        socket.emit('operator.operators.all.byAvailability.get', null, (ack) => {
            if(ack && ack.result && ack.operators) updateOperatorsByAvailability(operatorsByAvailability => (ack.operators ? ack.operators : {}));
        });
    }, [updateOperatorsByAvailability]);

    useEffect(() => {
        if(isSocketConnected) {
            getOperatorsByAvailability();

            socket.off('operator.operators.operator.connected').on('operator.operators.operator.connected', (pOperator) => {
                getOperatorsByAvailability();
            });

            socket.off('operator.operators.operator.away').on('operator.operators.operator.away', (pOperator) => {
                getOperatorsByAvailability();
            });

            socket.off('operator.operators.operator.disconnected').on('operator.operators.operator.disconnected', (pOperator) => {
                getOperatorsByAvailability();
            });
        }

        return function cleanup() {
            socket.off('operator.operators.operator.connected');
            socket.off('operator.operators.operator.away');
            socket.off('operator.operators.operator.disconnected');
        }
    }, [isSocketConnected, getOperatorsByAvailability]);

    const handleUpdateAvailability = (operatorId, availability) => {
        if(!availability) return;
        if(!availabilityOptions) return;
        
        availability = Object.keys(availabilityOptions).find(key  => availabilityOptions[key] === availability);
        if(!availability) return;

        socket.emit('admin.operator.availability.update', { operatorId: operatorId, availability: availability });
    };

    const handleObserveChats = (operatorId) => {
        socket.emit('admin.operator.chats.observe', { operatorId: operatorId }, (ack) => {
            if(ack && ack.result) history.push('my-chats');
        });
    }

    const onlineOperatorsTableColumns = [
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (val, row) => { return row.displayName }
        },
        {
            title: 'Active Chats',
            dataIndex: 'activeChats',
            key: 'activeChats',
            align: 'center',
            render: (val, row) => { return (parseInt(row.numberOfActiveChats) >= 0) && row.parameters && row.parameters.maximumNumberOfConcurrentChats ? <div><b>{ parseInt(row.numberOfActiveChats) }</b> / { row.parameters.maximumNumberOfConcurrentChats }</div> : 'N/A' }
        },
        {
            title: 'Automatically Accept Chats',
            dataIndex: 'automaticallyAcceptChats',
            key: 'automaticallyAcceptChats',
            align: 'center',
            render: (val, row) => { return (row && row.parameters && row.parameters.automaticallyAcceptChats ? 'Yes' : 'No')  }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '320px',
            render: (val, row) => {
                return (
                    <>
                        {
                            (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                <>
                                    <Button onClick={ () => { handleUpdateAvailability(row.userId, 'Away') } } className="btnSecondary mr-1">Away</Button>
                                    <Button onClick={ () => { handleUpdateAvailability(row.userId, 'Offline') } } className="btnSecondary mr-1">Offline</Button>
                                </>
                            )
                        }

                        { 
                            (currentUser._id === row.userId || row.numberOfActiveChats <= 0) ? (
                                <Button className="btnDisabled mr-1" disabled={ true }>Observe</Button>
                            ) : (
                                <Button onClick={ () => { handleObserveChats(row.userId) } } className="btnAdd mr-1">Observe</Button>
                            )
                        }
                    </>
                );
            }
        }
    ];

    const awayOperatorsTableColumns = [
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (val, row) => { return row.displayName }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (val, row) => { return 'N/A' }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (val, row) => { return 'N/A' }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '200px',
            render: (val, row) => {
                return (
                    <>
                        {
                            (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                <Button onClick={ () => { handleUpdateAvailability(row.userId, 'Offline') } } className="btnSecondary mr-1">Offline</Button>
                            )
                        }

                        {
                            (currentUser._id === row.userId || row.numberOfActiveChats <= 0) ? (
                                <Button className="btnDisabled mr-1" disabled={ true }>Observe</Button>
                            ) : (
                                <Button onClick={ () => { handleObserveChats(row.userId) } } className="btnAdd mr-1">Observe</Button>
                            )
                        }
                    </>
                );
            }
        }
    ];

    const offlineOperatorsTableColumns = [
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (val, row) => { return row.displayName }
        },
        {
            title: 'Last Online',
            dataIndex: 'lastOnline',
            key: 'lastOnline',
            render: (val, row) => { return 'N/A' }
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: (val, row) => { return 'Available soon.' }
        },
        {
            title: 'Next Shift',
            dataIndex: 'nextShift',
            key: 'nextShift',
            render: (val, row) => { return 'N/A' }
        },
    ];

    return (
        <>
            <Helmet>
                 <title>Monitor | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                {
                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin')].includes(''+currentUser.role)) && (
                        <div style={{ display: 'none', margin: '0 0 10px 0', textAlign: 'right' }}>
                            <Button onClick={ () => { socket.emit('admin.clients.visitors.all.kill') } } className="btnSecondary float-right">Kick All Visitors Out</Button>
                        </div>
                    )
                }

                {
                    (operatorsByAvailability) ? (
                        <Row gutter={ 16 }>
                            <Col span={ 24 }>
                                <h2 style={{ display: 'inline' }}><TeamOutlined className="ml-2" /> Online <span className={ classes.onlineIndicator } /> <span className="ml-2"> { operatorsByAvailability && operatorsByAvailability['Online'] && Object.keys(operatorsByAvailability['Online']).length } </span></h2>

                                <Table rowKey={ row => row._id } className="mt-2" columns={ onlineOperatorsTableColumns } dataSource={ (operatorsByAvailability && operatorsByAvailability['Online'] && Object.values(operatorsByAvailability['Online'])) } pagination={ false } />
                            </Col>

                            <Col span={ 12 } className="mt-4">
                                <h2 style={{ display: 'inline' }}><TeamOutlined className="ml-2" /> Away <span className={ classes.outerAwayIndicator } /> <span className="ml-2"> { operatorsByAvailability && operatorsByAvailability['Away'] && Object.keys(operatorsByAvailability['Away']).length } </span></h2>

                                <Table rowKey={ row => row._id } className="mt-2" columns={ awayOperatorsTableColumns } dataSource={ (operatorsByAvailability && operatorsByAvailability['Away'] && Object.values(operatorsByAvailability['Away'])) } pagination={ false } />
                            </Col>

                            <Col span={ 12 } className="mt-4">
                                <h2 style={{ display: 'inline' }}><TeamOutlined className="ml-2" /> Offline <span className={ classes.outerOfflineIndicator } /> <span className="ml-2"> { operatorsByAvailability && operatorsByAvailability['Offline'] && Object.keys(operatorsByAvailability['Offline']).length } </span></h2>

                                <Table rowKey={ row => row._id } className="mt-2" columns={ offlineOperatorsTableColumns } dataSource={ (operatorsByAvailability && operatorsByAvailability['Offline'] && Object.values(operatorsByAvailability['Offline'])) } pagination={ false } />
                            </Col>
                        </Row>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </MainLayout>
        </>
    );
}

export default Monitor;