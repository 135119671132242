import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Spin, Table, Row, Col } from 'antd';
import { CustomerServiceOutlined, MessageOutlined, TeamOutlined, PieChartOutlined, EyeOutlined, SolutionOutlined, StarOutlined, ExclamationCircleOutlined, LikeOutlined } from '@ant-design/icons';

import classes from './Reports.module.css';

const RealTime = (props) => {
    const {
        systemVariables,
        isSocketConnected
    } = useSystemContext();

    const availabilityOptions = (systemVariables && systemVariables.user && systemVariables.user.availabilityOptions ? systemVariables.user.availabilityOptions : null);

    const [report, setReport] = useState(null);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.reports.realTime.get', null, (ack) => {
                if(ack && ack.result && ack.report) {
                    setReport(ack.report);
                    return;
                }

                setReport({});
            });
        }
    }, [isSocketConnected]);

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (val, row) => {
                let availability = (availabilityOptions && row.availability && availabilityOptions[row.availability] ? availabilityOptions[row.availability] : 'N/A');
                if(availability === 'Online') return <div className={ classes.statusOnlineIndicator }></div>;
                else if(availability === 'Away') return <div className={ classes.statusAwayIndicator }></div>;
                else return <div className={ classes.statusOfflineIndicator }></div>;
            }
        },
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (val, row) => row.displayName
        },
        {
            title: 'Active Chats / Maximum Chat Limit',
            dataIndex: 'activeChats',
            key: 'activeChats',
            render: (val, row) => {
                return (row.numberOfActiveChats ? row.numberOfActiveChats : 0) + '/' + (row.parameters && row.parameters.maximumNumberOfConcurrentChats ? row.parameters.maximumNumberOfConcurrentChats : 0);
            }
        },
        {
            title: 'Today\'s Chats',
            dataIndex: 'todaysChats',
            key: 'todaysChats',
            render: (val, row) => (row.numberOfChatsToday ? row.numberOfChatsToday : 0)
        },
        {
            title: 'Today\'s Linear Online Time',
            dataIndex: 'todaysOnlineTime',
            key: 'todaysOnlineTime',
            render: (val, row) => 'N/A'
        },
        {
            title: 'Today\'s Linear Away Time',
            dataIndex: 'todaysAwayTime',
            key: 'todaysAwayTime',
            render: (val, row) => 'N/A'
        },
        {
            title: 'Likes / Unlikes',
            dataIndex: 'likesUnlikes',
            key: 'likesUnlikes',
            render: (val, row) => 'N/A'
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            render: (val, row) => 'N/A'
        }
    ];

    return (
        <>
            <Helmet>
                <title>Real-time | Reports | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                {
                    (report) ? (
                        <>
                            <Row gutter={ 24 }>
                                <Col span={ 12 }>
                                    <h3 style={{ borderBottom: '1px solid #EBECFF' }}>Now</h3>

                                    <Row gutter={ 16 } className="mt-4">
                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Online Visitors</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><EyeOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.numberOfOnlineVisitors) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Online Agents</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.numberOfOnlineOperators) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Agents in Chat</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.numberOfOperatorsInChat) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Pending Chats</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><TeamOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.numberOfPendingChats) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Active Chats</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><MessageOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.numberOfActiveChats) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Chat Utilization</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><PieChartOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.chatUtilization) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard }>
                                                <div className={ classes.statLabel }>Pending Tickets</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><SolutionOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.now && report.now.numberOfPendingTickets) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={ 12 }>
                                    <h3 style={{ borderBottom: '1px solid #EBECFF' }}>Today</h3>

                                    <Row gutter={ 16 } className="mt-4">
                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard + ' ' + classes.cardPurple }>
                                                <div className={ classes.statLabel }>Chat Requests</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><TeamOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.today && report.today.numberOfChatsInitiatedByVisitors) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard + ' ' + classes.cardPurple }>
                                                <div className={ classes.statLabel }>Chats</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><MessageOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.today && report.today.numberOfChatsTaken) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard + ' ' + classes.cardPurple }>
                                                <div className={ classes.statLabel }>Chat Acceptance Rate</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><StarOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.today && report.today.chatAcceptanceRate) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard + ' ' + classes.cardPurple }>
                                                <div className={ classes.statLabel }>Missed Chats</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><ExclamationCircleOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.today && report.today.numberOfChatsMissed) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard + ' ' + classes.cardPurple }>
                                                <div className={ classes.statLabel }>Like / Dislike</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><LikeOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>N/A</div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="my-2" span={ 8 }>
                                            <div className={ classes.statCard + ' ' + classes.cardPurple }>
                                                <div className={ classes.statLabel }>Visitors on Site</div>
                                                <div className="mt-2" style={{ overflow: 'auto' }}>
                                                    <div className={ classes.statIconC } style={{ float: 'left' }}><EyeOutlined /></div>
                                                    <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.today && report.today.visitorsOnSite) || 0) }</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <div className="tableW mt-4">
                                <div className="mb-2 mr-2" style={{ float: 'right' }}>
                                    <b>Status</b>
                                    <div className={ classes.statusOnlineIndicator }></div><span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Online</span>
                                    <div className={ classes.statusAwayIndicator }></div><span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Away</span>
                                    <div className={ classes.statusOfflineIndicator }></div><span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Offline</span>
                                </div>

                                <Table rowKey={ row => row._id } className="mt-2" columns={ columns } dataSource={ (report && report['dataTable'] ? Object.values(report['dataTable']) : []) } pagination={ false } />
                            </div>
                        </>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </MainLayout>
        </>
    );
};

export default RealTime;