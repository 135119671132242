import React, { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Spin, Table, Row, Col, Tabs } from 'antd';
import { CustomerServiceOutlined, EyeOutlined, StarOutlined } from '@ant-design/icons';

import classes from './Reports.module.css';

const ChatVolume = (props) => {
    const { 
        isSocketConnected
    } = useSystemContext();

    const moment = require('moment');

    const [reportParameters, updateReportParameters] = useImmer({ term: 'yesterday' });
    const [report, setReport] = useState(null);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.reports.chatVolume.get', reportParameters, (ack) => {
                if(ack && ack.result && ack.report) {
                    setReport(ack.report);
                    return;
                }

                setReport({});
            });
        }
    }, [isSocketConnected, reportParameters]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (val, row) => {
                return moment(parseInt(row.date)).format('dddd, MMMM Do YYYY');
            }
        },
        {
            title: 'Chat Requests',
            dataIndex: 'chatRequests',
            key: 'chatRequests',
            render: (val, row) => row.numberOfChatRequests
        },
        {
            title: 'All Chats',
            dataIndex: 'allChats',
            key: 'allChats',
            render: (val, row) => row.numberOfChats
        },
        {
            title: 'Chats Initiated',
            dataIndex: 'chatsInitiated',
            key: 'chatsInitiated',
            render: (val, row) => row.numberOfChatsOperatorsInitiated
        },
        {
            title: 'Missed Chats',
            dataIndex: 'missedChats',
            key: 'missedChats',
            render: (val, row) => row.numberOfMissedChats
        },
        {
            title: 'Chat Acceptance Rate',
            dataIndex: 'chatAcceptanceRate',
            key: 'chatAcceptanceRate',
            render: (val, row) => row.chatAcceptanceRate
        }
    ];

    return (
        <>
            <Helmet>
                <title>Chat Volume | Reports | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                {
                    (report) ? (
                        <>
                            <Tabs defaultActiveKey={ (reportParameters && reportParameters.term) } onChange={ (activeKey) => { updateReportParameters((reportParameters) => { reportParameters.term = activeKey; }) } }>
                                <Tabs.TabPane tab="Yesterday" key="yesterday"></Tabs.TabPane>
                                <Tabs.TabPane tab="This Week" key="thisWeek"></Tabs.TabPane>
                                <Tabs.TabPane tab="Last Week" key="lastWeek"></Tabs.TabPane>
                                <Tabs.TabPane tab="This Month" key="thisMonth"></Tabs.TabPane>
                                <Tabs.TabPane tab="Last Month" key="lastMonth"></Tabs.TabPane>
                            </Tabs>

                            <div>
                                <Row gutter={ 16 } className="mt-0">
                                    <Col className="my-2" span={ 4 }>
                                        <div className={ classes.statCard }>
                                            <div className={ classes.statLabel }>Chat Requests</div>
                                            <div className="mt-2" style={{ overflow: 'auto' }}>
                                                <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfChatRequests) || 0) }</div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="my-2" span={ 4 }>
                                        <div className={ classes.statCard }>
                                            <div className={ classes.statLabel }>All Chats</div>
                                            <div className="mt-2" style={{ overflow: 'auto' }}>
                                                <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfChats) || 0) }</div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="my-2" span={ 4 }>
                                        <div className={ classes.statCard }>
                                            <div className={ classes.statLabel }>Chats Initiated</div>
                                            <div className="mt-2" style={{ overflow: 'auto' }}>
                                                <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfChatsOperatorsInitiated) || 0) }</div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="my-2" span={ 4 }>
                                        <div className={ classes.statCard }>
                                            <div className={ classes.statLabel }>Missed Chats</div>
                                            <div className="mt-2" style={{ overflow: 'auto' }}>
                                                <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfMissedChats) || 0) }</div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="my-2" span={ 4 }>
                                        <div className={ classes.statCard }>
                                            <div className={ classes.statLabel }>Chat Acceptance Rate</div>
                                            <div className="mt-2" style={{ overflow: 'auto' }}>
                                                <div className={ classes.statIconC } style={{ float: 'left' }}><StarOutlined /></div>
                                                <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.chatAcceptanceRate) || 0) }</div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="my-2" span={ 4 }>
                                        <div className={ classes.statCard }>
                                            <div className={ classes.statLabel }>Visitors on site</div>
                                            <div className="mt-2" style={{ overflow: 'auto' }}>
                                                <div className={ classes.statIconC } style={{ float: 'left' }}><EyeOutlined /></div>
                                                <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.visitorsOnSite) || 0) }</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="tableW mt-4">
                                    <Table rowKey={ row => row.date } className="mt-2" columns={ columns } dataSource={ (report && report['dataTable'] ? Object.values(report['dataTable']) : []) } pagination={ false } />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </MainLayout>
        </>
    );
};

export default ChatVolume;