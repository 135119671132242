import React from 'react';

import classes from './PlainLayout.module.css';

const PlainLayout = (props) => (
    <div className={ classes.plainLayout }>
        { props.children }
    </div>
);

export default PlainLayout;