import React, { useState, useEffect, useCallback } from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import ChatlineAPI from '../../utils/ChatlineAPI';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import Error from '../Error/Error';

import { Link } from 'react-router-dom';
import { Spin, Button, Table, Form, Input, Select, DatePicker, Row, Col } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';

import ReactCountryFlag from 'react-country-flag';
import moment from 'moment';

const PreviousChats = (props) => {
    const { 
        isSocketConnected,
        operators
    } = useSystemContext();

    const [chatsFiltersForm] = Form.useForm();

    const [chatsDataset, setChatsDataset] = useState(null);
    const [chatsFilters, setChatsFilters] = useState(null);
    const [dateRangeFilterValue, setDateRangeFilterValue] = useState(null);

    const [departments, setDepartments] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.client.departments.get', null, (ack) => {
                setDepartments((ack && ack.result && ack.departments) || {});
            });
        }
    }, [isSocketConnected]);

    const getPreviousChats = useCallback((page = 1, filters = null) => {
        filters = (filters && Object.keys(filters).length > 0 ? Object.keys(filters).map(key => key + '=' + filters[key]).join('&') : '');

        ChatlineAPI.HttpGetRequest('chats?page=' + page + (filters ? '&'+filters : ''), (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            setChatsDataset((res && res.data) || {});
        });
    }, []);

    useEffect(() => {
        getPreviousChats(1, chatsFilters);
    }, [getPreviousChats, chatsFilters]);

    const onApplyChatsFilters = (values) => {
        if(!values) {
            setChatsFilters(null);
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            if(values[key]) filters[key] = (''+values[key]).trim();
        }

        if(dateRangeFilterValue) {
            filters['filters[dateRange]'] = dateRangeFilterValue;
        } else if(filters['filters[dateRange]']) {
            delete filters['filters[dateRange]'];
        }
        
        setChatsFilters(filters);
    }

    const onResetChatsFilters = () => {
        chatsFiltersForm.resetFields();
        setChatsFilters(null);
    }

    const handlePreviousChatsTableChange = (pagination, filters, sorter) => {
        getPreviousChats(pagination.current, chatsFilters);
    }

    const columns = [
        {
            title: 'Chat #',
            dataIndex: '_id',
            key: '_id',
            render: (val, row) => { return (row._id && row._id ? row._id : 'N/A'); }
        },
        {
            title: 'Date/Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (val, row) => { return (row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm') : 'N/A'); }
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (val, row) => { return (row.visitor && row.visitor.username ? row.visitor.username : 'N/A'); }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (val, row) => { return (row.visitor && row.visitor.name ? row.visitor.name : 'N/A'); }
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            render: (val, row) => { return (row.visitor.ip ? <span> { row.visitor.ip } { (row.visitor.countryCode && typeof(row.visitor.countryCode) === 'string' && <ReactCountryFlag countryCode={ row.visitor.countryCode } style={{ fontSize: '18px'}} />) } </span> : 'N/A'); }
        },
        {
            title: 'Department',
            dataIndex: 'departmentId',
            key: 'departmentId',
            render: (val, row) => { return (row && row.departmentId && departments && departments[row.departmentId] ? departments[row.departmentId].name : 'N/A'); }
        },
        {
            title: 'Operator',
            dataIndex: 'operatorId',
            key: 'operatorId',
            render: (val, row) => { return (row.operatorId && operators && operators[row.operatorId] ? operators[row.operatorId].displayName : 'N/A'); }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '120px',
            render: (val, row) => {
                return (
                    <Link to={ '/chat-history/' + row._id }><Button onClick={ () => {} } className="btnAdd">View</Button></Link>
                );
            }
        }
    ];

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Chat History | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (chatsDataset) ? (
                            <>
                                <div className="mb-4">
                                    <Link to="/archived-chat-history">If you are you are unable to find here the chat history record you are looking for, please click here to search in the archived chat history.</Link>
                                    <hr />
                                </div>

                                <Form form={ chatsFiltersForm } initialValues={{}} onFinish={ onApplyChatsFilters } layout="vertical" hideRequiredMark={ true }>
                                    <Row gutter={ 16 }>
                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 8 }>
                                            <Form.Item 
                                                name="filters[search]"
                                                label="Search by"
                                            >
                                                <Input className="filterOptions searchBar" placeholder="Search by Chat Id, Visitor Username or IP Address..." prefix={ <SearchOutlined /> } />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 4 } xl={ 4 }>
                                            <Form.Item 
                                                name="filters[operatorId]"
                                                label="Operator"
                                            >
                                                <Select>
                                                    { operators && Object.values(operators).map(operator => <Select.Option key={ operator._id } value={ operator._id }>{ operator.displayName }</Select.Option>) }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 6 } xl={ 5 }>
                                            <Form.Item 
                                                name="filters[dateRange]"
                                                label="Date"
                                            >
                                                <DatePicker.RangePicker onChange={ (date, dateString) => { setDateRangeFilterValue(dateString); } } format={ 'DD/MM/YYYY' } />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 4 } xl={ 4 }>
                                            <Form.Item>
                                                <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                <Button className="btnBack" onClick={ onResetChatsFilters }>Reset</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                
                                <Table rowKey="_id" columns={ columns } dataSource={ chatsDataset.chats } pagination={ chatsDataset.pagination ? { ...chatsDataset.pagination, showSizeChanger: false } : null } onChange={ handlePreviousChatsTableChange } />
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
};

export default PreviousChats;