import React, { useState } from 'react';

import MainLayout from '../../../Components/Layouts/MainLayout';

import ChatlineAPI from '../../../utils/ChatlineAPI';

import Helmet from 'react-helmet';
import { Button, Form, Input, message, Alert } from 'antd';

const ChangePassword = (props) => {
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [changePasswordErrors, setChangePasswordErrors] = useState([]);
    const onChangePasswordClientFinish = values => {
        ChatlineAPI.HttpPostRequest('auth/change-password', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setChangePasswordErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setPasswordChanged(true);
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Change Password | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                { passwordChanged ? <Alert style={{ margin: '0 0 12px 0' }} message="You have successfully changed your password!" type="success" showIcon /> : null }

                <div className="pageBody">
                    <h1>Change Password</h1>

                    <Form initialValues={{}} name="editClient" onFinish={ onChangePasswordClientFinish } layout="vertical" hideRequiredMark={ true }>
                        <Form.Item 
                            name="currentPassword"
                            label="Current Password"
                            validateStatus={ changePasswordErrors && changePasswordErrors.currentPassword ? "error" : null }
                            help={ changePasswordErrors && changePasswordErrors.currentPassword ? changePasswordErrors.currentPassword : null }
                        >
                            <Input type="password" />
                        </Form.Item>

                        <Form.Item 
                            name="newPassword"
                            label="New Password"
                            validateStatus={ changePasswordErrors && changePasswordErrors.newPassword ? "error" : null }
                            help={ changePasswordErrors && changePasswordErrors.newPassword ? changePasswordErrors.newPassword : null }
                        >
                            <Input type="password" />
                        </Form.Item>

                        <Form.Item 
                            name="confirmPassword"
                            label="Confirm Password"
                            validateStatus={ changePasswordErrors && changePasswordErrors.confirmPassword ? "error" : null }
                            help={ changePasswordErrors && changePasswordErrors.confirmPassword ? changePasswordErrors.confirmPassword : null }
                        >
                            <Input type="password" />
                        </Form.Item>

                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit">Change Password</Button>
                        </Form.Item>
                    </Form>
                </div>
            </MainLayout>
        </>
    )
}

export default ChangePassword;