const string2Hex = (str) => {
    if(!str) return '#FFD967';

    let hash = 0;

    for(let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let hex = '#';
    for(let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        hex += `CC${value.toString(16)}`.slice(-2);
    }
    
    hex = hex.substring(0, 7);
    hex += '80';

    return hex;
}

export default string2Hex;