import React, { useState, useEffect, useCallback } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';
import { useImmer } from "use-immer";
import { useParams, Link } from 'react-router-dom';

import { socket } from '../../../service/socket';

import MainLayout from '../../../Components/Layouts/MainLayout';
import Helmet from 'react-helmet';
import ReactFlow, { Background, Controls } from 'react-flow-renderer';

import { Row, Col, Modal, Form, Input, Select, Button, message } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, FontSizeOutlined, PictureOutlined, MessageOutlined, RetweetOutlined, CloseOutlined } from '@ant-design/icons';

import AddNodeModal from './AddNodeModal/AddNodeModal';
import EditNodeModal from './EditNodeModal/EditNodeModal';

import TextNode from './Node/TextNode/TextNode';
import UserResponseNode from './Node/UserResponseNode/UserResponseNode';
import ButtonNode from './Node/ButtonNode/ButtonNode';
import ImageNode from './Node/ImageNode/ImageNode';
import CarouselNode from './Node/CarouselNode/CarouselNode';
import ConnectToOperatorNode from './Node/ConnectToOperatorNode/ConnectToOperatorNode';
import EndChatNode from './Node/EndChatNode/EndChatNode';
import CustomEdge from './Edge/CustomEdge/CustomEdge';

import './Chatbot.less';

import ChatbotIMG from '../../../assets/images/chatbot.png';

const customEdgeTypes = {
    'CustomEdge': CustomEdge
}

const customNodeTypes = {
    'Text': TextNode,
    'User response': UserResponseNode,
    'Button': ButtonNode,
    'Image': ImageNode,
    'Carousel': CarouselNode,
    'Connect to operator': ConnectToOperatorNode,
    'End chat': EndChatNode,
}

const Chatbot = () => {
    const { 
        systemVariables,
        client,
        isSocketConnected
    } = useSystemContext();

    const userGroupOptions = (systemVariables && systemVariables.chatbot && systemVariables.chatbot.userGroupOptions) || null;
    const conditionOptions = (systemVariables && systemVariables.chatbot && systemVariables.chatbot.conditionOptions) || null;
    const statusOptions = (systemVariables && systemVariables.chatbot && systemVariables.chatbot.statusOptions) || null;
    const nodeTypeOptions = (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.typeOptions) || null;

    const { chatbotId } = useParams();

    const [chatbot, updateChatbot] = useImmer();

    const [chatbotNodes, updateChatbotNodes] = useImmer(null);
    const [chatbotEdges, updateChatbotEdges] = useImmer(null);
    const [chatbotFlowDiagram, setChatbotFlowDiagram] = useState(null);
    const [chatbotFlowDiagramDisabled, setChatbotFlowDiagramDisabled] = useState(false);
    const [chatbotFlowDiagramData, updateChatbotFlowDiagramData] = useState([]);

    const [editChatbotModalVisible, setEditChatbotModalVisible] = useState(false);
    const [editChatbotForm] = Form.useForm();

    const [nodeTypeForAddNodeModal, setNodeTypeForAddNodeModal] = useState(null);
    const [nodeIdForEditNodeModal, setNodeIdForEditNodeModal] = useState(null);

    const fetchChatbot = useCallback(() => {
        socket.emit('operator.chatbots.chatbot.get', { chatbotId: chatbotId }, (ack) => {
            updateChatbot(chatbot => (ack && ack.result && ack.chatbot) || []);
        });
    }, [chatbotId, updateChatbot]);

    const fetchChatbotNodes = useCallback(() => {
        socket.emit('operator.chatbots.chatbot.nodes.get', { chatbotId: chatbotId }, (ack) => {
            updateChatbotNodes(chatbotNodes => (ack && ack.result && ack.nodes));
        });
    }, [chatbotId, updateChatbotNodes]);

    const fetchChatbotEdges = useCallback(() => {
        socket.emit('operator.chatbots.chatbot.edges.get', { chatbotId: chatbotId }, (ack) => {
            updateChatbotEdges(chatbotNodes => (ack && ack.result && ack.edges));
        });
    }, [chatbotId, updateChatbotEdges]);

    useEffect(() => {
        if(isSocketConnected) {
            fetchChatbot();
        }
    }, [isSocketConnected, fetchChatbot]);

    useEffect(() => {
        fetchChatbotNodes();
        fetchChatbotEdges();
    }, [fetchChatbotNodes, fetchChatbotEdges]);

    useEffect(() => {
        setChatbotFlowDiagramDisabled((chatbot && chatbot.status !== null && statusOptions && statusOptions[chatbot.status] && statusOptions[chatbot.status] === 'Active') || false);
    }, [chatbot, statusOptions]);

    useEffect(() => {
        if(chatbotNodes && chatbotEdges) {
            let xChatbotNodes = [];
            if(chatbotNodes && Object.keys(chatbotNodes).length > 0) {
                for(let node of Object.values(chatbotNodes)) {
                    if(node.withError) console.log('------ node', node);
                    xChatbotNodes.push({
                        id: node._id+'',
                        type: (node.type && nodeTypeOptions && nodeTypeOptions[node.type]) || 'Text',
                        data: { node: node },
                        position: node.position,
                        isNode: true
                    })
                }
            }

            let xChatbotEdges = [];
            if(chatbotEdges && Object.keys(chatbotEdges).length > 0) {
                for(let edge of Object.values(chatbotEdges)) {
                    xChatbotEdges.push({
                        type: 'CustomEdge',
                        data: { edge: edge, fetchChatbotEdges, isRemovable: !chatbotFlowDiagramDisabled },
                        id: edge._id+'',
                        source: edge.sourceId+'',
                        target: edge.targetId+'',
                        animated: true,
                        style: { stroke: '#40456B' },
                        isNode: false,
                    })
                }
            }

            updateChatbotFlowDiagramData(chatbotFlowDiagramData => {
                return [
                    ...xChatbotNodes,   
                    ...xChatbotEdges
                ];
            });
        }
    }, [chatbotFlowDiagramDisabled, nodeTypeOptions, chatbotNodes, chatbotEdges, fetchChatbotEdges, updateChatbotFlowDiagramData]);
    
    const handlePublishChatbot = (chatbotId, displayName) => {
        Modal.confirm({
            title: 'Chatbot: ' + displayName,
            content: 'Are you sure you want to publish this chatbot?',
            onOk() {
                socket.emit('operator.chatbots.chatbot.publish', { chatbotId: chatbotId }, (ack) => {
                    if(ack && ack.result) {
                        fetchChatbot();
                        return;
                    }

                    if(ack.error) {
                        if(ack.error.message) message.error(ack.error.message);
                        
                        if(ack.error.nodeId) {
                            updateChatbotNodes(chatbotNodes => {
                                if(chatbotNodes && chatbotNodes[ack.error.nodeId]) {
                                    chatbotNodes[ack.error.nodeId].withError = true;
                                }
                            });
                        }
                    }
                })
            },
            onCancel() {
            },
        });
    }

    const handleUnpublishChatbot = (chatbotId, displayName) => {
        Modal.confirm({
            title: 'Chatbot: ' + displayName,
            content: 'Are you sure you want to unpublish this chatbot?',
            onOk() {
                socket.emit('operator.chatbots.chatbot.unpublish', { chatbotId: chatbotId }, (ack) => {
                    if(ack && ack.result) {
                        fetchChatbot();
                    }
                })
            },
            onCancel() {
            },
        });
    }

    const handlePreviewChatbot = (chatbotId) => {
        let previewChatbotExternalScript = document.getElementById(process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME);
        if(previewChatbotExternalScript) {
            document.getElementById(process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME + '-container').innerHTML = '';

            let previewChatbotScript = document.createElement('script');
            previewChatbotScript.innerHTML = `
                ${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME}('init', {
                    targetElementId: '${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME + '-container'}',
                    licenseKey: '${client && client.licenseKey}',
                    hideDefaultLauncher: false,
                    previewChatbotId: '${chatbotId}'
                });
            `;
            document.body.appendChild(previewChatbotScript);

            return;
        }

        let previewChatbotDiv = document.createElement('div');
        previewChatbotDiv.id = process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME + '-container';
        document.body.appendChild(previewChatbotDiv);

        let previewChatbotScript = document.createElement('script');
        previewChatbotScript.innerHTML = `
            (function (w, d, s, o, f, js, fjs) {
                w['${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME}'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };  w[o].l = Date.now();
                js = d.createElement(s); fjs = d.getElementsByTagName(s); fjs = fjs[fjs.length-1];
                js.id = o; js.src = f + (!f.includes('?') ? '?' : '&')+'_t='+Date.now(); js.async = 1; fjs.parentNode.insertBefore(js, fjs);
            }(window, document, 'script', '${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME}', '${process.env.REACT_APP_CHATLINE_CLIENT_API_ADDRESS + process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_PATH}'));

            // to initialize the widget
            ${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME}('init', {
                targetElementId: '${process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME + '-container'}',
                licenseKey: '${client && client.licenseKey}',
                hideDefaultLauncher: false,
                previewChatbotId: '${chatbotId}'
            });
        `;
        document.body.appendChild(previewChatbotScript);

        return;
    }

    const handleEditChatbotFinish = (values) => {
        socket.emit('operator.chatbots.chatbot.edit', { chatbotId: chatbotId, ...values }, (ack) => {
            if(ack && ack.result) {
                setEditChatbotModalVisible(false);
                editChatbotForm.resetFields();
                fetchChatbot();
            }
        });
    }

    useEffect(() => {
        if(chatbotFlowDiagram && chatbotFlowDiagramData) {
            // if(chatbotFlowDiagramData.length > 0) chatbotFlowDiagram.fitView();
        }
    }, [chatbotFlowDiagram, chatbotFlowDiagramData]);

    const addBlock = (nodeType) => {
        socket.emit('operator.chatbots.chatbot.nodes.add', { chatbotId: chatbotId, type: nodeType }, (ack) => {
            if(ack && ack.result) {
                fetchChatbotNodes();
            }
        });
    }

    const chatbotFlowDiagram_onLoad = useCallback((reactFlowInstance) => {
        if(!chatbotFlowDiagram) {
            setChatbotFlowDiagram(reactFlowInstance);
        }
    }, [chatbotFlowDiagram]);

    const handleUpdateBlockPosition = (nodeId, positionX, positionY) => {
        socket.emit('operator.chatbots.chatbot.nodes.node.updatePosition', { chatbotId: chatbotId, nodeId: nodeId, position: { x: positionX, y: positionY } }, (ack) => {
            fetchChatbotNodes();
        })
    }

    const handleCreateEdge = (sourceId, targetId) => {
        socket.emit('operator.chatbots.chatbot.edges.add', { chatbotId: chatbotId, sourceId: sourceId, targetId: targetId }, (ack) => {
            fetchChatbotEdges();
        })
    }

    return (
        (chatbot) ? (
            <>
                <Helmet>
                    <title>{ chatbot.name ? chatbot.name+'' : 'Chatbot' } | Chatbots | { process.env.REACT_APP_NAME }</title>
                </Helmet>

                <MainLayout>
                    <>
                        <div className="chatbot-sectionHeader px-4">
                            <Link className="btnTransparent btn_seeAllChatbots" to={ '/chatbots' }><ArrowLeftOutlined className="mr-2" />See all chatbots</Link>

                            <div className="info-W">
                                <div className={ 'status' + (statusOptions[chatbot.status] === 'Active' ? ' active' : '') + (statusOptions[chatbot.status] === 'Inactive' ? ' inactive' : '') }>{ statusOptions[chatbot.status] }</div>

                                <div className="name">
                                    { chatbot.name ? chatbot.name : 'Chatbot' }
                                </div>
                            </div>

                            <div className="actions-W">
                                <img src={ ChatbotIMG } className="chatbotIMG mx-4" alt="Chatbot" />

                                {
                                    statusOptions[chatbot.status] === 'Inactive' ? (
                                        <Button className="chatbot_btnPublish mx-1" onClick={ () => { handlePublishChatbot(chatbot._id+'', chatbot.name+'') } }>Publish</Button>
                                    ) : (
                                        <Button className="chatbot_btnUnpublish mx-1" onClick={ () => { handleUnpublishChatbot(chatbot._id+'', chatbot.name+'') } }>Unpublish</Button>
                                    )
                                }

                                <Button className="chatbot_btnPreview mx-1" onClick={ () => { handlePreviewChatbot(chatbot._id+'') } }>Preview</Button>

                                <Button className="chatbot_btnEdit mx-1" onClick={ () => { setEditChatbotModalVisible(true) } }>Settings</Button>
                            </div>
                        </div>

                        <div className="chatbot-sectionBody mt-4" style={{ height: 'calc(100vh - 240px)' }}>
                            <Row type="flex" gutter={ 16 } style={{ height: '100%' }}>
                                <Col span={ !chatbotFlowDiagramDisabled ? 20 : 24 } style={{ height: '100%' }}>
                                    {
                                        (chatbotFlowDiagramData && Array.isArray(chatbotFlowDiagramData) && chatbotFlowDiagramData.length > 0) ? (
                                            <ReactFlow
                                                className="chatbot-flowDiagram"
                                                elements={ chatbotFlowDiagramData }
                                                nodeTypes={ customNodeTypes }
                                                edgeTypes={ customEdgeTypes }
                                                onElementClick={ (event, element) => { !chatbotFlowDiagramDisabled && setNodeIdForEditNodeModal(element.id+'') } }
                                                onConnect={ (params) => { !chatbotFlowDiagramDisabled && handleCreateEdge(params.source, params.target) } }
                                                onNodeDragStop={ (event, node) => { !chatbotFlowDiagramDisabled && handleUpdateBlockPosition(node.id, node.position.x, node.position.y) } }
                                                onLoad={ chatbotFlowDiagram_onLoad }
                                                snapToGrid={ true }
                                                snapGrid={ [16, 16] }
                                                defaultZoom={ 1 }

                                                nodesDraggable={ !chatbotFlowDiagramDisabled }
                                                nodesConnectable={ !chatbotFlowDiagramDisabled }
                                                elementsSelectable={ !chatbotFlowDiagramDisabled }
                                                selectNodesOnDrag={ !chatbotFlowDiagramDisabled }
                                            >
                                                <Controls />

                                                <Background gap={ 16 } />
                                            </ReactFlow>
                                        ) : (
                                            <div className="chatbot-flowDiagram p-4" style={{ height: '100%' }}>
                                                No blocks available for this chatbot yet.
                                            </div>
                                        )
                                    }
                                </Col>

                                {
                                    (!chatbotFlowDiagramDisabled) && (
                                        <Col span={ 4 } style={{ height: '100%' }}>
                                            <div className="chatbot-actions py-3 px-4" style={{ height: '100%' }}>
                                                <div className="header">
                                                    <PlusOutlined /> Add blocks
                                                </div>

                                                <Row gutter={ 8 } className="mt-2">
                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { setNodeTypeForAddNodeModal(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'Text')) } }>
                                                            <div>
                                                                <FontSizeOutlined style={{ fontSize: '24px', color: '#40456B' }} />
                                                                <div className="mt-2">Text</div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { setNodeTypeForAddNodeModal(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'User response')) } }>
                                                            <div>
                                                                <MessageOutlined style={{ fontSize: '24px', color: '#40456B' }} />
                                                                <div className="mt-2">User response</div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { setNodeTypeForAddNodeModal(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'Button')) } }>
                                                            <div>
                                                                <div className="blockActions_icon button">
                                                                    Button
                                                                </div>
                                                                <div className="mt-2">Button</div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { setNodeTypeForAddNodeModal(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'Image')) } }>
                                                            <div>
                                                                <PictureOutlined style={{ fontSize: '24px', color: '#40456B' }} />
                                                                <div className="mt-2">Image</div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    
                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { setNodeTypeForAddNodeModal(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'Carousel')) } }>
                                                            <div>
                                                                <PictureOutlined style={{ fontSize: '24px', color: '#40456B' }} />
                                                                <div className="mt-2">Carousel</div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { addBlock(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'Connect to operator')) } }>
                                                            <div>
                                                                <RetweetOutlined style={{ fontSize: '24px', color: '#40456B' }} />
                                                                <div className="mt-2">Connect to Operator</div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="mt-3" span={ 12 }>
                                                        <div className="action-C" onClick={ () => { addBlock(Object.keys(nodeTypeOptions).find(key => nodeTypeOptions[key] === 'End chat')) } }>
                                                            <div>
                                                                <CloseOutlined style={{ fontSize: '24px', color: '#40456B' }} />
                                                                <div className="mt-2">End Chat</div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div> 
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    </>
                </MainLayout>

                {
                    (editChatbotModalVisible) && (
                        <Modal
                            visible={ true }
                            onCancel={ () => { setEditChatbotModalVisible(false); editChatbotForm.resetFields(); } }
                            footer={ null }
                        >
                            <h1 style={{ marginBottom: '20px' }}>Settings</h1>

                            <Form form={ editChatbotForm } onFinish={ handleEditChatbotFinish} layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                <Form.Item 
                                    name="name"
                                    label="Name"
                                    rules={ [{ required: true, message: 'Please enter a name for the chatbot.' }] }
                                    initialValue={ chatbot.name ? chatbot.name : null }
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item 
                                    name="displayName"
                                    label="Display name"
                                    rules={ [{ required: true, message: 'Please enter a display name for the chatbot.' }] }
                                    initialValue={ chatbot.displayName ? chatbot.displayName : null }
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item 
                                    name="language"
                                    label="Language"
                                    rules={ [{ required: true, message: 'Please select the language for the chatbot.' }] }
                                    initialValue={ chatbot.language ? { value: chatbot.language+'' } : null }
                                >
                                    <Select labelInValue>
                                        <Select.Option key={ 'en' } value={ 'en' }>[EN] English</Select.Option>
                                        <Select.Option key={ 'tr' } value={ 'tr' }>[TR] Turkish</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item 
                                    name="userGroup"
                                    label="Show to"
                                    initialValue={ chatbot.userGroup ? { value: chatbot.userGroup+'' } : null }
                                >
                                    <Select labelInValue>
                                        {
                                            userGroupOptions && Object.keys(userGroupOptions).map(key => <Select.Option key={ key } value={ key }>{ userGroupOptions[key] }</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item 
                                    name="condition"
                                    label="Condition"
                                    initialValue={ chatbot.condition ? { value: chatbot.condition+'' } : null }
                                >
                                    <Select labelInValue>
                                        {
                                            conditionOptions && Object.keys(conditionOptions).map(key => <Select.Option key={ key } value={ key }>{ conditionOptions[key] }</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                    <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    )
                }

                { 
                    (nodeTypeForAddNodeModal) && (
                        <AddNodeModal
                            chatbotId={ chatbotId }
                            nodeType={ nodeTypeForAddNodeModal }
                            onSuccess={ () => { setNodeTypeForAddNodeModal(null); fetchChatbotNodes(); } }
                            onCancel={ () => { setNodeTypeForAddNodeModal(null); } }
                        />
                    )
                }

                {
                    (nodeIdForEditNodeModal && chatbotNodes && chatbotNodes[nodeIdForEditNodeModal]) && (
                        <EditNodeModal
                            node={ chatbotNodes && chatbotNodes[nodeIdForEditNodeModal] }
                            onSuccess={ () => { setNodeIdForEditNodeModal(null); fetchChatbotNodes(); fetchChatbotEdges(); } }
                            onCancel={ () => { setNodeIdForEditNodeModal(null); } }
                        />
                    )
                }
            </>
        ) : (
            null
        )
    )
}

export default Chatbot;