import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useHistory } from 'react-router-dom';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import debounce from 'lodash.debounce';

import moment from 'moment';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Spin, Button, Table, Tabs, message } from 'antd';

import ReactCountryFlag from 'react-country-flag';

import './ChatQueue.less';

const ChatQueuePendingChats = () => {
    const { 
        currentUser,
        isSocketConnected
    } = useSystemContext();
    
    const [departments, setDepartments] = useState(null);

    const [isPendingChatsLoading, setIsPendingChatsLoading] = useState(false);
    const [pendingChats, updatePendingChats] = useImmer(null);
    const [pendingChatsPagination, setPendingChatsPagination] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.client.departments.get', null, (ack) => {
                if(ack && ack.result && ack.departments) {
                    setDepartments(ack.departments);
                }
            });
        }
    }, [isSocketConnected]);

    const loadPendingChats = useCallback((page = 1) => {
        setIsPendingChatsLoading(true);

        socket.emit('operator.chats.pending.get', { page: page }, (ack) => {
            setIsPendingChatsLoading(false);

            setPendingChatsPagination((ack && ack.result && ack.response && ack.response.pagination) || {});

            updatePendingChats(pendingChats => {
                if(!pendingChats) return (ack && ack.result && ack.response && ack.response.chats ? ack.response.chats : {});

                if(ack && ack.response && ack.response.chats && Object.values(ack.response.chats).length > 0) {
                    for(let chat of Object.values(ack.response.chats)) {
                        pendingChats[chat._id] = chat;
                    }
                }
            });
        });
    }, [updatePendingChats]);

    window.onscroll = debounce(() => {
        let hasMore = (pendingChatsPagination && pendingChatsPagination.pageSize && pendingChatsPagination.current && pendingChatsPagination.total && (pendingChatsPagination.pageSize * pendingChatsPagination.current) < pendingChatsPagination.total);

        if(isPendingChatsLoading || !hasMore) return;

        // Checks that the page has scrolled to the bottom
        if(window.innerHeight + document.documentElement.scrollTop === document.getElementById('root').offsetHeight) {
            loadPendingChats(((pendingChatsPagination && pendingChatsPagination.current && (parseInt(pendingChatsPagination.current) + 1)) || 1));
        }
    }, 100);

    useEffect(() => {
        if(isSocketConnected) {
            loadPendingChats(1);
        }
    }, [isSocketConnected, loadPendingChats]);

    useEffect(() => {
        if(isSocketConnected && departments) {
            socket.on('operator.chats.chat.start', (pChat) => {
                if(!pChat) return;

                if(!departments || !departments[pChat.departmentId]) return; // if the current user does not have access to the chat's department

                if(pChat.operatorId) {
                    updatePendingChats(pendingChats => {
                        if(pendingChats && pendingChats[pChat._id]) delete pendingChats[pChat._id];
                    });
                } else {
                    updatePendingChats(pendingChats => {
                        if(!pendingChats) pendingChats = {};
                        pendingChats[pChat._id] = { ...pChat };
                    });
                }
            });
        }

        return function cleanup() {
            socket.off('operator.chats.chat.start');
        }
    }, [isSocketConnected, departments, updatePendingChats]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.on('operator.chats.chat.end', (pChat) => {
                if(!pChat) return;

                updatePendingChats(pendingChats => {
                    if(pendingChats && pendingChats[pChat._id]) delete pendingChats[pChat._id];
                });
            });
        }

        return function cleanup() {
            socket.off('operator.chats.chat.end');
        }
    }, [isSocketConnected, updatePendingChats]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if(event && event.keyCode === 78) {
                document.getElementById('btnTakeNext').click();
            }
        }

        document.addEventListener('keydown', handleKeyPress, false);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyPress, false);
        }
    }, []);

    const handleTakeNextChat = () => {
       socket.emit('operator.chats.take', { force: true }, (ack) => {
            if(ack && ack.result && ack.chat) {
                history.push('/my-chats/' + ack.chat._id);
                return;
            }

            message.error('There are no pending chats that you can take from!');
            return;
        });
    }

    const handleStartChat = (chatId) => {
        socket.emit('operator.chat.join', { chatId: chatId }, (ack) => {
            if(!ack) return;

            if(ack.result && ack.chat) {
                history.push('/my-chats/' + ack.chat._id);
            } else if(ack.error) {
                message.error(ack.error);
            }
        });
    };

    const handleObserveChat = (chatId) => {
        socket.emit('operator.chat.observe', { chatId: chatId }, (ack) => {
            if(ack && ack.result && ack.chat) history.push('/my-chats/' + ack.chat._id);
        });
    }

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (val, row) => { return (row.visitor && row.visitor.username ? row.visitor.username : 'N/A'); }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (val, row) => { return (row.visitor && row.visitor.name ? row.visitor.name : 'N/A'); }
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            render: (val, row) => { return (row.visitor && row.visitor.ip ? <span> { row.visitor.ip } { (row.visitor.countryCode && <ReactCountryFlag countryCode={ row.visitor.countryCode } style={{ fontSize: '18px'}} />) } </span> : 'N/A'); }
        },
        {
            title: 'Registration Date',
            dataIndex: 'registeredAt',
            key: 'registeredAt',
            render: (val, row) => { return (row.visitor && row.visitor.registeredAt ? moment.unix(row.visitor.registeredAt+'').format('DD/MM/YYYY') : 'N/A'); }
        },
        {
            title: 'Department',
            dataIndex: 'departmentId',
            key: 'departmentId',
            render: (val, row) => { return (row.departmentId && departments && departments[row.departmentId] ? departments[row.departmentId].name : 'N/A'); }
        },
        {
            title: 'Current Page',
            dataIndex: 'currentPage',
            key: 'currentPage',
            className: 'currentPage',
            render: (val, row) => {
                let currentPage = null;
                currentPage = (
                    !row.visitor || !row.visitor.currentPage || (!row.visitor.currentPage.title && !row.visitor.currentPage.URL) ? (
                        <span>N/A</span>
                    ) : (
                        row.visitor.currentPage && row.visitor.currentPage.title && row.visitor.currentPage.URL && <div><a href={ row.visitor.currentPage.URL } target="_blank" rel="noopener noreferrer">{  row.visitor.currentPage.title }</a></div>
                    )
                );

                return currentPage;
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (val, row) => { return (row.createdAt ? moment.duration(moment().diff(moment(row.createdAt))).humanize() : 'N/A'); }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '120px',
            render: (val, row) => {
                return (
                    <>
                        { 
                            currentUser && (
                                <>
                                    <Button onClick={ () => { handleStartChat(row._id) } } className={ (row.operatorId ? 'btnDisabled' : 'btnAdd') + ' mr-1' } disabled={ row.operatorId ? true : false }>{ row.operatorId ? 'In Chat' : 'Chat' }</Button>

                                    { 
                                        row.operatorId && [20, 99].includes(currentUser.role) && (
                                            (row.operatorId === currentUser._id || (row.operatorsJoined && Array.isArray(row.operatorsJoined) && row.operatorsJoined.includes(currentUser._id)) || (row.observers && Array.isArray(row.observers) && row.observers.includes(currentUser._id))) ? (
                                                <Button className="btnDisabled mr-1" disabled={ true }>Join</Button>
                                            ) : (
                                                <Button onClick={ () => { handleStartChat(row._id) } } className="btnSecondary mr-1">Join</Button>
                                            )
                                        )
                                    }
                                    
                                    {
                                        row.operatorId && [20, 99].includes(currentUser.role) && (
                                            (row.operatorId === currentUser._id || (row.operatorsJoined && Array.isArray(row.operatorsJoined) && row.operatorsJoined.includes(currentUser._id)) || (row.observers && Array.isArray(row.observers) && row.observers.includes(currentUser._id))) ? (
                                                <Button className="btnDisabled" disabled={ true }>Observe</Button>
                                            ) : (
                                                <Button onClick={ () => { handleObserveChat(row._id) } } className="btnSecondary">Observe</Button>
                                            )
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                );
            }
        }
    ];

    const onTabChange = (key) => {
        if(key === 'PendingChats') {
            history.push('/chat-queue/pending-chats');
        } else if(key === 'ActiveChats') {
            history.push('/chat-queue/active-chats');
        }
    }

    return (
        <>
            <Helmet>
                <title>Pending Chats | Chat Queue | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (currentUser && departments && pendingChats) ? (
                            <Tabs defaultActiveKey="PendingChats" onChange={ onTabChange }>
                                <Tabs.TabPane tab="Pending" key="PendingChats">
                                    <Button id="btnTakeNext" className="btnAdd btnTakeNext" onClick={ () => { handleTakeNextChat() } }>Take Next [n]</Button>

                                    <Table rowKey="_id" columns={ columns } dataSource={ (pendingChats ? Object.values(pendingChats) : []) } pagination={ false } />

                                    { isPendingChatsLoading && <div style={{ padding: '20px 0 20px 0', textAlign: 'center' }}><Spin /></div> }
                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Active" key="ActiveChats">
                                </Tabs.TabPane>
                            </Tabs>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
};

export default ChatQueuePendingChats;