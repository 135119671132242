import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import moment from 'moment';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Table, Button, Form, Input, Select, Row, Col, Modal, DatePicker } from 'antd';
import { StopOutlined, FilterOutlined, SearchOutlined, PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const BanList = (props) => {
    const { 
        currentUser,
        systemVariables,
        operators, fetchOperators,
        isSocketConnected
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [bannedVisitors, updateBannedVisitors] = useImmer(null);
    const [bannedVisitorsPagination, setBannedVisitorsPagination] = useState(null);

    const [addNewRecordVisibility, setAddNewRecordVisibility] = useState(false);
    const [addNewRecordForm] = Form.useForm();

    const [applyFilterForm] = Form.useForm();

    useEffect(() => {
        if(isSocketConnected) {
            fetchOperators();
        }
    }, [isSocketConnected, fetchOperators]);

    
    const loadBannedVisitors = useCallback((page = 1, limit = 10, filters = null) => {
        socket.emit('operator.visitors.banned.get', { page: page, limit: limit, filters: filters }, (ack) => {
            if(ack && ack.result && ack.response) {
                if(ack.response.bannedVisitors) updateBannedVisitors(bannedVisitors => ack.response.bannedVisitors);
                if(ack.response.pagination) setBannedVisitorsPagination(ack.response.pagination);
                return;
            }

            updateBannedVisitors(bannedVisitors => []);
        });
    }, [updateBannedVisitors]);
    
    useEffect(() => {
        if(isSocketConnected) {
            loadBannedVisitors(1);
        }
    }, [isSocketConnected, loadBannedVisitors]);

    const onBannedVisitorsTableChange = (pagination) => {
        loadBannedVisitors(pagination.current);
    }

    const onApplyFilter = (values) => {
        if(!values) return;

        let limit = 10;
        if(values.limit) {
            limit = values.limit;
            delete values.limit;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            filters[key] = (values[key] ? values[key].trim() : null);
        }
        
        loadBannedVisitors(1, limit, filters);
    }

    const onResetFields = () => {
        applyFilterForm.resetFields();
        loadBannedVisitors(1, 10, null);
    }

    const handleUnban = (visitorBanId) => {
        Modal.confirm({
            title: 'Unban',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure that you would like to unban this session/user?',
            onOk() {
                socket.emit('operator.chat.unban', { visitorBanId: visitorBanId }, (ack) => {
                    if(ack && ack.result) {
                        if(bannedVisitors && bannedVisitors[visitorBanId]) {
                            updateBannedVisitors(bannedVisitors => {
                                if(bannedVisitors && Object.keys(bannedVisitors).length > 0) {
                                    if(bannedVisitors[visitorBanId]) delete bannedVisitors[visitorBanId];
                                }
                            });
                        }
                    }
                });
            },
            onCancel() {},
        });
    }

    const handleAddNewRecordFinish = (values) => {
        socket.emit('operator.visitors.banned.add', { ...values }, (ack) => {
            if(ack && ack.result) {
                addNewRecordForm.resetFields();
                setAddNewRecordVisibility(false);

                loadBannedVisitors(1);
            }
        });
    }

    const columns = [
        {
            title: 'Session Id',
            dataIndex: 'sessionId',
            key: 'sessionId',
            width: '260px'
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            width: '120px',
            render: (val, row) => { return (row.ip ? row.ip : 'N/A'); }
        },
        {
            title: 'Visitor',
            dataIndex: 'visitor',
            key: 'visitor',
            width: '200px',
            render: (val, row) => { return (
                <>
                    <div>Name: <b>{ row.visitor && row.visitor.name ? row.visitor.name : 'N/A' }</b></div>
                    <div>Username: <b>{ row.visitor && row.visitor.username ? row.visitor.username : 'N/A' }</b></div>
                </>
            ); }
        },
        {
            title: 'Banned By',
            dataIndex: 'bannedBy',
            key: 'bannedBy',
            width: '200px',
            render: (val, row) => { return (row.operatorId && operators && operators[row.operatorId] ? operators[row.operatorId].displayName : 'N/A'); }
        },
        {
            title: 'Banned At',
            dataIndex: 'bannedAt',
            key: 'bannedAt',
            width: '120px',
            render: (val, row) => { return (row && row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm') : 'N/A'); }
        },
        {
            title: 'Until',
            dataIndex: 'bannedUntil',
            key: 'bannedUntil',
            width: '120px',
            render: (val, row) => { return (row && row.bannedUntil ? moment(row.bannedUntil).format('DD/MM/YYYY HH:mm') : 'N/A'); }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '60px',
            render: (val, row) => { 
                if(currentUser && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) {
                    return (<Button onClick={ () => { handleUnban(row._id) } } className="btnDelete">Unban</Button>);
                }

                return (<Button onClick={ () => { handleUnban(row._id) } } className="btnDisabled" disabled={ true }>Unban</Button>);
            }
        }
    ];

    return (
        <>
            <Helmet>
                <title>Ban List | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    { 
                        (bannedVisitors) && (
                            <>
                                <div style={{ float: 'right' }}>
                                    <Button onClick={ () => { setAddNewRecordVisibility(true) } } className={ "btnAdd mx-1" }><PlusCircleOutlined /> Add New Record</Button>
                                </div>

                                <h2 className="mb-2"><StopOutlined className="mr-1" /> Ban List</h2>
                                <div className="mb-4" style={{ background: '#EBECFF', width: '100%', height: '1px' }} />

                                <Form form={ applyFilterForm } initialValues={{}} name="applyFilter" onFinish={ onApplyFilter } layout="vertical" hideRequiredMark={ true }>
                                    <Row gutter={ 16 }>
                                        <Col xs={ 24 } sm={ 12 } xl={ 6 }>
                                            <Form.Item 
                                                name="search"
                                                label="Search by"
                                            >
                                                <Input className="searchBar filterOptions" placeholder="Search by Session Id, IP address or Username..." prefix={ <SearchOutlined /> } />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 6 } xl={ 4 }>
                                            <Form.Item 
                                                name="operatorId"
                                                label="Banned By"
                                            >
                                                <Select className="filterOptions">
                                                    <Select.Option key={ '' } value={ '' }></Select.Option>
                                                    { operators && Object.values(operators) && Object.values(operators).map(operator => <Select.Option key={ operator._id } value={ operator._id }>{ operator.displayName }</Select.Option>) }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                                            <Form.Item 
                                                name="limit"
                                                label="Show"
                                                initialValue={ (bannedVisitorsPagination && bannedVisitorsPagination.pageSize ? bannedVisitorsPagination.pageSize : 10) }
                                            >
                                                <Select className="filterOptions">
                                                    <Select.Option value={ 10 }>10</Select.Option>
                                                    <Select.Option value={ 25 }>25</Select.Option>
                                                    <Select.Option value={ 50 }>50</Select.Option>
                                                    <Select.Option value={ 100 }>100</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                                            <Form.Item>
                                                <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                <Button className="btnBack" onClick={ onResetFields }>Reset</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Table rowKey="_id" className="bannedUsersT" columns={ columns } onChange={ onBannedVisitorsTableChange } dataSource={ (bannedVisitors ? Object.values(bannedVisitors) : []) } pagination={ (bannedVisitorsPagination ? bannedVisitorsPagination : false) } />
                            </>
                        )
                    }

                    {
                        (addNewRecordVisibility) && (
                            <Modal
                                visible={ true }
                                onCancel={ () => { setAddNewRecordVisibility(false); addNewRecordForm.resetFields(); } }
                                footer={ null }
                            >
                                <h1 style={{ marginBottom: '20px' }}>Add New Record</h1>

                                <Form form={ addNewRecordForm } onFinish={ handleAddNewRecordFinish } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                    <Form.Item 
                                        name="ip"
                                        label="IPv4/IPv6 Address"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp(/^[A-Za-z0-9.]+$/i),
                                                message: 'IPv4/IPv6 Address can contain alphanumeric characters and dots only!'
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item 
                                        name="bannedUntil"
                                        label="Banned Until"
                                    >
                                        <DatePicker format={ 'DD/MM/YYYY' } />
                                    </Form.Item>

                                    <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                        <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default BanList;