import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useSystemContext } from '../../../../Context/SystemContext';

import Helmet from 'react-helmet';
import MainLayout from '../../../../Components/Layouts/MainLayout';

import Error from '../../../Error/Error';

import ChatlineAPI from '../../../../utils/ChatlineAPI';

import { Breadcrumb, Button, Form, Input, Select, message, Spin } from 'antd';

const Edit = (props) => {
    const { 
        systemVariables
    } = useSystemContext();

    const { clientId } = useParams();

    const [error, setError] = useState(null);
    
    const [clientData, setClientData] = useState(null);
    
    const history = useHistory();

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('clients/' + clientId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setClientData(res.data); }
        });
    }, [clientId, history]);

    const [editClientErrors, setEditClientErrors] = useState([]);
    const onEditClientFinish = values => {
        ChatlineAPI.HttpPostRequest('clients/' + clientId + '/edit', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setEditClientErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                history.push('/clients');
                return;
            }
        });
    };

    const statusOptions = (systemVariables && systemVariables.client && systemVariables.client.statusOptions ? systemVariables.client.statusOptions : null);
    const statusKey2Value = (status) => {
        if(typeof(statusOptions[status]) !== 'undefined') return statusOptions[status];
        return 'Unknown';
    };

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Edit Client | Clients | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                {
                    (systemVariables && clientData && clientData.client) ? (
                        <>
                            <Breadcrumb>
                                <Breadcrumb.Item>Clients</Breadcrumb.Item>
                                <Breadcrumb.Item>{ clientData && clientData.client && clientData.client.businessName ? clientData.client.businessName : clientId }</Breadcrumb.Item>
                                <Breadcrumb.Item>Edit</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="pageBody">
                                <h1>Edit Client</h1>
                                {
                                    clientData && clientData.client
                                    ?
                                    <Form initialValues={{}} name="editClient" onFinish={ onEditClientFinish } layout="vertical" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="licenseKey"
                                            label="License Key"
                                            initialValue={ (clientData && clientData.client && clientData.client.licenseKey ? clientData.client.licenseKey : '') }
                                        >
                                            <Input disabled />
                                        </Form.Item>

                                        <Form.Item 
                                            name="businessName"
                                            label="Business Name"
                                            initialValue={ (clientData && clientData.client && clientData.client.businessName ? clientData.client.businessName : '') }
                                            validateStatus={ editClientErrors && editClientErrors.businessName ? "error" : null }
                                            help={ editClientErrors && editClientErrors.businessName ? editClientErrors.businessName : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="businessEmail"
                                            label="Business Email"
                                            initialValue={ (clientData && clientData.client && clientData.client.businessEmail ? clientData.client.businessEmail : '') }
                                            validateStatus={ editClientErrors && editClientErrors.busibusinessEmailnessEmail ? "error" : null }
                                            help={ editClientErrors && editClientErrors.businessEmail ? editClientErrors.businessEmail : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="websiteURL"
                                            label="Website URL"
                                            initialValue={ (clientData && clientData.client && clientData.client.websiteURL ? clientData.client.websiteURL : '') }
                                            validateStatus={ editClientErrors && editClientErrors.websiteURL ? "error" : null }
                                            help={ editClientErrors && editClientErrors.websiteURL ? editClientErrors.websiteURL : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="status"
                                            label="Status"
                                            initialValue={ (clientData && clientData.client && clientData.client.status+'' ? clientData.client.status+'' : '') }
                                        >
                                            <Select>
                                                {
                                                    statusOptions
                                                    ?
                                                    Object.keys(statusOptions).map(statusId => {
                                                        return (
                                                            <Select.Option key={ statusId } value={ statusId }>{ statusKey2Value(statusId) }</Select.Option>
                                                        );
                                                    })
                                                    :
                                                    null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Link to="/clients" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                    :
                                    null
                                }
                            </div>
                        </>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </MainLayout>
        </>
    )
}

export default Edit;