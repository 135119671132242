import axios from 'axios';

class ChatlineAPI {
    static HttpGetRequest = (endpoint, cb) => {
        axios.get(process.env.REACT_APP_CHATLINE_API_ADDRESS + endpoint, { headers: { Authorization: localStorage.getItem('token') } })
            .then((res) => {
                cb(null, res);
            }).catch(err => {
                if(err && err.response && err.response.status && err.response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href='/login';
                    return;
                }

                cb(err.response, null);
            });
    }

    static HttpPostRequest = (endpoint, values, cb) => {
        axios.post(process.env.REACT_APP_CHATLINE_API_ADDRESS + endpoint, values, { headers: { Authorization: localStorage.getItem('token') } })
            .then((res) => {
                cb(null, res);
            }).catch(err => {
                cb(err.response, null);
            });
    }

    static FileReader = (clientId, endpoint, type, originalFileName, cb) => {
        axios(process.env.REACT_APP_CHATLINE_API_ADDRESS + endpoint,  {
            headers: { Authorization: localStorage.getItem('token'), clientId: clientId },
            method: "GET",
            responseType: "blob",
        }).then(response => {
            const file = new Blob([response.data], { type: type });
            const fileURL = URL.createObjectURL(file);
 
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', originalFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            cb(error.response, null);
        });
    }
}

export default ChatlineAPI;