import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../../../../Context/SystemContext';

import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';
import MainLayout from '../../../../../Components/Layouts/MainLayout';

import Error from '../../../../Error/Error';

import ChatlineAPI from '../../../../../utils/ChatlineAPI';

import { Button, Form, Input, InputNumber, Select, message, Spin, Modal, notification } from 'antd';
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const EditOperator = (props) => {
    const { 
        currentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const statusOptions = (systemVariables && systemVariables.user && systemVariables.user.statusOptions ? systemVariables.user.statusOptions : null);

    const [memberData, setMemberData] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('members/' + props.match.params.operatorId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMemberData(res.data); }
        });
    }, [props.match.params])

    const [editOperatorErrors, setEditOperatorErrors] = useState([]);
    const onEditOperatorFinish = values => {
        ChatlineAPI.HttpPostRequest('members/' + props.match.params.operatorId +  '/edit', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setEditOperatorErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                props.history.push('/control-panel/operators');
                return;
            }
        });
    };

    const onResetPasswordConfirmation = () => {
        Modal.confirm({
            title: 'Reset Password',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure that you want to reset the password for the selected record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('members/' + props.match.params.operatorId +  '/reset-password', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }
        
                    if(res.data && res.data.password) {
                        const args = {
                            message: 'Password reset!',
                            description: <>The password has successfully been reset for the selected user. New password is: <b>{ res.data.password }</b></>,
                            duration: 0,
                        };

                        notification.open(args);
                    }
                });
            },
            onCancel() {
            },
        });
    }

    const statusKey2Value = (status) => {
        if(typeof(statusOptions[status]) !== 'undefined') return statusOptions[status];
        return 'Unknown';
    };

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Edit Operator | Operators | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (currentUser && roleOptions && memberData && memberData.member) ? (
                            <>
                                <div style={{ margin: '0 0 8px 0' }}>
                                    <Link to="/control-panel/operators"><Button className="btnBack" size="medium" onClick={ () => {} }><ArrowLeftOutlined /> Operators</Button></Link>
                                </div>

                                <div className="pageBody">
                                    <h1 className="mb-4">Edit Member</h1>

                                    <Form initialValues={{}} name="editOperator" onFinish={ onEditOperatorFinish } layout="vertical" hideRequiredMark={ true }>
                                        <div style={{ display: ([''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin')].includes(''+currentUser.role) ? 'block' : 'none') }}>
                                            <Form.Item 
                                                name="displayName"
                                                label="Display Name"
                                                initialValue={ (memberData && memberData.member && memberData.member.displayName ? memberData.member.displayName : '') }
                                                validateStatus={ editOperatorErrors && editOperatorErrors.displayName ? 'error' : null }
                                                help={ editOperatorErrors && editOperatorErrors.displayName ? editOperatorErrors.displayName : null }
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item 
                                                name="username"
                                                label="Username"
                                                initialValue={ (memberData && memberData.member && memberData.member.username ? memberData.member.username : '') }
                                                validateStatus={ editOperatorErrors && editOperatorErrors.username ? 'error' : null }
                                                help={ editOperatorErrors && editOperatorErrors.username ? editOperatorErrors.username : null }
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name="email"
                                                label="Email Address"
                                                initialValue={ (memberData && memberData.member && memberData.member.email ? memberData.member.email : '') }
                                                validateStatus={ editOperatorErrors && editOperatorErrors.email ? 'error' : null }
                                                help={ editOperatorErrors && editOperatorErrors.email ? editOperatorErrors.email : null }
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name="password"
                                                label={ <>Password <Button onClick={ onResetPasswordConfirmation } className="btnPlain ml-2" style={{ fontSize: '11px', lineHeight: '14px', height: '14px' }}><span style={{ textDecoration: 'underline' }}>Reset</span></Button></> }
                                                initialValue={ '****************' }
                                            >
                                                <Input type="password" disabled={ true } />
                                            </Form.Item>

                                            <Form.Item 
                                                name="role"
                                                label="Role"
                                                initialValue={ (memberData && memberData.member && memberData.member.role+'' ? memberData.member.role+'' : '') }
                                                validateStatus={ editOperatorErrors && editOperatorErrors.role ? 'error' : null }
                                                help={ editOperatorErrors && editOperatorErrors.role ? editOperatorErrors.role : null }
                                            >
                                                <Select>
                                                    {
                                                        roleOptions
                                                        ?
                                                        Object.keys(roleOptions).map(roleId => {
                                                            return (
                                                                <Select.Option key={ roleId } value={ roleId }>{ roleOptions[roleId] }</Select.Option>
                                                            );
                                                        })
                                                        :
                                                        null
                                                    }
                                                </Select>
                                            </Form.Item>

                                            <Form.Item 
                                                name="status"
                                                label="Status"
                                                initialValue={ (memberData && memberData.member && typeof(memberData.member.status) !== 'undefined' && memberData.member.status !== null ? memberData.member.status+'' : '') }
                                            >
                                                <Select>
                                                    { (statusOptions && Object.keys(statusOptions).map(statusId => <Select.Option key={ statusId } value={ statusId }>{ statusKey2Value(statusId) }</Select.Option>)) }
                                                </Select>
                                            </Form.Item>

                                            <hr />
                                        </div>

                                        <Form.Item 
                                            name="automaticallyAcceptChats"
                                            label="Automatically accept chats?"
                                            initialValue={{ value: (memberData && memberData.member && memberData.member.parameters && typeof(memberData.member.parameters.automaticallyAcceptChats) !== 'undefined' && memberData.member.parameters.automaticallyAcceptChats !== null ? ''+memberData.member.parameters.automaticallyAcceptChats : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="maximumNumberOfConcurrentChats"
                                            label="Maximum number of concurrent chats that can be automatically accepted"
                                            initialValue={ (memberData && memberData.member && memberData.member.parameters && memberData.member.parameters.maximumNumberOfConcurrentChats+'' ? memberData.member.parameters.maximumNumberOfConcurrentChats+'' : '') }
                                        >
                                            <InputNumber min={ 1 } />
                                        </Form.Item>

                                        <Form.Item 
                                            name="isAllowedToManuallyPickFromPendingChats"
                                            label="Is allowed to manually pick from pending chats?"
                                            initialValue={{ value: (memberData && memberData.member && memberData.member.parameters && typeof(memberData.member.parameters.isAllowedToManuallyPickFromPendingChats) !== 'undefined' && memberData.member.parameters.isAllowedToManuallyPickFromPendingChats !== null ? ''+memberData.member.parameters.isAllowedToManuallyPickFromPendingChats : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Link to="/control-panel/operators" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default EditOperator;