import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useSystemContext } from '../../../../../../Context/SystemContext';

import Helmet from 'react-helmet';
import MainLayout from '../../../../../../Components/Layouts/MainLayout';

import Error from '../../../../../Error/Error';

import ChatlineAPI from '../../../../../../utils/ChatlineAPI';

import { Breadcrumb, Button, Form, Input, Select, message, Spin, Modal, Alert } from 'antd';

const Edit = (props) => {
    const { 
        systemVariables
    } = useSystemContext();
    
    const history = useHistory();
    const { clientId, memberId } = useParams();

    const [error, setError] = useState(null);
    
    const [clientData, setClientData] = useState(null);
    const [memberData, setMemberData] = useState(null);

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('clients/' + clientId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }
            
            if(res && res.data) { setClientData(res.data); }
        });

        ChatlineAPI.HttpGetRequest('clients/' + clientId + '/members/' + memberId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { setMemberData(res.data); }
        });
    }, [clientId, memberId, history])

    const [editMemberErrors, setEditMemberErrors] = useState([]);
    const onEditMemberFinish = values => {
        ChatlineAPI.HttpPostRequest('clients/' + clientId + '/members/' + memberId +  '/edit', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setEditMemberErrors(formErrors); return;
                }

                message.error('System is currently unavailable. Please try again later.'); return;
            }

            if(res.data && res.data.result === true) {
                history.push('/clients/' + clientId + '/members'); return;
            }
        });
    };

    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [newPassword, setNewPassword] = useState(null);
    const onResetPassword = (memberId, displayName) => {
        Modal.confirm({
            title: 'Member: ' + displayName,
            content: 'Are you sure you want to reset password for this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('clients/' + clientId + '/members/' + memberId + '/reset-password', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.'); return;
                    }
        
                    if(res.data && res.data.result === true) {
                        setIsPasswordReset(true);
                        setNewPassword(res.data.password);
                    }
                });
            },
            onCancel() {

            },
        });
    }

    const statusOptions = (systemVariables && systemVariables.user && systemVariables.user.statusOptions ? systemVariables.user.statusOptions : null);
    const statusKey2Value = (status) => {
        if(typeof(statusOptions[status]) !== 'undefined') return statusOptions[status];
        return 'Unknown';
    };

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Edit Member | Members | Client | Clients | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (clientData && clientData.client && memberData && memberData.member) ? (
                            <>
                                <Breadcrumb>
                                    <Breadcrumb.Item>Clients</Breadcrumb.Item>
                                    <Breadcrumb.Item>{ clientData && clientData.client && clientData.client.businessName ? clientData.client.businessName : clientId }</Breadcrumb.Item>
                                    <Breadcrumb.Item>Members</Breadcrumb.Item>
                                    <Breadcrumb.Item>{ memberData && memberData.member && memberData.member.displayName ? memberData.member.displayName : memberId }</Breadcrumb.Item>
                                    <Breadcrumb.Item>Edit</Breadcrumb.Item>
                                </Breadcrumb>

                                { isPasswordReset && newPassword ? <Alert style={{ margin: '12px 0 12px 0' }} message={ 'Password has successfully been reset. New password: ' + newPassword } type="success" showIcon /> : null }

                                <div className="pageBody">
                                    <h1>Edit Member</h1>

                                    <Form initialValues={{}} name="editMember" onFinish={ onEditMemberFinish } layout="vertical" hideRequiredMark={ true }>

                                        <Form.Item 
                                            name="displayName"
                                            label="Display Name"
                                            initialValue={ (memberData && memberData.member && memberData.member.displayName ? memberData.member.displayName : '') }
                                            validateStatus={ editMemberErrors && editMemberErrors.displayName ? "error" : null }
                                            help={ editMemberErrors && editMemberErrors.displayName ? editMemberErrors.displayName : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="username"
                                            label="Username"
                                            initialValue={ (memberData && memberData.member && memberData.member.username ? memberData.member.username : '') }
                                            validateStatus={ editMemberErrors && editMemberErrors.username ? "error" : null }
                                            help={ editMemberErrors && editMemberErrors.username ? editMemberErrors.username : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            label="Email Address"
                                            initialValue={ (memberData && memberData.member && memberData.member.email ? memberData.member.email : '') }
                                            validateStatus={ editMemberErrors && editMemberErrors.email ? "error" : null }
                                            help={ editMemberErrors && editMemberErrors.email ? editMemberErrors.email : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        {
                                            memberData && memberData.member && memberData.member._id && memberData.member.displayName ?
                                            <Form.Item
                                                name="password"
                                                label={ <span>Password <a href="/reset-password" onClick={ (e) => { e.preventDefault(); onResetPassword(memberData.member._id, memberData.member.displayName); } } style={{ textDecoration: 'underline' }}>Reset</a></span> }
                                                initialValue="********">
                                                <Input type="password" disabled />
                                            </Form.Item>
                                            : null
                                        }

                                        <Form.Item 
                                            name="role"
                                            label="Role"
                                            initialValue={ (memberData && memberData.member && memberData.member.role+'' ? memberData.member.role+'' : '') }
                                        >
                                            <Select>
                                                {
                                                    roleOptions
                                                    ?
                                                    Object.keys(roleOptions).map(roleId => {
                                                        return (
                                                            <Select.Option key={ roleId } value={ roleId }>{ roleOptions[roleId] }</Select.Option>
                                                        );
                                                    })
                                                    :
                                                    null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="status"
                                            label="Status"
                                            initialValue={ (memberData && memberData.member && memberData.member.status+'' ? memberData.member.status+'' : '') }
                                        >
                                            <Select>
                                                {
                                                    statusOptions
                                                    ?
                                                    Object.keys(statusOptions).map(statusId => {
                                                        return (
                                                            <Select.Option key={ statusId } value={ statusId }>{ statusKey2Value(statusId) }</Select.Option>
                                                        );
                                                    })
                                                    :
                                                    null
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Link to="/clients" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Edit;