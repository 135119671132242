import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSystemContext } from '../../../Context/SystemContext';

import ChatlineAPI from '../../../utils/ChatlineAPI';
import string2Hex from '../../../utils/String2Hex';

import nl2br from 'react-nl2br';
import Linkify from 'react-linkify';
import Helmet from 'react-helmet';
import MainLayout from '../../../Components/Layouts/MainLayout';
import CardWithShadow from '../../../Components/Cards/CardWithShadow';

import Error from '../../Error/Error';

import { Avatar, Modal, Spin, Button, Carousel, Row, Col, message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, UserOutlined, EyeOutlined, UpOutlined, DownloadOutlined, FileOutlined, StopOutlined } from '@ant-design/icons';

import ReactCountryFlag from 'react-country-flag';

import moment from 'moment';

import './ChatHistory.less';

const ChatHistory = (props) => {
    const { chatId } = useParams();

    const { 
        currentUser,
        systemVariables
    } = useSystemContext();

    const [chatData, setChatData] = useState(null);

    const [messagesPagination, setMessagesPagination] = useState(null);
    const [messages, setMessages] = useState(null);

    const [fontSizeSelection, setFontSizeSelection] = useState(null);

    const [error, setError] = useState(null);
    
    const history = useHistory();

    useEffect(() => {
        ChatlineAPI.HttpGetRequest('chats/' + chatId, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) { 
                setChatData(res.data);
            }
        });

        ChatlineAPI.HttpGetRequest('chats/' + chatId + '/messages', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) {
                setMessagesPagination(res.data.pagination);
                setMessages(res.data.chatMessages);

                setTimeout(() => {
                    var chatLog = document.getElementById('chat-log');
                    if(chatLog) chatLog.scrollTop = chatLog.scrollHeight;
                }, 100);
            }
        });
    }, [chatId, history]);

    const loadMoreMessages = (resolve, reject) => {
        if(!messagesPagination) return;

        let page = messagesPagination.current + 1;
        if(page > Math.ceil(messagesPagination.total / messagesPagination.pageSize)) return;

        ChatlineAPI.HttpGetRequest('chats/' + chatId + '/messages?page=' + page, (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data) {
                setMessagesPagination(res.data.pagination);
                setMessages([ ...res.data.chatMessages, ...messages ]);
            }
        });
    }

    // eslint-disable-next-line no-unused-vars
    const [attachmentToDisplay, setAttachmentToDisplay] = useState(null);
    const viewFile = (attachment) => {
        setAttachmentToDisplay(attachment);
    }

    const downloadChatbotFile = async (clientId, chatbotId, fileName, type, originalFileName) => {
        ChatlineAPI.FileReader(clientId, 'chatbots/' + chatbotId + '/nodes/files/view-image/' + fileName, type, originalFileName, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
        });
    }

    const downloadFile = async (chatId, fileName, type, originalFileName) => {
        ChatlineAPI.FileReader('chats/' + chatId + '/attachments/' + fileName, type, originalFileName, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
        });
    }

    useEffect(() => {
        if(currentUser) {
            if(currentUser.parameters && currentUser.parameters.preferredFontSizeForChatRoom) {
                setFontSizeSelection(currentUser.parameters.preferredFontSizeForChatRoom);
            }
        }
    }, [currentUser]);

    const formatAvatar = (userType, userName) => {
        let avatarSize = 35;

        if(userType === 'chatbot') {
            return (
                <Avatar size={ avatarSize } style={{ background: '#FFD967', color: '#000' }} className="avatar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-robot" viewBox="0 0 16 16">
                        <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z" />
                        <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z" />
                    </svg>
                </Avatar>
            );  
        }

        if(!userName) userName = '?';

        return (
            <Avatar size={ avatarSize } style={{ background: string2Hex(userName), color: '#000' }} className="avatar">{ userName.substring(0, 1).toUpperCase() }</Avatar>
        );
    }

    const formatChatMessage = (message, i, cDate) => {
        // if(message.status !== null && systemVariables && systemVariables.chatMessage && systemVariables.chatMessage.statusOptions && systemVariables.chatMessage.statusOptions[message.status] && systemVariables.chatMessage.statusOptions[message.status] === 'Deleted') return;

        if(message.visibleToSenderOnly && (!message.messageBy.userId || message.messageBy.userId !== currentUser._id)) return;

        if(systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Info') {
            return (
                <div key={ 'chat-log-message-wrapper-' + i } className={ 'chat-log-message-wrapper chat-log-message-wrapper--center mt-3' + ('font-size--' + fontSizeSelection || '20') }>
                    { message.messageBy && message.messageBy.userType && message.messageBy.userId && message.messageBy.name && formatAvatar(message.messageBy.userType, message.messageBy.name) }
                    <div className="message-text mt-3">{ nl2br(message.message) }</div>
                    <div className="message-details">{ moment(message.createdAt).format('HH:mm:ss') }</div>
                </div>
            );
        }

        let isMessageSentByCurrentUser = (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' && message.messageBy.userId && ''+message.messageBy.userId === ''+currentUser._id ? true : false);

        return (
            <React.Fragment key={ 'chat-log-message' + i }>
                {
                    (message.previousVersions && Array.isArray(message.previousVersions) && message.previousVersions.length > 0 && message.previousVersions.map((previousMessage, j) => {
                        if(!systemVariables || !systemVariables.chat || !systemVariables.chat.messageTypes) return null;
                        
                        return (
                            <React.Fragment key={ 'chat-log-message--previous' + i + '-' + j }>
                                {
                                    formatChatMessage({
                                        chatId: message.chatId,
                                        messageType: Object.keys(systemVariables.chat.messageTypes).find(key => systemVariables.chat.messageTypes[key] === 'Error'),
                                        messageBy: message.messageBy,
                                        message: previousMessage,
                                        visibleToOperatorsOnly: true,
                                        createdAt: message.createdAt,
                                        updatedAt: message.updatedAt,
                                        status: Object.keys(systemVariables.chat.statusOptions).find(key => systemVariables.chat.statusOptions[key] === 'Active')
                                    }, (i+'-'+j), null)
                                }

                                <div style={{ float: 'right', clear: 'both', background: '#dfdfdf', width: '2px', height: '16px', margin: '-4px 16px 8px auto' }}>&nbsp;</div>
                            </React.Fragment>
                        )
                    }))
                }

                <div key={ 'chat-log-message-wrapper' + i } className={ 'chat-log-message-wrapper ' + (isMessageSentByCurrentUser ? 'chat-log-message-wrapper--right' : 'chat-log-message-wrapper--left') + ' ' + ('font-size--' + fontSizeSelection || '20') }>
                    <div className="chat-log-message-container">
                        { !isMessageSentByCurrentUser && <div className="message-by--avatar">{ formatAvatar(message.messageBy.userType, message.messageBy.name) }</div> }

                        {
                            (systemVariables && systemVariables.chatMessage && systemVariables.chatMessage.statusOptions && systemVariables.chatMessage.statusOptions[message.status] && systemVariables.chatMessage.statusOptions[message.status] === 'Deleted') ? (
                                <div className="message-text message-text-error"><StopOutlined style={{ fontSize: '11px' }} /> This message has been deleted.</div>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Error') ? (
                                <div className="message-text message-text-error">
                                    <Linkify componentDecorator={ (href, text, key) => (<a href={ href}  key={ key } target="_blank" rel="noopener noreferrer">{ text }</a>) }>{ nl2br(message.message) }</Linkify>
                                </div>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'File') ? (
                                <>
                                    {
                                        /*
                                        <div className="message-file">
                                            {
                                                ['image/jpeg', 'image/png', 'image/gif'].includes(message.attachment.mime) ? (
                                                    <>
                                                        <img className="message-file--image" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + message.attachment.filePath.replace(/^public\//, '') } alt={ message.attachment.originalFileName } onClick={ () => {
                                                            if(!message.parameters || !message.parameters.linkTo) return false;

                                                            if(message.parameters.target) window.open(message.parameters.linkTo, message.parameters.target);
                                                            else window.open(message.parameters.linkTo, '_blank');
                                                            return true;
                                                        }} style={ ((message.parameters && message.parameters.linkTo) ? { cursor: 'pointer' } : {}) } />

                                                        <div className="message-file---actions">
                                                            <Button onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? downloadChatbotFile(currentUser.clientId, message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) }} className="action"><DownloadOutlined /></Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? downloadChatbotFile(currentUser.clientId, message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) }} className="file"><FileOutlined /> { message.attachment.originalFileName } <DownloadOutlined /></div>
                                                )
                                            }
                                        </div>
                                        */
                                    }

                                    <div className={ 'message-text' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                        <FileOutlined /> <span style={{ marginRight: '8px' }}>{ message.attachment.originalFileName }</span>
                                        { ['image/jpeg', 'image/png', 'image/gif'].includes(message.attachment.mime) && <Button onClick={ () => viewFile(message.attachment) } className="action" style={{ marginLeft: '2px' }}><EyeOutlined /></Button> }
                                        <Button onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? downloadChatbotFile(currentUser.clientId, message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : downloadFile(currentUser.clientId, message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) } } className="action" style={{ marginLeft: '2px' }}><DownloadOutlined /></Button>
                                    </div>
                                </>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Button Group') ? (
                                <div className={ 'message-button-group' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                    {
                                        (message.parameters && message.parameters.buttons && Array.isArray(message.parameters.buttons) && message.parameters.buttons.map((button, buttonIndex) => {
                                            if(!button.label) return null;

                                            return (    
                                                <Button
                                                    key={ 'btn-action-' + buttonIndex }
                                                    className={ 'btn-action type-' + (button.type || (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'User response'))) }
                                                    onClick={ () => { return false; } }
                                                >
                                                    { button.label }
                                                </Button>
                                            );
                                        }))
                                    }
                                </div>
                            ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Carousel') ? (
                                <div className={ 'message-carousel' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                    {
                                        (message.parameters && message.parameters.fields && message.parameters.carouselItems && Array.isArray(message.parameters.carouselItems) && (
                                            <Carousel effect="scrollx" dotPosition="top" arrows={ true } prevArrow={ <ArrowLeftOutlined /> } nextArrow={ <ArrowRightOutlined /> }>
                                                {
                                                    (message.parameters.carouselItems.map((carouselItem, carouselItemIndex) => {
                                                        if(!carouselItem.image) return null;

                                                        return (
                                                            <div key={ 'carousel-item-' + carouselItemIndex } className="carousel-item">
                                                                <img className="carousel-item--image" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + carouselItem.image.filePath.replace(/^public\//, '') } alt={ carouselItem.title || carouselItem.image.fileName || null } />

                                                                { (carouselItem.title) && <div className="carousel-item--title">{ carouselItem.title }</div> }
                                                                { (carouselItem.description) && <div className="carousel-item--description">{ carouselItem.description }</div> }

                                                                {
                                                                    (carouselItem.buttons && Array.isArray(carouselItem.buttons) && carouselItem.buttons.length > 0) && (
                                                                        <div className="carousel-item--button-group">
                                                                            {
                                                                                (carouselItem.buttons.map((button, buttonIndex) => {
                                                                                    if(!button.label) return null;

                                                                                    return (    
                                                                                        <Button
                                                                                            key={ 'carousel-item--button-group--button-' + buttonIndex }
                                                                                            className={ 'btn-action type-'+(button.type || (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'User response'))) }
                                                                                            onClick={ () => false }
                                                                                        >
                                                                                            { button.label }
                                                                                        </Button>
                                                                                    );
                                                                                }))
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        );
                                                    }))
                                                }
                                            </Carousel>
                                        ))
                                    }
                                </div>
                            ) : (
                                <div className={ 'message-text' + (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'operator' ? ' message-by-operator' : '') }>
                                    <Linkify componentDecorator={ (href, text, key) => (<a href={ href}  key={ key } target="_blank" rel="noopener noreferrer">{ text }</a>) }>{ nl2br(message.message) }</Linkify>
                                </div>
                            )
                        }
                    
                        <div className="message-details">{ !isMessageSentByCurrentUser && ((message.messageBy && message.messageBy.name) || 'Bot') + ' - ' }{ moment(message.createdAt).format('HH:mm:ss') }</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    if(error) {
        return error;
    }

    return (
        <>
            <Helmet>
                <title>Chat History | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (systemVariables && chatData && chatData.chat && messages) ? (
                            <div className="page-ChatHistory">
                                <Row type="flex" gutter={ 8 }>
                                    <>
                                        <Col span={ 3 }></Col>

                                        <Col span={ 13 } style={{ height: 'calc(100vh - 160px)' }}>
                                            <CardWithShadow>
                                                <section className="chat-container">
                                                    <div className="chat-container-header py-2 px-3">
                                                        <h4>{ (chatData.chat.visitor.name ? chatData.chat.visitor.name : chatData.chat.visitor.ip) }</h4>
                                                    </div>

                                                    <div className="chat-log-wrapper">
                                                        <div className="chat-log-container">
                                                            <>
                                                                { messagesPagination && messagesPagination.current < Math.ceil(messagesPagination.total / messagesPagination.pageSize) && <Button className="btnSecondary mr-1" onClick={ () => { loadMoreMessages(); } }><UpOutlined /> Load Previous Messages</Button> }

                                                                {
                                                                    messages && Array.isArray(messages) && messages.length > 0 && messages.map((message, i) => {
                                                                        let cDate = null;

                                                                        if(i === 0) {
                                                                            cDate = moment(message.createdAt).format('LL');
                                                                        } else if(i > 0) {
                                                                            let cPreviousMessage = messages[i-1];
                                                                            if(cPreviousMessage) {
                                                                                if(moment(cPreviousMessage.createdAt).startOf('day').format('LL') !== moment(message.createdAt).startOf('day').format('LL')) {
                                                                                    cDate = moment(message.createdAt).format('LL');
                                                                                }
                                                                            }
                                                                        }

                                                                        return formatChatMessage(message, i, cDate);
                                                                    })
                                                                }

                                                                <Modal onCancel={ () => setAttachmentToDisplay(null) } className="imgModal" visible={ attachmentToDisplay ? true : false } footer={ null }>
                                                                    {  attachmentToDisplay && attachmentToDisplay.filePath ? <img style={{ width: '100%' }} src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + attachmentToDisplay.filePath.replace(/^public\//, '') } alt={ attachmentToDisplay.originalFileName ? attachmentToDisplay.originalFileName : 'Image' } /> : null }
                                                                </Modal>
                                                            </>
                                                        </div>
                                                    </div>
                                                </section>
                                            </CardWithShadow>
                                        </Col>

                                        <Col span={ 5 }>
                                            <CardWithShadow style={{ height: 'auto' }}>
                                                <section className="chat-details">
                                                    <div className="section-title py-2 px-3">
                                                        <h4 style={{ color: '#6800D0' }}><span style={{ float: 'right' }}><UserOutlined /></span> User Details</h4>
                                                    </div>

                                                    <div className="user-details py-2 px-3">
                                                        <Row>
                                                            <Col span={ 6 }><b>Email</b></Col><Col style={{ textAlign: 'right' }} span={ 18 }> { (chatData.chat.visitor && chatData.chat.visitor.email ? chatData.chat.visitor.email : 'N/A') } </Col>
                                                            <Col className="mt-1" span={ 8 }><b>Username</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chatData.chat.visitor && chatData.chat.visitor.username ? chatData.chat.visitor.username : 'N/A') } </Col>
                                                            <Col className="mt-1" span={ 8 }><b>Name</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chatData.chat.visitor && chatData.chat.visitor.name ? chatData.chat.visitor.name : 'N/A') } </Col>
                                                            <Col className="mt-1" span={ 8 }><b>IP</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chatData.chat.visitor && chatData.chat.visitor.ip ? <span> { chatData.chat.visitor.ip } { (chatData.chat.visitor.countryCode && typeof(chatData.chat.visitor.countryCode) === 'string' && <ReactCountryFlag countryCode={ chatData.chat.visitor.countryCode } style={{ fontSize: '18px'}} />) } </span> : 'N/A') } </Col>
                                                            <Col className="mt-1" span={ 8 }><b>User-Agent</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chatData.chat.visitor && chatData.chat.visitor.userAgent ? chatData.chat.visitor.userAgent : 'N/A') } </Col>
                                                            <Col className="mt-1" span={ 8 }><b>Reg. Date</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chatData.chat.visitor && chatData.chat.visitor.registeredAt ? moment.unix(chatData.chat.visitor.registeredAt+'').format('DD/MM/YYYY') : 'N/A') } </Col>
                                                            <Col className="mt-1" span={ 8 }><b>Duration</b></Col><Col style={{ textAlign: 'right' }} span={ 16 }> { (chatData.chat.createdAt && chatData.chat.updatedAt ? moment.duration(moment(chatData.chat.updatedAt).diff(moment(chatData.chat.createdAt))).humanize() : 'N/A') } </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="pb-2"></div>
                                                </section>
                                            </CardWithShadow>
                                        </Col>
                                        <Col span={ 3 }></Col>
                                    </>
                                </Row>
                            </div>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
};

export default ChatHistory;
