import React, { useState } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';

import ChatlineAPI from '../../../utils/ChatlineAPI';

import MainLayout from '../../../Components/Layouts/MainLayout';
import Helmet from 'react-helmet';

import { Link } from 'react-router-dom';
import { Button, Form, Input, Select, message, Spin } from 'antd';

import './Settings.css';
import '../../../lib/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Settings = (props) => {
    const { 
        client, fetchClient
    } = useSystemContext();

    const [settingsForm] = Form.useForm();

    const [settingsFormErrors, setSettingsFormErrors] = useState([]);
    const onSettingsFormSubmit = values => {
        ChatlineAPI.HttpPostRequest('client/update-parameters', settingsForm.getFieldsValue(), (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formErr of err.data.errors) {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    }
                    setSettingsFormErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                fetchClient();
                props.history.push('/control-panel');
                return;
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Settings | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (client) ? (
                            <>
                                <div className="pageBody">
                                    <h1>Settings</h1>

                                    <Form form={ settingsForm } className="mt-4" initialValues={{}} onFinish={ onSettingsFormSubmit } layout="vertical" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="autoAssignChatsToTheSameOperator"
                                            label="Automatically Assign Chats to the Same Operator (24-hour Rule)"
                                            initialValue={{ value: (client && client.parameters && client.parameters.autoAssignChatsToTheSameOperator && typeof(client.parameters.autoAssignChatsToTheSameOperator) !== 'undefined' && client.parameters.autoAssignChatsToTheSameOperator !== null ? client.parameters.autoAssignChatsToTheSameOperator+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <hr style={{ margin: '20px 0 20px 0' }} />

                                        <Form.Item 
                                            name="autoEndIdleChats"
                                            label="Automatically End Idle Chats"
                                            initialValue={{ value: (client && client.parameters && client.parameters.autoEndIdleChats && typeof(client.parameters.autoEndIdleChats) !== 'undefined' && client.parameters.autoEndIdleChats !== null ? client.parameters.autoEndIdleChats+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="autoEndIdleChats_notification1"
                                            label="Automatically End Idle Chats | Notification [1]"
                                            initialValue={ (client && client.parameters && client.parameters.autoEndIdleChats_notification1 ? client.parameters.autoEndIdleChats_notification1 : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification1 ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification1 ? settingsFormErrors.autoEndIdleChats_notification1 : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="autoEndIdleChats_notification1_timeout"
                                            label="Automatically End Idle Chats | Notification [1] | Timeout (in minutes)"
                                            initialValue={ (client && client.parameters && client.parameters.autoEndIdleChats_notification1_timeout ? client.parameters.autoEndIdleChats_notification1_timeout : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification1_timeout ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification1_timeout ? settingsFormErrors.autoEndIdleChats_notification1_timeout : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="autoEndIdleChats_notification2"
                                            label="Automatically End Idle Chats | Notification [2]"
                                            initialValue={ (client && client.parameters && client.parameters.autoEndIdleChats_notification2 ? client.parameters.autoEndIdleChats_notification2 : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification2 ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification2 ? settingsFormErrors.autoEndIdleChats_notification2 : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="autoEndIdleChats_notification2_timeout"
                                            label="Automatically End Idle Chats | Notification [2] | Timeout (in minutes)"
                                            initialValue={ (client && client.parameters && client.parameters.autoEndIdleChats_notification2_timeout ? client.parameters.autoEndIdleChats_notification2_timeout : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification2_timeout ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.autoEndIdleChats_notification2_timeout ? settingsFormErrors.autoEndIdleChats_notification2_timeout : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <hr style={{ margin: '20px 0 20px 0' }} />

                                        <Form.Item 
                                            name="autoEndMissedChats"
                                            label="Automatically End Missed Chats (when visitor left)"
                                            initialValue={{ value: (client && client.parameters && typeof(client.parameters.autoEndMissedChats) !== 'undefined' && client.parameters.autoEndMissedChats !== null ? client.parameters.autoEndMissedChats+'' : '0') }}
                                        >
                                            <Select labelInValue>
                                                <Select.Option key={ '1' } value={ '1' }>Yes</Select.Option>
                                                <Select.Option key={ '0' } value={ '0' }>No</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="autoEndMissedChats_notification"
                                            label="Automatically End Missed Chats | Notification"
                                            initialValue={ (client && client.parameters && client.parameters.autoEndMissedChats_notification ? client.parameters.autoEndMissedChats_notification : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.autoEndMissedChats_notification ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.autoEndMissedChats_notification ? settingsFormErrors.autoEndMissedChats_notification : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name="autoEndMissedChats_timeout"
                                            label="Automatically End Missed Chats | Timeout (in minutes)"
                                            initialValue={ (client && client.parameters && client.parameters.autoEndMissedChats_timeout ? client.parameters.autoEndMissedChats_timeout : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.autoEndMissedChats_timeout ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.autoEndMissedChats_timeout ? settingsFormErrors.autoEndMissedChats_timeout : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <hr style={{ margin: '20px 0 20px 0' }} />

                                        <Form.Item 
                                            name="sendingTooManyMessages_notification"
                                            label="Sending Too Many Messages (Message Flood) | Notification"
                                            initialValue={ (client && client.parameters && client.parameters.sendingTooManyMessages_notification ? client.parameters.sendingTooManyMessages_notification : '') }
                                            validateStatus={ (settingsFormErrors && settingsFormErrors.sendingTooManyMessages_notification ? 'error' : null) }
                                            help={ (settingsFormErrors && settingsFormErrors.sendingTooManyMessages_notification ? settingsFormErrors.sendingTooManyMessages_notification : null) }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'center' }}>
                                            <Link to="/control-panel" style={{ margin: '0 8px 0 0' }}><Button type="default">Cancel</Button></Link>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Settings;