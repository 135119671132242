import React from 'react';

import { Handle } from 'react-flow-renderer';

const ImageNode = ({ data, isConnectable, ...restProps }) => {
    return (
        <div style={ data.node.withError && { background: 'rgba(255, 0, 0, 0.25)', border: '1px solid rgb(255, 0, 0)', borderRadius: '5px' } }>
            <Handle
                type="target"
                position="top"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            <Handle
                type="source"
                position="bottom"
                style={{ background: '#555' }}
                isConnectable={ isConnectable }
            />

            {
                (data && data.node && data.node.parameters && data.node.parameters.file) && (
                    <img
                        src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + data.node.chatbotId + '/nodes/files/view-image/' + data.node.parameters.file.fileName } alt={ data.node.parameters.file.fileName } 
                        style={{ width: '100%', borderRadius: '5px' }} 
                        onDragStart={ (e) => { e.preventDefault(); } }
                    />
                )
            }
        </div>
    );
};

export default ImageNode;