import React, { useEffect, useState, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import debounce from 'lodash.debounce';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import string2Hex from '../../utils/String2Hex';

import { Spin, Row, Col, Avatar } from 'antd';

import classes from './Notifications.module.css';
import CardWithShadow from '../../Components/Cards/CardWithShadow';

import moment from 'moment';

const Notifications = (props) => {
    const { 
        currentUser,
        operators,
        isSocketConnected,
        openNotification
    } = useSystemContext();

    const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
    const [notifications, updateNotifications] = useImmer(null);
    const [notificationsPagination, setNotificationsPagination] = useState(null);
    
    const loadNotifications = useCallback((page = 1) => {
        setIsNotificationsLoading(true);

        socket.emit('operator.notifications.get', { page: page }, (ack) => {
            setIsNotificationsLoading(false);

            if(ack && ack.result && ack.response) {
                setNotificationsPagination(ack.response.pagination);

                updateNotifications(notifications => {
                    if(!notifications) return (ack.response && ack.response.notifications ? ack.response.notifications : {});

                    if(ack.response && ack.response.notifications) {
                        for(let notification of Object.values(ack.response.notifications)) {
                            notifications[notification._id] = notification;
                        }
                    }
                });
            }
        });
    }, [updateNotifications]);

    window.onscroll = debounce(() => {
        let hasMore = (notifications && notificationsPagination && notificationsPagination.total && Object.keys(notifications).length < notificationsPagination.total);

        if(isNotificationsLoading || !hasMore) return;

        // Checks that the page has scrolled to the bottom
        if(window.innerHeight + document.documentElement.scrollTop === document.getElementById('root').offsetHeight) {
            loadNotifications((notificationsPagination && notificationsPagination.page && (notificationsPagination.page + 1)) || 1);
        }
    }, 100);

    useEffect(() => {
        if(isSocketConnected) {
            loadNotifications(1);
        }

    }, [isSocketConnected, loadNotifications]);

    return (
        <>
            <Helmet>
                <title>Notifications | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (operators && notifications) ? (
                            <Row gutter={ 16 }>
                                <Col span={ 6 }></Col>
                                <Col span={ 12 }>
                                    <h2 className="mb-2 ml-3">Notifications</h2>
                                    <div className="mb-4" style={{ background: '#EBECFF', width: '100%', height: '1px' }} />

                                    <CardWithShadow>
                                        <>
                                            {
                                                Object.values(notifications).map(notification => {
                                                    return (
                                                        <div key={ notification._id } className={ classes.notificationC + ' py-4' } onClick={ () => { openNotification(notification) } }>
                                                            <div className={ classes.notificationBody }>
                                                                <div className={ classes.flexRow }>
                                                                    <div className={ classes.colContentPaneLeft }>
                                                                        {
                                                                            (notification.notifiedBy && operators && operators[notification.notifiedBy] && operators[notification.notifiedBy].displayName) ? (
                                                                                <Avatar style={ 
                                                                                    (currentUser.displayName === operators[notification.notifiedBy].displayName) ? (
                                                                                        { margin: '4px 0 0 0' }
                                                                                    ) : (
                                                                                        { background: string2Hex(operators[notification.notifiedBy].displayName), margin: '4px 0 0 0' }
                                                                                    )
                                                                                }>
                                                                                    { operators[notification.notifiedBy].displayName && operators[notification.notifiedBy].displayName[0] }
                                                                                </Avatar>
                                                                            ) : (
                                                                                <Avatar style={{ background: string2Hex('system'), margin: '4px 0 0 0' }}>S</Avatar>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div className={ classes.colContentPaneRight }>
                                                                        <div className={ classes.notifiedBy }>{ (notification.notifiedBy && operators && operators[notification.notifiedBy] ? operators[notification.notifiedBy].displayName : 'System Notification') }{ !notification.seen ? <span className={ classes.seenIndicator }>&#8226;</span> : null }</div>
                                                                        <div className={ classes.message }>{ (notification.message ? notification.message : 'Notification') }</div>
                                                                        <div className={ classes.dateAndTime }>{ moment(notification.createdAt).format('DD.MM.YYYY - hh:mm:ss') }</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                    </CardWithShadow>
                                </Col>
                                <Col span={ 6 }></Col>
                            </Row>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    );
}

export default Notifications;
