import React, { useState, useEffect, useCallback } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';

import ChatlineAPI from '../../../utils/ChatlineAPI';
import moment from 'moment';

import MainLayout from '../../../Components/Layouts/MainLayout';
import Helmet from 'react-helmet';
import Error from '../../Error/Error';

import { Link } from 'react-router-dom';
import { Spin, Table, Button, Modal, Form, Input, Select, message, Tabs, Row, Col } from 'antd';
import { EditOutlined, ArrowLeftOutlined, FilterOutlined, PlusCircleOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import './Shortcuts.css';

const Shortcuts = (props) => {
    const { 
        systemVariables
    } = useSystemContext();

    const shortcutStatusOptions = (systemVariables && systemVariables.shortcut && systemVariables.shortcut.statusOptions ? systemVariables.shortcut.statusOptions : null);

    const [shortcutGroups, setShortcutGroups] = useState(null);
    const [shortcuts, setShortcuts] = useState(null);
    const [shortcutsPagination, setShortcutsPagination] = useState(null);
    const [shortcutsFilters, setShortcutsFilters] = useState(null);
    const [shortcutsFiltersForm] = Form.useForm();

    const [activeShortcutGroupTab, setActiveShortcutGroupTab] = useState(null);
    
    const [addShortcutGroupModalVisibility, setAddShortcutGroupModalVisibility] = useState(false);
    const [addShortcutGroupForm] = Form.useForm();
    const [addShortcutGroupErrors, setAddShortcutGroupErrors] = useState([]);
    const [addShortcutModalVisibility, setAddShortcutModalVisibility] = useState(false);
    const [addShortcutForm] = Form.useForm();
    const [addShortcutErrors, setAddShortcutErrors] = useState([]);
    const [shortcutInEdit, setShortcutInEdit] = useState(null);
    const [editShortcutForm] = Form.useForm();
    const [editShortcutErrors, setEditShortcutErrors] = useState([]);
    const [editShortcutModalIsVisible, setEditShortcutModalVisibility] = useState(false);

    const [error, setError] = useState(null);
    
    const getShortcutGroups = useCallback(() => {
        ChatlineAPI.HttpGetRequest('shortcut-groups', (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data && res.data.result) {
                if(res.data.shortcutGroups) setShortcutGroups(res.data.shortcutGroups);
                return;
            }

            setShortcutGroups({});
        });
    }, []);

    const getShortcuts = useCallback((shortcutGroupId, filters = null) => {
        filters = (filters && Object.keys(filters).length > 0 ? Object.keys(filters).map(key => key + '=' + filters[key]).join('&') : '');

        ChatlineAPI.HttpGetRequest('shortcut-groups/' + shortcutGroupId + '/shortcuts' + (filters ? ('?'+filters) : ''), (err, res) => {
            if(err) {
                setError(<Error status={ (err.status ? err.status : 500) } />);
                return;
            }

            if(res && res.data && res.data.result) {
                if(res.data.shortcuts) setShortcuts(res.data.shortcuts);
                if(res.data.pagination) setShortcutsPagination(res.data.pagination);
                return;
            }

            setShortcuts({});
            setShortcutsPagination(null);
        });
    }, []);

    useEffect(() => {
        getShortcutGroups();
    }, [getShortcutGroups]);

    useEffect(() => {
        if(shortcutGroups && Object.keys(shortcutGroups).length > 0) {
            let firstShortcutGroup = (Object.values(shortcutGroups))[0];
            setActiveShortcutGroupTab(firstShortcutGroup._id);
        }
    }, [shortcutGroups]);

    useEffect(() => {
        if(activeShortcutGroupTab) {
            getShortcuts(activeShortcutGroupTab, shortcutsFilters);
        }
    }, [getShortcuts, activeShortcutGroupTab, shortcutsFilters]);

    const handleAddShortcutGroupFinish = values => {
        ChatlineAPI.HttpPostRequest('shortcut-groups/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = {};
                    err.data.errors.forEach(formErr => { if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg; });
                    setAddShortcutGroupErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setAddShortcutGroupModalVisibility(false);
                addShortcutGroupForm.resetFields();
                getShortcutGroups();
            }
        });
    };

    const handleDeleteShortcutGroup = (shortcutGroupId) => {
        Modal.confirm({
            title: 'Delete Shortcut Group',
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('shortcut-groups/' + shortcutGroupId + '/delete', {}, (err, res) => {
                    if(err && err.data && err.data.error) {
                        message.error(err.data.error);
                        return;
                    }

                    if(!res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }

                    if(res.data && res.data.result === true) {
                        getShortcutGroups();
                    }
                });
            },
            onCancel() {

            },
        });
    }

    const handleAddShortcutFinish = values => {
        ChatlineAPI.HttpPostRequest('shortcuts/add', values, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = {};
                    err.data.errors.forEach(formErr => { if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg; });
                    setAddShortcutErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setAddShortcutModalVisibility(false);
                addShortcutForm.resetFields();
                getShortcuts(values.groupId, shortcutsFilters);
            }
        });
    };

    useEffect(() => {
        if(editShortcutForm) {
            if(!shortcutInEdit) {
                editShortcutForm.resetFields();
                return;
            }

            editShortcutForm.setFieldsValue({
                groupId: shortcutInEdit.groupId,            
                key: shortcutInEdit.key,
                message: shortcutInEdit.message
            });
        }
    }, [shortcutInEdit, editShortcutForm]);

    const handleEditShortcutFinish = (values) => {
        if(shortcutInEdit && shortcutInEdit._id) {
            ChatlineAPI.HttpPostRequest('shortcuts/' + shortcutInEdit._id + '/edit', values, (err, res) => {
                if(err || !res) {
                    if(err && err.data && err.data.errors) {
                        let formErrors = {};
                        err.data.errors.forEach(formErr => { if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg; });
                        setEditShortcutErrors(formErrors);
                        return;
                    }

                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                if(res.data && res.data.result === true) {
                    setEditShortcutModalVisibility(false);
                    editShortcutForm.resetFields();
                    getShortcuts(values.groupId, shortcutsFilters);
                }
            });
        }
    };

    const handleDeleteShortcut = (shortcutGroupId, shortcutId) => {
        Modal.confirm({
            title: 'Delete Shortcut',
            content: 'Are you sure you want to delete this record?',
            onOk() {
                ChatlineAPI.HttpPostRequest('shortcuts/' + shortcutId + '/delete', {}, (err, res) => {
                    if(err || !res) {
                        message.error('System is currently unavailable. Please try again later.');
                        return;
                    }

                    if(res.data && res.data.result === true) {
                        getShortcuts(shortcutGroupId, shortcutsFilters);
                    }
                });
            },
            onCancel() {
            },
        });
    }

    const onApplyShortcutsFilters = (values) => {
        if(!values) {
            setShortcutsPagination(null);
            setShortcutsFilters(null);
            return;
        }

        let filters = {};
        for(let key of Object.keys(values)) {
            if(values[key]) filters[key] = (''+values[key]).trim();
        }
        
        setShortcutsPagination(null);
        setShortcutsFilters(filters);
    }

    const onResetShortcutsFilters = () => {
        shortcutsFiltersForm.resetFields();
        setShortcutsPagination(null);
        setShortcutsFilters(null);
    }

    const columns = [
        {
            title: 'Shortcut Key',
            dataIndex: 'key',
            key: 'key',
            width: '160px',
            render: (val, row) => {
                return (
                    <b>#{ row.key }</b>
                );
            }
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Group',
            dataIndex: 'groupId',
            key: 'groupId',
            width: '160px',
            render: (val, row) => {
                return (
                    <div>{ (shortcutGroups && shortcutGroups[row.groupId] ? shortcutGroups[row.groupId].name : '?') }</div>
                );
            }
        },
        {
            title: 'Scope',
            dataIndex: 'scope',
            key: 'scope',
            width: '160px',
            render: (val, row) => {
                return (
                    <div>Everyone</div>
                );
            }
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '120px',
            render: (val, row) => {
                return (
                    row.createdAt ? <b>{ moment(row.createdAt).format('DD.MM.YYYY') }</b> : '-'
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (val, row) => {
                return (
                    <div>{ (row.status && shortcutStatusOptions && shortcutStatusOptions[row.status] ? shortcutStatusOptions[row.status] : 'N/A') }</div>
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '240px',
            render: (val, row) => {
                return (
                    <>
                        <Button className="btnEdit btnInline mr-2" onClick={ () => { setShortcutInEdit(row); setEditShortcutModalVisibility(true); } }><EditOutlined /> Edit</Button>
                        { (row.status !== -1 ? <Button className="btnDelete btnInline" onClick={ () => { handleDeleteShortcut(row.groupId, row._id) } }><DeleteOutlined /> Delete</Button> : null) }
                    </>
                );
            }
        }
    ];

    if(error) return error;

    return (
        <>
            <Helmet>
                <title>Shortcuts | Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                <>
                    {
                        (shortcutGroups) ? (
                            <>
                                <div style={{ margin: '0 0 8px 0' }}>
                                    <Link to="/control-panel"><Button className="btnBack" size="medium" onClick={ () => {} }><ArrowLeftOutlined /> Control Panel</Button></Link>

                                    <div style={{ float: 'right' }}>
                                        <Button onClick={ () => { setAddShortcutGroupModalVisibility(true) } } className="btnAdd mx-1"><PlusCircleOutlined /> Create Group</Button>
                                        <Button onClick={ () => { setAddShortcutModalVisibility(true) } } className={ "btnAdd mx-1" }><PlusCircleOutlined /> Create Shortcut</Button>
                                    </div>
                                </div>
                
                                <Tabs activeKey={ activeShortcutGroupTab } onChange={ (activeKey) => { setActiveShortcutGroupTab(activeKey) } }>
                                    {
                                        (Object.keys(shortcutGroups).length > 0) ? (
                                            Object.values(shortcutGroups).map(shortcutGroup => {
                                                return (
                                                    <Tabs.TabPane tab={ <div>{ shortcutGroup.name } { activeShortcutGroupTab === shortcutGroup._id+'' && <DeleteOutlined className="ml-2" style={{ opacity: '0.5' }} onClick={ () => { handleDeleteShortcutGroup(shortcutGroup._id) } } />  }</div> } key={ shortcutGroup._id }>
                                                        <Form form={ shortcutsFiltersForm } name="applyFilter" onFinish={ onApplyShortcutsFilters } layout="vertical" hideRequiredMark={ true }>
                                                            <Row gutter={ 16 }>
                                                                <Col xs={ 24 } sm={ 12 } xl={ 6 }>
                                                                    <Form.Item 
                                                                        name="search"
                                                                        label="Search by"
                                                                    >
                                                                        <Input className="searchBar filterOptions" placeholder="Message..." prefix={ <SearchOutlined /> } />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col xs={ 24 } sm={ 4 } xl={ 2 }>
                                                                    <Form.Item 
                                                                        name="limit"
                                                                        label="Show"
                                                                        initialValue={ shortcutsPagination && shortcutsPagination.pageSize ? shortcutsPagination.pageSize : null }
                                                                    >
                                                                        <Select className="filterOptions">
                                                                            <Select.Option value={ 10 }>10</Select.Option>
                                                                            <Select.Option value={ 25 }>25</Select.Option>
                                                                            <Select.Option value={ 50 }>50</Select.Option>
                                                                            <Select.Option value={ 100 }>100</Select.Option>
                                                                            <Select.Option value={ 250 }>250</Select.Option>
                                                                            <Select.Option value={ 500 }>500</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                                                                    <Form.Item>
                                                                        <Button className="btnAdd" type="primary" htmlType="submit" style={{ margin: '18px 12px 0 0' }}><FilterOutlined /> Apply</Button>
                                                                        <Button className="btnBack" onClick={ onResetShortcutsFilters }>Reset</Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>

                                                        <Table className="shortcutsT" columns={ columns } dataSource={ shortcuts && Object.keys(shortcuts) ? Object.values(shortcuts) : [] } pagination={ false } />
                                                    </Tabs.TabPane>
                                                );
                                            })
                                        ) : (
                                            <Table className="shortcutsT" columns={ columns } dataSource={ [] } pagination={ false } />
                                        )
                                    }
                                </Tabs>

                                <Modal
                                    visible={ addShortcutGroupModalVisibility }
                                    onCancel={ () => { setAddShortcutGroupModalVisibility(false); addShortcutGroupForm.resetFields(); } }
                                    footer={ null }
                                >
                                    <h1>Add Shortcut Group</h1>

                                    <Form form={ addShortcutGroupForm } onFinish={ handleAddShortcutGroupFinish } layout="horizontal" hideRequiredMark={ true }>
                                        <Form.Item 
                                            name="name"
                                            label="Name"
                                            validateStatus={ addShortcutGroupErrors && addShortcutGroupErrors.name ? "error" : null }
                                            help={ addShortcutGroupErrors && addShortcutGroupErrors.name ? addShortcutGroupErrors.name : null }
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                <Modal
                                    visible={ addShortcutModalVisibility }
                                    onCancel={ () => { setAddShortcutModalVisibility(false); addShortcutForm.resetFields(); } }
                                    footer={ null }
                                >
                                    <h1>Add Shortcut</h1>
                                    <Form form={ addShortcutForm } onFinish={ handleAddShortcutFinish } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                        <Form.Item 
                                            name="groupId"
                                            label="Shortcut Group"
                                            validateStatus={ addShortcutErrors && addShortcutErrors.groupId ? "error" : null }
                                            help={ addShortcutErrors && addShortcutErrors.groupId ? addShortcutErrors.groupId : null }
                                        >
                                            <Select>
                                                {
                                                    (Object.keys(shortcutGroups).length > 0 && Object.values(shortcutGroups).map(shortcutGroup => {
                                                        return (
                                                            <Select.Option key={ shortcutGroup._id } value={ shortcutGroup._id }>{ shortcutGroup.name }</Select.Option>
                                                        )
                                                    }))
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="key"
                                            label="Shortcut Key"
                                            validateStatus={ addShortcutErrors && addShortcutErrors.key ? "error" : null }
                                            help={ addShortcutErrors && addShortcutErrors.key ? addShortcutErrors.key : null }
                                            rules={[
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^[A-Za-z0-9]+$/i),
                                                    message: 'Shortcut key can contain alphanumeric characters only!'
                                                }
                                            ]}
                                        >
                                            <Input addonBefore="#" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="message"
                                            label="Message"
                                            validateStatus={ addShortcutErrors && addShortcutErrors.message ? "error" : null }
                                            help={ addShortcutErrors && addShortcutErrors.message ? addShortcutErrors.message : null }
                                        >
                                            <TextArea rows={ 3 } />
                                        </Form.Item>

                                        <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                <Modal
                                    visible={ editShortcutModalIsVisible }
                                    onCancel={ () => { setEditShortcutModalVisibility(false); editShortcutForm.resetFields(); } }
                                    footer={ null }
                                >
                                    <h1>Edit Shortcut</h1>
                                    <Form form={ editShortcutForm } onFinish={ handleEditShortcutFinish } layout="horizontal" hideRequiredMark={ true } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                        <Form.Item 
                                            name="groupId"
                                            label="Shortcut Group"
                                            validateStatus={ editShortcutErrors && editShortcutErrors.groupId ? "error" : null }
                                            help={ editShortcutErrors && editShortcutErrors.groupId ? editShortcutErrors.groupId : null }
                                        >
                                            <Select>
                                                {
                                                    (Object.keys(shortcutGroups).length > 0 && Object.values(shortcutGroups).map(shortcutGroup => {
                                                        return (
                                                            <Select.Option key={ shortcutGroup._id } value={ shortcutGroup._id }>{ shortcutGroup.name }</Select.Option>
                                                        )
                                                    }))
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item 
                                            name="key"
                                            label="Shortcut Key"
                                            validateStatus={ editShortcutErrors && editShortcutErrors.key ? "error" : null }
                                            help={ editShortcutErrors && editShortcutErrors.key ? editShortcutErrors.key : null }
                                        >
                                            <Input addonBefore="#" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="message"
                                            label="Message"
                                            validateStatus={ editShortcutErrors && editShortcutErrors.message ? "error" : null }
                                            help={ editShortcutErrors && editShortcutErrors.message ? editShortcutErrors.message : null }
                                        >
                                            <TextArea rows={ 3 } />
                                        </Form.Item>

                                        <Form.Item wrapperCol={ 24 } style={{ textAlign: 'right' }}>
                                            <Button className="btnAdd" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </>
                        ) : (
                            <div className="spin-wrapper"><Spin /></div>
                        )
                    }
                </>
            </MainLayout>
        </>
    )
}

export default Shortcuts;
