import React from 'react';

import classes from './Card.module.css';

const CardWithShadow = (props) => {
    return (
        <div { ...props } className={ classes.cardWithShadow }>
            { props.children }
        </div>
    )
}

export default CardWithShadow;