import React, { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useSystemContext } from '../../Context/SystemContext';

import { socket } from '../../service/socket';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import { Spin, Table, Row, Col, Tabs } from 'antd';
import { FieldTimeOutlined, CustomerServiceOutlined, MessageOutlined, StarOutlined, LikeOutlined } from '@ant-design/icons';

import classes from './Reports.module.css';

const OperatorPerformance = (props) => {
    const { 
        isSocketConnected
    } = useSystemContext();

    const moment = require('moment');

    const [reportParameters, updateReportParameters] = useImmer({ type: 'time', term: 'yesterday' });
    const [report, setReport] = useState(null);

    const [operators, setOperators] = useState(null);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.reports.operatorPerformance.get', reportParameters, (ack) => {
                if(ack && ack.result && ack.report) {
                    setReport(ack.report);
                    return;
                }

                setReport({});
            });
        }
    }, [isSocketConnected, reportParameters]);

    useEffect(() => {
        if(isSocketConnected) {
            socket.emit('operator.operators.all.get', null, (ack) => {
                if(ack && ack.result && ack.operators) setOperators(operators => (ack.operators ? ack.operators : {}));
            });
        }
    }, [isSocketConnected]);

    const typeTimeColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (val, row) => {
                return moment(parseInt(row.date)).format('dddd, MMMM Do YYYY');
            }
        },
        {
            title: 'Avg. Chat Time',
            dataIndex: 'averageChatTime',
            key: 'averageChatTime',
            render: (val, row) => row.averageChatTime + ' mins'
        },
        {
            title: 'Total Chat Time',
            dataIndex: 'totalChatTime',
            key: 'totalChatTime',
            render: (val, row) => row.totalChatTime + ' mins'
        },
        {
            title: 'Chats Initiated by Agent',
            dataIndex: 'chatsInitiated',
            key: 'chatsInitiated',
            render: (val, row) => row.numberOfChatsOperatorsInitiated
        },
        {
            title: 'Likes / Unlikes',
            dataIndex: 'likesUnlikes',
            key: 'likesUnlikes',
            render: (val, row) => 'N/A'
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: (val, row) => 'N/A'
        }
    ];

    const typeAgentColumns = [
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (val, row) => (operators && row.operatorId && operators[row.operatorId] ? operators[row.operatorId].displayName : 'N/A')
        },
        {
            title: 'Avg. Chat Time',
            dataIndex: 'averageChatTime',
            key: 'averageChatTime',
            render: (val, row) => row.averageChatTime + ' mins'
        },
        {
            title: 'Total Chat Time',
            dataIndex: 'totalChatTime',
            key: 'totalChatTime',
            render: (val, row) => row.totalChatTime + ' mins'
        },
        {
            title: 'All Chats',
            dataIndex: 'allChats',
            key: 'allChats',
            render: (val, row) => row.numberOfChats
        },
        {
            title: 'Chats Initiated by Agent',
            dataIndex: 'chatsInitiated',
            key: 'chatsInitiated',
            render: (val, row) => row.numberOfChatsOperatorInitiated
        },
        {
            title: 'Chat Acceptance Rate',
            dataIndex: 'chatAcceptanceRate',
            key: 'chatAcceptanceRate',
            render: (val, row) => row.chatAcceptanceRate
        },
        {
            title: 'Likes / Unlikes',
            dataIndex: 'likesUnlikes',
            key: 'likesUnlikes',
            render: (val, row) => 'N/A'
        }
    ];

    return (
        <>
            <Helmet>
                <title>Operator Performance | Reports | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            <MainLayout>
                {
                    (report) ? (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Tabs defaultActiveKey={ (reportParameters && reportParameters.type) } onChange={ (activeKey) => { updateReportParameters((reportParameters) => { reportParameters.type = activeKey; }) } }>
                                        <Tabs.TabPane tab={ <span><FieldTimeOutlined /> Time</span> } key="time"></Tabs.TabPane>
                                        <Tabs.TabPane tab={ <span><CustomerServiceOutlined /> Agent</span> } key="agent"></Tabs.TabPane>
                                    </Tabs>
                                </Col>

                                <Col span={12}>
                                    <Tabs defaultActiveKey={ (reportParameters && reportParameters.term) } onChange={ (activeKey) => { updateReportParameters((reportParameters) => { reportParameters.term = activeKey; }) } }>
                                        <Tabs.TabPane tab="Yesterday" key="yesterday"></Tabs.TabPane>
                                        <Tabs.TabPane tab="This Week" key="thisWeek"></Tabs.TabPane>
                                        <Tabs.TabPane tab="Last Week" key="lastWeek"></Tabs.TabPane>
                                        <Tabs.TabPane tab="This Month" key="thisMonth"></Tabs.TabPane>
                                        <Tabs.TabPane tab="Last Month" key="lastMonth"></Tabs.TabPane>
                                    </Tabs>
                                </Col>
                            </Row>

                            {
                                (reportParameters && reportParameters.type === 'time') ? ( 
                                    <div>
                                        <h3>Operator Performance | By Time</h3>

                                        <Row gutter={ 16 } className="mt-0">
                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Avg. Chat Time</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.averageChatTime) || 0) + ' mins' }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Total Chat Time</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.totalChatTime) || 0) + ' mins' }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Chats Initiated by Agent</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><MessageOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfChatsOperatorsInitiated) || 0) }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Like / Dislike</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><LikeOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>N/A</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Rating</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><StarOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>N/A</div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="tableW mt-4">
                                            <Table rowKey={ row => row.date } className="mt-2" columns={ typeTimeColumns } dataSource={ (report && report['dataTable'] ? Object.values(report['dataTable']) : []) } pagination={ false } />
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <h3>Operator Performance | By Agent</h3>

                                        <Row gutter={ 16 } className="mt-0">
                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Avg. Chat Time</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.averageChatTime) || 0) + ' mins' }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Total Chat Time</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><CustomerServiceOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.totalChatTime) || 0) + ' mins' }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>All Chats</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><MessageOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfChats) || 0) }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Chats Initiated by Agent</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><MessageOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.numberOfChatsOperatorsInitiated) || 0) }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Chat Acceptance Rate</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><StarOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>{ ((report && report.chatAcceptanceRate) || 0) }</div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="my-2" span={ 4 }>
                                                <div className={ classes.statCard }>
                                                    <div className={ classes.statLabel }>Like / Dislike</div>
                                                    <div className="mt-2" style={{ overflow: 'auto' }}>
                                                        <div className={ classes.statIconC } style={{ float: 'left' }}><LikeOutlined /></div>
                                                        <div className={ classes.statVal } style={{ float: 'right' }}>N/A</div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="tableW mt-4">
                                            <Table rowKey={ row => row.operatorId } className="mt-2" columns={ typeAgentColumns } dataSource={ (report && report['dataTable'] ? Object.values(report['dataTable']) : []) } pagination={ false } />
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </MainLayout>
        </>
    );
};

export default OperatorPerformance;