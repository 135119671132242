import React from 'react';

import { socket } from '../../../../../service/socket';

import { getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data, arrowHeadType, markerEndId, ...restProps }) => {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const onEdgeRemove = (e, id) => {
        e.stopPropagation();
    
        if(!data || !data.edge || !data.edge._id || !data.edge.chatbotId) return;
        if(id+'' !== data.edge._id+'') return;
        
        socket.emit('operator.chatbots.chatbot.edges.edge.delete', { chatbotId: data.edge.chatbotId+'', edgeId: data.edge._id+'' }, (ack) => {
            if(ack && ack.result) {
                if(data.fetchChatbotEdges) data.fetchChatbotEdges();
            }
        })
    };

    return (
        <>
            <path id={ id } className="react-flow__edge-path" d={ edgePath } markerEnd={ markerEnd }/>

            {
                (data && data.isRemovable) && (
                    <foreignObject
                        className="edgebutton-foreignobject"
                        width={ 20 }
                        height={ 20 }
                        x={ edgeCenterX - 10 }
                        y={ edgeCenterY - 10 }
                        requiredExtensions="http://www.w3.org/1999/xhtml"
                    >
                        <button
                            onClick={ (e) => onEdgeRemove(e, id) }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                background: '#EEE',
                                border: '1px solid #FFF',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                fontSize: '12px',
                                height: '20px',
                                width: '20px',
                            }}
                        >
                            <div>×</div>
                        </button>
                    </foreignObject>
                )
            }
        </>
    );
}

export default CustomEdge;