import React from 'react';

import { useSystemContext } from '../../Context/SystemContext';

import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

import { Layout, Spin } from 'antd';

const MainLayout = (props) => {
    const { 
        currentUser,
        client,
        operators
    } = useSystemContext();

    return (
        <Layout style={{ background: 'transparent' }}>
            <Layout.Sider style={{ position: 'fixed', background: 'linear-gradient(to bottom, #6800D0, #410083)', height: '100vh' }}>
                <Sidebar />
            </Layout.Sider>

            <Layout style={{ margin: '0 0 0 240px' }}>
                <Layout.Header style={{ padding: '0 0 0 0' }}>
                    <Navbar />
                </Layout.Header>


                {
                    ((currentUser && currentUser.role === 999) || (currentUser && client && operators)) ? (
                        <Layout.Content style={{ background: 'rgb(249, 250, 255)', width: '100%', minHeight: 'calc(100vh - 88px)', margin: '0 0 0 0', padding: '32px 32px 32px 32px' }}>
                            { /* <div className="container"> */ }
                                { props.children }
                            { /* </div> */ }
                        </Layout.Content>
                    ) : (
                        <div className="spin-wrapper"><Spin /></div>
                    )
                }
            </Layout>
        </Layout>
    );
}

export default MainLayout;