import React from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import { Link } from 'react-router-dom';

import HtmlEntities from '../../utils/HtmlEntities';

import Helmet from 'react-helmet';
import MainLayout from '../../Components/Layouts/MainLayout';

import SyntaxHighlighter from 'react-syntax-highlighter';

import { Spin, Row, Col } from 'antd';
import { TeamOutlined, CommentOutlined, ApartmentOutlined, SettingOutlined, RobotOutlined } from '@ant-design/icons';

import classes from './ControlPanel.module.css';

const ControlPanel = (props) => {
    const {
        currentUser,
        systemVariables,
        client
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    return (
        <>
            <Helmet>
                <title>Control Panel | { process.env.REACT_APP_NAME }</title>
            </Helmet>

            {
                (client) ? (
                    <MainLayout>
                        <h1>Control Panel</h1>

                        <Row gutter={ 16 }>
                            <>
                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                        <Col className="mt-2" xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 8 }>
                                            <Link to='/control-panel/operators'>
                                                <div className={ classes.card + ' ' + classes.operators }>
                                                    <TeamOutlined style={{ fontSize: '32px' }} />
                                                    <div className={ classes.title }>Operators</div>
                                                    <div className={ classes.description }>Add, edit or delete Operators</div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }

                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                        <Col className="mt-2" xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 8 }>
                                            <Link to='/control-panel/departments'>
                                                <div className={ classes.card + ' ' + classes.departments }>
                                                    <ApartmentOutlined style={{ fontSize: '32px' }} />
                                                    <div className={ classes.title }>Departments</div>
                                                    <div className={ classes.description }>Add, edit or delete departments</div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }

                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                        <Col className="mt-2" xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 8 }>
                                            <Link to='/control-panel/shortcuts'>
                                                <div className={ classes.card + ' ' + classes.shortcuts }>
                                                    <CommentOutlined style={{ fontSize: '32px' }} />
                                                    <div className={ classes.title }>Shortcuts</div>
                                                    <div className={ classes.description }>Add, edit or delete shortcut messages</div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }

                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin')].includes(''+currentUser.role)) && (
                                        <Col className="mt-2" xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 8 }>
                                            <Link to='/control-panel/settings'>
                                                <div className={ classes.card + ' ' + classes.settings }>
                                                    <SettingOutlined style={{ fontSize: '32px' }} />
                                                    <div className={ classes.title }>Settings</div>
                                                    <div className={ classes.description }>Manage your settings and preferences</div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }

                                {
                                    (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                        <Col className="mt-2" xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 8 }>
                                            <Link to='/control-panel/widget'>
                                                <div className={ classes.card + ' ' + classes.widget }>
                                                    <RobotOutlined style={{ fontSize: '32px' }} />
                                                    <div className={ classes.title }>Widget</div>
                                                    <div className={ classes.description }>Customize your chat widget</div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }
                            </>
                        </Row>

                        {
                            (currentUser && currentUser.role && roleOptions && [''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Admin'), ''+Object.keys(roleOptions).find(key => roleOptions[key] === 'Supervisor')].includes(''+currentUser.role)) && (
                                <>
                                    <h2>Integration</h2>

                                    <Row gutter={ 16 }>
                                        <Col span={ 24 }>
                                            <SyntaxHighlighter language="html">
                                                {
                                                    HtmlEntities.decode(`
    <div id="${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME }-container"></div>
    <script>
        (function (w, d, s, o, f, js, fjs) {
            w['${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME }'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };  w[o].l = Date.now();
            js = d.createElement(s); fjs = d.getElementsByTagName(s); fjs = fjs[fjs.length-1];
            js.id = o; js.src = f + (!f.includes('?') ? '?' : '&')+'_t='+Date.now(); js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', '${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME }', '${ process.env.REACT_APP_CHATLINE_CLIENT_API_ADDRESS }${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_PATH }'));

        // to initialize the widget
        ${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME }('init', {
            targetElementId: '${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_NAME }-container',
            licenseKey: '${ client && client.licenseKey ? client.licenseKey : 'LICENSE-KEY-IS-MISSING' }',
            hideDefaultLauncher: false
        });

        // to toggle (expand/collapse) the widget manually
        ${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME }('toggle');

        // to update visitor parameters whenever required
        // (*): mandatory, (o): optional
        ${ process.env.REACT_APP_CHATLINE_CLIENT_LIBRARY_METHOD_NAME }('setVisitor', {
            visitor: {
                uuid: '*',
                name: '*', 
                username: '*', 
                email: '*',
                dateOfBirth: 'o',
                gender: 'o',
                registeredAt: 'o', 
                extras: {
                    financialDetails: [
                        {
                            label: 'Last Deposit',
                            key: 'lastDepositTimestamp',
                            value: 'o'
                        },
                        {
                            label: 'Last Timestamp',
                            key: 'lastDepositAmount',
                            value: 'o'
                        },
                        {
                            label: 'Balance',
                            key: 'currentBalance',
                            value: 'o'
                        }
                    ],

                    interests: [
                        {
                            label: 'Sports',
                            key: 'sports',
                            value: 'o'
                        },
                        {
                            label: 'Live Casino',
                            key: 'liveCasino',
                            value: 'o'
                        },
                        {
                            label: 'Slots',
                            key: 'slots',
                            value: 'o'
                        },
                        {
                            label: 'Others',
                            key: 'others',
                            value: 'o'
                        }
                    ]
                }
            }
        });
    </script>`)
                                                }
                                            </SyntaxHighlighter>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </MainLayout>
                ) : (
                    <div className="spin-wrapper"><Spin /></div>
                )
            }
        </>
    );
}

export default ControlPanel;
