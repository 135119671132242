import React, { Component } from 'react'

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../lib/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class TextEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: null,
        };

        const html = this.props.initialHTML;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                ...this.state,
                editorState
            };
        }
    }

    onEditorStateChange = (editorState) => {
        this.props.getNewHTML(draftToHtml(convertToRaw(editorState.getCurrentContent())));

        this.setState({
            ...this.state,
            editorState,
        });
    };

    render() {
        return (
            <Editor
                editorState={ this.state.editorState }
                onEditorStateChange={ this.onEditorStateChange }
            />
        )
    }
}

export default TextEditor;